import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Button from '../ui/Button';
import MessagePopup from '../ui/MessagePopup';
import SpinnerPanel from '../ui/SpinnerPanel';

import DeleteAccountPopup from './DeleteAccountPopup'

import Toggle from 'react-toggle'
import "react-toggle/style.css"

import { getUser } from '../util/DatabaseUtils'
import { requestDeleteAccountFunction } from '../util/FunctionUtils'

const INITIAL_STATE = {
  dataLoaded : false,
  nudgesOptOut : false,
  goalNotificationsOptOut: false,

  monthlySummaryOptOut: false,

  showSettingsUpdated: false,
  showDeleteAccountConfirmation: false,
  showDeleteAccountRequestSent: false
};

class AccountSettings extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {

      getUser(this.props.firebase, (user) => {
        if (!user || !user.team) {
          this.props.history.push("/create-team")
          return
        }

        this.setState({
          nudgesOptOut: (user.settings && user.settings.nudgesOptOut) ? true : false,
          monthlySummaryOptOut: (user.settings && user.settings.monthlySummaryOptOut) ? true : false,
          goalNotificationsOptOut: (user.settings && user.settings.goalNotificationsOptOut) ? true : false,
          dataLoaded: true
        });
      })
    }
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  componentWillUnmount() {
  }

  closeSettingsUpdated = () => {
    this.setState({
      showSettingsUpdated : false
    })
  }

  closeDeleteAccountConfirmation = () => {
    this.setState({
      showDeleteAccountConfirmation : false
    })
  }

  deleteAccountClicked = () => {
    this.setState({
      showDeleteAccountConfirmation : true
    })
  }

  deleteAccountRequested = () => {
    requestDeleteAccountFunction(this.props.firebase.user.uid)
    
    this.setState({
      showDeleteAccountConfirmation : false,
      showDeleteAccountRequestSent : true
    })
  }

  closeDeleteAccountRequestSent = () => {
    this.setState({
      showDeleteAccountRequestSent : false
    })
  }

  monthlySummaryOptOutChange = () => {
    this.state.monthlySummaryOptOut = !this.state.monthlySummaryOptOut
  }

  nudgesOptOutChange = () => {
    this.state.nudgesOptOut = !this.state.nudgesOptOut
  }

  goalNotificationsOptOutChange = () => {
    this.state.goalNotificationsOptOut = !this.state.goalNotificationsOptOut
  }

  saveSettings = () => {
    var database = this.props.firebase.database;

    database.ref('users/' + this.props.firebase.user.uid + '/settings/nudgesOptOut').set(this.state.nudgesOptOut);
    database.ref('users/' + this.props.firebase.user.uid + '/settings/goalNotificationsOptOut').set(this.state.goalNotificationsOptOut);
    database.ref('users/' + this.props.firebase.user.uid + '/settings/monthlySummaryOptOut').set(this.state.monthlySummaryOptOut);

    this.setState({
      showSettingsUpdated: true
    })
  }

  render = () => {
    var history = this.props.history;

    return (
      <AppChrome title="Account Settings" history={history} requireLogin={true} firebase={this.props.firebase} back={true} tab="Today">
        { this.state.dataLoaded ? 
        <div>
          <div className='sectionTitle'>EMAIL SETTINGS</div>
          <div>
            <table>

              <tr>
                  <td>
                  <span className='accountSettingsToggle'>
                    <Toggle
                      defaultChecked={!this.state.goalNotificationsOptOut}
                      onChange={this.goalNotificationsOptOutChange} />
                  </span>
                </td>
                <td>
                  <span className='accountSettingsName'>Goal Completion emails</span>
                </td>
              </tr>

              <tr>
                  <td>
                  <span className='accountSettingsToggle'>
                    <Toggle
                      defaultChecked={!this.state.monthlySummaryOptOut}
                      onChange={this.monthlySummaryOptOutChange} />
                  </span>
                </td>
                <td>
                  <span className='accountSettingsName'>Monthly Summary emails</span>
                </td>
              </tr>

              <tr>
                  <td>
                  <span className='accountSettingsToggle'>
                    <Toggle
                      defaultChecked={!this.state.nudgesOptOut}
                      onChange={this.nudgesOptOutChange} />
                  </span>
                </td>
                <td>
                  <span className='accountSettingsName'>Weekly Wrap emails</span>
                </td>
              </tr>

              </table>

          </div>
          
          <p><Button type="primary" size="large" action="Save Settings" onClick={this.saveSettings}/></p>
          <p><Button type="secondary" size="large" action="Delete Account" onClick={this.deleteAccountClicked}/></p>
        </div>
        :
        <SpinnerPanel/>
  }
    { this.state.showSettingsUpdated ? 
      <MessagePopup title="Account Settings" message="Settings successfully updated" onClose={this.closeSettingsUpdated}/>
      :
      <div/>
    }

    { this.state.showDeleteAccountConfirmation ? 
      <DeleteAccountPopup onDeleteAccount={this.deleteAccountRequested} onClose={this.closeDeleteAccountConfirmation}/>
      :
      <div/>
    }

    { this.state.showDeleteAccountRequestSent ? 
      <MessagePopup title="Account Settings" message="Successful sent account deletion request" onClose={this.closeDeleteAccountRequestSent}/>
      :
      <div/>
    }

      </AppChrome>
    )
  }
}

export default AccountSettings;