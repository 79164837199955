import React, { Component } from 'react';
import { createDocumentRegistry } from 'typescript';

import TeamActivityItem from './TeamActivityItem'
import Button from '../ui/Button';

const INITIAL_STATE = {
  items: [],
  showAll: false
};

/*
 * Props 
 *   team = Team document
 */
class TeamActivity extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  } 
  
  componentWillMount = () => {
    this.loadActivityItems();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
   // this.loadActivityItems();
  }

  isItemVisible = (user, item, team) => {
    if (!item.visibility || item.visibility === "team")
      return true

    if (item.visibility === "manager") {
      for (var memberEmailKey in team.member) {
        var member = team.member[memberEmailKey];
        if (member['user-id'] === user.uid && member['manager-email'] && member['manager-email'] === user.email)
          return true
      }
    }
    
    return false
  }

  loadActivityItems = () => {
    var allItems = [];
    var team = this.props.team;
    var count = 0;

    // Get days
    if (team.activity) {
      for (const date of Object.keys(team.activity).sort().reverse()) {        
        for (var index = Object.keys(team.activity[date]).length - 1; index >= 0; index--) {
          var item = team.activity[date][index];

          if ((!this.props.habit || item.habit === this.props.habit) && this.isItemVisible(this.props.firebase.user, item, team)) {
              allItems.push({ index: index, date: date, item: item })
          }
        }
      }
    }

    this.setState({
      items : allItems,
      likes : {}
    });
  }

  hasMoreData = () => {
    return this.state.items.length < this.state.allItems.length;
  }

  itemClicked = (date, index) => {
    this.props.history.push("/activity-item/" + date + "/" + index);
  }

  avatarClicked = (userId) => {
    this.props.history.push("/team-member-activity/" + userId);
  }

  goalClicked = (item) => {
    if (item.userId && item.goalId)
      this.props.history.push("/goal-report-activity/" + item.userId + "/" + item.goalId);
  }

  findMember = (userId) => {
    for (var memberEmailKey in this.props.team.member) {
      var member = this.props.team.member[memberEmailKey];
      if (member['user-id'] === userId)
        return member;
    }
  }

  itemMatchesSearch = (item) => {
    if (!this.props.search) {
      return true;
    }

    var search = this.props.search.trim().toLowerCase()

    if (search.length === 0) 
        return true
    
    if (item.action && item.action.toLowerCase().indexOf(search) >= 0)
      return true;

    if (item.journal && item.journal.toLowerCase().indexOf(search) >= 0)
      return true;

    var habit = this.props.team.habits[item.habit]
    if (habit && habit.name.toLowerCase().indexOf(search) !== -1)
      return true;   
        
    var category = (habit && habit.category) ? this.props.team['habit-categories'][habit.category] : null
    if (category && category.name.toLowerCase().indexOf(search) !== -1)
      return true;

    var member = (item.userId) ? this.findMember(item.userId) : null;
    if (member && member.name && member.name.toLowerCase().indexOf(search) !== -1)
      return true;

    return false;
  }

  render = () => {
    var items = [];
    var count = 0

    this.state.items.map((item, index) => {
      
      if (this.itemMatchesSearch(item.item) && (this.state.showAll || count < 100)) {
          items.push(<TeamActivityItem onClick={this.itemClicked} onClickAvatar={this.avatarClicked} onClickGoal={this.goalClicked} firebase={this.props.firebase} teamId={this.props.teamId} team={this.props.team} date={item.date} index={item.index} item={item.item}/>)
          count += 1;
      }
    });

    if (count >= 100 && !this.state.showAll) {
      items.push(<Button action="Show All" type='primary' size='large' onClick={() => this.setState({ showAll: true })}/>)
    } 

    return (count > 0) ? <div>{items}</div> : <div className='activityNoActivity'>No matching activity found</div>;
  }
}

export default TeamActivity;