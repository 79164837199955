import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';

import Calendar from './Calendar';
import HabitActivityCard from './HabitActivityCard';
import HabitPopupWithImageUploader from './HabitPopupWithImageUploader';
import MessagePopup from '../ui/MessagePopup'

import AwardWonPopup from './AwardWonPopup'
import AwardNotWonPopup from './AwardNotWonPopup'
import Button from '../ui/Button';
import QuotePanel from '../ui/QuotePanel';
import ReloadDataCard from '../ui/ReloadDataCard';

import moment from 'moment';

import FeedbackPopup from './FeedbackPopup'

import { checkForCompletedChallenges, challengeResultIsDisplayed } from '../util/AwardUtils'; 
import { isChallengeInEffectOnDate }  from '../util/ChallengeUtils'
import { getUserAndTeam } from '../util/DatabaseUtils'
import { isSuperAdmin, isSuperAdminHomeTeam } from '../util/SwitchUtils'

import { toast } from 'react-hot-toast';

const calendarDateFormat = 'YYYY-MM-DD';

const INITIAL_STATE = {
  challenges: {},
  data: {},
  team: null,
  dataLoaded: false,
  habitPopup: false,
  awardWonPopup: false,
  awardNotWonPopup: false,
  messagePopup: false,
  messagePopupText: '',
  messagePopupCallback: null,
  date: moment().format('YYYY-MM-DD'),
  isOffline: false,
  showFeedbackPopup: false
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  getTitle = () => {
    if (this.props.firebase.initialised && !this.props.firebase.user)
      return "";
    else {
      var todayMoment = moment();
      var tomorrowMoment = todayMoment.clone().add(1, 'days');
      var yesterdayMoment = todayMoment.clone().subtract(1, 'days');

      if (this.state.date === todayMoment.format(calendarDateFormat))
        return "Today";
      else if (this.state.date === tomorrowMoment.format(calendarDateFormat))
        return "Tomorrow";
      else if (this.state.date === yesterdayMoment.format(calendarDateFormat))
        return "Yesterday";
      else
        return moment(this.state.date, calendarDateFormat).format('ddd, ll');
    }
  }

  checkDataLoaded = (force) => {
    if (this.props.firebase.user && (force || !this.state.dataLoaded)) {
      var database = this.props.firebase.database;

      /* Load days data and challenges data in parallel */

      getUserAndTeam(this.props.firebase, (user, team) => {
        this.setState({
          data: user,
          team: team,
          dataLoaded: true,
          isOffline: false
        });

        if (!user || !user.team) {
          this.props.history.push("/create-team")
          return
        }

        if (user && (!user.settings || !user.settings.timeZone)) {
          try {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            if (timezone) {
              database.ref('users/' + this.props.firebase.user.uid + '/settings/timezone').set(timezone);
              database.ref('users/' + this.props.firebase.user.uid + '/settings/browser').set(navigator.userAgent);
            }
          }
          catch (err) {
            // Some older browsers don't support the above call so ignore
          }
        }

        /*
        if (this.props.firebase.user && force) {
          checkForCompletedChallenges(this.props.firebase, user, this.props.firebase.user.uid, () => {
             this.displayCompletedChallenges()
          }) 
        } */
      },
      () => {
        this.setState({ dataLoaded: true, isOffline: true })
      })
    }
  }
 
  displayCompletedChallenges = () => {
    // Make sure we have the latest data

    this.props.firebase.database.ref('users/' + this.props.firebase.user.uid).get().then((snapshot) => {
      const data = snapshot.val();

      var challenges = (data && data.challenges) ? data.challenges : {};

      Object.keys(challenges).forEach((challengeId) => {
        var challenge = challenges[challengeId];

        if (challenge.finished && !challenge.finishedDisplayed) {
          if (challenge.award) {
            this.setState({
              awardWonPopup: true,
              awardNotWonPopup: false,
              awardWonPopupAward: data.awards[challenge.award],
              awardWonPopupChallengeId: challengeId
            })
          }
          else {
            this.setState({
              awardWonPopup: false,
              awardNotWonPopup: true,
              awardNotWonPopupChallengeId: challengeId,
              awardNotWonPopupChallengeName: challenge.name
            })
          }
        }
      })
    })
  }

  closeMessagePopup = () => {
    this.setState({
      messagePopup: false,
      messagePopupMessage: '',
      messagePopupCallback: null
    })
  }

  showMessagePopup = (message, callback) => {
    this.setState({
      messagePopup: true,
      messagePopupMessage: message,
      messagePopupCallback: callback
    })
  }

  closeAwardWonPopup = () => {
    // Mark the challenge result as dislayed
    this.setState({
      awardWonPopup: false
    })

    challengeResultIsDisplayed(this.props.firebase, this.state.awardWonPopupChallengeId, () => {});
  }

  closeAwardNotWonPopup = () => {
    // Mark the challenge result as dislayed
    this.setState({
      awardNotWonPopup: false
    })

    challengeResultIsDisplayed(this.props.firebase, this.state.awardNotWonPopupChallengeId, () => {});
  }

  componentWillMount = () => {
    this.checkDataLoaded(true);
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded(false);
  }

  componentWillUnmount() {
  }

  nextDay = () => {
    var nextDate = moment(this.state.date, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
    this.setState({ date: nextDate });
  }

  previousDay = () => {
    var previousDate = moment(this.state.date, 'YYYY-MM-DD').add(-1, 'days').format('YYYY-MM-DD');
    this.setState({ date: previousDate });
  }

  onChangeDate = (newDate) => {
    this.setState({ date: newDate });
  }

  handleInfoClick = (challengeId, challenge, activity) => {
      this.props.history.push("/habit-home/" + challenge.habit);
  }

  handleHabitClick = (challengeId, challenge, activity) => {
    var todayMoment = moment(new Date());
    var todayMomentFormatted = todayMoment.format(calendarDateFormat);

    var challenge = this.state.data.challenges[challengeId];

    if (activity) {
      this.props.history.push("/goal-report-home/" + challengeId);
    }
    else if (this.state.date !== todayMomentFormatted) {
      // Show a message popup that says you must check in habits on the day they are due

      this.showMessagePopup("You cannot check in habits for past days.", this.closeMessagePopup);
    }
    else {
      var journal = (activity && activity.journal) ? activity.journal : "";

      this.setState({ habitPopup: true, challengeId: challengeId, challenge: challenge, journal: journal })
    };
  }

  closeHabitPopup = (completed) => {
    this.setState({ habitPopup: false });

  /*  if (completed)
      this.props.history.push("/goal-report-home/" + this.state.challengeId);
*/

    this.checkDataLoaded(true);
  }

  onViewReport = (challengeId) => {
    this.props.history.push("/goal-report-home/" + challengeId);
  }

  addHabit = () => {
    this.props.history.push("/add-habit-1");
  }

  getActivityByChallengeIdAndDate = (challengeId, date, days) => {
    var dateFormatted = moment(date).format(calendarDateFormat);
    if (days && dateFormatted in days && challengeId in days[dateFormatted])
      return days[dateFormatted][challengeId];
    else
      return null;
  }

  renderHabitsSectionTitle = () => {
    var habitSectionTitle = "HABITS FOR " + moment(this.state.date, calendarDateFormat).format('MMM D').toUpperCase();
    return <div className='sectionTitle'>{habitSectionTitle}</div>;
  }

  reload = () => {
    this.setState({ isOffline: false, dataLoaded: false })
    this.checkDataLoaded(true);
  }

  showFeedbackPopup = () => {
    this.setState({ showFeedbackPopup: true })
  }

  closeFeedbackPopup = () => {
    this.setState({ showFeedbackPopup: false })
  }

  renderHabits = () => {
    const items = []
    var challenges = (this.state.data && this.state.data.challenges) ? this.state.data.challenges : {};
    var days = (this.state.data && this.state.data.days) ? this.state.data.days : {};
    var hasChallengesToday = false;

    items.push(<img class='curvesImage' src='/illustration/curves.png'/>);

    items.push(<Calendar date={this.state.date} inactive='true' onChangeDate={this.onChangeDate} firebase={this.props.firebase} days={days} challenges={challenges} />);

    items.push(<QuotePanel/>);

    // We will display a HabitActivityCard for all challenges that are active on this 
    // particular day. 

    if (this.state.isOffline) {
        items.push(<ReloadDataCard firebase={this.props.firebase} onClick={this.reload} message="Could not load current habits."/>)
    }
    else if (!this.state.dataLoaded)
      items.push(this.renderHabitsPlaceholders())
    else if (isSuperAdmin(this.props.firebase.user) && !isSuperAdminHomeTeam(this.props.firebase.user, this.state.data.team))
      items.push(<div><p>Please switch to your home team to view your habits.</p></div>)
    else { 
      if (challenges) {
        var challengesOrdered = [];

        Object.keys(challenges).forEach((challengeId) => {
          challengesOrdered.push({
            id: challengeId,
            challenge: challenges[challengeId],
            activity: this.getActivityByChallengeIdAndDate(challengeId, this.state.date, days)
          })
        });

        const periodOrder = [ 'day', 'week', 'month' ]

        challengesOrdered.sort((c1, c2) => {
          if (c1.activity && !c2.activity)
            return 1
          else if (!c1.activity && c2.activity)
            return -1;

          const c1Period = periodOrder.indexOf(c1.challenge.period)
          const c2Period = periodOrder.indexOf(c2.challenge.period)
          
          if (c1Period < c2Period)
            return -1
          else if (c1Period > c2Period)
            return 1

          if (c1.challenge.name < c2.challenge.name)
            return -1;
          else if (c1.challenge.name > c2.challenge.name)
            return 1;

          return 0;
        });

        challengesOrdered.forEach((c) => {
          if (isChallengeInEffectOnDate(c.id, c.challenge, this.state.date, days)) {
            hasChallengesToday = true;

            var objective = (c.challenge.objective) ? this.state.data.objectives[c.challenge.objective].name : null;
            var programType = (this.state.team && this.state.team.settings) ? this.state.team.settings['program-type'] : null;
            items.push(<HabitActivityCard challengeId={c.id} date={this.state.date} objective={objective} challenge={c.challenge} activity={c.activity} programType={programType} onClick={this.handleHabitClick} onInfoClick={this.handleInfoClick}/>)
          }
        });

        if (!hasChallengesToday) {
            items.push(
              <div className='habitActivityEmptyStateCard'>
                <img src='/illustration/home-empty-state.png' className='habitActivityEmptyStateImage' />
                <div className='habitActivityEmptyStateMessage'>There are no habits for this day</div>
              </div>);
          
            items.push(<p><Button type='primary' size='large' onClick={this.addHabit} action='Try a new habit'/></p>);
        }
      }
    }

    if (this.state.dataLoaded) {
      items.push(<div className='homePageFeedback'>
        <div className='homePageFeedbackPrompt'>Have questions or feedback about Lasoo?</div>
        <div className='homePageFeedbackButton'>
          <Button onClick={this.showFeedbackPopup} onClose={this.closeFeedbackPopup} action='Send feedback'/>
        </div>
      </div>);
    }

    return items;
  }

  renderHabitsPlaceholders = () => {    
    const items = []

    items.push(<div class='calendarLoadingPlaceholder' />);
    for (var i = 1; i <= 2; i++)
      items.push(<div className='habitActivityCardPlaceholder' />)

    return <div>{items}</div>;
  }

  habitClicked = (challengeId) => {
    this.props.history.push("/habit-home/" + this.state.data.challenges[challengeId].habit);
  }

  render = () => {
    var history = this.props.history; 

    return (
      <AppChrome title="" history={history} requireLogin={true} user={this.state.data} team={this.state.team} firebase={this.props.firebase} tab="Today">
          <div>
            { this.renderHabits()}
          </div>
          
          {this.state.habitPopup
            ?
            <HabitPopupWithImageUploader challengeId={this.state.challengeId} challenge={this.state.challenge} userData={this.state.data} team={this.state.team} journal={this.state.journal} habitClicked={this.habitClicked.bind(this, this.state.challengeId)} onClose={this.closeHabitPopup} onViewReport={this.onViewReport} date={this.state.date} firebase={this.props.firebase} />
            :
            <div></div>
          }
          {this.state.awardWonPopup
            ?
            <AwardWonPopup percentage={this.state.awardWonPopupAward.percentage} days={this.state.awardWonPopupAward.days} colour={this.state.awardWonPopupAward.colour} category={this.state.awardWonPopupAward.category} name={this.state.awardWonPopupAward.name} onClose={this.closeAwardWonPopup}/>
            :
            <div></div>
          }
          {this.state.awardNotWonPopup
            ?
            <AwardNotWonPopup name={this.state.awardNotWonPopupChallengeName} onClose={this.closeAwardNotWonPopup}/>
            :
            <div></div>
          }
          {this.state.messagePopup
            ?
            <MessagePopup message={this.state.messagePopupMessage} title="Lasoo" onClose={this.closeMessagePopup}/>
            :
            <div></div>
          }
          {this.state.showFeedbackPopup
            ?
            <FeedbackPopup firebase={this.props.firebase} onClose={this.closeFeedbackPopup}/>
            :
            <div/>
          }
      </AppChrome>
    )
  }
}

export default Home; 

