import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Award from '../ui/Award';
import Button from '../ui/Button';

import { getUserAndTeam } from '../util/DatabaseUtils';

import Profile from '../team/Profile';

const INITIAL_STATE = {
  dataLoaded: false,
};

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = () => {

    if (this.props.firebase.user && !this.state.dataLoaded) {

      getUserAndTeam(this.props.firebase, (user, team) => {
        this.setState({ 
            dataLoaded: true, 
            user: user, 
            team : team, 
         });
      },
      () => {
        this.setState({ isOffline: true })
      })
    }

  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  logout = () => {
    this.props.firebase.auth.signOut().then(() => {
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.userLoggedOut) {
        window.webkit.messageHandlers.userLoggedOut.postMessage({
            "message": {}
        });
      }   
      this.props.history.push("/");
    }).catch((error) => {
      alert(error);
    });
  }

  onGoalClicked = (goalId) => {
    this.props.history.push("/goal-report-profile/" + goalId)
  }

  onLevelClicked = () => {
    this.props.history.push("/levels-and-points")
  }

  accountSettingsClicked = (goalId) => {
    this.props.history.push("/account-settings")
  }
  
  render = () => {
    var history = this.props.history;

    return (
      <AppChrome title="My Profile" history={history} requireLogin={true} user={this.state.user} team={this.state.team} firebase={this.props.firebase} tab="Today">
        <Profile firebase={this.props.firebase} history={this.props.history} onGoalClicked={this.onGoalClicked} onLevelClicked={this.onLevelClicked} user={this.props.firebase.user ? this.props.firebase.user.uid : 0}/>
        <p><Button type="secondary" size="large" action="Account Settings" onClick={this.accountSettingsClicked}/></p>
        <p><Button type="secondary" size="large" action="Logout" onClick={this.logout}/></p>
      </AppChrome>
    )
  }
}

export default MyProfile;