import React, { Component } from 'react';

import Avatar from '../ui/Avatar'
import Button from '../ui/Button'
import ConfirmPopup from '../ui/ConfirmPopup'
import MessagePopup from '../ui/MessagePopup'
import SpinnerPanel from '../ui/SpinnerPanel';

import EditObjectivePopup from '../objectives/EditObjectivePopup';

import CalenderEventPopup from './CalendarEventPopup'
import EmailReminderPopup from './EmailReminderPopup'
import GoalFormConfirmPopup from './GoalFormConfirmPopup'
import TeamMembersPopup from '../today/TeamMembersPopup'
import VisibilitySelectorPopup from './VisibilitySelectorPopup'

import Select from "react-select";

import { v4 as uuidv4 } from 'uuid';

import { getUser } from '../util/DatabaseUtils'
import { getVisibilityOption } from '../util/VisibilityUtils';

import moment, { parseZone } from 'moment';
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-toggle/style.css';

const calendarDateFormat = 'YYYY-MM-DD';
const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const showStartAndEndDate = false

const INITIAL_STATE = {
  creatingGoal: false,

  messagePopup: false,
  messagePopupMessage: '',
  messagePopupCallback: null,

  deleteConfirmPopup: false,
  deleteConfirmPopupCallback: null,

  creationConfirmPopup: false,
  creationConfirmPopupCallback: null,

  emailReminderPopup: false,
  emailReminderPopupIndex: -1,

  calendarEventPopup: false,
  calendarEventPopupIndex: -1,

  addObjectivePopup: false,
  creatingObjective: false,

  visibilitySelectorPopup: false,
  disableTeamVisibility: false,

  teamMembersPopup: false,
  dataLoaded : false,
};

class GoalForm extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

    this.state.habit = (this.props.habit) ? this.props.habit : "";
    this.state.name = this.props.name;
    this.state.category = this.props.category;
    this.state.period = this.props.period;
    this.state.selectedDays = (this.props.daysOfWeek) ? this.props.daysOfWeek : [];

    this.state.reminders = this.props.reminders ? this.props.reminders : this.getDefaultRemindersForPeriod(this.props.period);
    this.state.calendarEvents = this.props.calendarEvents ? this.props.calendarEvents : [];
    this.state.teamMembers = this.props.teamMembers ? this.props.teamMembers : [];

    this.state.objective = this.props.objective;
  
    this.state.colour = this.props.colour;
    this.state.startDate = moment(this.props.startDate,calendarDateFormat).toDate();
    this.state.endDate = moment(this.props.endDate,calendarDateFormat).toDate();
    this.state.actionName = this.props.actionName;

    this.state.disableTeamVisibility = this.props.disableTeamVisibility ? true : false;
    this.state.visibility = this.props.visibility ? this.props.visibility : this.getDefaultVisibility(this.props.disableTeamVisibility);
  } 

  getDefaultRemindersForPeriod = (period) => {
    switch (period) {
      case 'day':
        return [{ time: "09:00" }, { time: "16:30" }];
      case 'week':
        return [{ day: 'Mon', time: "09:00" }, { day: 'Wed', time: "16:30" }];
      case 'month':
        return [{ day: 'Mon', time: "09:00" } ];
      default:
        return [];
    }
  
  }

  initialisedStateFromProps = () => {
    /* Is this an anti-pattern? We should probably not initialise the form
       until the data is loaded to avoiding having to update the props */

    this.setState( {
      habit : (this.props.habit) ? this.props.habit : "",
      name : this.props.name,
      category: this.props.category ? this.props.category : "",
      period : this.props.period,
      selectedDays : (this.props.daysOfWeek) ? this.props.daysOfWeek : [],
      reminders : this.props.reminders ? this.props.reminders : [],
      calendarEvents : this.props.calendarEvents ? this.props.calendarEvents : [],
      teamMembers : this.props.teamMembers ? this.props.teamMembers : [],
      colour : this.props.colour,
      
      objective : this.props.objective ? this.props.objective : null,

      startDate : moment(this.props.startDate,calendarDateFormat).toDate(),
      endDate : moment(this.props.endDate,calendarDateFormat).toDate(),
      actionName : this.props.actionName,
      visibility: this.props.visibility ? this.props.visibility : this.getDefaultVisibility(this.props.disableTeamVisibility),
      disableTeamVisibility : this.props.disableTeamVisibility ? true : false
    });
  }

  getDefaultVisibility = () => {
    return (this.state.disableTeamVisibility) ? 'manager' : "team"
  }

  checkDataLoaded = (force, callback) => {
    if (this.props.firebase.user && (force || !this.state.dataLoaded)) {

      /* Load days data and challenges data in parallel */

      getUser(this.props.firebase, (user) => {
        this.setState({
          objectives: user.objectives ? user.objectives : null,
          dataLoaded: true
        });
        if (callback) callback()
      })
    }
    else 
      if (callback) callback()
  }

  componentWillMount = () => {
    this.checkDataLoaded(true);
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.name !== this.props.name) 
      this.initialisedStateFromProps();  

    this.checkDataLoaded(false);
  }

  componentWillUnmount() {
  }

  dayButtonClicked = (day) => {
    var selectedDays = this.state.selectedDays;
    const index = selectedDays.indexOf(day);
    if (index > -1)
      selectedDays.splice(index, 1);
    else 
      selectedDays.push(day)

    this.setState({ selectedDays: selectedDays });
  }

  renderDayButton = (day) => {
    var isSelected = (this.state.selectedDays.includes(day));
    var buttonClass = (isSelected) ? "addHabitFormDayButtonSelected" : "addHabitFormDayButtonNotSelected";

    return (
      <div className="addHabitFormDayButtonContainer">
        <div onClick={() => this.dayButtonClicked(day)} class={buttonClass}>{day}</div>
      </div>);
  }

  renderDaysButtonGroup = () => {
    const items = [];

    for (var day in daysOfWeek)
      items.push(this.renderDayButton(daysOfWeek[day]));

    return <div className="addHabitFormDayButtonGroup">{items}</div>;
  }

  onGoalChange = (e) => {
    const newVal = e.target.value;
    this.setState({ selectedGoal: newVal });
  }

  onChangeReminders = (e) => {
    this.setState({ reminders: e.target.checked });
  }

  setStartDate = (date) => {
    this.setState({ startDate: date });
  }

  setEndDate = (date) => {
    this.setState({ endDate: date });
  }

  isValidDayOfWeek = (date) => {
    const dayOfWeek = moment(date).format('ddd');
    var today = new Date();

    return (this.state.selectedDays.indexOf(dayOfWeek) >= 0);;
  }
  
  showCreationConfirmPopup = (challengeId, callback) => {
    this.setState({
      creatingGoal : false,
      creationConfirmPopupChallengeId: challengeId,
      creationConfirmPopup: true,
      creationConfirmPopupCallback : callback
    })
  }

  closeCreationConfirmPopup = (callback) => {
    this.setState({
      creationConfirmPopup: false
    })
  }

  closeMessagePopup = () => {
    this.setState({
      messagePopup: false,
      messagePopupMessage: '',
      messagePopupCallback: null
    })
  }

  showMessagePopup = (message, callback) => {
    this.setState({
      messagePopup: true,
      messagePopupMessage: message,
      messagePopupCallback: callback
    })
  }

  closeDeleteConfirmPopup = () => {
    this.setState({
      deleteConfirmPopup: false,
      deleteConfirmPopupMessage: '',
      deleteConfirmPopupCallback: null
    })
  }

  showDeleteConfirmPopup = (message, callback) => {
    this.setState({
      deleteConfirmPopup: true,
      deleteConfirmPopupMessage: message,
      deleteConfirmPopupCallback: callback
    })
  }

  showTeamMembersPopup = (callback) => {
    this.setState({
      teamMembersPopup: true
    })
  }

  closeTeamMembersPopup = () => {
    this.setState({
      teamMembersPopup: false
    })
  }

  showVisibilitySelectorPopup = (callback) => {
    this.setState({
      visibilitySelectorPopup: true
    })
  }

  closeVisibilitySelectorPopup = () => {
    this.setState({
      visibilitySelectorPopup: false
    })
  }

  visibilityChanged = (visibility) => {
    this.setState({
      visibility: visibility,
      visibilitySelectorPopup: false
    })
  }

  teamMembersPopupCallback = (teamMember) => {
    // First check the teamMember is not already in the list

    for (var member of this.state.teamMembers) {
      if (member.userId === teamMember.userId) {
        this.setState({ teamMembersPopup: false })
        return;
      }
    }
    teamMember.inviteSent = false;
    this.state.teamMembers.push(teamMember);
    
    this.setState({
      teamMembers : this.state.teamMembers,
      teamMembersPopup : false
    })
  }

  removeTeamMember = (i, e) => {
    this.state.teamMembers.splice(i, 1)

    this.setState({
      teamMembers : this.state.teamMembers
    })

    e.stopPropagation();
  }

  stringIsBlank = (str) => {
    return (!str || /^\s*$/.test(str));
  } 

  validateFields = () => {

    if (!this.state.objective || this.state.objective === 'add-new-objective') {
      this.showMessagePopup("Please select an objective", this.closeMessagePopup);
      return false;
    }

    if (this.stringIsBlank(this.state.name)) {
      this.showMessagePopup("Please enter a habit name", this.closeMessagePopup);
      return false;
    }

    if (showStartAndEndDate && this.state.endDate < this.state.startDate) {
      this.showMessagePopup("End date must follow start date", this.closeMessagePopup);
      return false;
    }

    if (this.state.period === 'day' && this.state.selectedDays.length === 0) {
      this.showMessagePopup("Please select a day or days to practice habit", this.closeMessagePopup);
      return false;
    }

    return true;
  }

  orderSelectedDays = (selectedDays) => {
    var orderedDays = [];
    daysOfWeek.forEach((day) => {
       if (selectedDays.indexOf(day) >= 0) {
         orderedDays.push(day);
       }
    });
    return orderedDays;
  }

  onSubmission = (e) => {
    if (!this.validateFields())
      return;

    if (this.props.onSubmission !== null) {
      this.setState({ creatingGoal : true })
      this.props.onSubmission(this.state.objective, this.state.habit, this.state.name, this.state.category, this.state.period, this.orderSelectedDays(this.state.selectedDays), moment(this.state.startDate).format(calendarDateFormat), showStartAndEndDate ? moment(this.state.endDate).format(calendarDateFormat) : null, this.state.colour, this.state.reminders, this.state.calendarEvents, this.state.teamMembers, this.state.visibility, this.showCreationConfirmPopup);
    }
  }

  onChangeName = (e) => {
    this.setState({ name: e.target.value })
  }
  
  onDelete = () => {
    this.showDeleteConfirmPopup('Are you sure you want to delete this habit?', this.onDeleteConfirm);
  }

  onDeleteConfirm = (confirmed) => {
    if (confirmed)
      this.props.onDelete();
    else
      this.closeConfirmPopup();
  }

  formatTime = (t) => {
    return moment(t, ['H:m']).format('hh:mm A');
  }

  removeEmailReminder = (i, e) => {
    e.stopPropagation();
    
    var reminders = this.state.reminders;
    if (i < this.state.reminders.length) {
      reminders.splice(i, 1);
      this.setState({ reminders: reminders } );
    }
  }

  showEmailReminderPopup = (i) => {
    this.setState({ emailReminderPopup: true, emailReminderPopupIndex : i})
  }

  closeEmailReminderPopup = () => {
    this.setState({ emailReminderPopup: false })
  }

  emailReminderChanged = (v) => {
    var reminders = this.state.reminders;

    if (this.state.emailReminderPopupIndex < 0) {
      if (reminders.indexOf(v) < 0)
        reminders.push(v);
    }
    else {
      reminders[this.state.emailReminderPopupIndex] = v;
    }
    reminders.sort((r1, r2) => {
      const days = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];

      if (r1.day && r2.day) {
        if (days.indexOf(r1.day) < days.indexOf(r2.day))
          return -1;
        if (days.indexOf(r1.day) > days.indexOf(r2.day))
          return 1;
      }

      if (r1.time < r2.time) return -1;
      if (r1.time > r2.time) return 1;
      return 0;
    });

    this.setState({ emailReminderPopup: false, reminders : reminders })
  }

  removeCalendarEvent = (i, e) => {
    e.stopPropagation();
    
    var calendarEvents = this.state.calendarEvents;
    if (i < this.state.calendarEvents.length) {
      calendarEvents.splice(i, 1);
      this.setState({ calendarEvents: calendarEvents } );
    }
  }

  showCalendarEventPopup = (i) => {
    this.setState({ calendarEventPopup: true, calendarEventPopupIndex : i})
  }

  closeCalendarEventPopup = () => {
    this.setState({ calendarEventPopup: false })
  }

  calendarEventChanged = (startTime, endTime) => {
    var calendarEvents = this.state.calendarEvents;

    if (this.state.calendarEventPopupIndex < 0) {
      calendarEvents.push({ start: startTime, end: endTime, sent: false});
      this.setState({ calendarEventPopup: false, calendarEvents : calendarEvents })
    }
    else {
      if (calendarEvents[this.state.calendarEventPopupIndex].start !== startTime || calendarEvents[this.state.calendarEventPopupIndex].end !== endTime) {
        calendarEvents[this.state.calendarEventPopupIndex] = { start: startTime, end: endTime, sent: false};
        this.setState({ calendarEventPopup: false, calendarEvents : calendarEvents })
      }
    }
  }

  renderReminders = () => {
    const items = []

    items.push(<div className='cardRow' onClick={ this.showEmailReminderPopup.bind(this, -1)}>
      <div className='cardRowPlusIcon'>
         <div className="material-icons md-light md-28">add</div>
      </div>
      <div className='cardRowText'>Add time</div>
    </div>);

    for (var i = 0; i < this.state.reminders.length; i++) { 
      const reminder = this.state.reminders[i];
      var reminderText = (this.state.period === 'day') ? 
        this.formatTime(reminder.time) : 
        reminder.day + " - " + this.formatTime(reminder.time);

      items.push(<div className='cardRow' onClick={ this.showEmailReminderPopup.bind(this, i)}>
        <div className='cardRowIcon'>
          <div className="material-icons md-30">schedule</div>
        </div>
        <div className='cardRowText'>{reminderText}</div>
        <div className='cardRowDeleteIcon' onClick={ this.removeEmailReminder.bind(this, i) }>
           <div className="material-icons md-30">delete_outline</div>
        </div>
      </div>);
    }

    return <div className='habitFormEmailRemindersCard'>{items}</div>
  }

  renderCalendarEvents = () => { 
    const items = [];
    items.push(<div className='cardRow' onClick={ this.showCalendarEventPopup.bind(this, -1)}>
      <div className='cardRowPlusIcon'>
        <div className="material-icons md-light md-28">add</div>
      </div>
      <div className='cardRowText'>Add calendar event</div>
    </div>);

    for (var i = 0; i < this.state.calendarEvents.length; i++) { 
      const startFormatted = this.formatTime(this.state.calendarEvents[i].start);
      const endFormatted = this.formatTime(this.state.calendarEvents[i].end);

      items.push(<div className='cardRow' onClick={ this.showCalendarEventPopup.bind(this, i)}>
        <div className='cardRowIcon'>
          <div className="material-icons md-30">schedule</div>
        </div>
        <div className='cardRowText'>{startFormatted} - {endFormatted}</div>
        <div className='cardRowDeleteIcon' onClick={ this.removeCalendarEvent.bind(this, i) }>
           <div className="material-icons md-30">delete_outline</div>
        </div>
      </div>);  
    } 

    return <div className='habitFormCalendarEventsCard'>{items}</div>
  }

      
  addObjective = (name, colour) => {
    this.setState({ addObjectivePopup: false, creatingObjective: true })

    var objectiveId = "objective-" + uuidv4();

    var objective = {
      name: name,
      colour: colour,
      createdDate: moment().format(calendarDateFormat)
    }

    this.props.firebase.database.ref('users/' + this.props.firebase.user.uid + '/objectives/' + objectiveId).set(objective)
    .then(() => {
 
       this.checkDataLoaded(true, () => {
          this.setState({
            creatingObjective: false,
            objective: objectiveId
          })
       })
    })
  }

  closeAddObjective = () => {
    this.setState({ addObjectivePopup: false })
  }

  renderEmailReminderPopup = () => {
    const title = (this.state.emailReminderPopupIndex < 0) ? "Add Reminder" : "Edit Reminder";
    const value = (this.state.emailReminderPopupIndex < 0) ? { time : "09:00" } : this.state.reminders[this.state.emailReminderPopupIndex]

    return <EmailReminderPopup title={title} value={value} showDayOfWeek={this.state.period !== 'day'} onSubmit={this.emailReminderChanged} onClose={this.closeEmailReminderPopup}/>
  }

  renderCalendarEventPopup = () => {
    const title = (this.state.calendarEventPopupIndex < 0) ? "Add Calendar Event" : "Edit Calendar Event";
    const startTime = (this.state.calendarEventPopupIndex < 0) ? "09:00" : this.state.calendarEvents[this.state.calendarEventPopupIndex].start
    const endTime = (this.state.calendarEventPopupIndex < 0) ? "09:30" : this.state.calendarEvents[this.state.calendarEventPopupIndex].end

    return <CalenderEventPopup title={title} startTime={startTime} endTime={endTime} onSubmit={this.calendarEventChanged} onClose={this.closeCalendarEventPopup}/>
  }

  renderTeamMembers = () => { 
    const items = [];
    items.push(<div className='cardRow' onClick={ this.showTeamMembersPopup.bind(this, -1)}>
      <div className='cardRowPlusIcon'>
        <div className="material-icons md-light md-28">add</div>
      </div>
      <div className='cardRowText'>Add team member</div>
    </div>);

    for (var i = 0; i < this.state.teamMembers.length; i++) { 
      var teamMember = this.state.teamMembers[i];

      items.push(<div className='cardRow'>
        <div className='cardRowIcon'>
          <Avatar size='small' name={teamMember.name} image={teamMember.photoURL}/>
        </div>
        <div className='cardRowText'>{teamMember.name}</div>
        <div className='cardRowDeleteIcon' onClick={ this.removeTeamMember.bind(this, i) }>
           <div className="material-icons md-30">delete_outline</div>
        </div>
      </div>);  
    }

    return <div className='habitFormCalendarEventsCard'>{items}</div>
  }

  renderVisibility = () => {
    var visibilityOption = getVisibilityOption(this.props.programType, this.props.disableTeamVisibility, this.state.visibility)

    if (!visibilityOption)
      return <div/>
    else
      return <div className='cardRow' onClick={ this.showVisibilitySelectorPopup }>
          <div className='cardRowIcon'>
            <div className="material-icons-outlined md-30">{visibilityOption.icon}</div>
          </div>        
          <div className='cardRowText'>{visibilityOption.label} { visibilityOption.recommended ? "(Recommmended)" : "" }</div>
      </div>
  }

  objectiveChanged = (option) => {
    if (option.value === 'add-new-objective') {
      this.setState({ addObjectivePopup: true })
      return false;
    }
    else {
      this.setState({ objective: option.value, colour: this.state.objectives[option.value].colour })
      return true
    }
  }

  renderObjective = () => {
      var options = []
      var defaultValue = null;

      if (this.state.objectives) {

        Object.keys(this.state.objectives).forEach(objectiveId => {
          if (!this.state.objectives[objectiveId].endedDate)
            options.push({ value: objectiveId, label: this.state.objectives[objectiveId].name })
        })

        options.sort((o1, o2) => {
          if (o1.label < o2.label) return -1;
          if (o1.label > o2.label) return 1;
          return 0;
        });


        if (this.state.objective) {
          defaultValue = options.filter(option => option.value === this.state.objective)
        }
       }

       options.push({ value: 'add-new-objective', label: ' -- Add a new objective' })

      return <Select name="objective" defaultValue={defaultValue} options={options} onChange = {this.objectiveChanged}/>
  }

  periodChanged = (option) => {
    this.setState({ period: option.value, reminders: this.getDefaultRemindersForPeriod(option.value) })
  }

  renderPeriodSelector = () => {
    var options = [
      { label: "Specific Day(s)", value: "day" },
      { label: "Weekly", value: "week" },
      { label: "Monthly", value: "month" },
    ]
    
    var defaultValue = options.filter(option => option.value === this.state.period)
    
    return <Select name="period" defaultValue={defaultValue} options={options} onChange = {this.periodChanged}/>
}

  getPracticeDaysTitle = () => {
    return "PRACTICE " + (this.state.selectedDays.length) + " " + ((this.state.selectedDays.length === 1) ? "DAY" : "DAYS") + " PER WEEK ON"
  }

  render = () => {

    if (this.state.creatingGoal || !this.state.dataLoaded || this.state.creatingObjective) {
      return <div>
        <SpinnerPanel/>
      </div>;
    }


    return ( 
      <div>
        <div className='habitForm'>

          <div className='sectionTitle'>HABIT NAME</div>
          <input onChange={this.onChangeName} value={this.state.name} placeholder="A short name for the habit"/>

          <div className='sectionTitle'>LINK HABIT TO GOAL</div>
          { this.renderObjective() }

          <>
            <div className='sectionTitle'>PRACTICE PERIOD</div>
            { this.renderPeriodSelector() }
          </>
      
          { 
            this.state.period === 'day' &&
            <><div className='sectionTitle'>{this.getPracticeDaysTitle()}</div>
              {this.renderDaysButtonGroup()}
            </> 
          }

          { showStartAndEndDate ? 
          <div class="addHabitStartEndDateRow">
            <div className='addHabitStartDateContainer'>         
              <div className='sectionTitle'>START HABIT</div>
              <div className='addHabitStartDate'>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={(date) => this.setStartDate(date)}
                  selectsStart
                  dateFormat="d MMM yyyy"
                  filterDate={this.isValidDayOfWeek}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                />
              </div>
            </div>

            <div className='addHabitEndDateContainer'>         
              <div className='sectionTitle'>PRACTICE UNTIL</div>
              <div className='addHabitEndDate'>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={(date) => this.setEndDate(date)}
                  selectsEnd
                  dateFormat="d MMM yyyy"
                  filterDate={this.isValidDayOfWeek}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  minDate={this.state.startDate}
                />
              </div>
            </div>
            
            <div/>
          </div>
          :
          <div/>
        }
        
        </div>

        <div className='sectionTitle'>REMINDERS</div>
        { this.renderReminders() }

        <div className='sectionTitle'>VISIBILITY</div>
        { this.renderVisibility() }

        <p><Button type='primary' size='large' onClick={this.onSubmission} action={this.state.actionName}/></p>

        { this.props.onDelete ?
          <Button type='secondary' size='large' onClick={this.onDelete} action="Delete habit"/> : <div/> }

        { this.state.deleteConfirmPopup
              ?
              <ConfirmPopup title='Habit Form' message={this.state.deleteConfirmPopupMessage} callback={this.state.deleteConfirmPopupCallback}/>
              :
              <div></div>
        }

        { this.state.emailReminderPopup
              ?
               this.renderEmailReminderPopup()
              :
              <div></div>
        }

        { this.state.calendarEventPopup
              ?
               this.renderCalendarEventPopup()
              :
              <div></div>
        }

        { this.state.creationConfirmPopup
              ?
              <GoalFormConfirmPopup history={this.props.history} firebase={this.props.firebase} challengeId={this.state.creationConfirmPopupChallengeId} onClose={this.state.creationConfirmPopupCallback} />
              :
              <div></div>
        }

        { this.state.visibilitySelectorPopup
              ?
              <VisibilitySelectorPopup firebase={this.props.firebase} callback={this.visibilityChanged} onClose={this.closeVisibilitySelectorPopup} value={this.state.visibility} programType={this.props.programType} disableTeamVisibility={this.props.disableTeamVisibility}/>
              :
              <div></div>
        }

        { this.state.teamMembersPopup
              ?
              <TeamMembersPopup firebase={this.props.firebase} callback={this.teamMembersPopupCallback} onClose={this.closeTeamMembersPopup} />
              :
              <div></div>
        }

        { this.state.messagePopup
              ?
              <MessagePopup title='Habit Form' message={this.state.messagePopupMessage} onClose={this.state.messagePopupCallback} />
              :
              <div></div>
        }

        { !this.state.creatingObjective && this.state.addObjectivePopup 
              ?
              <EditObjectivePopup newObjective={true} onAddOrUpdate={this.addObjective} onClose={this.closeAddObjective}/>
              :
              <div/>
        }
      </div>
    )
  }
}

export default GoalForm;