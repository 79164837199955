import React, { Component } from 'react';

const INITIAL_STATE = {
};

class ReloadDataCard extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  reload = () => {
    if (this.props.firebase) {
      this.props.firebase.database.goOffline();
      this.props.firebase.database.goOnline();
    }

    this.props.onClick();
  }

  render = () => {
    return (
      <div class="reloadDataCardContainer" onClick={this.reload}>
        <div class="reloadDataCard">
          <div class="reloadDataCardArrow">⟳</div>
          <div class="reloadDataCardMessage">{this.props.message}</div>
        </div>
      </div>
    );
  }
}

export default ReloadDataCard;

