import React, { Component } from 'react';

import Popup from 'reactjs-popup';
import PopupHeader from '../ui/PopupHeader';

import Button from '../ui/Button'

const INITIAL_STATE = {
  confirmBox : ""
};

class DeleteAccountPopup extends Component {

  constructor(props) {
      super(props);
      this.state = INITIAL_STATE;
  } 

  onChangeConfirmBox = (e) => {
    this.setState({ confirmBox: e.target.value })
  }

  onDeleteAccountClicked = () => {
    if (this.state.confirmBox.toLowerCase() === 'delete') {
        if (this.props.onDeleteAccount) {
            this.props.onDeleteAccount()
        }
    }
  }

  render = () => {
    return <div>
        { 
          <Popup open="true" closeOnDocumentClick className='delete-account-popup' onClose={this.props.onClose}>
            <PopupHeader title='Delete Account'/>

            <div className='deleteAccountPopupMessage'>
                <p>Lasoo supports deletion of your profile and associated data.</p>
                <p>A request for account deletion will be processed within 5 business days and
                you will receive an email confirming completion.</p>
                <p>Please enter the word 'delete' below and click the Delete button to confirm.</p>
            </div>

            <div className='deleteAccountInputBox'>
                <input onChange={this.onChangeConfirmBox} value={this.state.confirmBox} placeholder="Enter 'delete' here" />
            </div>

            <div className='deleteAccountPopupCancelButton'>
                <Button type='secondary' size='large' onClick={this.props.onClose} action='Cancel'/>
            </div>

            <div className='deleteAccountPopupDeleteButton'>
                <Button type='primary' size='large' onClick={this.onDeleteAccountClicked} action='Delete Account'/>
            </div>      
          </Popup>
        }
    </div>;
  }
};

export default DeleteAccountPopup;
