import React, { Component } from 'react';

import Button from '../ui/Button'
import Popup from 'reactjs-popup';
import PopupHeader from '../ui/PopupHeader'

import 'reactjs-popup/dist/index.css';

class CalendarEventPopup extends Component {

  constructor(props) {
      super(props);
      this.state = { 
        startTime : props.startTime,
        endTime : props.endTime
      };
  } 

  componentWillMount = () => {

  }

  onChangeStartTime = (e) => {
    this.setState({startTime : e.target.value })
  }

  onChangeEndTime = (e) => {
    this.setState({endTime : e.target.value })
  }

  onOK = () => {
    this.props.onSubmit(this.state.startTime, this.state.endTime)
  }

  onCancel = () => {
    this.props.onClose();
  }

  render = () => {
    return (
        <Popup open="true" closeOnDocumentClick className='calendar-event-popup' onClose={this.props.onClose}>
          <PopupHeader title={this.props.title}/>
          <div className='sectionTitle'>START TIME</div>
          <div className='calendarEventCard'>
            <input type='time' value={this.state.startTime} onChange={this.onChangeStartTime}/>
          </div>

          <div className='sectionTitle'>END TIME</div>
          <div className='calendarEventCard'>
            <input type='time' value={this.state.endTime} onChange={this.onChangeEndTime}/>
          </div>

          <div className='calendarEventPopupCancelButton'>
            <Button type='secondary' size='large' action='Cancel' onClick={this.onCancel}/>   
          </div>     

          <div className='calendarEventPopupOKButton'>
            <Button type='primary' size='large' action='OK' onClick={this.onOK}/>   
          </div>             
        </Popup>
    );
  }
};

export default CalendarEventPopup;
