import React, { Component } from 'react';

import Button from '../ui/Button'
import Popup from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';
import moment from 'moment';

import { fetchTeamFromServer } from '../util/DatabaseUtils';
import { askLumosForHabitCheckinFeedback } from '../util/FunctionUtils';
import { awardPoints } from '../util/PointsUtils';
import SpinnerPanel from '../ui/SpinnerPanel'
import { calculateCurrentStreak, calculateDaysDoneBeforeDate, calculateDaysDoneBeforeDateByDayOfWeek } from '../util/StatsUtils';

const calendarDateFormat = 'YYYY-MM-DD';

class CheckinConfirmationPopup extends Component {

  constructor(props) {
      super(props);

      this.state = {
        showLumos: true,
        dataLoaded: false,
        streak: calculateCurrentStreak(this.props.challengeId, this.props.userData, this.props.date), 
        habitDone: calculateDaysDoneBeforeDate(this.props.challengeId, this.props.userData, this.props.date)+1,
        habitDoneByDayOfWeek: this.getHabitDoneByDayOfWeek(),
      };
  } 

  
  checkDataLoaded = () => {
    if (this.state.showLumos && this.props.firebase.user && !this.state.dataLoaded) {
      askLumosForHabitCheckinFeedback(this.props.firebase.user.uid, this.props.challengeId, this.props.date, (results) => {
        this.setState({ dataLoaded: true, lumosResponse: results.reply })
      })
    }
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  componentWillUnmount() {}

  getHabitDoneByDayOfWeek = () => {
    var habitDoneByDayOfWeek = calculateDaysDoneBeforeDateByDayOfWeek(this.props.challengeId, this.props.userData, this.props.date)

    var dayOfWeek = moment(this.props.date, calendarDateFormat).format('ddd');
    if (!(dayOfWeek in habitDoneByDayOfWeek))
      habitDoneByDayOfWeek[dayOfWeek] = 1
    else
      habitDoneByDayOfWeek[dayOfWeek] += 1

    return habitDoneByDayOfWeek
  }

  getCheckinEventName = (streak) => {
     if (streak < 5)
        return "checkin"
     else if (streak < 10) 
        return "fiveStreak"
     else if (streak < 20) 
        return "tenStreak"
     else if (streak < 50) 
        return "twentyStreak"
     else 
        return "fiftyStreak"
   }


  getWellDoneMessage = () => {
    var isLastDay = (this.props.challenge.endDate === moment().format(calendarDateFormat))
    var streak = this.state.streak + 1;

    if (isLastDay)
      return <div className='checkinConfirmWellDoneMessage'><b>Contratulations!</b> You make it to the end of your goal period.</div>
    else if (this.state.habitDone === 1)
      return <div className='checkinConfirmWellDoneMessage'><b>Great!</b> You are on your way!</div>
    else if (streak === 1)
      return <div className='checkinConfirmWellDoneMessage'><b>Well done!</b> You missed a day but you're back at it.</div>
    else if (streak % 5 === 0)
      return <div className='checkinConfirmWellDoneMessage'><b>Contratulations!</b> You've hit a new streak milestone.</div>
    else
      return <div className='checkinConfirmWellDoneMessage'><b>Bravo!</b> You've been able to stick with the habit.</div>
  }

  getWellDoneComment = () => {
    var isLastDay = (this.props.challenge.endDate === moment().format(calendarDateFormat))
    var streak = this.state.streak + 1;

    var periodName = this.props.challenge.period + "s"

    if (isLastDay)
      return <div className='checkinConfirmWellDoneComment'>We hope that Lasoo helped to ingrain your habit</div>
    else if (this.state.habitDone === 1)
      return <div className='checkinConfirmWellDoneComment'>Continue to check off the habit and journal your experience!</div>
    else if (streak === 1)
      return <div className='checkinConfirmWellDoneComment'>Continue to check off the habit to extend your streak.</div>
    else if (streak % 5 === 0)
      return <div className='checkinConfirmWellDoneComment'>You're on fire, you have a streak of <b>{streak} {periodName}</b>. Keep going to extend your streak!</div>
    else
      return <div className='checkinConfirmWellDoneComment'>You've successfully completed the habit the last <b>{streak} {periodName}</b>. Keep it up!</div>
  }

  displayLumos = () => {
    return <div className='checkinConfirmLumosContainer'>
      <div className='checkinConfirmLumosAvatar'><img width="40" height="40" class="lumosAvatar" src="/illustration/lumos.png"/></div>

        { !this.state.dataLoaded && <div className='checkinConfirmLumosSpinner'><SpinnerPanel size="50"/></div> }
        { this.state.dataLoaded && <div className='checkinConfirmLumosResponse'>{this.state.lumosResponse}</div> }
    </div>
  }

  displayWellDoneMessageAndComment = () => {
    return <div>
      { this.getWellDoneMessage() }
      { this.getWellDoneComment() }
    </div>
  }

  renderCheckinByDayOfWeek = () => {  
    const daysOfWeek = [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ];

    const daysOfWeekReps = [];
    const daysOfWeekNames = [];

    for (var i = 0; i < daysOfWeek.length; i++) {
      if (daysOfWeek[i] in this.state.habitDoneByDayOfWeek) {
        daysOfWeekReps.push(
          <td className='checkinConfirmCheckinsByDayOfWeekReps' key={i}>{this.state.habitDoneByDayOfWeek[daysOfWeek[i]]}</td>)
        if (i < daysOfWeek.length - 1) 
          daysOfWeekReps.push(<td className='checkinConfirmCheckinsByDayOfWeekSeparator' key={i + 7}>&nbsp;</td>)
      }
    }

    for (var i = 0; i < daysOfWeek.length; i++) {
      if (daysOfWeek[i] in this.state.habitDoneByDayOfWeek) {
        daysOfWeekNames.push(<td className='checkinConfirmCheckinsByDayOfWeekName' key={i}>{daysOfWeek[i]}</td>)
        if (i < daysOfWeek.length - 1) 
          daysOfWeekNames.push(<td className='checkinConfirmCheckinsByDayOfWeekSeparator' key={i + 7}>&nbsp;</td>)
      }
    }

    return <div className='checkinConfirmCheckinsByDayOfWeek'>
      <table className='checkinConfirmCheckinsByDayOfWeekCellContainer'>
        <tr>{daysOfWeekReps}</tr>
        <tr>{daysOfWeekNames}</tr>
      </table>
    </div>
  }

  render = () => {
    const habitDescription = (this.state.habitDone > 1) ? this.state.habitDone + " CHECKINS AND COUNTING!" : "YOUR FIRST CHECKIN!";

    return <Popup open="true" closeOnDocumentClick className='checkin-confirm-popup' onClose={() => {
        this.props.onClose()
      
        awardPoints(this.props.firebase, this.props.userData.team, this.props.team, this.props.firebase.user.uid, this.props.firebase.user.email, this.getCheckinEventName(this.state.streak + 1), (results) => {
          // This is necessary to make sure we get the user latest level and points

          fetchTeamFromServer(this.props.firebase, this.props.userData.team, (team) => {})
        }) 
      }}>

      <div className=''></div>
        <img className='checkinConfirmRibbon' src="/grey_ribbon.png"/>
        <img className='checkinConfirmPinkStar' src="/pink_star.png"/>
        <div className='checkinConfirmCheckinsCounter'>{this.state.habitDone}</div>
        <div className='checkinConfirmHabitName'>{this.props.challenge.name}</div>
        <div className='checkinConfirmCheckinsDescription'>{habitDescription}</div>

        { this.renderCheckinByDayOfWeek() }

        { this.state.showLumos &&  this.displayLumos() }
        { !this.state.showLumos && this.displayWellDoneMessageAndComment() }

        <div className='checkinConfirmViewReportButton'>
            <Button size='large' type='primary' onClick={() => {
              awardPoints(this.props.firebase, this.props.userData.team, this.props.team, this.props.firebase.user.uid, this.props.firebase.user.email, this.getCheckinEventName(this.state.streak + 1), (results) => {
                // This is necessary to make sure we get the user latest level and points
        
                fetchTeamFromServer(this.props.firebase, this.props.userData.team, (team) => {})
              })     

              this.props.onViewReport(this.props.challengeId)
 
            }}
            action="View Report"/>
        </div>  

        <div className='checkinConfirmViewBackToHomeButton'>
        <Button size='large' type='secondary' onClick={() => {
              this.props.onClose(true)

              awardPoints(this.props.firebase, this.props.userData.team, this.props.team, this.props.firebase.user.uid, this.props.firebase.user.email, this.getCheckinEventName(this.state.streak + 1), (results) => {
                // This is necessary to make sure we get the user latest level and points
        
                fetchTeamFromServer(this.props.firebase, this.props.userData.team, (team) => {})
              })
      
            }}
            action="Back to Home"/>

        </div>

    </Popup>
  }
};

export default CheckinConfirmationPopup;
