import React, { Component } from 'react';

const INITIAL_STATE = {

};

/*
 * Props 
 *   colour = hex
 */
class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  } 

  render = () => {
    return ( 
      <svg width="167" height="209" viewBox="0 0 167 209" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 14C0 6.26801 6.26801 0 14 0H153C160.732 0 167 6.26801 167 14V201.934C167 201.974 166.976 202.009 166.939 202.023L156.079 206.185C149.781 208.599 142.731 208.049 136.883 204.687L135.91 204.128C129.346 200.355 121.233 200.501 114.807 204.504V204.504C108.387 208.501 100.283 208.648 93.7267 204.879V204.879C87.3947 201.239 79.5066 201.296 73.1747 204.936V204.936C66.741 208.634 58.6937 208.552 52.3944 204.629V204.629C45.8782 200.571 37.6218 200.571 31.1056 204.629L30.4056 205.065C24.4357 208.783 17.023 209.339 10.5648 206.555L0.053214 202.023C0.0209189 202.009 0 201.977 0 201.942V14Z" fill="#F1F5F9"/>
      </svg>      
    )
  }
}

export default Banner;