import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import GoalForm from '../goals/GoalForm';

import MessagePopup from '../ui/MessagePopup'

import { sendCalendarEventsFunction, sendSharedGoalInvitesFunction } from '../util/FunctionUtils';

import { activityNewGoal } from '../activity/ActivityUtils';
import { challengeStatsUpdate } from '../util/StatsUtils';
import { getUserAndTeam } from '../util/DatabaseUtils'
import { sendImmediateNudgesFunction, sendBadge } from '../util/FunctionUtils';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { awardPoints } from '../util/PointsUtils';

const calendarDateFormat = 'YYYY-MM-DD';

class AddSelectedHabit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataLoaded : false,
      name : '',
      category : '',
      period: 'day',
      objectives: null,
      categoryId : '',
      reward: '',
      icon: '',
      colour: '000000',
      programType: null,
      disableTeamVisibility: false,
    };
  }
  
  /**
   * This component is designed to be subclassed and configured by overriding the 
   * following three methods
   */
  getTab = () => {
    return "Today";
  }

  navigateAway = () => {
    alert("Navigate away")
    this.props.history.push("/");
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && this.props.match.params.habit && !this.state.dataLoaded) {

      getUserAndTeam(this.props.firebase, (user, team) => {
        var habit = {};
        if (team && team.habits && team.habits[this.props.match.params.habit]) {
          var habit = team.habits[this.props.match.params.habit];
          var category = team['habit-categories'][habit.category];

          this.setState({
            user: user,
            team: team,
            dataLoaded: true,
            name: habit.name,
            objectives: (user.objectives) ? user.objectives: null,
            category: (category) ? category.name : "",
            categoryId: habit.category,
            icon: habit.icon,
            colour: habit.colour,
            programType: team.settings['program-type'],
            disableTeamVisibility : (team.settings.disableTeamVisibility) ? true: false,
          });
        }
        else {
          alert("Invalid habit id")
        }
      })
    }
  } 

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  signout = () => this.props.firebase.doSignOut().then(() => this.forceUpdate())
 
  addHabit = (objective, habit, name, category, period, selectedDays, startDate, endDate, colour, reminders, calendarEvents, teamMembers, visibility, showCreationConfirmPopup) => {
    var challengeId = 'challenge-' + uuidv4();
    var challenge = {
      objective: objective,
      habit: habit,
      name: name,
      category: category,
      startDate: startDate,
      period: period,
      daysOfWeek: selectedDays,
      reminders: reminders,
      calendarEvents: calendarEvents,
      teamMembers: teamMembers,
      colour: colour,
      visibility: visibility
    }

    if (endDate) {
      challenge.endDate = endDate;
    }

    const isFirstChallenge = !this.state.user.challenges;
    
    this.props.firebase.database.ref('users/' + this.props.firebase.user.uid + '/challenges/' + challengeId).set(challenge)
      .then(() => {

        if (isFirstChallenge) {
           sendImmediateNudgesFunction(this.props.firebase.user.email, this.state.user.team, "Set Habit", () => {})
        }

        awardPoints(this.props.firebase, this.state.user.team, this.state.team, this.props.firebase.user.uid, this.props.firebase.user.email, "addHabit", () => {})


        challengeStatsUpdate(this.props.firebase, this.props.firebase.user.uid, challengeId, () => {
          sendCalendarEventsFunction(this.props.firebase.user.uid, challengeId);
          sendSharedGoalInvitesFunction(this.props.firebase.user.uid, challengeId);
          activityNewGoal(this.props.firebase, this.props.firebase.user.uid, moment().format(calendarDateFormat), challengeId);
          showCreationConfirmPopup(challengeId, this.navigateAway)
        });

        var today = moment().format(calendarDateFormat);
        sendBadge(this.props.firebase.user.uid, today, () => {});
      })
      .catch((error) => {
        alert("Error writing document: ", error);
      });
  }

  getDefaultSelectedDays = () => {
    return [ ];
  }

  render = () => {
    var history = this.props.history;

    var todayFormatted = moment(new Date()).format(calendarDateFormat);
    var threeWeeksFormatted = moment(new Date()).add(3, 'weeks').format(calendarDateFormat)

    var title = (this.state.name) ? "Add '" + this.state.name + "'" : " ";

    return (
      <AppChrome title={title} history={history} requireLogin={true} team={this.state.team} firebase={this.props.firebase} back={true} tab={this.getTab()}>
          <div>
            <GoalForm history={history} firebase={this.props.firebase} actionName="Add habit" habit={this.props.match.params.habit} name={this.state.name} category={this.state.category} period='day' daysOfWeek={this.getDefaultSelectedDays()} startDate={todayFormatted} endDate={threeWeeksFormatted} colour={this.state.colour} calendarEvents={[]} teamMembers={[]} visibility={ this.state.disableTeamVisibility ? 'manager' : 'team' } programType={this.state.programType} disableTeamVisibility={this.state.disableTeamVisibility} onSubmission={this.addHabit}/>
          </div>
          { this.state.messagePopup
            ?
            <MessagePopup title='Add Team Habit' message={this.state.messagePopupMessage} onClose={this.state.messagePopupCallback} />
            :
            <div/>
          }
      </AppChrome>
    )
  }
}

export default AddSelectedHabit;