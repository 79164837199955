import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome'
import EditHabitForm from './EditHabitForm'
import SpinnerPanel from '../ui/SpinnerPanel'

import firebase from 'firebase/app'

import 'react-toggle/style.css';

import { getUserAndTeam, fetchTeamFromServer } from '../util/DatabaseUtils';

const INITIAL_STATE = {
  dataLoaded: false,

  name: "",
  videoURL: "",
  description: "",
  colour: '#FF0000',
  category: '',
  recommended: false,
  visible: true,
  order : '',

  updatingHabit: false,
  isContentAdmin : false
};

class EditHabitPost extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {

      getUserAndTeam(this.props.firebase, (user, team) => {
        var state = {
          teamId : user.team,
          team: team,
          isContentAdmin: (team['content-admins'] && team['content-admins'].indexOf(this.props.firebase.user.uid) >= 0) ? true : false,
          dataLoaded: true
        }

        if (this.props.match.params.habit && team.habits && team.habits[this.props.match.params.habit]) {
           var habit = team.habits[this.props.match.params.habit];
           state.name = habit.name;
           state.videoURL = (habit.videoURL) ? habit.videoURL : '';
           // We support quoted newlines because the Firebase Firestore editor does support entry of \n characters
           state.description = habit.description.replaceAll("\\n","\n"); 
           state.colour = habit.colour
           state.category = habit.category
           state.recommended = (habit.recommended) ? true : false
           state.order = (habit.order) ? habit.order.toString() : ''
           state.visible = (habit.visible) ? true : false
           state.creator = (habit.creator) ? habit.creator : user.uid
           state.creatorName = habit['creator-name'] ? habit['creator-name']  : user.displayName
           if (habit['creator-photo-url'])
              state.creatorPhotoURL = habit['creator-photo-url']
           else if (user.photoURL)
              state.creatorPhotoURL = user.photoURL
        }
        
        this.setState(state)
      });
    }
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  isAlphaNumeric = (ch) => {
    return (ch.match(/^[0-9a-zA-Z]+$/));
  }

  /* TODO. This needs to be improved! */

  generateHabitId = (name) => {
    var id = "";
    var nameTrimmed = name.trim();

    for (let i = 0; i < nameTrimmed.length; i++) {
       var ch = nameTrimmed[i];
       if (this.isAlphaNumeric(ch)) 
          id += ch.toLowerCase()
       else 
          id += "-";
    }

    return id;
  }

  onSubmit = (name, videoURL, description, colour, recommended, visible, order) => {
    this.setState({ updatingHabit: true })

    var habitId = this.props.match.params.habit;
    var user = this.props.firebase.user;

    // Update Team 'habits/id'

    var http = new XMLHttpRequest();

    // alert("Posting " + JSON.stringify(habit));
 
     var data = {
       name: name,
       videoURL: (videoURL) ? videoURL : '',
       description: description,
       colour: colour.replace('#', ''),
       category: this.state.category,
       recommended: recommended,
       visible: visible,
       creator: user.uid,
       'creator-name': user.displayName,
       teamId: this.state.teamId,
       habitId: habitId
     }

     if (order) {
        data.order = order
     }

     if (this.state.creator)
       data.creator = this.state.creator

    if (this.state.creatorName)
       data['creator-name'] = this.state.creatorName

    if (this.state.creatorPhotoURL)
       data['creator-photo-url'] = this.state.creatorPhotoURL
 
    if (user.photoURL) 
       data['creator-photo-url'] = user.photoURL
  
     var url = 'https://us-central1-lasoo-d9fad.cloudfunctions.net/updateHabit'
     http.open('POST', url, true);
     http.setRequestHeader('Content-type', 'application/json')
 
     //Send the proper header information along with the request
    // http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
 
     http.onreadystatechange = () => { // Call a function when the state changes.
      fetchTeamFromServer(this.props.firebase, this.state.teamId, (doc) => { 
         if (http.readyState == 4 && http.status == 200) {
            this.props.history.push("/habit/" + habitId)
         }
      })
    }

     http.send(JSON.stringify(data));
  }

  onDelete = () => {    
    this.setState({ updatingHabit: true })

    var fields = {}
    fields["habits." + this.props.match.params.habit] = firebase.firestore.FieldValue.delete();

    this.props.firebase.firestore
    .collection('teams')
    .doc(this.state.teamId)
    .update(fields)
    .then(() => {
        fetchTeamFromServer(this.props.firebase, this.state.teamId, (doc) => { 
          this.props.history.push("/category/" + this.state.category);
        });
    });

  }

  renderHabitForm = () => {
    var action = (this.props.match.params.habit) ? "Update habit" : "Add habit";

    if (!this.state.dataLoaded)
      return <div/>;
    else 
      return <EditHabitForm action={action} onSubmit={this.onSubmit} onDelete={this.onDelete}
                            videoURL={this.state.videoURL} 
                            category={this.state.category}
                            name={this.state.name} description={this.state.description} 
                            colour={this.state.colour} 
                            recommended={this.state.recommended}
                            visible={this.state.visible}
                            order={this.state.order}
                            allowEditOrder={this.state.isContentAdmin}
                            allowEditVisibility={false}
                            allowEditRecommended={false}
                            />
  }

  render = () => {
    var history = this.props.history;
    var title = "Edit Habit";
  
    return (
      <AppChrome title={title} history={history} requireLogin={true} team={this.state.team} firebase={this.props.firebase} back={true} tab="Discover">
        { this.state.updatingHabit ?
          <SpinnerPanel/>
          :
          <div>
            { this.renderHabitForm() } 
          </div>
        }
      </AppChrome>
    )
  }
}

export default EditHabitPost;