import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Button from '../ui/Button';
import SpinnerPanel from '../ui/SpinnerPanel';

import GoalReportComponent from './GoalReportComponent'
import GoalReportShareLinkPopup from './GoalReportShareLinkPopup';

import { getUser } from '../util/DatabaseUtils';
import { getGoalReportFunction } from '../util/FunctionUtils'; 
import moment from 'moment';

import ReloadDataCard from '../ui/ReloadDataCard';

const calendarDateFormat = 'YYYY-MM-DD';
const calendarDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

const INITIAL_STATE = {
  dataLoaded: false,
  displayShareLinkPopup: false,
  shareLink : "",
  isOffline: false
};

class GoalReport extends Component {

    constructor(props) {
       super(props);
       this.state = { ...INITIAL_STATE };
    }

    getTimestampSlot = (timestamp) => {
      var t = moment(timestamp, calendarDateTimeFormat)
      var hours = t.hours()

      if (hours >= 0 && hours <= 4) {
        return 0
      }
      else if (hours >= 5 && hours <= 7) {
        return 1
      }
      else if (hours >= 8 && hours <= 10) {
        return 2
      }
      else if (hours >= 11 && hours <= 13) {
        return 3
      } 
      else if (hours >= 14 && hours <= 16) {
        return 4
      }
      else if (hours >= 17 && hours <= 19) {
        return 5
      }
      else {
        return 6
      }
    }

    getGoalReportStatsAndActivity = (user, goalId, todayInUserTimezone) => {
      var activity = {};
      var bestStreak = 0;
      var currentStreak = 0;
      var completionsByDay = [ 0, 0, 0, 0, 0, 0, 0];
      var completionsByHour = [ 0, 0, 0, 0, 0, 0, 0, 0, 0 ];  
      var completionsByWeek = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];

      var numOfDays = 0;
      var numOfDaysTicked = 0;
      var numOfWeeks = 1;
      var numOfMonths = 1;

      var challenge = user.challenges[goalId]
      var lastTicked = null;
  
      var dateMoment = moment(challenge.startDate, calendarDateFormat);
      var startDateMoment = moment(challenge.startDate, calendarDateFormat);
      var today = moment(todayInUserTimezone, calendarDateFormat)
      var endDateMoment = (challenge.endDate) ? moment(challenge.endDate, calendarDateFormat) : today;

      var startOfWeekMoment = today.clone().startOf('week')

      while (dateMoment <= endDateMoment && dateMoment.format(calendarDateFormat) <= today.format(calendarDateFormat)) {
          var dateFormatted = dateMoment.format(calendarDateFormat);

          if (dateMoment > startDateMoment) {
            // Sunday is the first day of the week

            if (dateMoment.day() === 0) {
              numOfWeeks += 1;
              if (challenge.period === 'week' && (!lastTicked || dateMoment.clone().subtract(7, 'days').format(calendarDateFormat) > lastTicked)) { 
                currentStreak = 0
              }

              if (challenge.period === 'month' && (!lastTicked || dateMoment.clone().subtract(1, 'months').format(calendarDateFormat) > lastTicked)) { 
                currentStreak = 0
              }
            }

            if (dateMoment.date() == 1)
              numOfMonths += 1;
          }

          if (user.days && dateFormatted in user.days && goalId in user.days[dateFormatted]) {
              numOfDays += 1;
              activity[dateFormatted] = user.days[dateFormatted][goalId]

              if (user.days[dateFormatted][goalId].done) {
                numOfDaysTicked += 1;

                lastTicked = dateFormatted
                
                completionsByDay[dateMoment.day()] += 1
                
                if (user.days[dateFormatted][goalId].timestamp) {
                  var slot = this.getTimestampSlot(user.days[dateFormatted][goalId].timestamp)
                  completionsByHour[slot] += 1
                }  
                else 
                  completionsByHour[3] += 1;

                const daysSinceStartOfWeek = startOfWeekMoment.diff(dateMoment, 'days');

                if (daysSinceStartOfWeek <= 0)
                  completionsByWeek[completionsByWeek.length - 1] += 1
                else {
                  const weeksAgo = Math.floor(daysSinceStartOfWeek / 7);
                  if (weeksAgo <= completionsByWeek.length - 2)
                    completionsByWeek[completionsByWeek.length - (weeksAgo + 2)] += 1
                }
              }
              
              if (challenge.period !== 'day' || (challenge.daysOfWeek && challenge.daysOfWeek.indexOf(dateMoment.format('ddd')) >= 0)) { 
                currentStreak += 1
                if (currentStreak > bestStreak)
                    bestStreak = currentStreak
            }

          }
          else if (dateMoment < today) {
            if (challenge.period ==='day' && challenge.daysOfWeek.indexOf(dateMoment.format('ddd')) >= 0) { 
              numOfDays += 1;
              currentStreak = 0
            }
          } 
          
          dateMoment.add(1, 'days');
      }

      if (currentStreak > bestStreak)
          bestStreak = currentStreak
  
      var completionRate = 0;
      
      if (challenge.period === 'day')
        completionRate = (numOfDays > 0) ? Math.round(100 * (numOfDaysTicked / numOfDays)): 0;
      else if (challenge.period === 'week')
        completionRate = Math.round(100 * (numOfDaysTicked / numOfWeeks));
      else if (challenge.period === 'month')
        completionRate = Math.round(100 * (numOfDaysTicked / numOfMonths));
        

      var startOfWeeks = [];
      for (var i = completionsByWeek.length-1; i >= 0; i--) {
        startOfWeeks.push(startOfWeekMoment.clone().subtract(i, 'weeks').format('D MMM'))
      }
      
      return {
          stats : {
              completionRate: completionRate,
              completions: numOfDaysTicked,
              numOfDays: numOfDays,
              bestStreak: bestStreak,
              completionsByDay: completionsByDay,
              completionsByHour: completionsByHour,
              completionsByWeek: completionsByWeek,
              startOfWeeks : startOfWeeks,
          },
          activity : activity
      }
    }

    getReportUserId = () => {
      return (this.props.match.params.member) ? this.props.match.params.member : this.props.firebase.user.uid;
    }

    checkDataLoaded = () => {
      if (this.props.firebase.user && !this.state.dataLoaded && this.props.match.params.goal) {

        var reportUserId = this.getReportUserId()

          // If the user is the logged in user, then we pull the data locally
          // otherwise we request it from the server
        if (reportUserId == this.props.firebase.user.uid) {
          getUser(this.props.firebase, (user) => {
            var today = moment().format(calendarDateFormat);
            var statsAndActivity = this.getGoalReportStatsAndActivity(user, this.props.match.params.goal, today);
            var challenge = user.challenges[this.props.match.params.goal]

            var habit = {
              habit: (challenge.habit) ? challenge.habit : null,
              name: challenge.name,
              category: challenge.category,
              colour: challenge.colour,
              startDate: challenge.startDate,
              daysOfWeek: challenge.daysOfWeek
            }

            if (challenge.endDate)
              habit.endDate = challenge.endDate

            if (challenge.endJournal)
              habit.endJournal = challenge.endJournal

            if (challenge.recommend)
              habit.recommend = challenge.recommend

            if (challenge.ingrained)
              habit.ingrained = challenge.ingrained

            if (challenge.objective) {
              habit.objective = user.objectives[challenge.objective].name
            }

            this.setState({
              dataLoaded: true,
              report: {
                today: today,
                user: {
                  name: this.props.firebase.user.displayName,
                  photoURL : (this.props.firebase.user.photoURL) ? this.props.firebase.user.photoURL : null
                },
                habit: habit,
                stats: statsAndActivity.stats,
                activity: statsAndActivity.activity
              },
            });
          },
          () => {
            this.setState({ isOffline: true })
          });
        }
        else {
          getGoalReportFunction(reportUserId, this.props.match.params.goal, (report) => {
            this.setState({
              dataLoaded: true,
              report: report,
            });
          })
        }
      }
    }

    componentWillMount = () => {
      this.checkDataLoaded();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
      this.checkDataLoaded();
    }

    componentWillUnmount() {
    }   

    headerClicked = () => {
      if (this.state.report.habit.habit)  
        this.props.history.push("/habit/" + this.state.report.habit.habit)
    }

    displayShareLinkPopup = (link) => {
      this.setState({
        displayShareLinkPopup: true,
        shareLink: link
      })
    }

    closeShareLinkPopup = () => {
      this.setState({ 
        displayShareLinkPopup: false
      })
    }

    shareReport = () => {      
      var report = {
        userId: this.props.firebase.user.uid,
        goalId: this.props.match.params.goal
      }

      this.props.firebase.firestore.collection("reports").add(report)
      .then((doc) => {
        this.displayShareLinkPopup("https://app.lasoohabits.com/report/" + doc.id)
      })
    }

    getBackURL = () => {
      if (this.props.location.pathname.startsWith("/goal-report-activity"))
        return "/activity";
      else if (this.props.location.pathname.startsWith("/goal-report-home"))
        return "/"
      else if (this.props.location.pathname.startsWith("/goal-report-member-activity"))
        return "/team-member-activity/" + this.props.match.params.member
      else if (this.props.location.pathname.startsWith("/goal-report-member"))
        return "/team-member/" + this.props.match.params.member
      else if (this.props.location.pathname.startsWith("/goal-report-profile"))
        return "/profile"
      else 
        return "/goals";
    }

    getTab = () => {
      if (this.props.location.pathname.startsWith("/goal-report-activity") || this.props.location.pathname.startsWith("/goal-report-member-activity") || this.props.location.pathname.startsWith("/goal-report-home"))
        return "Activity";
      else if (this.props.location.pathname.startsWith("/goal-report-member") || this.props.location.pathname.startsWith("/goal-report-profile"))
        return "Today"
      else
        return "Goals";
    }

    refresh = () => {
      this.setState({ isOffline: false })
      this.checkDataLoaded();
    }

    render = () => {
      var history = this.props.history;
      var title = (this.state.dataLoaded && this.state.report.user) ? this.state.report.user.name : " ";
      
      return (
        <AppChrome title={title} back={this.getBackURL()} team={this.state.team} history={history} requireLogin={true} firebase={this.props.firebase} tab={this.getTab()}>
            { this.state.isOffline ?
              <ReloadDataCard firebase={this.props.firebase} message="Could not load goal report." onClick={this.refresh}/>
              :
              !this.state.dataLoaded ?
              <div className='goalReportCenter'>
                <SpinnerPanel/>
              </div>
              :
              <div>
                <GoalReportComponent report={this.state.report} history={this.props.history}/>                  
              </div>
            }

            { this.state.dataLoaded && this.getReportUserId() === this.props.firebase.user.uid ?
                <div><Button type='secondary' size='large' action='Share Report' onClick={this.shareReport}/><br/></div>
                :
                <div/>
            }

            { this.state.dataLoaded && this.state.displayShareLinkPopup ?
              <GoalReportShareLinkPopup link={this.state.shareLink} onClose={this.closeShareLinkPopup}/>
              :
              <div/>
            }
        </AppChrome>
      )      
    }
}

export default GoalReport;