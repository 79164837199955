import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';

import EditObjectivePopup from './EditObjectivePopup';
import Button from '../ui/Button'

import moment from 'moment';

import AskLumosPopup from '../lumos/AskLumosPopup'
import LumosTouchpoint from '../lumos/LumosTouchpoint'

import { v4 as uuidv4 } from 'uuid';
import { getUserAndTeam } from '../util/DatabaseUtils'
import SpinnerPanel from '../ui/SpinnerPanel';

import { isSuperAdmin, isSuperAdminHomeTeam } from '../util/SwitchUtils'
import ReloadDataCard from '../ui/ReloadDataCard';

import { getPercentageTicked } from '../util/ChallengeUtils'

const calendarDateFormat = 'YYYY-MM-DD';

const INITIAL_STATE = {
  dataLoaded: false,
  creatingObjective: false,
  showAddObjectivePopup: false,
  showAskLumosPopup: false,
  isOffline: false
};

class Objectives extends Component {

    constructor(props) {
       super(props);
       this.state = { ...INITIAL_STATE };
    }

    checkDataLoaded = () => {
      if (this.props.firebase.user && !this.state.dataLoaded) {

        getUserAndTeam(this.props.firebase, (user, team) => {
          if (!user || !user.team) {
            this.props.history.push("/create-team")
            return
          }

          this.setState({
            data: user,
            team: team,
            dataLoaded: true,
            isOffline: false
          });
        },
        () => {
          this.setState({
            isOffline: true
          });
        })
      }
    }

    componentWillMount = () => {
      this.checkDataLoaded();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
      this.checkDataLoaded();
    }

    componentWillUnmount() {
    }

    refresh = () => {
      this.setState({ isOffline: false, dataLoaded: false })
      this.checkDataLoaded();
    }

    renderLoading = () => {
      return <span className='loading'>Loading...</span>
    }

    renderObjectivesPlaceholder = () => {
      const items = []

      for (var i = 1; i <= 3; i++)
        items.push(<div className='objectiveCardPlaceholder' />)

      return <div>{items}</div>;
    }

    objectiveClicked = (objectiveId) => {
      this.props.history.push('/goal/'+ objectiveId);
    }

    isChallengeActive = (challengeId) => {
      var challenge = this.state.data.challenges[challengeId];
      var todayMoment = moment();

      return (!challenge.endDate || challenge.endDate > todayMoment.format(calendarDateFormat))
    }

    getCurrentHabits = (objectiveId) => {

      var habits = [];

      if (this.state.data.challenges) {    
        Object.keys(this.state.data.challenges).forEach( (challengeId) => {
          if (this.isChallengeActive(challengeId) && this.state.data.challenges[challengeId].objective === objectiveId)
            habits.push({ id: challengeId, name: this.state.data.challenges[challengeId].name, challenge: this.state.data.challenges[challengeId]});
        });
      }

      return habits
    }

    getCurrentHabitCount = (objectiveId) => {

      var habitCount = 0;
        
      Object.keys(this.state.data.challenges).forEach( (challengeId) => {
        if (this.isChallengeActive(challengeId) && this.state.data.challenges[challengeId].objective === objectiveId)
          habitCount += 1;
      });

      return habitCount
    }

    renderObjectiveHabits = (habits) => {
      const items = []

      if (habits.length === 0) {
        return <table className='objectiveCardHabit'>
          <tr><td><div className='objectiveCardNoHabits'>No current habits</div></td></tr>
        </table>;
      }

      var habitCount = 0;
      habits.forEach( (habit) => {
        habitCount += 1;

        var completion = getPercentageTicked(habit.id, habit.challenge, this.state.data);

        items.push(
        <table className='objectiveCardHabit'>
          <tr>
          <td className='objectiveCardHabitTickCell'><div className='objectiveCardHabitTickContainer' style={{ background: '#' + habit.challenge.colour }}><img className='objectiveCardHabitTickImage' src='/icons/tick-symbol.png'/></div></td>
          <td className='objectiveCardHabitName'>{habit.name}</td>
          <td className='objectiveCardHabitCompletion'>{completion}%</td>
          </tr>
        </table>)
      });
  
      return <div>{items}</div>;
    }

    renderObjective = (objectiveId, objective) => {

      var habits = this.getCurrentHabits(objectiveId)
      habits.sort((h1, h2) => {
        if (h1.name < h2.name)
          return -1;
        else if (h1.name > h2.name)
          return 1;
        else
          return 0;
      })

      return <div className='objectiveCard' style={{ borderLeft: "4px solid #" + objective.colour }} onClick={this.objectiveClicked.bind(this, objectiveId)}>
        <div className='objectiveCardName'><div className="objectiveCardObjectiveName" style={{ color: "#" + objective.colour }}>{objective.name}</div></div>
        <div className='objectiveCardHabits'>{this.renderObjectiveHabits(habits)}</div>
      </div>;
    }

    showAskLumosPopup = () => {
      this.setState({ showAskLumosPopup: true })
    }

    closeAskLumosPopup = () => {
      this.setState({ showAskLumosPopup: false })
    }

    addObjectiveClicked = () => {
      this.setState({ showAddObjectivePopup: true })

    }
    
    addObjective = (name, colour) => {
      this.setState({ showAddObjectivePopup: false, creatingObjective: true })

      var objectiveId = "objective-" + uuidv4();

      var objective = {
        name: name,
        colour: colour,
        createdDate: moment().format(calendarDateFormat)
      }

      this.props.firebase.database.ref('users/' + this.props.firebase.user.uid + '/objectives/' + objectiveId).set(objective)
      .then(() => {
        this.setState({ creatingObjective: false, dataLoaded: false })
        this.checkDataLoaded()
      })
    }

    closeAddObjective = () => {
      this.setState({ showAddObjectivePopup: false })
    }

    getObjectiveNamesAndIds = () => {
      var objectiveNamesAndIds = {};

      Object.keys(this.state.data.objectives).forEach( (objectiveId) => {
        var objectiveName = this.state.data.objectives[objectiveId].name
        objectiveNamesAndIds[objectiveName + " " + objectiveId] = objectiveId;  
      });

      return objectiveNamesAndIds;
    }

    isObjectiveActive = (objectiveId) => {
      var objective = this.state.data.objectives[objectiveId];
      var todayMoment = moment();

      return (!objective.endedDate)
    }

    renderActiveObjectives = (objectiveNamesAndIds) => {
      var items = [];
      var first = true;
  
      Object.keys(objectiveNamesAndIds).sort().forEach( (objectiveNameAndId) => {
          var objectiveId = objectiveNamesAndIds[objectiveNameAndId];

          if (this.isObjectiveActive(objectiveId)) {
            if (first) {
            //  items.push(<div className='sectionTitle'>GOALS</div>);
              first = false;
            }
            items.push(this.renderObjective(objectiveId, this.state.data.objectives[objectiveId]));
          }
      });
      return <div>{items}</div>;
    }


    renderObjectives = () => {
      var items = [];

      items.push(<LumosTouchpoint description='Do you need help coming up with goals?' onClick={this.showAskLumosPopup}/>)

      if (this.state.isOffline) {
        items.push(<ReloadDataCard firebase={this.props.firebase} message='Could not load goals.' onClick={this.refresh}/>)
      }
      else if (!this.state.dataLoaded)
        items.push(this.renderObjectivesPlaceholder());

      if (!this.state.isOffline && this.state.dataLoaded && isSuperAdmin(this.props.firebase.user) && !isSuperAdminHomeTeam(this.props.firebase.user, this.state.data.team))
        items.push(<div><p>Please switch to your home team to view your goals.</p></div>)
      else if (this.state.dataLoaded) {
        if (this.state.data && this.state.data.objectives) {
            var objectiveNamesAndIds = {};

            Object.keys(this.state.data.objectives).forEach( (objectiveId) => {
              var objectiveName = this.state.data.objectives[objectiveId].name
              objectiveNamesAndIds[objectiveName + " " + objectiveId] = objectiveId;  
            });
            items.push(this.renderActiveObjectives(objectiveNamesAndIds));
        }

        if (items.length === 0) {
          items.push(
            <div className='habitActivityEmptyStateCard'>
              <img src='/illustration/home-empty-state.png' className='habitActivityEmptyStateImage'/>
              <div className='habitActivityEmptyStateMessage'>You have no active goals</div>
            </div>);
        }

        items.push(<p><Button type='secondary' size='large' onClick={this.addObjectiveClicked} action='Add a new goal'/></p>);
      }

      return <div>{items}</div>
    }

    render = () => {
      var user = this.props.firebase.user
      var history = this.props.history;

      if (false && user && !user.emailVerified)
        this.props.history.push("/verify-email");
      
      return (
        <AppChrome title="" history={history} requireLogin={true} firebase={this.props.firebase} team={this.state.team} tab="Habits">
          { this.state.creatingObjective ? 
            <SpinnerPanel/> : 
            this.renderObjectives()
          }

            { !this.state.creatingObjective && this.state.showAddObjectivePopup ?
              <EditObjectivePopup newObjective={true} onAddOrUpdate={this.addObjective} onClose={this.closeAddObjective}/>
              :
              <div/>
          }

          { this.state.showAskLumosPopup ? 
            <AskLumosPopup firebase={this.props.firebase} onClose={this.closeAskLumosPopup}/>
            :
            <div/>
          }

        </AppChrome>
      )      
    }
}

export default Objectives;