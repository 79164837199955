import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';

import { getUserAndTeam } from '../util/DatabaseUtils'
import AvatarPile from '../ui/AvatarPile';

const INITIAL_STATE = {
  team : {},
  dataLoaded: false
};

class Onboarding1Step3 extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {
      getUserAndTeam(this.props.firebase, (user, team) => {
        this.setState({ 
          dataLoaded: true, 
          user: user,
          team: team, 
          habits: this.getSuggestedHabits(team) });
      })
    }
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  componentWillUnmount() {
  }

  continueClicked = () => {
    this.props.history.push("/onboarding-1-step-3");
  }

  getSuggestedHabits = (team) => {
    var habits = [];

    var userEmailKey = this.props.firebase.user.email.replaceAll(".", "_")
    var member = team.member[userEmailKey]

    if (team['recommended-habit']) {
      for (var recommendedHabit of team['recommended-habit']) {
        var habit = team.habits[recommendedHabit.id];
        if (!habit || !habit.visible) 
          continue

        if (recommendedHabit.circles && recommendedHabit.circles.length > 0 && member) {
          const recommendedHabitCircles = recommendedHabit.circles.split(',')
         
          // If the user is not in one of the circles then continue

          var found = false;
          for (var circle of recommendedHabitCircles) {

            if (member && member.circles && member.circles.indexOf(circle) >= 0) {
              found = true;
              break;
            }
          }

          if (!found) 
            continue;
        }
        
        habits.push({ habitId: recommendedHabit.id, habit : habit });
      }
    }

    /*
    habits.sort((h1, h2) => {
      if (h1.habit.name < h2.habit.name)
        return -1;
      else if (h1.habit.name > h2.habit.name) 
        return 1;
      else
        return 0;
    }) */

    return habits;
  }

  habitClicked = (habitId) => {
    this.props.history.push("/habit-onboarding-1/" + habitId);
  }

  findMember = (userId, team) => {
      for (var emailKey of Object.keys(team.member)) {
          if (team.member[emailKey]['user-id'] === userId) {
              return team.member[emailKey];
          }
      }
      return null
  }
  
  getTeamMembers = (habitId, team) => {
    var names = []
    var images = []

    if (team && team.stats && team.stats.habits && team.stats.habits[habitId] && team.stats.habits[habitId].users) {
      var users = team.stats.habits[habitId].users;

      for (var userId of Object.keys(users).sort()) {
        const member = this.findMember(userId, team);

        if (member) {
          names.push(member.name)
          images.push(member['photo-url'])
        }
      }
    }

    return {
      names : names,
      images : images
    }
  }

  renderOnboarding = () => {
    var items = [];

    items.push(<img class='curvesImage' src='/illustration/curves.png'/>);
    items.push(<div className='onboardingTitle'>Start simple</div>);
    items.push(<div className='onboardingContent'>Choose one of our recommended habits to get started.</div>);

    if (this.state.dataLoaded) {
      this.state.habits.map(h => {
        var habitId = h.habitId;
        var habit = h.habit;
        var categoryName = habit.category;
        var teamMembers = this.getTeamMembers(habitId, this.state.team);

        if (this.state.team && this.state.team['habit-categories'] && this.state.team['habit-categories'][habit.category])
          categoryName = this.state.team['habit-categories'][habit.category].name
        items.push(<div onClick={this.habitClicked.bind(this, habitId)} className='onboardingHabitCard' style={{ 'border-left': '4px solid #' + habit.colour}}>
          <div className='onboardingHabitCardCategory' style={{ 'color' : '#' + habit.colour}}>{categoryName}</div>
          <div className='onboardingHabitCardName'>{habit.name}</div>
          <div className='onboardingHabitCardAvatarPile'><AvatarPile names={teamMembers.names} images={teamMembers.images}/></div>
          <div className='onboardingHabitCardChevron'>›</div>

        </div>);
      })
    }
    else {
      for (var i = 1; i <= 3; i++) {
        items.push(<div className='onboardingHabitCardPlaceholder'/>);
      }
    }

    return items;
  }

  render = () => {
    var history = this.props.history;
    
    return (
      <AppChrome title="" history={history} requireLogin={true} firebase={this.props.firebase} back={true} tab="Today">
      {
          this.renderOnboarding()
      }
      </AppChrome>
    )
  }
}

export default Onboarding1Step3;