import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Button from '../ui/Button';
import QuotePanel from '../ui/QuotePanel';

const INITIAL_STATE = {
  data : {},
  dataLoaded: false
};

class Onboarding3Step1  extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = () => {

  }

  componentWillMount = () => {
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
  }

  componentWillUnmount() {
  }

  getTitle = () => {
     return " "; 
  }

  continueClicked = () => {
    this.props.history.push("/onboarding-3-step-2");
  }

  renderOnboarding = () => {
    var items = [];

    items.push(<QuotePanel quote="We are what we repeatedly do. Excellence, therefore, is not an act but a habit." author="Aristotle"/>);
    items.push(<img class='curvesImage' src='/illustration/curves.png'/>);
    items.push(<div className='onboardingTitle'><p>Welcome to Lasoo</p></div>);
    items.push(<div className='onboardingContent'>Lasoo helps you build routines<br/> and habits to reach your work objectives.</div>);
    items.push(<Button onClick={this.continueClicked} type='primary' size='large' action='Continue'/>);

    return items;
  }

  render = () => {
    var history = this.props.history;
    
    return (
      <AppChrome title="" history={history} requireLogin={true} firebase={this.props.firebase} tab="Today">
      {
          this.renderOnboarding()
      }
      </AppChrome>
    )
  }
}

export default Onboarding3Step1;