import React, { Component } from 'react';

import Button from '../ui/Button'
import MessagePopup from '../ui/MessagePopup';
import PopupHeader from '../ui/PopupHeader';

import moment from 'moment';
import Popup from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';
import TokenInput from 'react-customize-token-input';
import 'react-customize-token-input/dist/react-customize-token-input.css'

import { getUser } from '../util/DatabaseUtils';

const INITIAL_STATE = {
  emails : [],
  showMessage : false,
  showMessageCallback: null,
  showMessageText : '',
  data: null,
  dataLoaded: false
};

class InviteUsersPopup extends Component {

  constructor(props) {
      super(props);
      this.state = INITIAL_STATE;
  } 


  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {
      getUser(this.props.firebase, (user) => {
        this.setState({
          data: user,
          dataLoaded: true
        });
      })
    }
  }

  displayMessage = (message, callback) => {
    this.setState({
      showMessage : true,
      showMessageText : message,
      showMessageCallback : callback
    })
  }

  closeMessage = () => {
    this.setState({
      showMessage : false
    })
  }

  handleTokenValuesChange = (tokens) => {
    this.setState({ emails : tokens });
  }

  doSend = () => {
    if (this.state.emails.length === 0) {
      this.displayMessage('Please enter some teammates to invite', this.closeMessage);
      return;
    }

    for (var i = 0; i < this.state.emails.length; i++) {
      var email = this.state.emails[i];

      if (!this.validateEmail(email)) {
        this.displayMessage("'" + email + "' is not a valid email address", this.closeMessage);
        return;
      }
    }

    // First we need to look up the team record

    if (this.props.firebase.user && this.state.dataLoaded && this.state.data.team) {
      var team = this.state.data.team;
      var user = this.props.firebase.user;

      this.props.firebase.firestore
      .collection('teams')
      .doc(team)
      .get()
      .then((doc) => {
        if (doc.exists) {
          var teamName = (doc.data().name) ? doc.data().name : '';

          var invite = {
            'created-time': moment().format(),
            'inviter-id': user.uid, 
            'inviter-name': user.displayName,
            'inviter-email': user.email,
            'team': team,
            'team-name': teamName,
            'invitee-email': '',
            'invitee-accepted': false
          };

          var teamData = doc.data();
          if (teamData && teamData.settings && teamData.settings.defaultObjective && teamData.settings.defaultObjective.length > 0) {
            invite['default-objective'] = teamData.settings.defaultObjective;
          }
 
          for (var i = 0; i < this.state.emails.length; i++) {
            invite['invitee-email'] = this.state.emails[i];
            
            this.props.firebase.firestore.collection("invitations").add(invite)
            .then((doc) => {
            })
            .catch((error) => {
              alert('Error creating invite: ' + error);
             });
          }
    
          this.displayMessage('Invites have been sent', this.props.onClose);
        }
        else {
           // A user should always be loaded and they should have a team so this should not happen
          this.displayMessage('Could not send invites');
        }
      })
      .catch((error) => {
          alert('Error getting document' + error);
      });
    }
  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateToken = (tokenValue, tokenIndex, tokenValues) => {
    if (!this.validateEmail(tokenValue))
      return "Invalid email address";

    return undefined;
  }

  getTokenErrorMessage = (error) => {
    return 'Invalid token';
  }

  render = () => {
    var challenge = this.props.challenge;

    return <div>
        { this.state.showMessage 
          ?
          <MessagePopup title='Invite Teammates to Lasoo' message={this.state.showMessageText} onClose={this.state.showMessageCallback}/>
          :
          <Popup open="true" closeOnDocumentClick className='invite-users-popup' onClose={this.props.onClose}>
                <PopupHeader title='Invite Teammates to Lasoo'/>
                <TokenInput className="inviteUsersPopupEmailInput" onTokenValueValidate={this.validateToken} placeholder="name@lasoo.com" tokenValues={this.state.emails} onTokenValuesChange={this.handleTokenValuesChange} />
                <div className='inviteUsersPopupCancelButton'>
                  <Button type='secondary' size='large' onClick={this.props.onClose} action='Cancel'/>
                </div>
                <div className='inviteUsersPopupSendButton'>
                  <Button type='primary' size='large' onClick={() => this.doSend()} action='Send'/> 
                </div>   
          </Popup>
        }
    </div>;
  }
};

export default InviteUsersPopup;
