import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Avatar from '../ui/Avatar';
import Button from '../ui/Button';
import InviteUsersPopup from '../layout/InviteUsersPopup';

import { getUserAndTeam } from '../util/DatabaseUtils';

import { calculateLevel } from '../util/PointsUtils';

const INITIAL_STATE = {
  data : {},
  dataLoaded: false,
  showInviteUsersPopup: false
};

class TeamMembers extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {

      getUserAndTeam(this.props.firebase, (user, team) => {
        this.setState({ dataLoaded : true, user: user, team : team })
      });

    }
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  componentWillUnmount() {
  }

  onClick = (userId) => {
    this.props.history.push("/team-member/" + userId);
  }

  showInviteUsersPopup = () => {
    this.setState({ showInviteUsersPopup : true });
  }

  closeInviteUsersPopup = () => {
   this.setState({ showInviteUsersPopup : false });
  }
   

  getTeamMembersOrdered = (showPoints) => {
    var teamMembers = [];

    for (var emailKey of Object.keys(this.state.team.member)) {
      if (!this.state.team.member[emailKey].disabled && this.state.team.member[emailKey]['user-id']) {
        teamMembers.push({
          userId: this.state.team.member[emailKey]['user-id'],
          member: this.state.team.member[emailKey],
          level: calculateLevel(this.state.team, this.state.team.member[emailKey]['user-id']),
        })
      }
    }

    teamMembers.sort((m1, m2) => {
      if (showPoints) {
        const m1Points = m1.member.points ? parseInt(m1.member.points) : 0;
        const m2Points = m2.member.points ? parseInt(m2.member.points) : 0;

        if (m1Points > m2Points)
          return -1;
        else if (m1Points < m2Points)
          return 1;
      }
      if (m1.member.name < m2.member.name)
        return -1;
      else if (m1.member.name > m2.member.name)
        return 1;
      else
        return 0;
    })

    return teamMembers;
  }

  renderTeamMembers = (user) => {
    var items = [];

    if (this.state.dataLoaded) {
      const showPoints = this.state.team && this.state.team.settings && this.state.team.settings.showPoints

      const teamMembersOrdered = this.getTeamMembersOrdered(showPoints);

      teamMembersOrdered.forEach((teamMember) => {

        const badge = (showPoints) ? teamMember.level : null;

        items.push(
          <div className='teamMemberCell' onClick={this.onClick.bind(this.props, teamMember.userId)}>
            <div className='teamMemberCellAvatar'><Avatar size='medium' badge={badge} name={teamMember.member.name} image={teamMember.member['photo-url']}/></div>
            <div className='teamMemberCellName'>{teamMember.member.name}</div>
            <div className='teamMemberCellHabits'>{teamMember.member.email}</div>
            <div className='teamMemberCellAdmin'>
              { this.state.team['team-admin'].indexOf(teamMember.email) >= 0 ? 
                  <span class="material-icons-outlined md-30">
                    admin_panel_settings
                  </span>
               : <div/>
              }
            </div>

            { showPoints && <div className='teamMemberCellPoints'>{teamMember.member.points}</div> }

          </div>
        );
      });
    }
    else {
      for (var i = 1; i <= 6; i++) {
        items.push(<div className='teamMemberPlaceholderCell'/>);
      }
    }

//    items.push(<p><Button size='large' type='secondary' action='Invite Team Member' onClick={this.showInviteUsersPopup}/></p>);

    if (user && (user.email === 'antony@lasoohabits.com' || user.email === 'g.kid@icloud.com' || user.email === 'sally@lasoohabits.com' || user.email === 'keith.abbott@adlerianconsulting.com.au' || user.email === 'david.royle@adlerianconsulting.com.au'))
      items.push(<p><Button size='large' type='primary' action='Switch Team' onClick={this.switchTeam}/></p>);

    return <div>{items}</div>
  }

  switchTeam = () => {
    this.props.history.push("/switch");
  }

  getTitle = () => {
     return " "; 
  }

  render = () => {
    var user = this.props.firebase.user
    var history = this.props.history;
    
    return (
      <AppChrome title="Team Members" user={this.state.user} team={this.state.team} history={history} requireLogin={true} firebase={this.props.firebase} back={true} tab="Today">
      {
          this.renderTeamMembers(user)
      }
      { this.state.showInviteUsersPopup 
            ? 
            <InviteUsersPopup onClose={this.closeInviteUsersPopup} firebase={this.props.firebase} />
            :
            <div/>
      }
      </AppChrome>
    )
  }
}

export default TeamMembers;