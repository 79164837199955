import React, { Component } from 'react';

import Avatar from '../ui/Avatar';
import Popup from 'reactjs-popup';
import PopupHeader from '../ui/PopupHeader';

import { getUserAndTeam } from '../util/DatabaseUtils';

const INITIAL_STATE = {
  team: null,
  members : [],
  dataLoaded: false
};

class TeamMembersPopup extends Component {

  constructor(props) {
      super(props);
      this.state = INITIAL_STATE;
  } 

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {
      getUserAndTeam(this.props.firebase, (user, team) => {
        var members = this.getTeamMembersOrdered(team)

        this.setState({
          user: user,
          team: team,
          members: members,
          dataLoaded: true
        });
      })
    }
  }

  getTeamMembersOrdered = (team) => {
    var members = []

    for (var memberEmailKey in team.member) {
      var member = {};

      if (team.member[memberEmailKey]['user-id'] !== this.props.firebase.user.uid && !team.member[memberEmailKey].disabled) {
        member.userId = team.member[memberEmailKey]['user-id']
        member.name = team.member[memberEmailKey].name
        if (team.member[memberEmailKey]['photo-url']) {
          member.photoURL = team.member[memberEmailKey]['photo-url']
        }
        members.push(member);
      }
    }

    members.sort((m1, m2) => {
      if (m1.name < m2.name)
        return -1;
      else if (m1.name > m2.name)
        return 1;
      else
        return 0;
    });

    return members;
  }

  teamMemberClicked = (i) => {
    this.props.callback(this.state.members[i])
  }

  renderMembers = () => {
    var items = [];
    var membersExist = false

    for (var i = 0; i < this.state.members.length; i++) {
      var member = this.state.members[i]

      if (this.props.exclude && this.props.exclude.indexOf(member.userId) >= 0) 
        continue;

      membersExist = true

      items.push(<div onClick={this.teamMemberClicked.bind(this, i)} className='teamMembersPopupRow'>
        <div className='teamMembersPopupRowAvatar'><Avatar size='medium' name={member.name} image={member['photo-url']}/></div>
        <div className='teamMembersPopupRowName'>{member.name}</div>
      </div>)
    }
    if (membersExist)
      return <div className='teamMembersPopupContainer'>{items}</div>;
    else 
     return <div className='teamMembersPopupContainer'>
        <div className='teamMembersPopupRow'>
          <div className='teamMembersPopupRowName'>
            No team members available to add
          </div>
        </div>
      </div>;
  }

  render = () => {
    return <div>
        { 
          <Popup open="true" closeOnDocumentClick className='team-members-popup' onClose={this.props.onClose}>
                <PopupHeader title='Select Team Member'/>
                { this.state.dataLoaded ?
                    this.renderMembers()
                    : <div/>
                }
          </Popup>
        }
    </div>;
  }
};

export default TeamMembersPopup;
