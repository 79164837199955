import { useImperativeHandle } from "react/cjs/react.development";
import { updateLiteralTypeNode } from "typescript";

export function askLumosForObjectivesFunction(userId, queryString, callback) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = () => { 
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
          callback(JSON.parse(xmlHttp.responseText))
      }
  }

  var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/askLumosForObjectives";
  xmlHttp.open("POST", url, true); // true for asynchronous 
  xmlHttp.setRequestHeader('Content-type', 'application/json')

  xmlHttp.send(JSON.stringify({
    userId: userId,
    query: queryString
  }));
}

export function askLumosForHabitsFunction(userId, callback) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = () => { 
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
          callback(JSON.parse(xmlHttp.responseText))
      }
  }

  var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/askLumosForHabits?v=1&user="  + userId; 
  xmlHttp.open("GET", url, true); // true for asynchronous
  xmlHttp.send(null);
}

export function askLumosForHabitsForObjectiveFunction(userId, objectiveId, callback) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = () => { 
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
          callback(JSON.parse(xmlHttp.responseText))
      }
  }


  var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/askLumosForHabitsForObjective?user="  + userId + "&objective=" + objectiveId;
  xmlHttp.open("GET", url, true); // true for asynchronous 
  xmlHttp.send(null);
}

export function askLumosForHabitCheckinFeedback(userId, challengeId, date, callback) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = () => { 
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
          callback(JSON.parse(xmlHttp.responseText))
      }
  }


  var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/askLumosForHabitCheckinFeedback?user="  + userId + "&challenge=" + challengeId + "&date=" + date;
 
//  var url = "http://127.0.0.1:5001/lasoo-d9fad/us-central1/askLumosForHabitCheckinFeedback?user="  + userId + "&challenge=" + challengeId + "&date=" + date;
  
  xmlHttp.open("GET", url, true); // true for asynchronous 
  xmlHttp.send(null);
}


export function getGoalReportFunction(userId, challengeId, callback) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = () => { 
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
          callback(JSON.parse(xmlHttp.responseText))
      }
  }
  var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/getGoalReport?user=" + userId + "&goal=" + challengeId;
  xmlHttp.open("GET", url, true); // true for asynchronous 
  xmlHttp.send(null);
}

export function getSharedGoalReportFunction(reportId, callback) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = () => { 
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
          callback(JSON.parse(xmlHttp.responseText))
      }
  }
  var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/getSharedGoalReport?report=" + reportId;
  xmlHttp.open("GET", url, true); // true for asynchronous 
  xmlHttp.send(null);
}

export function getProfileFunction(userId, callback) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = () => { 
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
          callback(JSON.parse(xmlHttp.responseText))
      }
  }
  var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/getProfile?user=" + userId;
  xmlHttp.open("GET", url, true); // true for asynchronous 
  xmlHttp.send(null);
}


export function userSelfRegistrationFunction(userId, callback) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = () => { 
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
          if (callback) callback()
      }
  }
  var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/userSelfRegistration?userId=" + userId;
  xmlHttp.open("GET", url, true); // true for asynchronous 
  xmlHttp.send(null);
}

export function requestDeleteAccountFunction(userId, callback) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = () => { 
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
          if (callback) callback()
      }
  }
  var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/requestDeleteAccount?userId=" + userId;
  xmlHttp.open("GET", url, true); // true for asynchronous 
  xmlHttp.send(null);
}

export function sendCalendarEventsFunction(userId, challengeId, callback) {
  var xhr = new XMLHttpRequest()
  xhr.addEventListener('load', () => {
    if (callback) callback();
  })
  var url = 'https://us-central1-lasoo-d9fad.cloudfunctions.net/sendCalendarEvents?user=' + userId + "&challenge=" + challengeId;
  // open the request with the verb and the url
  xhr.open('GET', url)
  // send the request
  xhr.send()
}

export function sendBadge(userId, date, callback) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = () => { 
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
          callback()
      }
  }
  var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/sendBadge?userId=" + userId + "&date=" + date;
  xmlHttp.open("GET", url, true); // true for asynchronous 
  xmlHttp.send(null);
}

export function sendSharedGoalInvitesFunction(userId, challengeId, callback) {
  var xhr = new XMLHttpRequest()
  xhr.addEventListener('load', () => {
    if (callback) callback();
  })
  var url = 'https://us-central1-lasoo-d9fad.cloudfunctions.net/sendSharedGoalInvites?user=' + userId + "&goal=" + challengeId;
  // open the request with the verb and the url
  xhr.open('GET', url)
  // send the request
  xhr.send()
}

export function sendFeedbackFunction(userId, msg, callback) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = () => { 
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
          callback()
      }
  }
  var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/sendFeedback"
  xmlHttp.open("POST", url, true); // true for asynchronous 
  xmlHttp.setRequestHeader('Content-type', 'application/json')

  xmlHttp.send(JSON.stringify({
    userId: userId,
    msg: msg
  }));
}

export function sendImmediateNudgesFunction(email, teamId, state, callback) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = () => { 
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
          if (callback) callback()
      }
  }
  var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/sendImmediateNudges?email=" + email + "&team=" + teamId + "&state=" + state;  
  xmlHttp.open("GET", url, true); // true for asynchronous 
  xmlHttp.send(null);
}


