import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';

import { getUserAndTeam } from '../util/DatabaseUtils'

const INITIAL_STATE = {
  team : {},
  dataLoaded: false
};

class Onboarding2Step4 extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {
      getUserAndTeam(this.props.firebase, (user, team) => {
        this.setState({ 
          dataLoaded: true, 
          user: user,
          team: team, 
          habits: this.getSuggestedHabits(user, team) });
      })
    }
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  componentWillUnmount() {
  }

  continueClicked = () => {
    this.props.history.push("/onboarding-1-step-3");
  }

  getCategoriesByScore = (user) => {
    var wheel = user['wheel-of-work'];

    if (wheel) {
      var categories = [];

      for (var categoryId of Object.keys(wheel)) {
        categories.push({
          id: categoryId,
          score: wheel[categoryId]
        })
      }
      categories.sort((c1, c2) => {
        if (c1.score < c2.score)
          return -1;
        else if (c1.score > c2.score)
          return 1;
        else if (c1.id < c2.id)
          return -1;
        else if (c1.id > c2.id)
          return 1;
        else
          return 0;
      })
      return categories;
    }      
    else
      return [];

  }

  getCategoryIndex = (categoryId, categories) => {
    for (var i = 0; i < categories.length; i++) {
      if (categories[i].id === categoryId)
        return i
    }
    return -1;
  }

  getSuggestedHabits = (user, team) => {

    // Pick habits from the bottom 3 categories
    
    var categoriesByScore = this.getCategoriesByScore(user);
    var habitCountByCategory = {};

    var habits = [];

    for (var habitId of Object.keys(team.habits).sort()) {
      var habit = team.habits[habitId];
      var categoryIndex = this.getCategoryIndex(habit.category, categoriesByScore);
      var habitsInCategory = habitCountByCategory[habit.category] ? habitCountByCategory[habit.category] : 0;

      if (habit.visible && categoryIndex >= 0 && categoryIndex <= 2 && habitsInCategory <= 1) {
        habits.push({ habitId: habitId, habit : habit });
        habitCountByCategory[habit.category] = habitsInCategory + 1;
      }
    }

    habits.sort((h1, h2) => {
      if (h1.habit.name < h2.habit.name)
        return -1;
      else if (h1.habit.name > h2.habit.name) 
        return 1;
      else
        return 0;
    })

    return habits; 
  }

  habitClicked = (habitId) => {
    this.props.history.push("/habit-onboarding-2/" + habitId);
  }

  renderOnboarding = () => {
    var items = [];

    items.push(<img class='curvesImage' src='/illustration/curves.png'/>);
    items.push(<div className='onboardingTitle'>Start simple</div>);
    items.push(<div className='onboardingContent'>Choose one of our recommended habits to get started or pick your own.</div>);

    if (this.state.dataLoaded) {
      this.state.habits.map(h => {
        var habitId = h.habitId;
        var habit = h.habit;
        var categoryName = habit.category;
        if (this.state.team && this.state.team['habit-categories'] && this.state.team['habit-categories'][habit.category])
          categoryName = this.state.team['habit-categories'][habit.category].name
        items.push(<div onClick={this.habitClicked.bind(this, habitId)} className='onboardingHabitCard' style={{ 'border-left': '4px solid #' + habit.colour}}>
          <div className='onboardingHabitCardCategory' style={{ 'color' : '#' + habit.colour}}>{categoryName}</div>
          <div className='onboardingHabitCardName'>{habit.name}</div>
        </div>);
      })
    }
    else {
      for (var i = 1; i <= 6; i++) {
        items.push(<div className='onboardingHabitCardPlaceholder'/>);
      }
    }

    items.push(<div className='onboardingContent'>Or <a href="/add-habit-1">Let Me Explore</a></div>);

    return items;
  }

  render = () => {
    var history = this.props.history;
    
    return (
      <AppChrome title="" history={history} requireLogin={true} firebase={this.props.firebase} back={true} tab="Today">
      {
          this.renderOnboarding()
      }
      </AppChrome>
    )
  }
}

export default Onboarding2Step4;