import React, { Component } from 'react';

import { Bar } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

class GoalDaysChart extends Component {

    getOptions = () => {
        return {
            maintainAspectRatio: false,
            animations: false,

            elements: {
                bar: {
                    borderWidth: 2,
                },
            },
            responsive: true,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        color: 'black',
                        font: {
                            size: 8
                        },
                        autoSkip: false,
                        maxRotation: 0,
                        minRotation: 0
                    }
                },
                y: {
                    display: false,
                    grid: {
                        display: false
                    }
                }
            }
        }
    }

    getData = () => {
        return {
            labels: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            datasets: [
                {
                    label: 'Goal count',
                    backgroundColor: 'rgba(123,57,237,1)',
                    borderColor: 'rgba(123,57,237,1)',

                    borderWidth: 0,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: this.props.report.stats.completionsByDay
                }
            ]    
        }
    }

    render = () => {
        return <Bar options={this.getOptions()} data={this.getData()} />
    }

}

export default GoalDaysChart;