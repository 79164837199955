import React, { Component } from 'react';

import ActivityLikesPopup from './ActivityLikesPopup';
import Avatar from '../ui/Avatar'
import Award from '../ui/Award'
import HabitSummaryCard from '../ui/HabitSummaryCard';

import moment from 'moment';

import { postActivityLike } from './ActivityUtils';

import { awardPoints } from '../util/PointsUtils'
import { fetchTeamFromCache, fetchTeamFromServer } from '../util/DatabaseUtils';

import { toast } from 'react-hot-toast'

import ImageCarouselPopup from '../ui/ImageCarouselPopup'

const calendarDateFormat = 'YYYY-MM-DD';

const INITIAL_STATE = {
  status: "done",
  like: false,
  displayLikes : false,
  showImageCarouselPopup: false
};

class TeamActivityItem extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  formatDate = (date) => {
    var todayMoment = moment();
    var tomorrowMoment = todayMoment.clone().add(1, 'days');
    var yesterdayMoment = todayMoment.clone().subtract(1, 'days');

    if (date === todayMoment.format(calendarDateFormat))
      return "Today";
    else if (date=== tomorrowMoment.format(calendarDateFormat))
      return "Tomorrow";
    else if (date === yesterdayMoment.format(calendarDateFormat))
      return "Yesterday";
    else
      return moment(date, calendarDateFormat).format('D MMM');
  }

  likeClicked = (date, index, toUserId, e) => {
    //  alert("Like clicked " + date + " " + index);
  
      e.stopPropagation();

      var team = this.props.team
      
      // Check if the user has already liked the item
      if (this.state.like || team.activityLikes && team.activityLikes[date] && team.activityLikes[date][index] && team.activityLikes[date][index][this.props.firebase.user.uid]) {
          return
      }
  
      postActivityLike(this.props.firebase, this.props.teamId, date, index, this.props.firebase.user.uid, toUserId, () => {});

      if (team.member[this.props.firebase.user.email.replaceAll(".","_")]) {
        awardPoints(this.props.firebase, this.props.teamId, team, this.props.firebase.user.uid, this.props.firebase.user.email, "like", (results) => {

          // This is necessary to make sure we get the user latest level and points

          fetchTeamFromServer(this.props.firebase, this.props.teamId, (team) => {})
        });
      }
      
      this.state.like = true
      this.setState({ like : this.state.like })
    }
  
    getLikesCount = (team, date, itemIndex) => {
      var likesCount = 0
  
      if (team.activityLikes && team.activityLikes[date] && team.activityLikes[date] && team.activityLikes[date][itemIndex])
        likesCount = Object.keys(team.activityLikes[date][itemIndex]).length
      else
        likesCount = 0
      
      if (this.state.like)
        likesCount += 1
  
      return (likesCount) ? likesCount : ""
    }
  
    getCommentsCount = (team, date, itemIndex) => {
      if (team.activityComments && team.activityComments[date] && team.activityComments[date] && team.activityComments[date][itemIndex])
        return Object.keys(team.activityComments[date][itemIndex]).length
      else
        return ""
    }

    messagesClicked = () => {

      if (this.props.onClick) {
        this.props.onClick(this.props.date, this.props.index)
      }
    }

    onLikesClicked = (e) => {
      e.stopPropagation();

      this.showActivityLikes();
    }

    showActivityLikes = () => {
      this.setState({ displayLikes : true })
    }

    closeActivityLikes = () => {
      this.setState({ displayLikes : false })
    }

    displayImageCarouselPopup = (image)  => {
      this.setState({ showImageCarouselPopup : true, selectedImageIndex : this.props.item.images.indexOf(image)})
    }

    closeImageCarouselPopup = () => {
      this.setState({ showImageCarouselPopup : false })
    }

    getHabitDescription = (user, item) => {
      var userName = (user) ? user.name : "Unknown user"
        
      if (item.action === 'started')
         return userName + " started a new habit";
      else if (item.action === 'ended')
         return userName + " ended a habit";
      else if (item.action === 'complete') {
        if (item.streak && item.streak % 5 === 0) 
          return userName + " hit a " + item.streak + " day streak 🔥"
        else
          return userName + " checked off a habit"
      }
      else if (item.action === 'award') 
        return userName + ' completed a goal';
      else if (item.action === 'joined') {
        return userName + " joined the team";
      }
    }

    habitCardClicked = () => {
      if (this.props.onClickGoal) {
        this.props.onClickGoal(this.props.item)
      }
    }

    renderHabitCard = (habit, userId, goalId, objective, colour) => {
      if (!habit)
        return <div/>

      var categoryName = habit.category;
      if (this.props.team && this.props.team['habit-categories'] && this.props.team['habit-categories'][habit.category])
        categoryName = this.props.team['habit-categories'][habit.category].name
    
      return <div className='teamActivityCardHabitSummaryCard'>
        <HabitSummaryCard objective={objective} category={categoryName} name={habit.name} colour={(colour) ? colour : habit.colour} onClick={this.habitCardClicked}/>
      </div>;
    }

    renderAward = (item) => {
      return <div className='teamActivityCardItemAward' onClick={this.habitCardClicked}>
        <Award orientation='landscape' colour={item.colour} category={item.category} name={item.name} days={item.days} percentage={item.percentage}/>
      </div>
    }

    rendedEndedQuestions = (item) => {
      return <div>
        Did Lasoo help you ingrain this habit or routine? {item.ingrained}<br/>
        Do you recommend this habit or routine? {item.recommend}<br/>
      </div>

    }

    onClickAvatar = (userId, e) => {
      if (this.props.onClickAvatar) {
        this.props.onClickAvatar(userId)
      }
    }

    findTeamMember = (team, itemUserId) => {
      if (!team || !team.member)
        return null;

      for (var memberEmailKey in team.member) {
        var member = team.member[memberEmailKey];
        if (member['user-id'] === itemUserId)
          return member;
      }
    }

    renderImages = (images) => {
        var imagesHtml = []
        for (var i = 0; i < Math.min(images.length, 5); i++) {
          imagesHtml.push(<img onClick={this.displayImageCarouselPopup.bind(this, images[i])} width="35" height="35" className='teamActivityCardImage' src={images[i]} alt=''/>)
        }
    
        return <div className='teamActivityCardImageContainer'>{imagesHtml}</div>
    }

    renderActivityItem = (date, index, item, showDate) => {
      var user = this.findTeamMember(this.props.team, item.userId)
      var habit = null;
      
      if (!user)
        return <div/>
 
      if (item.habit)
        habit = this.props.team.habits[item.habit]

      var likesCount = this.getLikesCount(this.props.team, date, index)
      var commentsCount = this.getCommentsCount(this.props.team, date, index)      
      var habitDescription = this.getHabitDescription(user, item)

      var cardClass = null;
      
      
      var visibility = (item.visibility) ? item.visibility : 'team'

      
      if (item.action === 'award')
        cardClass = 'teamActivityCardAwardItem'
      else if (habit) 
        cardClass = 'teamActivityCardItemWithHabit';
      else
        cardClass = 'teamActivityCardItemWithoutHabit';

      return <div className='teamActivityCard'>
        <div className={cardClass}>
          <div className='teamActivityCardItemAvatar' onClick={this.onClickAvatar.bind(this, item.userId)}>
            <Avatar name={user.name} image={user['photo-url']}  size="medium" round={true} />
          </div>

          <div className='teamActivityCardItemDescription'>{habitDescription}</div>

          { item.action === 'award' ?
              this.renderAward(item) : 
              <div/> }
            
          { (item.action !== 'award' && habit) ?
              this.renderHabitCard(habit, item.userId, item.goalId, item.objective, item.colour) 
              : <div/> }

          { showDate 
            ?
            <div className='teamActivityCardItemDate'>{this.formatDate(date)}</div>
          : <div/>
          }
        </div>
        { item.journal 
          ?
          <div className='teamActivityCardItemJournal'>&quot;{item.journal}&quot;</div>
          :
          <div/>
        }
        { item.images && item.images.length > 0 
          ?
          this.renderImages(item.images)
          :
          <div/>
        }
        <div className='teamActivityCardItemLikesAndComments'>
            <div className='teamActivityCardItemLikeIcon' onClick={this.likeClicked.bind(this, date, index, item.userId)}><img width="25" height="25" src="/icons/like-icon.png"/></div>
            { likesCount ? 
              <div className='teamActivityCardItemLikes' onClick={this.onLikesClicked}>&nbsp;{likesCount}&nbsp;</div>
              : 
              <div/>
            }
            <div className='teamActivityCardItemMessagesIcon' onClick={this.messagesClicked}>
              <img width="21" height="21" src="/icons/comment-icon.png"/>
            </div>
            <div className='teamActivityCardItemMessages'>{commentsCount}</div>
            <div className='teamActivityCardItemVisibilityIcon'>
              { visibility !== 'team' ?
                <img width="21" height="21" src="/icons/eye-closed-icon.png"/>
                :
                <div/>
              }
            </div>
        </div>
        
        <div className='teamActivityCardSeparator'></div>

        { this.state.displayLikes ?
          <ActivityLikesPopup firebase={this.props.firebase} date={this.props.date} index={this.props.index} onClose={this.closeActivityLikes}/>
          :
          <div/>
        }
      </div>
    }
  

  render = () => {
    var items = [];
    if (['complete', 'started', 'award', 'ended', 'joined'].indexOf(this.props.item.action) >= 0) {
      items.push(this.renderActivityItem(this.props.date, this.props.index, this.props.item, true))
    }
    else {
      items.push(<div/>)
    }

    if (this.state.showImageCarouselPopup) {
      items.push(<ImageCarouselPopup selectedItem={this.state.selectedImageIndex} images={this.props.item.images} onClose={this.closeImageCarouselPopup}/>)
    }

    return items;
  }
}

export default TeamActivityItem;

