import React, { Component } from 'react';

import HabitCategoryCard from './HabitCategoryCard';

import { getUserAndTeam } from '../util/DatabaseUtils';
import ReloadDataCard from '../ui/ReloadDataCard';

const INITIAL_STATE = {
  habitCategories: {},
  dataLoaded: false,
  isOffline: false
};

class HabitCategories extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {
      getUserAndTeam(this.props.firebase, (user, team) => {
        var habitCategories = (team['habit-categories']) ? team['habit-categories'] : {}
        this.setState({
          habitCategories: this.sortHabitCategories(habitCategories),
          dataLoaded: true,
          isOffline: false
        })
      },
      () => {
        this.setState({ isOffline: true })
      })
    }
  }

  sortHabitCategories = (habitCategories) => {
    var habitCategoriesList = [];

    for (var id of Object.keys(habitCategories)) {
      habitCategoriesList.push({
        id: id,
        category: habitCategories[id]
      })
    }

    return habitCategoriesList.sort((c1, c2) => {
       if (c1.category.order && c2.category.order) {
          if (c1.category.order < c2.category.order)
            return -1;
          else if (c1.category.order > c2.category.order)
            return 1;
       }
       else if (c1.category.order && !c2.category.order)
          return -1;
       else if (!c1.category.order && c2.category.order)
          return 1;

      if (c1.category.name < c2.category.name)
        return -1;
      else if (c1.category.name > c2.category.name)
        return 1
      else 
        return 0
    })
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  shouldDisplayCategory = (habitCategory) => {
    if (!this.props.showInvisibleCategories && !habitCategory.visible)
      return false;

    if (!this.props.mode)
      alert("Warning: No mode set for HabitCategories component")

    if (this.props.mode === 'all')
      return true;
    else if (this.props.mode === 'top-level')
      return !habitCategory.parent;
    else if (this.props.mode === 'children')
      return habitCategory.parent === this.props.parent;

    return false;
  }

  refresh = () => {
    this.setState({ isOffline: false })
    this.checkDataLoaded();
  }

  renderHabitCategories = (habitCategories) => {
    if (this.state.isOffline)
      return <ReloadDataCard firebase={this.props.firebase} message="Could not load habit categories." onClick={this.refresh}/>
    else if (!this.state.dataLoaded)
      return this.renderHabitCategoriesLoading();

    if (habitCategories.size === 0)
      return "No habit categories"

    return habitCategories.map((cat) => {
      var id = cat.id;
      var habitCategory = cat.category
      if (this.shouldDisplayCategory(habitCategory))
         return <HabitCategoryCard id={id}  visible={habitCategory.visible} onClick={this.props.onClick} icon={habitCategory.icon} colour={"ea2952"} name={habitCategory.name} description={habitCategory.description} parent={habitCategory.parent} onClick={this.props.onClick}/>
      else
         return null;
    })
  }

  renderHabitCategoriesLoading = () => {
    const items = [];

    for (var i = 1; i <= 4; i++)
      items.push(<div className='habitCategoryCardPlaceholder' />);

    return <div>{items}</div>;
  }

  render = () => {
    var habitCategories = this.state.habitCategories;

    return (<div>
      { this.renderHabitCategories(habitCategories) }
      </div>)

  }
}

export default HabitCategories;