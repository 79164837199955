import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import HabitCard from '../discover/HabitCard'
import PinnedCard from '../ui/PinnedCard'

import { getUserAndTeam } from '../util/DatabaseUtils'

const INITIAL_STATE = {
  habits: {},
  dataLoaded: false
};

class AddHabit2 extends Component {

    constructor(props) {
       super(props);
       this.state = { ...INITIAL_STATE };
    }

    checkDataLoaded = () => {
      if (this.props.firebase.user && !this.state.dataLoaded) {

        getUserAndTeam(this.props.firebase, (user, team) => {
          var habits = [];

          if (team.habits) {
            Object.keys(team.habits).forEach((habitId) => {
              var habit = team.habits[habitId]; 
               if (habit.category === this.props.match.params.category)
                  habits.push({ id: habitId, habit : habit });
            });
          }
          habits.sort((h1, h2) => {
            if (h1.habit.order && h2.habit.order) {
               if (h1.habit.order < h2.habit.order)
                 return -1;
               else if (h1.habit.order > h2.habit.order)
                 return 1;
            }
            else if (h1.habit.order && !h2.habit.order)
               return -1;
            else if (!h1.habit.order && h2.habit.order)
               return 1;
     
           if (h1.habit.name < h2.habit.name)
             return -1;
           else if (h1.habit.name > h2.habit.name)
             return 1
           else 
             return 0
          })

          this.setState({ dataLoaded: true, user: user, habits : habits, team: team });
        })
      }
    }

    componentWillMount = () => {
      this.checkDataLoaded();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
      this.checkDataLoaded();
    }

    componentWillUnmount() {}

    habitSelected = (habitId) => {
      this.props.history.push('/habit-add/' + habitId);
    }

    onCreate = () => {
      this.props.history.push("/create-habit-post/" + this.props.match.params.category);
    }

    renderHabits = () => {


      var items = [];

      items.push(<PinnedCard description='Pick from the list or create a new habit in this category' action="Create" onButtonClick={this.onCreate}/>)

      if (!this.state.dataLoaded)
         items.push(this.renderHabitsLoading());
      else if (this.state.habits.length === 0)
         items.push(<div>No habits</div>);
      else 
        for (var habitRecord of this.state.habits) {
          var id = habitRecord.id;
          var habit = habitRecord.habit;
          if (habit.visible)
            items.push(<HabitCard id={id} visible='true' displayChevron={true} team={this.state.team} onClick={this.habitSelected} name={habit.name} colour={habit.colour}/>);
        }

      return items;
    }

    renderHabitsLoading = () => {
       const items = [];

       for (var i = 1; i <= 3; i++) 
        items.push(<div className='habitCardPlaceholder'/>);

       return <div>{items}</div>;
    }

    render = () => {
      var history = this.props.history;

      return (
        <AppChrome title="Select a Habit" history={history} requireLogin={true} team={this.state.team} firebase={this.props.firebase} back={true} tab="Today">
            <div>
              { this.renderHabits() } 
            </div>
        </AppChrome>
      )      
    }
}

export default AddHabit2;