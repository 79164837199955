import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import GoalForm from '../goals/GoalForm';

import MessagePopup from '../ui/MessagePopup'

import { sendCalendarEventsFunction, sendSharedGoalInvitesFunction } from '../util/FunctionUtils';

import { activityNewGoal } from '../activity/ActivityUtils';
import { challengeStatsUpdate } from '../util/StatsUtils';
import { getDefaultVisibility } from '../util/VisibilityUtils';

import { getUser } from '../util/DatabaseUtils';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import SpinnerPanel from '../ui/SpinnerPanel';

const calendarDateFormat = 'YYYY-MM-DD';

class AddSharedHabit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataLoaded : false,
      sharedHabit : null,
      addingHabit : false
    };
  }
  
  /**
   * This component is designed to be subclassed and configured by overriding the 
   * following three methods
   */
  getTab = () => {
    return "Today";
  }

  navigateAway = () => {
    this.props.history.push("/");
  }

  checkDataLoaded = () => {
    const query = new URLSearchParams(this.props.location.search);

    if (this.props.firebase.user && query.get('user') && query.get('goal') && !this.state.dataLoaded) {
      // Load details from backend
      getUser(this.props.firebase, (user) => {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = () => { 
            if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
              this.setState({ dataLoaded : true, data: user, sharedHabit : JSON.parse(xmlHttp.responseText)})
            }
        }
        var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/getSharedGoal?user=" + query.get('user') + "&goal=" + query.get('goal')
        xmlHttp.open("GET", url, true); // true for asynchronous 
        xmlHttp.send(null);
      });
    }
  } 

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  signout = () => this.props.firebase.doSignOut().then(() => this.forceUpdate())
 

  addHabit = (objective, habit, name, category, period, selectedDays, startDate, endDate, colour, reminders, calendarEvents, teamMembers, visibility, showCreationConfirmPopup) => {     
    var challengeId = 'challenge-' + uuidv4();
    var challenge = {
      objective: objective,
      habit: habit,
      name: name,
      category: category,
      period: period,
      startDate: startDate,
      daysOfWeek: selectedDays,
      reminders: reminders,
      calendarEvents: calendarEvents,
      teamMembers: teamMembers,
      colour: colour,
      isSharedChallenge: true,
      visibility: visibility
    }
    if (endDate)
      challenge.endDate = endDate;

    this.props.firebase.database.ref('users/' + this.props.firebase.user.uid + '/challenges/' + challengeId).set(challenge)
      .then(() => {
          challengeStatsUpdate(this.props.firebase, this.props.firebase.user.uid, challengeId, () => {
          sendCalendarEventsFunction(this.props.firebase.user.uid, challengeId);
          sendSharedGoalInvitesFunction(this.props.firebase.user.uid, challengeId);
          activityNewGoal(this.props.firebase, this.props.firebase.user.uid, moment().format(calendarDateFormat), challengeId);
          showCreationConfirmPopup(challengeId, this.navigateAway)
        });
      })
      .catch((error) => {
        alert("Error writing document: ", error);
      });
  }

  getDefaultSelectedDays = () => {
    return [ 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
  }

  renderChallengeText = () => {
    return (
        <div className="addSharedHabitChallengeText"><span className='addSharedHabitChallengerName'>{this.state.sharedHabit.challengerName}</span> challenged you to share a goal:</div>
    )
  }

  render = () => {
    var history = this.props.history;

    var todayFormatted = moment(new Date()).format(calendarDateFormat);
  
    return (
      <AppChrome title="Habit Challenge" history={history} requireLogin={true} firebase={this.props.firebase} back={true} tab={this.getTab()}>
        { this.state.dataLoaded ? 
        <div>
            { this.renderChallengeText() }
            <GoalForm history={history} firebase={this.props.firebase} actionName="Accept challenge" habit={this.state.sharedHabit.habit} name={this.state.sharedHabit.name} category={this.state.sharedHabit.category} period={this.state.sharedHabit.period} daysOfWeek={this.state.sharedHabit.daysOfWeek} startDate={this.state.sharedHabit.startDate} endDate={this.state.sharedHabit.endDate} colour={this.state.sharedHabit.colour} calendarEvents={this.state.sharedHabit.calendarEvents} teamMembers={[]} visibility={getDefaultVisibility()} onSubmission={this.addHabit}/>
        </div>
        :
        <SpinnerPanel/>
       }
        {this.state.messagePopup
                ?
                <MessagePopup title='Add Shared Habit' message={this.state.messagePopupMessage} onClose={this.state.messagePopupCallback} />
                :
                <div></div>
        }
      </AppChrome>
    )
  }
}

export default AddSharedHabit;