import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';

class NewHabitsStop extends Component {

    constructor(props) {
       super(props);
       this.state = { optedOut : false };
    }

    setNudgesOptOut = () => {
      if (this.props.firebase.user && !this.state.optedOut) {
        this.props.firebase.database.ref('users/' + this.props.firebase.user.uid + '/settings/newHabitsOptOut').set(true)
        .then(() => {
            this.setState({ optedOut : true })
         })
        .catch((error) => {
          alert("Error writing document: ", error);
        });
      }
    }

    componentWillMount = () => {
      this.setNudgesOptOut();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
      this.setNudgesOptOut();
    }

    componentWillUnmount() {
    }

    render = () => {
      var user = this.props.firebase.user
      var history = this.props.history;

      if (user && !user.emailVerified)
        this.props.history.push("/verify-email");

      return (
        <AppChrome title="New Habits Notifications" history={history} requireLogin={true} firebase={this.props.firebase} back={true} tab="Today">
            <div><p>You have successfully opted out of new habit emails</p></div>
        </AppChrome>
      )      
    }
}

export default NewHabitsStop;