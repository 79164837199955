import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Button from '../ui/Button'
import MessagePopup from '../ui/MessagePopup'
import SpinnerPanel from '../ui/SpinnerPanel'

import moment from 'moment';

import { activityJoined } from '../activity/ActivityUtils'
import { getUser } from '../util/DatabaseUtils'
import { userSelfRegistrationFunction } from '../util/FunctionUtils'

const calendarDateFormat = 'YYYY-MM-DD';

const INITIAL_STATE = {
  data : {},
  dataLoaded: false,
  teamName : '',
  creatingTeam: false,
  messagePopup: false,
  messagePopupMessage: '',
  messagePopupCallback: null,
};

class CreateTeam extends Component {

    constructor(props) {
       super(props);
       this.state = { ...INITIAL_STATE };
    }

    checkDataLoaded = () => {
      if (this.props.firebase.user && !this.state.dataLoaded) {

        getUser(this.props.firebase, (user) => {
          this.setState({
            data: user ? user : {},
            dataLoaded: true
          });

            // If the user already has a team set then redirect home

          if (user && user.team) {
            this.props.history.push("/")
          }
        })
      }
    }

    componentWillMount = () => {
      this.checkDataLoaded();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
      this.checkDataLoaded();
    }

    componentWillUnmount() {}

    signout = () => this.props.firebase.doSignOut().then(() => this.forceUpdate())
   
    login = () => {
      this.props.history.push("/login");
    }

    signUp = () => {
      this.props.history.push("/signup");
    }

    onChange = (event) => {
      this.setState({ [event.target.name]: event.target.value });
    }

    closeMessagePopup = () => {
      this.setState({
        messagePopup: false,
        messagePopupMessage: '',
        messagePopupCallback: null
      })
    }
  
    showMessagePopup = (message, callback) => {
      this.setState({
        messagePopup: true,
        messagePopupMessage: message,
        messagePopupCallback: callback
      })
    }

    renderCreateTeamForm = () => {
      return <div>
        <div className='welcomeTitle'><p>What is the name of your company or team?</p></div>
        <div className='welcomeDescription'><p>This will be the name of your Lasoo space, so enter a name that your colleagues will recognise.</p></div>

        <input
          name="teamName"
          onChange={this.onChange}
          type="text"
          placeholder="Company or team"
        />

        <p><Button type='primary' size='large' action='Continue' onClick={() => this.onSubmit()}/></p>
      </div>
    }

    stringIsBlank = (str) => {
      return (!str || /^\s*$/.test(str));
    }   

    validateFields = () => {
      if (this.stringIsBlank(this.state.teamName) || this.state.teamName.trim().length < 3) {
        this.showMessagePopup("Please enter a team name", this.closeMessagePopup);
        return false;
      }

      return true;
    }

    onSubmit = () => {
      if (!this.validateFields())
        return;

        // Check for double submits - we don't want to create two teams
      if (this.state.creatingTeam)
        return;

      this.setState({ creatingTeam: true });

      var user = this.props.firebase.user;

      var team = {
        name : this.state.teamName.trim(),
        creator: user.uid,
        'creator-name': user.displayName,
        settings: {
          'program-type': 'performance-management',
          allowChallenges: true,
          disableTeamVisibility: false,
          'recommended-habit': [ 
            { id: 'achievement-journal', circles: '' },
            { id: 'stay-hydrated', circles: '' },
            { id: 'the-daily-highlight', circles: '' }
          ],
          'defaultObjective' : 'Professional Development',
          'sendHabitOfTheWeek' : true,
          'sendManagerSummary' : false,
          'sendWeeklyDigest' : true,
        }
      }

      const escapedEmail = user.email.replaceAll(".","_")

      team.member = {};
      team.member[escapedEmail] = { 
        name : user.displayName,
        'joined-date':  moment().format(calendarDateFormat),
        email: user.email
      }
      if (user.photoURL)
        team.member[escapedEmail]['photo-url'] = user.photoURL

      team['team-admin'] = [];
      team['team-admin'].push(user.email);

      team['content-admin'] = [];
      team['content-admin'].push(user.email);

      this.props.firebase.firestore.collection("habit-categories")
      .get()
      .then(snapshot => {

        team['habit-categories'] = {};
        snapshot.forEach(category => {
          team['habit-categories'][category.id] = category.data()
        });

        this.props.firebase.firestore.collection("habits")
        .get()
        .then(snapshot => {
          team.habits = {};
          snapshot.forEach(habit => {
            team.habits[habit.id] = habit.data()
          });

          this.props.firebase.firestore.collection("teams").doc(team)
          .then((doc) => {

            // Set team

            this.props.firebase.database.ref('users/' + user.uid + '/team').set(doc.id)
            .then(() => {
              activityJoined(this.props.firebase, user.uid, moment().format(calendarDateFormat))
              userSelfRegistrationFunction(user.uid)

              this.props.history.push("/onboarding-3-step-1")
            })
            .catch((error) => {
              alert("Error writing document: ", error);
            });

          })
          .catch((error) => {
            alert("Error adding document: ", error);
            this.setState({ creatingTeam: false });
          });
        });
      });
    }

    render = () => {
      var history = this.props.history;

      return (
        <AppChrome title="Create Team" history={history} requireLogin={true} firebase={this.props.firebase} tab="Today">
            { this.state.creatingTeam 
            ? <SpinnerPanel/>
              :
            <div>
              {this.renderCreateTeamForm()}
            </div>
            }

            { this.state.messagePopup
              ?
              <MessagePopup title='Create Team' message={this.state.messagePopupMessage} onClose={this.state.messagePopupCallback} />
              :
              <div></div>
            }
        </AppChrome>
      )
      
    }
}

export default CreateTeam;