import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown'

import AppChrome from '../layout/AppChrome';
import Avatar from '../ui/Avatar';
import Button from '../ui/Button'
import HabitVideo from './HabitVideo'

import { getUserAndTeam } from '../util/DatabaseUtils'
import AvatarPile from '../ui/AvatarPile';

const INITIAL_STATE = {
  habit : {},
  dataLoaded: false
};

class Habit extends Component {

    constructor(props) {
       super(props);
       this.state = { ...INITIAL_STATE };
    }

    checkDataLoaded = () => {
      if (this.props.firebase.user && !this.state.dataLoaded) {

        getUserAndTeam(this.props.firebase, (user, team) => {
          if (team.habits && team.habits[this.props.match.params.habit]) {
            this.setState({ dataLoaded: true, teamId: user.team, team: team, habit : team.habits[this.props.match.params.habit] });
          }
          else {
            alert("Invalid habit id")
          }
        });
      }
    }

    componentWillMount = () => {
      this.checkDataLoaded();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
      this.checkDataLoaded();
    }

    componentWillUnmount() {}

    addHabit = () => {
      this.props.history.push('/add-viewed-habit/' + this.props.match.params.habit);
    }

    editHabit = () => {
      this.props.history.push('/edit-habit-post/' + this.props.match.params.habit);
    }

    getVideoId = (videoURL) => {
      if (videoURL && videoURL !== '') {
          const url = new URL(videoURL);
          return url.searchParams.get('v');
      }
      else
        return null;
    }

    getTimestamp = (videoURL) => {
      if (videoURL && videoURL !== '') {
          const url = new URL(videoURL);
          var t = url.searchParams.get('t');
          if (t)
            return parseInt(t.replace('s',''))
          else 
            return null;
      }
      else
        return null;
    }

    renderVideoDescription = () => {
      var items = [];

      if (this.state.habit.videoURL) {
          items.push(<HabitVideo url={this.state.habit.videoURL}/>);
      }

      return items;
    }

    renderHabitDescription = () => {
      var items = [];

   //   items.push(<div className='sectionTitle'>HABIT DESCRIPTION</div>);

      var desc = this.state.habit.description.replaceAll("\\n","\n");
      items.push(<div className='habitDescriptionCard'>
        <ReactMarkdown>{desc}</ReactMarkdown> 
      </div>);

      return items;
    }


    findMember = (userId, team) => {
      for (var memberEmailKey in team.member) {
        var member = team.member[memberEmailKey];
        if (member['user-id'] === userId)
          return member;
      }
    }

    getTeamMembers = (habitId, team) => {
      var names = []
      var images = []
  
      if (team && team.stats && team.stats.habits && team.stats.habits[habitId] && team.stats.habits[habitId].users) {
        var users = team.stats.habits[habitId].users;
  
        for (var userId of Object.keys(users).sort()) {
          var member = this.findMember(userId, team);
          if (member) {
            names.push(member.name)
            images.push(member['photo-url'])
          }
        }
      }
  
      return {
        names : names,
        images : images
      }
    }

    renderPracticedBy = (teamMembers) => {
      var items = []

      if (teamMembers.names.length > 0) {

        items.push(
          <div className='habitPracticedByCell'>
            <div className='sectionTitle'>PRACTICED BY</div>
          </div>)
        items.push(
          <div className='habitPracticedByCell'>
            <div className='habitPracticedByAvatarPile'>
              <AvatarPile alignment='left' names={teamMembers.names} images={teamMembers.images} />
            </div>
          </div>    
        )
      }
      return items;
    }

    renderCreatedBy = () => {
      var items = [];

//      items.push(<div className='sectionTitle'>CREATED BY</div>);

      items.push(<div className='sectionTitle'>CREATED BY</div>)

      if (this.state.habit.creator) {
              //  items.push(<div className='habitCreatedBy'>Created by: {this.state.habit['creator-name']}</div>);


      //  items.push(<div className='habitCreatedBy'>Created by: {this.state.habit['creator-name']}</div>);
      
        items.push(
          <div className='habitCreatedByCell'>
            <div className='habitCreatedByAvatar'>
              <Avatar size='small' image={this.state.habit['creator-photo-url']} name={this.state.habit['creator-name']}/>
            </div>
          </div>
        ); 
      
      }
      else

        items.push(
          <div className='habitCreatedByCell'>
            <div className='habitCreatedByAvatar'><img src="https://app.lasoohabits.com/lasoo_icon.png" width="28" height="28"/></div>
          </div>
        );  
 
      return items;
    }


    renderSocialPanel = () => {
      var practicedByMembers = this.getTeamMembers(this.props.match.params.habit, this.state.team);

      if (practicedByMembers.names.length > 0) {
        return (
          <table width="100%">
            <tr>
              <td width='50%' align='center'>{ this.renderCreatedBy() }</td>
              <td width='50%' align='center'>{ this.renderPracticedBy(practicedByMembers) }</td>
            </tr>
          </table>
        )
      }
      else {
        return (
          <table width="100%">
            <tr>
              <td width='100%' align='center'>{ this.renderCreatedBy() }</td>
            </tr>
          </table>
        )
      }
    }

    renderHabit = () => {
      if (!this.state.dataLoaded)
        return this.renderHabitLoading();

      var styles = {
        backgroundColor: "#" + this.state.habit.colour
      };

      return (
        <div> 
          { this.renderVideoDescription() }
          { this.renderHabitDescription() }

          { this.renderSocialPanel() }


          <p><Button type='primary' size='large' onClick={this.addHabit} action='Try this habit'/></p>

          { this.canEditHabit() ?
            <p><Button type='secondary' size='large' onClick={this.editHabit} action='Edit this habit'/></p>
            : <div/>
          }

          { this.canPublishHabit() ?
            <p><Button type='secondary' size='large' onClick={this.publishHabit} action='Publish this habit'/></p>
            : <div/>
          }

        </div>
      )
    }

    renderHabitLoading = () => {
       return <div></div>;
    }

    canEditHabit = () => {
      // A user can edit a habit if they are:
      //   1/ A Content admin
      //   2/ The creator
      // And we are not in onboarding

      if (this.props.location.pathname.startsWith("/habit-onboarding"))
        return false;

      if (!this.state.dataLoaded)
        return false;

      var team = this.state.team;
      if (team['content-admin'] && team['content-admin'].indexOf(this.props.firebase.user.email) >= 0) {
        return true;
      }
     
      if (this.state.habit['creator-name'] && this.state.habit['creator-name'] === this.props.firebase.user.displayName)
        return true;
      
      return false;
    }

    canPublishHabit = () => {
      return window.location.href.startsWith("http://localhost:3000/") && this.props.firebase.user.email === 'antony@lasoohabits.com';
    }

    publishHabit = () => {
      var url = 'http://localhost:5001/lasoo-d9fad/us-central1/habitPublish?team=' + this.state.teamId + "&habit=" + this.props.match.params.habit 

      var http = new XMLHttpRequest();
      http.open('GET', url);

      http.onreadystatechange = () => {//Call a function when the state changes.
        if (http.readyState == 4 && http.status == 200) {
           alert("Published successfully")
        }
        else if (http.readyState == 4 && http.status != 200) {
          alert("Published failed: " + http.status)
        }
      }
      http.send()
  
      //Send the proper header information along with the request
     // http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  

    }

    getTab = () => {
      if (this.props.location.pathname.startsWith("/habit-onboarding") || this.props.location.pathname.startsWith("/habit-home") )
        return "Today"
      else if (this.props.location.pathname.startsWith("/habit-add"))
        return "Today";
      else
        return "Discover";
    }

    render = () => {
      var history = this.props.history;
      var title = (this.state.dataLoaded) ? this.state.habit.name : " ";
      var tab = this.getTab()

      return (
        <AppChrome title={title} history={history} team={this.state.team} requireLogin={true} firebase={this.props.firebase} back={true} tab={tab}>
            <div>
              { this.renderHabit() } 
            </div>
        </AppChrome>
      )     
    }
}

export default Habit;