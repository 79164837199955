import React, { Component } from 'react';
import 'material-icons/iconfont/material-icons.css';

import moment, { min } from 'moment';

import { getDefaultVisibility, getVisibilityOption } from '../util/VisibilityUtils';

import ImageCarouselPopup from '../ui/ImageCarouselPopup';

const calendarDateFormat = 'YYYY-MM-DD';

class HabitActivityCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showImageCarouselPopup: false,
      selectedImage: 0
    };

  }

  renderJournal = (journal) => {
    return <div className='habitActivityCardVariableHeight'>
      {this.renderHabitLoopRow('/icons/journal-icon.png', journal)}
    </div>;
  }

  renderHabitLoopAndJournal = () => {
    var activity = this.props.activity;
    var challenge = this.props.challenge;

    var items = [];
    var journal = (activity && activity.journal) ? activity.journal : "";

    if (!activity || !activity.done)
      items.push(this.renderHabitLoop(challenge));

    if (journal.trim() !== '')
      items.push(this.renderJournal(journal));

    return items;
  }

  getPercentageGone = () => {
    var numOfDays = 0;
    var numOfDaysGone = 0;

    var dateMoment = moment(this.props.challenge.startDate, calendarDateFormat);
    var endDateMoment = (this.props.challenge.endDate) ? moment(this.props.challenge.endDate, calendarDateFormat) : moment(new Date()).format(calendarDateFormat);
    var todayMoment = moment(new Date());
    var todayMomentFormatted = todayMoment.format(calendarDateFormat);

    while (dateMoment <= endDateMoment) {
      var dateFormatted = dateMoment.format(calendarDateFormat);

      if (this.props.challenge.daysOfWeek && this.props.challenge.daysOfWeek.indexOf(dateMoment.format('ddd')) >= 0) { 
        numOfDays += 1;

        if (dateFormatted < todayMomentFormatted)
          numOfDaysGone += 1;
      }
      dateMoment.add(1, 'days');
    }

    if (this.props.date && this.props.date === todayMomentFormatted && this.props.activity && this.props.activity.done)
      numOfDaysGone += 1

    return {
      numOfDays : numOfDays,
      numOfDaysGone : numOfDaysGone,
      percentageGone : (numOfDays) ? Math.round(100 * (numOfDaysGone / numOfDays)) : 0
    }
  }

  cardClicked = () => {
    this.props.onClick(this.props.challengeId, this.props.challenge, this.props.activity)
  }

  infoClicked = (e) => {
    this.props.onInfoClick(this.props.challengeId, this.props.challenge, this.props.activity)

    e.stopPropagation()

//    this.props.onInfoClick(this.props.challengeId, this.props.challenge, this.props.activity)
  }

  imageClicked = (image, e) => {
    e.stopPropagation(); 

    this.displayImageCarouselPopup(image)
  }

  displayImageCarouselPopup = (image) => {
    this.setState({
      showImageCarouselPopup: true,
      selectedImage: this.props.activity.images.indexOf(image)
    })
  }


  renderImages = (images) => {
      var imagesHtml = []
      for (var i = 0; i < Math.min(images.length, 5); i++) {
        imagesHtml.push(<img onClick={this.imageClicked.bind(this,images[i])} width="35" height="35" className='habitActivityCardImage' src={images[i]} alt=''/>)
      }
  
      return <div className='habitActivityCardImageContainer'>{imagesHtml}</div>
  }

  renderHabitDescription = () => {
    var items = [];
    var activity = this.props.activity
    var done = (activity && activity.done) 
    var journal = (activity && activity.journal && activity.journal.trim() !== '') ? activity.journal.trim() : null;
    var percentageGone = this.getPercentageGone()
    var images = (activity && activity.images && activity.images.length > 0) ? activity.images : null;

    if (journal)
      items.push(<div className='habitActivityCardLineItalic'>{journal}</div>)

    if (images) 
      items.push(<div className='habitActivityImages'>{this.renderImages(images)}</div>)

    if (this.props.challenge.endDate) {
      items.push(<div className='habitActivityCardProgressContainer'>
        <div className='habitActivityCardProgressBar'/>
        <div className='habitActivityCardProgressDone' style={{width: 220 * percentageGone.percentageGone / 100.0 }}/>
      </div>)
      items.push(<div className='habitActivityCardLine'>{percentageGone.numOfDaysGone}&#47;{percentageGone.numOfDays} days gone</div>)
    }
    return <div className='habitActivityCardHabitDescription'>
      {items}
    </div>;
  }

  getTickClass = () => {
 
    var todayMoment = moment(new Date());
    var todayMomentFormatted = todayMoment.format(calendarDateFormat);

    if (this.props.date && this.props.date > todayMomentFormatted) {
      return "habitActivityCardNotTickedFuture"
    }
    else if (this.props.date && this.props.date < todayMomentFormatted) {
      return (this.props.activity && this.props.activity.done) ? "habitActivityCardTicked" : "habitActivityCardNotTickedPast"; 
    }
    else if (this.props.activity) {
      return (this.props.activity.done) ? "habitActivityCardTicked" : "habitActivityCardNotTickedPast";
    }
    else {
      return "habitActivityCardNotTicked";
    }
  }

  getVisibilityLabel = (challenge) => {
    var visibility = (challenge.visibility) ? challenge.visibility : getDefaultVisibility();

    return getVisibilityOption(this.props.programType, false, visibility).label;
  }

  getPeriodLabel = (challenge, dateFormatted) => {

    if (challenge.period === 'day')
      return "Today"
    else {
      var dateMoment = moment(dateFormatted, calendarDateFormat);
      var endPeriodMoment = dateMoment.clone().endOf(challenge.period);

      var daysLeft = endPeriodMoment.diff(dateMoment, 'days') + 1;

      if (daysLeft === 1)
        return "Today"
      else
        return daysLeft + " days left" 
    }
  }

  render = () => {
    var visibility = this.getVisibilityLabel(this.props.challenge)
    var period = this.getPeriodLabel(this.props.challenge, this.props.date)

    var objectiveOrCategory = "";

    if (this.props.objective) {
      objectiveOrCategory = this.props.objective;
    }
    else if (this.props.challenge.category) {
      objectiveOrCategory = this.props.challenge.category;
    }
    else {
      objectiveOrCategory = "No category";
    }

    return (
      <div className='habitActivityCardContainer'>
        <div className='habitActivityCard' style={{ borderLeft: '2px solid #' + this.props.challenge.colour}} onClick={this.cardClicked}>

          <div className='habitActivityCardInfoIcon' onClick={this.infoClicked}/>

          <div className='habitActivityCardVisibility'>
            {period}
          </div>
       
          <div className='habitActivityCardCategoryLabel' style={{ backgroundColor: "#" + this.props.challenge.colour }}>{objectiveOrCategory}</div>
          <div className='habitActivityCardHabitName'>{this.props.challenge.name}</div>
          {this.renderHabitDescription()}
          <div className={this.getTickClass()} />

        </div>

        { this.state.showImageCarouselPopup && <ImageCarouselPopup images={this.props.activity.images} selectedImage={this.state.selectedImage} onClose={() => this.setState({showImageCarouselPopup: false})}/> }
      </div>
    )
  }
}

export default HabitActivityCard;