import { relativeTimeThreshold } from 'moment';
import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Button from '../ui/Button'
import SpinnerPanel from '../ui/SpinnerPanel'

import { getUser, getTeamFromServer } from '../util/DatabaseUtils';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  loggingIn: false
};

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  shouldSigninWithRedirect = () => {  
     var userAgent = window.navigator.userAgent.toLowerCase();
     var safari = /safari/.test(userAgent);
     var ios = /iphone|ipod|ipad/.test(userAgent);
     var webView = ios && !safari;
     var standaloneSafari = 'standalone' in window.navigator && window.navigator.standalone;
    
     return (webView || standaloneSafari);
  }

  signInWithGoogle = () => {
    this.setState({ loggingIn: true })

    if (this.shouldSigninWithRedirect()) {
        // After this is finished Google redirects back to this page

      this.props.firebase.doSignInWithGoogleUsingRedirect();
    }
    else {
      this.props.firebase.doSignInWithGoogle()
      .then(() => {
        getUser(this.props.firebase, (user) => {
          if (user) {
            this.props.history.push("/");
          }
          else {
            this.props.firebase.auth.signOut().then(() => {
              this.setState({
                loggingIn: false,
                error: new Error("Account does not exist, create account using Signup")
              })
            }).catch((error) => {
              alert(error);
            });
          }
        })
      });
    }
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.setState({ loggingIn: true })

    event.preventDefault();

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.props.history.push("/");
      })
      .catch(error => {
        this.setState({ loggingIn: false, error });
      });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  resetMyPassword = () => {
    this.props.history.push("/reset-my-password");
  }

  render = () => {

    // In the case of doSignInWithGoogleUsingRedirect, Google redirects back to
    // this page but the user will be logged in
    if (this.props.firebase.user) {
      this.props.history.push("/");
      return <div/>;
    }

    var history = this.props.history;

    const {
      email,
      password,
      error,
    } = this.state;

    const isInvalid =
      password === '' ||
      email === '';

    return (
      <AppChrome title="Login" history={history} requireLogin={false} firebase={this.props.firebase} back={true} tab="Today">
        { this.state.loggingIn 
          ?
          <SpinnerPanel/>
          :
        <div>
          <div className='welcomeTitle'><p>Login to Lasoo...</p></div>

          <div className='welcomeDescription'><p>Please enter your email and password.</p>
                <p>Don't have an existing account? Please wait for an invite email from your program administrator.</p></div>

          <p>
            <input
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email"
            />

            <input
              name="password"
              value={password}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
            />
          </p>

          <Button action="Login" size='large' type='primary' disabled={isInvalid} onClick={this.onSubmit}/>

          <p className='loginTroubleLoggingIn'>Trouble logging in? <span className='resetMyPasswordLink' onClick={this.resetMyPassword}>Reset your password</span></p>

          {error && <p>{error.message}</p>}

          { false && 
          <p>
            <div className='buttonContainer'>
              <button type="button" className='btn-large btn-google' onClick={this.signInWithGoogle}>
                <img src="google_logo.png" width="18" height="18" />&nbsp;&nbsp;Sign in with Google
              </button>
            </div>
          </p>
          }
        </div>
      }
      </AppChrome>
    );
  }
}

export default Login;

