import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import HabitCategoryCard from './HabitCategoryCard';
import PinnedCard from '../ui/PinnedCard'

import { getUserAndTeam } from '../util/DatabaseUtils';

class EditCategories extends Component {

    constructor(props) {
       super(props);
       this.state = { dataLoaded : false };
    }

    checkDataLoaded = () => {
      if (this.props.firebase.user && !this.state.dataLoaded) {
        getUserAndTeam(this.props.firebase, (user, team) => {
          var habitCategories = (team['habit-categories']) ? team['habit-categories'] : {}
          this.setState({
            user: user,
            team: team,
            habitCategories: habitCategories,
            dataLoaded: true
          })
        })
      }
    }
  
    componentWillMount = () => {
      this.checkDataLoaded();
    }
  
    componentDidUpdate = (prevProps, prevState, snapshot) => {
      this.checkDataLoaded();
    }

    habitCategorySelected = (habitCategory) => {
       this.props.history.push('/edit-category/' + habitCategory)
    }

    addCategory = () => {
      this.props.history.push('/add-category');
    }

    onCategoryClick = (id) => {
      this.props.history.push("/category/" + id);
    }

    onCategoryEditClick = (id) => {
      this.props.history.push("/edit-category/" + id);
    }

    onHabitsEditClick = (id) => {
      this.props.history.push("/edit-habits/" + id);

    }

    getHabitCategoryPath = (habitCategory) => {
      var path = ""
      if (habitCategory.parent)
        path = this.state.habitCategories[habitCategory.parent].name + " > " + habitCategory.name;
      else
        path = habitCategory.name;

      if (habitCategory.order)
        path += " [" + habitCategory.order + "]";

      return path
    }

    compareCategoryPaths = (categoryId1, categoryId2) => {
      var habitCategory1 = this.state.habitCategories[categoryId1];
      var habitCategory2 = this.state.habitCategories[categoryId2];
      var path1 = this.getHabitCategoryPath(habitCategory1);
      var path2 = this.getHabitCategoryPath(habitCategory2);

      if (path1 > path2) {
        return 1;
      }
      else if (path1 < path2) {
        return -1;
      }
      else {
        return 0;
      }
    }

    renderHabitCategories = () => {
      var categories = [];
      var habitCategories = this.state.habitCategories;

      if (!habitCategories || habitCategories.size === 0)
        return "No habit categories"

      Object.keys(habitCategories).sort(this.compareCategoryPaths).map((id) => {
        var habitCategory = habitCategories[id];
        var buttonStyles = { 'background-color' : '#' + habitCategory.colour }
        var icon = (habitCategory.icon) ? habitCategory.icon : "check_box";
        var path = this.getHabitCategoryPath(habitCategory)
        categories.push(<HabitCategoryCard id={id} visible={habitCategory.visible} onClick={this.onCategoryEditClick.bind(this, id)} icon={icon} colour={"ea2952"} name={path} description={habitCategory.description} illustration={habitCategory.illustration}/>);

        /*   categories.push(<div className='categoriesEditButtonsContainer'>
          <div className='categoriesEditCategoryButton' style={buttonStyles}  onClick={this.onCategoryEditClick.bind(this, id)}>EDIT CATEGORY</div>
          <div className='categoriesEditHabitsButton'  style={buttonStyles}  onClick={this.onHabitsEditClick.bind(this, id)}>EDIT HABITS</div>
        </div>); */
      })

      return <div>
        <PinnedCard description='Select a category to update or create a new category' action='Create' onButtonClick={this.addCategory}/>
        {categories}
      </div>
    }

    render = () => {
      var user = this.props.firebase.user
      var history = this.props.history;
      var dataLoaded = this.state.dataLoaded;

      if (user && !user.emailVerified)
        this.props.history.push("/verify-email");

      return (
        <AppChrome title="Edit Categories" history={history} requireLogin={true} firebase={this.props.firebase} team={this.state.team} back={true} tab="Discover">
        { dataLoaded
            ?
              this.renderHabitCategories()
            : 
            <div/>
        }
        </AppChrome>
      )
    }
}

export default EditCategories;