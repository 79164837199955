import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';

import { getUser } from '../util/DatabaseUtils'
import { askLumosForHabitsFunction } from '../util/FunctionUtils'

import SpinnerPanel from '../ui/SpinnerPanel'
import LumosTouchpoint from '../lumos/LumosTouchpoint'

const INITIAL_STATE = {
  dataLoaded: false,
  habits : []
};

class DiscoverAskLumos extends Component {

    constructor(props) {
       super(props);
       this.state = { ...INITIAL_STATE };
    }

    checkDataLoaded = () => {
      if (this.props.firebase.user && !this.state.dataLoaded) {
        askLumosForHabitsFunction(this.props.firebase.user.uid, (habits) => {
          this.setState({ dataLoaded: true, habits: (habits) ? habits : [] })
        })
      }
    }

    componentWillMount = () => {
      this.checkDataLoaded();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
      this.checkDataLoaded();
    }

    componentWillUnmount() {
    }

    signout = () => this.props.firebase.doSignOut().then(() => this.forceUpdate())
   
    habitClicked = (habitId) => {
      this.props.history.push('/habit/' + habitId)
    }

    renderHabitCard = (habitId, colour, habitName, categoryName) => {
      
      return (<div onClick={this.habitClicked.bind(this, habitId)} className='onboardingHabitCard' style={{ 'border-left': '4px solid #' + colour}}>
        <div className='onboardingHabitCardCategory' style={{ 'color' : '#' + colour}}>{categoryName}</div>
        <div className='onboardingHabitCardName'>{habitName}</div>
       </div>);
    }

    renderHabits = () => {
      var results = []
    
      this.state.habits.forEach((habit) => {
        results.push(this.renderHabitCard(habit.id, habit.colour, habit.name, habit.category))
      })
      return results
    }

    render = () => {
      var user = this.props.firebase.user
      var history = this.props.history;

      var title = "Ask Lumos";
      
      return (
        <AppChrome title={title} history={history} requireLogin={true} firebase={this.props.firebase} back="/goals" tab="Habits">

            { this.state.dataLoaded ?
            <div>
                <LumosTouchpoint description='Some habits you may consider for your goals are...'/>
                { this.renderHabits() }
            </div>
              :
              <SpinnerPanel/>
            }
           
        </AppChrome>
      )
    }
}

export default DiscoverAskLumos;