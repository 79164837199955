import React, { Component } from 'react';

import Popup from 'reactjs-popup';
import PopupHeader from '../ui/PopupHeader'

import { getVisibilityOptions } from '../util/VisibilityUtils';

import 'reactjs-popup/dist/index.css';

class VisibilitySelectorPopup extends Component {

  constructor(props) {
      super(props);
      this.state = { 
      };
  } 

  componentWillMount = () => {

  }

  optionClicked = (value) => {
    if (this.props.callback)
      this.props.callback(value)
  }

  renderVisibilityOption = (option) => {
    return <div className='cardRow' onClick={this.optionClicked.bind(this, option.value)}>
      <div className='cardRowIcon' >
         <div className="material-icons-outlined md-30">{option.icon}</div>
      </div>
      <div className='cardRowText'>{option.label} {option.recommended ? "(Recommended)" : ""}</div>
    </div>
  }

  renderVisibilityOptions = () => {
    var items = [];
    var options = getVisibilityOptions(this.props.programType, this.props.disableTeamVisibility);
    var isFirst = true;

    for (var option of options) {
      items.push(this.renderVisibilityOption(option))
    }
 
    return items;
  }

  render = () => {  
    return (
        <Popup open="true" closeOnDocumentClick className='visibility-selector-popup' onClose={this.props.onClose}>
          <PopupHeader title="Select Visibility"/>
          { this.renderVisibilityOptions() }
        </Popup>
    );
  }
};

export default VisibilitySelectorPopup;
