import AddSelectedHabit from './AddSelectedHabit.js'

class AddViewedHabit extends AddSelectedHabit {

  constructor(props) {
    super(props);
  }

  getTab = () => {
    return "Discover";
  }

  getBackURL = () => {
    return '/habit/' + this.props.match.params.habit;
  }

  navigateAway = () => {
    this.props.history.push(this.getBackURL());
  }
}

export default AddViewedHabit;