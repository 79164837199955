import React, { Component } from 'react';

import moment from 'moment';

var quotes = [
  { "quote" : "Habit is at the intersection of knowledge, skill, and desire.",
    "author": "Stephen R. Covey" },

  { "quote" : "The more I practice, the luckier I get.",
    "author": "Arnold Palmer" },

  { "quote" : "All things are difficult before they are easy.",
    "author" : "Thomas Fuller" },

  { "quote" : "There is no elevator to success, you have to take the stairs.",
    "author": "Zig Zigler" },

  { "quote" : "Don't strive to be a success, but rather to be of value.",
    "author": "Albert Einstein" },

  { "quote" : "We are what we repeatedly do. Excellence, therefore, is not an act but a habit.",
    "author": "Aristotle" },
   
  { "quote" : "Drop by drop is the water pot filled.",
    "author": "Buddha" },

  { "quote" : "A person who never made a mistake never tried anything new.",
    "author": "Albert Einstein" },

  { "quote" : "If you are going to achieve excellence in big things, you develop the habit in little matters.",
    "author": "Colin Powell" },
    
  { "quote" : "Motivation is what gets you started. Habit is what keeps you going.",
    "author": "John Rohn" },  

  { "quote" : "First we make our habits, then our habits make us.",
    "author": "John Dryden" },

  { "quote" : "It’s a marathon, not a sprint.",
    "author" : "Mary Barra" },

  { "quote" : "Everybody does a better job when they're able to balance.",
    "author" : "Mary Barra" },

  { "quote" : "Growth and comfort do no coexist.",
    "author" : "Ginni Rometty" },
    
  { "quote" : "Be first and be lonely.",
    "author" : "Ginni Rometty" },

  { "quote" : "The most important thing for any of us to be in our jobs is curious.",
    "author" : "Ginni Rometty" },

  { "quote" : "Do one thing every day that scares you.",
    "author" : "Eleanor Roosevelt" },

  { "quote" : "I am who I am today because of the choices I made yesterday.",
    "author" : "Eleanor Roosevelt" },

  { "quote" : "Have no fear of perfection; you'll never reach it.",
    "author" : "Marie Curie" },
  
  { "quote" : "The most difficult thing is the decision to act, the rest is merely tenacity.",
  "author" : "Amelia Earhart" },

  { "quote" : "Habits change into character.",
    "author": "Ovid" },

  { "quote" : "Make the most of yourself by fanning the tiny, inner sparks of possibility into the flames of achievement.",
    "author" : "Golda Meir" },

  { "quote" : "Done is better than perfect.",
    "author" : "Sheryl Sandberg" },

  { "quote" : "The chains of habit are too light to be felt until they are too heavy to be broken.",
    "author" : "Warren Buffet" },

  { "quote" : "Good habits make time your ally. Bad habits make time your enemy.",
    "author" : "James Clear" },

  { "quote" : "People do not decide their futures, they decide their habits and their habits decide their futures.",
    "author" : "F. M. Alexander" },

  { "quote" : "Consistency finishes what intensity starts.",
    "author" : "Shane Parrish" },

  { "quote" : "Not every problem needs to be overcome, just the ones stopping you from getting where you want to be.",
    "author" : "Ann Hill" },

  { "quote" : "It's not about ideas, it's about making ideas happen.",
    "author" : "Scott Belsky" },

  { "quote" : "Smile in the mirror. Do that every morning and you'll start to see a big difference in your life.",
    "author" : "Yoko Ono" },

  { "quote" : "People change by feeling good, not by feeling bad.",
    "author" : "BJ Fogg" },

  { "quote" : "It is easier to prevent bad habits than break them.",
    "author" : "Benjamin Franklin" },

  { "quote" : "Tension is a habit. Relaxing is a habit. Bad habits can be broken, good habits formed.",
    "author" : "William James" },

  { "quote" : "Most of the choices we make each day may feel like the products of well-considered decision making, but they're not. They're habits.",
    "author": "Charles Duhigg" },

  { "quote" : "Bad habits are easier to abandon today than tomorrow.",
    "author" : "Yiddish Proverb" },

  { "quote" : "Habits are where our lives and careers and bodies are made",
    "author" : "Seth Godin" },

  { "quote" : "Watch your habits, they determine your character.",
    "author" : "Winston Churchill" },

  { "quote" : "Habit is a cable; we weave a thread of it each day, and at last we cannot break it.",
    "author" : "Horace Mann"},

  { "quote" : "The hard must become habit. The habit must become easy. The easy must become beautiful.",
    "author" : "Doug Henning"},

  { "quote" : "Nothing so needs reforming as other people's habits.",
    "author" : "Mark Twain"},

  { "quote" : "Creativity is a habit, and the best creativity is the result of good work habits.",
    "author" : "Twyla Tharp"},

  { "quote" : "Consistency before intensity. Start small and become the kind of person who shows up every day. Build a new identity. Then increase the intensity.",
    "author" : "James Clear" },

  { "quote" : "Good choices create opportunities. Good habits make the most of them.",
    "author" : "James Clear" },

  { "quote" : "You do not rise to the level of your goals. You fall to the level of your systems.",
    "author" : "James Clear" },

  { "quote" : "If you do something every day, it's a system. If you're waiting to achieve something in the future, it's a goal.",
    "author" : "Scott Adams" },

  { "quote" : "You'll feel better once you get started.",
    "author": "James Clear" },

  { "quote" : "Routine is not a prison, it's a platform for creativity. Without routine, there can be no creativity.",
    "author" : "Twyla Tharp" },

  { "quote" : "Urgent things are not always important, and the important things are not always urgent.",
    "author" : "Dwight Eisenhower" },

  { "quote" : "The key is not to prioritize what's on your schedule, but to schedule your priorities.",
    "author" : "Stephen Covey" },

  { "quote" : "The journey is the reward.",
    "author" : "Chinese Proverb" },

  { "quote" : "Focus on the process, not the outcome.",
    "author" : "Tim Ferriss" },

  { "quote" : "When you improve a little each day, eventually big things occur.",
    "author" : "John Wooden" },

  { "quote" : "Your habits and routines are your identity in action.",
    "author" : "Brendon Burchard" },

  { "quote" : "Success is nothing more than a few simple disciplines, practiced every day.",
    "author" : "Jim Rohn" },

  { "quote" : "Your daily routine is the backbone of your identity.",
    "author" : "Caroline Leaf" },

  { "quote" : "Habits are the invisible architecture of everyday life.",
    "author": "Gretchen Rubin" },

  { "quote" : "Success is the sum of small efforts, repeated day in and day out.",
    "author" : "Robert Collier" },

  { "quote" : "Time is a created thing. To say 'I don't have time,' is like saying, 'I don't want to.'",
    "author" : "Lao Tzu" },

  { "quote" : "The future depends on what you do today.",
    "author" : "Mahatma Gandhi" },

  { "quote" : "The future belongs to those who prepare for it today.",
    "author" : "Malcolm X" },

  { "quote" : "Don't wait for opportunities, create them.",
    "author" : "Chris Grosser" },

  { "quote" : "By far the best investment you can make is in yourself.",
    "author" : "Warren Buffett" },

  { "quote" : "Habits are the compound interest of improvement.",
    "author" : "James Clear" },
  
  { "quote" : "Goals are good for setting a direction, but systems are best for making progress.",
    "author" : "James Clear" },

  { "quote" : "Real behavioural change is identity change.",
    "author" : "James Clear" },

  { "quote" : "If you plant behaviour in the right spot it will grow without coaxing.",
    "author" : "BJ Fogg" },

  { "quote" : "Small steps, taken consistently over time, lead to big results.",
    "author": "John C. Maxwell" },

  { "quote" : "The person who moves a mountain begins by carrying away small stones.", 
    "author" : "Confucius" },

  { "quote" : "The most effective way to change your habits is to focus not on what you want to achieve, but on who you wish to become.",
    "author" : "James Clear" },

  { "quote" : "You can't change what you don't acknowledge.",
    "author" : "Dr. Phil McGraw" },

  { "quote" : "The biggest room in the world is the room for improvement",
    "author" : "Helmut Schmidt" },

  { "quote" : "Continuous improvement is better than delayed perfection.",
    "author" : "Mark Twain" },

  { "quote": "Feedback is the breakfast of champions.",
    "author": "Ken Blanchard" },

  { "quote" : "The goal is not to run a marathon, the goal is to become a runner... Focus on the identity you want to build.",
    "author" : "James Clear" },

  { "quote" : "Be the hero of your own movie.",
    "author" : "Joe Rogan" },

  { "quote" : "Everyone you meet is fighting a battle you know nothing about. Be kind. Always.",
    "author" : "Robin Williams" },

  { "quote": "The two things in life you in total control over are your attitude and your effort", 
    "author": "Billy Cox" },

  { "quote" : "Success is not final, failure is not fatal: It is the courage to continue that counts.",
    "author" : "Bonnie Prudden" },

  { "quote" : "I am not afraid of storms, for I am learning how to sail my ship.",
    "author" : "Louisa May Alcott" },

  { "quote" : "The only way to do great work is to love what you do.",
    "author" : "Esther Dyson" },

  { "quote" : "Do the best you can until you know better. Then when you know better, do better.",
    "author" : "Maya Angelou" },

  { "quote" : "True behaviour change is identity change. You are simply acting like the type of person you already believe yourself to be.",
    "author" : "James Clear" },

  { "quote" : "Sometimes all you need for exceptional results is average effort repeated for an above-average amount of time.",
    "author" : "James Clear" },

  { "quote" : "The obstacle is the way",
    "author" : "Marcus Aurelius" },

  { "quote" : "The more often we do a thing, the more easy it becomes. Not that the nature of the thing itself has changed, but our power to do it has increased.",
    "author" : "Musonius Rufus" },

  { "quote" : "Progress is not achieved by luck or accident, but by working on yourself daily.",
    "author" : "Epictetus" },

  { "quote" : "Champions keep playing until they get it right.",
    "author": "Billie Jean King" },

  { "quote" : "When action is our priority, vanity falls away.",
    "author" : "Ryan Holiday" },
  
  { "quote" : "The obstacle in the path becomes the path. Never forget, within every obstacle is an opportunity to improve our condition.",
    "author" : "Ryan Holiday" },
  
  { "quote" : "Start before you're ready. Don't prepare, begin.",
    "author" : "Mel Robbins" },

  { "quote" : "If you have the courage to start, you have the courage to succeed.",
    "author" : "Mel Robbins" },

  { "quote" : "Win or lose, at least I'm doing something",
    "author" : "Mel Robbins" },

  { "quote" : "If you want to make your dreams come true, get ready for the long game.",
    "author" : "Mel Robbins" },

  { "quote" : "Small things are not small at all. They are the most important things of all. And they add up.",
    "author" : "Mel Robbins" },

  { "quote" : "Either you run the day or the day runs you.",
    "author" : "Mel Robbins" },

  { "quote" : "Habit is, as it were, a second nature.",
    "author" : "Cicero" },

  { "quote" : "Knowing is not enough; we must apply. Willing is not enough; we must do.",
    "author" : "Goethe" },

  { "quote" : "Stability is not immobility.",
    "author" : "Klemens von Metternich" },

  { "quote" : "If we are facing in the right direction, all we have to do is keep on walking.",
    "author" : "Joseph Goldstein" },

  { "quote" : "Emotions create habits. Not repetition. Not frequency. Not fairy dust. Emotions.",
    "author" : "BJ Fogg" },

  { "quote" : "Know what you want. Go after it relentlessly.",
    "author" : "James Clear" },

  { "quote": "If you habits don't line up with your dream, then you need to either change your habits or change your dream.",
    "author": "John Maxwell" },

  { "quote" : "There is no influence like the influence of habit.",
    "author" : "Gilbert Parker" },

  { "quote": "Once you understand that your habits can change, you have the freedom and the responsibility to remake them.",
    "author": "Charles Duhigg" },

  { "quote": "To love your habits is to love your days, and to love your days is to love your life.",
    "author": "James Clear" },

  { "quote": "Do not be too timid and squeamish about your actions. All life is an experiment. The more experiments you make the better.",
    "author": "Ralph Waldo Emerson" },

  { "quote" : "The climb is the fun part.",
    "author" : "James Clear" },
   
  { "quote" : "Process saves us from the poverty of our intentions.", 
    "author" : "Elizabeth King" },

  { "quote" : "Professionals stick to a schedule. Amateurs let life get in the way.",
    "author" : "James Clear" },
];

class QuotePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (this.props.quote && this.props.author) {
      this.state.quote = {
        "quote" : this.props.quote,
        "author" : this.props.author
      }
    } 
    else {
      this.state.quote = quotes[this.selectDailyQuote(moment().dayOfYear())];
    }
  }

  selectDailyQuote = (seed) => {
    var x = Math.sin(seed) * 10000;
    return Math.floor((x - Math.floor(x)) * (quotes.length-1)) ;
  }

  render = () => {
    return (
      <div className="quotePanel">
        <div className="quotePanelQuote">{this.state.quote.quote}</div>
        <div className="quotePanelAuthor">{this.state.quote.author}</div>
      </div>
    );
  }
}

export default QuotePanel;
