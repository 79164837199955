import React, { Component } from 'react';

import Button from '../ui/Button'
import Popup from 'reactjs-popup';
import PopupHeader from '../ui/PopupHeader'
import Select from "react-select";

import 'reactjs-popup/dist/index.css';

class EmailReminderPopup extends Component {

  constructor(props) {
      super(props);
      this.state = { 
        reminder : props.value
      };
  } 

  componentWillMount = () => {

  }

  onChangeReminderTime = (e) => {
    var reminder = this.state.reminder;

    reminder.time = e.target.value;

    this.setState({ reminder: reminder })
  }

  onOK = () => {
    this.props.onSubmit(this.state.reminder)
  }

  onCancel = () => {
    this.props.onClose();
  }

  dayOfWeekChanged = (selectedOption) => {
    const reminder = this.state.reminder;
    reminder.day = selectedOption.value;

    this.setState({ reminder: reminder});
  }

  renderDayOfWeekSelector = (day) => {
    
      var options = [
        { value: 'Sun', label: 'Sun' },
        { value: 'Mon', label: 'Mon' }, 
        { value: 'Tue', label: 'Tue' }, 
        { value: 'Wed', label: 'Wed' }, 
        { value: 'Thu', label: 'Thu' }, 
        { value: 'Fri', label: 'Fri' }, 
        { value: 'Sat', label: 'Sat' }
      ];

      const defaultDay = this.state.reminder.day ? this.state.reminder.day : 'Mon';
      const defaultValue = options.filter(option => option.value === defaultDay)

      return <>
        <div className="sectionTitle">DAY</div>
        <div className='emailReminderPopupDaySelector'><Select name="dayOfWeek" defaultValue={defaultValue} options={options} onChange = {this.dayOfWeekChanged}/></div>
      </>;

  }

  render = () => {
    var habit = this.props.habit;

    return (
        <Popup open="true" closeOnDocumentClick className='email-reminder-popup' onClose={this.props.onClose}>
          <PopupHeader title={this.props.title}/>

          { this.props.showDayOfWeek && this.renderDayOfWeekSelector(this.state.day) }

          <div className='sectionTitle'>REMINDER TIME</div>
          <div className='calendarEventCard'>
            <input type='time' step='60' value={this.state.reminder.time} onChange={this.onChangeReminderTime}/>
          </div>

          <div className='calendarEventPopupCancelButton'>
            <Button type='secondary' size='large' action='Cancel' onClick={this.onCancel}/>   
          </div>     

          <div className='calendarEventPopupOKButton'>
            <Button type='primary' size='large' action='OK' onClick={this.onOK}/>   
          </div>             
        </Popup>
    );
  }
};

export default EmailReminderPopup;
