
export function getUser(fb, callback, timeoutCallback) {
    var callSuccessful = false

    if (timeoutCallback) {
      setTimeout(() => {
        if (!callSuccessful) {
          if (timeoutCallback)
            timeoutCallback()
        }
      },
      8000);
    }

    fb.state = 'Fetching user'
    
    fb.database.ref('users/' + fb.user.uid)
    .once('value')
    .then ((snapshot) => {
      callSuccessful = true
      const data = snapshot.val();
      callback(data);
    });
}

export function setUserField(fb, field, fieldValue, callback) {
  fb.database.ref('users/' + fb.user.uid + '/' + field).set(fieldValue)
  .then(() => {
    callback();
  });
}

var lastFetchedTeamFromServer = null;
const cacheTimeout = 5 * 60 * 1000;

export function fetchTeamFromCache(fb, teamId, callback) {
  fb.firestore
  .collection('teams')
  .doc(teamId)
  .get({ source: 'cache' })
  .then(callback)
}

export function fetchTeamFromServer(fb, teamId, callback) {
  fb.firestore
  .collection('teams')
  .doc(teamId)
  .get({ source: 'server' })
  .then((doc) => {
    lastFetchedTeamFromServer = new Date().getTime();
    callback(doc);
  });
}

export function getUserAndTeam(fb, callback, timeoutCallback) {
    var callSuccessful = false

    if (timeoutCallback) {
      setTimeout(() => {
        if (!callSuccessful) {
          if (timeoutCallback)
            timeoutCallback()
        }
      },
      8000);
    }

    getUser(fb, (user) => {
        var teamId = (user && user.team) ? user.team : null;

        if (!teamId) {
            callSuccessful = true;

            callback(user, null)
            return
        }

        const getFromServer = !lastFetchedTeamFromServer || (new Date().getTime() - lastFetchedTeamFromServer) > cacheTimeout || !fb.firestorePersistenceEnabled;

        if (getFromServer) {
          fb.state = 'Fetching team from server 1'

          fetchTeamFromServer(fb, teamId, (doc) => {
            callSuccessful = true;

            if (doc.exists) {
              var team = doc.data();
              callback(user, team)
            }
            else {
              alert("Attempted to fetch team from server but it doesn't exist")
            }
          })
        }
        else {
          try {
            fb.state = 'Fetching team from cache'

            fetchTeamFromCache(fb, teamId, (doc) => {
              if (doc.exists) {
                callSuccessful = true;

                var team = doc.data();
                callback(user, team)
              }
            })
          }
          catch(e) {

            fb.state = 'Fetching team from server 2'

            fetchTeamFromServer(fb, teamId, (doc) => {
              callSuccessful = true;

              if (doc.exists) {
                var team = doc.data();              
                callback(user, team)
              }
              else {
                alert("Attempted to fetch team from server but it doesn't exist")
              }
            })
          }
        }
    })
  }

  export function getTeams(fb, callback) {
      fb.firestore
      .collection('teams')
      .get()
      .then((snapshot) => {
        callback(snapshot);
    });
}
