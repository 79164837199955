import React, { Component } from 'react';
import { isJSDocThisTag } from 'typescript';

import AppChrome from '../layout/AppChrome';
import Profile from './Profile';

import { getUserAndTeam } from '../util/DatabaseUtils';

class TeamMember extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      dataLoaded: false,
    };
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {

      getUserAndTeam(this.props.firebase, (user, team) => {
        this.setState({ dataLoaded : true, user: user, team : team })
      });

    }
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  componentWillUnmount() {
  }

  signout = () => this.props.firebase.doSignOut().then(() => this.forceUpdate())

  logout = () => {
    this.props.firebase.auth.signOut().then(() => {
      this.props.history.push("/");
    }).catch((error) => {
      alert(error);
    });
  }

  getTitle = () => {
    if (this.state.dataLoaded)
      return this.state.name;
    else 
      return " ";
  }

  onGoalClicked = (goalId) => {
    if (this.props.location.pathname.startsWith("/team-member-activity"))
      this.props.history.push("/goal-report-member-activity/" + this.props.match.params.user + "/" + goalId)
    else
      this.props.history.push("/goal-report-member/" + this.props.match.params.user + "/" + goalId)
  }

  
  onLevelClicked = () => {
    this.props.history.push("/levels-and-points");
  }

  getTab = () => {
    if (this.props.location.pathname.startsWith("/team-member-activity"))
      return "Activity";
    else
      return "Today";
  }
  
  render = () => {
    var history = this.props.history;

    return (
      <AppChrome title="Team Member" history={history} user={this.state.user} team={this.state.team} requireLogin={true} firebase={this.props.firebase} back={true} tab={this.getTab()}>
        <Profile firebase={this.props.firebase} history={this.props.history} onGoalClicked={this.onGoalClicked} onLevelClicked={this.onLevelClicked} user={this.props.match.params.user}/>
      </AppChrome>
    )
  }
}

export default TeamMember;