import React, { Component } from 'react';

import Avatar from '../ui/Avatar';
import Popup from 'reactjs-popup';
import PopupHeader from '../ui/PopupHeader';

import { getUserAndTeam } from '../util/DatabaseUtils';

const INITIAL_STATE = {
  team: null,
  members : [],
  dataLoaded: false
};

class ActivityLikesPopup extends Component {

  constructor(props) {
      super(props);
      this.state = INITIAL_STATE;
  } 

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {

      getUserAndTeam(this.props.firebase, (user, team) => {
        var members = this.getTeamMembersOrdered(team)

        this.setState({
          team: team,
          members: members,
          dataLoaded: true
        });
      })
    }
  }

  getTeamMembersOrdered = (team) => {
    var members = []

    for (var memberEmailKey in team.member) {
      var member = {};

      member.userId = team.member[memberEmailKey]['user-id']
      member.name = team.member[memberEmailKey].name
      if (team.member[memberEmailKey].photoURL) {
        member.photoURL = team.member[memberEmailKey]['photo-url']
      }
      members.push(member);
    }

    members.sort((m1, m2) => {
      if (m1.name < m2.name)
        return -1;
      else if (m1.name > m2.name)
        return 1;
      else
        return 0;
    });

    return members;
  }

  teamMemberClicked = (i) => {
    this.props.callback(this.state.members[i])
  }

  renderLikes = () => {
    var items = [];
    var date = this.props.date;
    var index = this.props.index;
    var team = this.state.team;

    for (var i = 0; i < this.state.members.length; i++) {
      var member = this.state.members[i]

      if (team.activityLikes && team.activityLikes[date] && team.activityLikes[date][index] && team.activityLikes[date][index][member.userId]) {
        items.push(<div className='teamMembersPopupRow'>
          <div className='teamMembersPopupRowAvatar'><Avatar size='medium' name={member.name} image={member.photoURL}/></div>
          <div className='teamMembersPopupRowName'>{member.name}</div>
        </div>)
      }
    }
    return <div className='teamMembersPopupContainer'>{items}</div>;
  }

  render = () => {
    return <div>
        { 
          <Popup open="true" closeOnDocumentClick className='activity-likes-popup' onClose={this.props.onClose}>
                <PopupHeader title='Liked By'/>
                { this.state.dataLoaded ?
                    this.renderLikes()
                    : <div/>
                }
          </Popup>
        }
    </div>;
  }
};

export default ActivityLikesPopup;
