import React, { Component } from 'react';

const INITIAL_STATE = {

};

/*
 * Props 
 *   type = 'primary'|'secondary'
 *   size = 'medium'|'small'
 *   onClick = function
 */
class Star extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  } 

  render = () => {
    var colour = "#" + this.props.colour;
  
    if (this.props.points === 7)
      return <svg width="96" height="92" viewBox="0 0 96 92" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_616_9)">
          <path d="M38.5664 7.92205C41.1644 -1.69538 54.8356 -1.69538 57.4336 7.92205V7.92205C58.9723 13.6181 65.2022 16.6476 70.6245 14.3216L71.6067 13.9003C80.5452 10.0659 88.7111 20.6619 82.754 28.3503V28.3503C79.1313 33.0258 80.6734 39.8516 85.9589 42.5064V42.5064C94.6753 46.8845 91.4077 60.0137 81.6579 60.3037V60.3037C75.76 60.4792 71.3355 65.9467 72.5094 71.7292V71.7292C74.4624 81.3492 62.4178 87.3928 55.936 80.0209L55.3678 79.3746C51.4609 74.9311 44.5391 74.9311 40.6322 79.3746L40.064 80.0209C33.5822 87.3928 21.5376 81.3492 23.4906 71.7292V71.7292C24.6645 65.9467 20.24 60.4792 14.3421 60.3037V60.3037C4.59229 60.0137 1.32474 46.8845 10.0411 42.5064V42.5064C15.3266 39.8516 16.8687 33.0258 13.246 28.3503V28.3503C7.2889 20.6619 15.4548 10.0659 24.3933 13.9002L25.3755 14.3216C30.7978 16.6476 37.0277 13.6181 38.5664 7.92205V7.92205Z" fill={colour}/>
          <path d="M71.2159 15.7001L72.198 15.2788C79.7023 12.0597 86.5845 20.9574 81.5682 27.4316C77.3683 32.8521 79.1547 40.7674 85.2856 43.8468C88.9303 45.6775 90.0808 49.2936 89.2762 52.5815C88.4688 55.8807 85.7271 58.682 81.6133 58.8044C74.8057 59.0069 69.6764 65.3139 71.0394 72.0277C72.6922 80.1691 62.5109 85.2271 57.0625 79.0304L56.4943 78.3841C51.99 73.2613 44.01 73.2613 39.5057 78.3841L38.9375 79.0305C33.489 85.2271 23.3078 80.1691 24.9606 72.0277C26.3236 65.3139 21.1943 59.0069 14.3867 58.8044C10.2729 58.682 7.53119 55.8807 6.7238 52.5815C5.91916 49.2936 7.06971 45.6775 10.7144 43.8468C16.8453 40.7674 18.6317 32.8521 14.4318 27.4316C9.41545 20.9574 16.2977 12.0597 23.802 15.2788L24.7841 15.7001C31.0482 18.3872 38.2387 14.8869 40.0145 8.31323C42.2131 0.174229 53.7869 0.174229 55.9855 8.31323C57.7613 14.8869 64.9517 18.3872 71.2159 15.7001Z" stroke="white" stroke-width="3"/>
          </g>
          <defs>
            <filter id="filter0_d_616_9" x="0.981689" y="0.708984" width="94.0366" height="90.5933" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_616_9"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_616_9" result="shape"/>
            </filter>
          </defs>
        </svg>
    else if (this.props.points === 5)
      return <svg  width="90" height="86" viewBox="0 0 90 86" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_618_14)">
        <path d="M31.5014 9.92543C35.9259 -2.83322 54.0741 -2.83322 58.4986 9.92543V9.92543C60.4313 15.4987 65.6728 19.2903 71.5714 19.3416L72.3978 19.3488C85.6443 19.464 91.013 36.4562 80.2373 44.1612V44.1612C75.3797 47.6346 73.3429 53.8788 75.1946 59.5561V59.5561C79.2998 72.1424 65.119 82.8462 54.2145 75.3388L53.1073 74.5766C48.225 71.2153 41.775 71.2153 36.8927 74.5766L35.7855 75.3388C24.881 82.8462 10.7002 72.1424 14.8054 59.5561V59.5561C16.6571 53.8788 14.6203 47.6346 9.76269 44.1612V44.1612C-1.013 36.4562 4.3557 19.464 17.6022 19.3488L18.4286 19.3416C24.3272 19.2903 29.5687 15.4987 31.5014 9.92543V9.92543Z" fill={colour}/>
        <path d="M71.5584 20.8416L72.3848 20.8488C84.1791 20.9513 88.9592 36.0807 79.3648 42.941C73.9723 46.7969 71.7147 53.7244 73.7685 60.0212C77.4367 71.2676 64.7672 80.7829 55.0651 74.1033L53.9579 73.3411C48.5634 69.6271 41.4366 69.6271 36.0421 73.341L34.9349 74.1033C25.2328 80.7829 12.5633 71.2676 16.2315 60.0212C18.2853 53.7244 16.0277 46.7969 10.6352 42.941C1.04077 36.0807 5.82091 20.9513 17.6152 20.8488L18.4416 20.8416C24.9792 20.7847 30.7797 16.5849 32.9186 10.4169C36.8768 -0.997038 53.1232 -0.997038 57.0814 10.4169C59.2203 16.5849 65.0208 20.7847 71.5584 20.8416Z" stroke="white" stroke-width="3"/>
        </g>
        <defs>
        <filter id="filter0_d_618_14" x="0.0134277" y="0.356445" width="89.9731" height="85.4712" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_618_14"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_618_14" result="shape"/>
        </filter>
        </defs>
      </svg>
      
    else if (this.props.points === 4)
      return <svg  width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_617_11)">
      <path d="M45.4489 1.80919C60.4315 -4.69969 75.6393 10.5081 69.1304 25.4908L68.6287 26.6457C66.608 31.2971 66.6693 36.59 68.7973 41.1934V41.1934C75.7392 56.2105 60.2105 71.7392 45.1934 64.7973V64.7973C40.59 62.6693 35.2971 62.608 30.6456 64.6287L29.4907 65.1305C14.5081 71.6393 -0.699712 56.4315 5.80917 41.4489L6.3109 40.294C8.33162 35.6425 8.27028 30.3496 6.1423 25.7463V25.7463C-0.799599 10.7292 14.7291 -4.79957 29.7462 2.14233V2.14233C34.3496 4.27031 39.6425 4.33165 44.2939 2.31093L45.4489 1.80919Z" fill={colour}/>
      <path d="M67.7546 24.8931L67.2529 26.048C65.0617 31.0918 65.1283 36.8311 67.4357 41.8228C73.7921 55.5732 59.5732 69.7921 45.8228 63.4358C40.8311 61.1283 35.0918 61.0618 30.048 63.2529L28.893 63.7547C15.159 69.7211 1.21848 55.7806 7.18495 42.0466L7.68669 40.8916C9.87785 35.8479 9.81133 30.1085 7.50386 25.1168C1.14751 11.3664 15.3664 -2.85247 29.1168 3.50389C34.1085 5.81136 39.8478 5.87788 44.8916 3.68671L46.0465 3.18498C59.7806 -2.7815 73.7211 11.159 67.7546 24.8931Z" stroke="white" stroke-width="3"/>
      </g>
      <defs>
      <filter id="filter0_d_617_11" x="0.262329" y="0.262207" width="74.415" height="74.415" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_617_11"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_617_11" result="shape"/>
      </filter>
      </defs>
      </svg>
      
  }
}

export default Star;