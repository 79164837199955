import React, { Component } from 'react';

class PopupHeader extends Component {
  constructor(props) {
    super(props);
  }

  headerClicked = () => {
    if (this.props.onClick)
      this.props.onClick();
  }

  render() {
    return (
      <div onClick={this.headerClicked} className="Popup-header">
        {this.props.title}
        {this.props.onClick && <div className='popupHeaderChevron'>›</div>}
      </div>
    );
  }
}

export default PopupHeader;