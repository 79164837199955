import Button from '../ui/Button'

import React, { Component } from 'react';

const INITIAL_STATE = {

};

class AppWelcomeForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  login = () => {
    this.props.history.push("/login");
  }

  signUp = () => {
    this.props.history.push("/signup");
  }

  render = () => {
    return ( 
      <form>
        <div className='welcomeTitle'>Welcome to Lasoo</div>

        <div className='welcomeDescription'><p>Improve your work life with great habits.</p></div>

        <p><Button type='primary' size='large' onClick={() => this.login()} action='Web Login'/></p>
      
        <div className="downloadOnAppStore"><a href="https://apps.apple.com/app/lasoo/id6443648678"><img width='200' src="/download-on-app-store.png"/></a></div>
     </form>
    )
  }
}

export default AppWelcomeForm;