import moment, { min } from 'moment';

import firebase from 'firebase/app';

const calendarDateFormat = 'YYYY-MM-DD';

export function teamsStatsUpdate(fb, userId, habitId, callback) {
  fb.database.ref().child("users").child(userId).get().then((snapshot) => {
    if (snapshot.exists()) {
      var userData = snapshot.val();

      var currentStreak = 0;
      var longestStreak = 0;
      var timesDone = 0;

      var challenge = null;
      var fields = {};

      for (var challengeId in userData.challenges) {
        challenge = userData.challenges[challengeId];
        if (challenge.habit && challenge.habit === habitId) {
//          fields["habits." + habitId + ".colour"] = (challenge.colour) ? challenge.colour : '000000';
 //         fields["habits." + habitId + ".name"] = challenge.name;

          if (challenge.stats.currentStreak > currentStreak)
            currentStreak = challenge.stats.currentStreak;
          if (challenge.stats.longestStreak > longestStreak)
            longestStreak = challenge.stats.longestStreak;
             
          timesDone += challenge.stats.habitDone;
        }
      }

      fields["stats.habits." + habitId + ".users." + userId] = {
        'current-streak' : currentStreak,
        'longest-streak' : longestStreak,
        'times-done' : timesDone
      } 

      if (userData.team) {
        fb.firestore
        .collection('teams')
        .doc(userData.team)
        .update(fields)
        .then(() => {
        })
        .catch((error) => {
          alert(error);
        });
      }
    }

    if (callback) {
      callback();
    }
  });
}

export function calculateTotalDays(challengeId, userData) {
  var totalDays = 0
  
  var challenge = userData.challenges[challengeId]
  var currentDate = challenge.endDate;

  while (currentDate >= challenge.startDate) {
    if (challenge.period !== 'day' && challenge.daysOfWeek.indexOf(moment(currentDate,calendarDateFormat).format('ddd')) >= 0) 
        totalDays += 1
    currentDate = moment(currentDate, calendarDateFormat).add(-1, 'days').format(calendarDateFormat);
  }
  return totalDays
}

export function calculateDaysDoneBeforeDate(challengeId, userData, dateFormatted) {
  var daysDone = 0
  var currentDate = moment(dateFormatted, calendarDateFormat).add(-1, 'days').format(calendarDateFormat);
  var challenge = userData.challenges[challengeId]

  while (currentDate >= challenge.startDate) {
    if (challenge.period !== 'day' || challenge.daysOfWeek.indexOf(moment(currentDate,calendarDateFormat).format('ddd')) >= 0) {
      if ('days' in userData && currentDate in userData.days && challengeId in userData.days[currentDate] && userData.days[currentDate][challengeId].done)
        daysDone += 1
    }
    currentDate = moment(currentDate, calendarDateFormat).add(-1, 'days').format(calendarDateFormat);
  }
  return daysDone
}

export function calculateDaysDoneBeforeDateByDayOfWeek(challengeId, userData, dateFormatted) {
  var daysDone = {}
  var currentDate = moment(dateFormatted, calendarDateFormat).add(-1, 'days').format(calendarDateFormat);
  var challenge = userData.challenges[challengeId]

  while (currentDate >= challenge.startDate) {
    if (challenge.period !== 'day' || challenge.daysOfWeek.indexOf(moment(currentDate,calendarDateFormat).format('ddd')) >= 0) {
      if ('days' in userData && currentDate in userData.days && challengeId in userData.days[currentDate] && userData.days[currentDate][challengeId].done) {
        const dayOfWeek = moment(currentDate, calendarDateFormat).format('ddd');

        if (dayOfWeek in daysDone)
          daysDone[dayOfWeek] += 1
        else  
          daysDone[dayOfWeek] = 1
      }
    }
    currentDate = moment(currentDate, calendarDateFormat).add(-1, 'days').format(calendarDateFormat);
  }
  return daysDone
}

export function calculateDaysDone(challengeId, userData) {
  var daysDone = 0
  var challenge = userData.challenges[challengeId]
  var currentDate = challenge.endDate;

  while (currentDate >= challenge.startDate) {
    if (challenge.period !== 'day' || challenge.daysOfWeek.indexOf(moment(currentDate,calendarDateFormat).format('ddd')) >= 0) {
      if ('days' in userData && currentDate in userData.days && challengeId in userData.days[currentDate] && userData.days[currentDate][challengeId].done)
        daysDone += 1
    }
    currentDate = moment(currentDate, calendarDateFormat).add(-1, 'days').format(calendarDateFormat);
  }
  return daysDone
}

export function calculateCurrentStreak(challengeId, userData, dateFormatted) {
  var currentStreak = 0
  var currentDate = null;
  var challenge = userData.challenges[challengeId]

  if (challenge.period === 'day') {
    currentDate = moment(dateFormatted, calendarDateFormat).add(-1, 'days').format(calendarDateFormat);
    while (currentDate >= challenge.startDate) {
      if (challenge.daysOfWeek.indexOf(moment(currentDate,calendarDateFormat).format('ddd')) >= 0) {
        if ('days' in userData && currentDate in userData.days && challengeId in userData.days[currentDate] && userData.days[currentDate][challengeId].done)
          currentStreak += 1
        else 
          break
      }
      currentDate = moment(currentDate, calendarDateFormat).add(-1, 'days').format(calendarDateFormat);
    }
  }
  else if (challenge.period === 'week') {
    currentDate = moment(dateFormatted, calendarDateFormat).clone().weekday(0).subtract(1, 'days').format(calendarDateFormat);

    while (currentDate >= challenge.startDate) {
      var weekDate = moment(currentDate, calendarDateFormat).clone().weekday(0).format(calendarDateFormat);
      var wasChecked = false;

      while (weekDate <= currentDate) {
        if ('days' in userData && weekDate in userData.days && challengeId in userData.days[weekDate] && userData.days[weekDate][challengeId].done)
          wasChecked = true
        
        weekDate = moment(weekDate, calendarDateFormat).add(1, 'days').format(calendarDateFormat);
      }

      if (!wasChecked)
        break

      currentStreak += 1
      currentDate = moment(currentDate, calendarDateFormat).add(-7, 'days').format(calendarDateFormat);
    } 
  }
  else if (challenge.period === 'month') {
    currentDate = moment(dateFormatted, calendarDateFormat).clone().startOf('month').subtract(1, 'days').format(calendarDateFormat);

    while (currentDate >= challenge.startDate) {
      var monthDate = moment(currentDate, calendarDateFormat).clone().startOf('month').format(calendarDateFormat);
      var wasChecked = false;

      while (monthDate <= currentDate) {
        if ('days' in userData && monthDate in userData.days && challengeId in userData.days[monthDate] && userData.days[monthDate][challengeId].done)
          wasChecked = true
        
          monthDate = moment(monthDate, calendarDateFormat).add(1, 'days').format(calendarDateFormat);
      }

      if (!wasChecked)
        break

      currentStreak += 1
      currentDate = moment(currentDate, calendarDateFormat).startOf('month').subtract(1, 'days').format(calendarDateFormat);
    } 
  }

  return currentStreak
}

export function challengeStatsUpdate(fb, userId, challengeId, callback) {

    fb.database.ref().child("users").child(userId).get().then((snapshot) => {
      if (snapshot.exists()) {
        
        var userData = snapshot.val();
        var habit = '';

        var daysPassed = 0, habitDone = 0, totalDays = 0;
        var currentStreak = 0, shortestStreak = 0, longestStreak = 0;
        var streak = 0;
    
        var updateStreaks = () => {
          if (streak > longestStreak)
            longestStreak = streak;
          if (streak > 0 && (shortestStreak === 0 || streak < shortestStreak))
            shortestStreak = streak;
          if (currentDate > todayFormatted && currentStreak === 0) 
            currentStreak = streak;
    
          streak = 0;
        }
    
        if (userData) {
          var challenge = userData.challenges[challengeId];
          var currentDate = challenge.startDate;
          var todayFormatted = moment(new Date()).format(calendarDateFormat)
    
          while (currentDate <= challenge.endDate) {
            if (challenge.period !== 'day' || challenge.daysOfWeek.indexOf(moment(currentDate,calendarDateFormat).format('ddd')) >= 0) {
              totalDays += 1;
              if (todayFormatted >= currentDate)
                daysPassed += 1;
              
              if ('days' in userData && currentDate in userData.days && challengeId in userData.days[currentDate] && userData.days[currentDate][challengeId].done) {
                habitDone += 1;
                streak += 1;
              }
              else {
                // A streak has ended
                updateStreaks();
              }
            }
            
            currentDate = moment(currentDate,calendarDateFormat).add(1,'days').format(calendarDateFormat);
          }
          updateStreaks();
          habit = challenge.habit;
        }
        
        fb.database.ref('users/' + userId + '/challenges/' + challengeId + "/stats").set({
            currentStreak : currentStreak,
            daysPassed: daysPassed,
            habitDone : habitDone,
            longestStreak : longestStreak,
            shortestStreak : shortestStreak,
            totalDays : totalDays
        }).then(() => {
          if (habit && habit !== '')
            teamsStatsUpdate(fb, userId, habit, callback) 
          else
            callback();
        });
      } 
    }).catch((error) => {
      alert(error);
    });
}
