import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Button from '../ui/Button';

const INITIAL_STATE = {
  data : {},
  dataLoaded: false
};

class Onboarding1Step2 extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = () => {

  }

  componentWillMount = () => {
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
  }

  componentWillUnmount() {
  }

  getTitle = () => {
     return " "; 
  }

  continueClicked = () => {
    this.props.history.push("/onboarding-1-step-3");
  }

  renderOnboarding = () => {
    var items = [];

    items.push(<div className='onboardingHabitLoopImageContainer'>
       <img className='onboardingHabitLoopImage' src='/illustration/the-habit-loop-drawing.png'/>
    </div>);
    items.push(<img class='curvesImage' src='/illustration/curves.png'/>);
    items.push(<div className='onboardingTitle'>Habit loops</div>);
    items.push(<div className='onboardingContent'><p><br/>Habits are loops that become ingrained over time.</p></div>);
    items.push(<div className='onboardingContent'></div>);

    items.push(<Button onClick={this.continueClicked} type='primary' size='large' action='Continue'/>);

    return items;
  }

  render = () => {
    var history = this.props.history;
    
    return (
      <AppChrome title="" history={history} requireLogin={true} firebase={this.props.firebase} back="/onboarding-1-step-1" tab="Today">
      {
          this.renderOnboarding()
      }
      </AppChrome>
    )
  }
}

export default Onboarding1Step2;