import React, { Component } from 'react';

import Button from '../ui/Button'
import MessagePopup from '../ui/MessagePopup';
import PopupHeader from '../ui/PopupHeader';

import moment from 'moment';
import Popup from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';
import { CirclePicker } from 'react-color';

const PALETTE = [
  "#ea2952", "#00D4FF", "#14223d", "#FF2E92", "#474448", "#7b39ED"
];

const INITIAL_STATE = {
  name: "",
  colour: "",
  showMessage : false,
  showMessageCallback: null,
  showMessageText : '',
  data: null,
  dataLoaded: false
};

class EditObjectivePopup extends Component {

  constructor(props) {
      super(props);
      this.state = INITIAL_STATE;

      if (!this.props.newObjective) {
        this.state.name = this.props.name
        this.state.colour = "#" + this.props.colour
      }
      else {
        this.state.name = ""
        this.state.colour = ""
      }
  } 

  displayMessage = (message, callback) => {
    this.setState({
      showMessage : true,
      showMessageText : message,
      showMessageCallback : callback
    })
  }

  closeMessage = () => {
    this.setState({
      showMessage : false
    })
  }

  
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  buttonClicked = () => {
    if (this.state.name.length < 5) {
      this.displayMessage("Please enter a valid objective name", this.closeMessage)
      return
    }

    if (this.state.name.length >= 38) {
      this.displayMessage("Objective names must be less than 38 characters", this.closeMessage)
      return
    }

    
    if (this.state.colour === '') {
      this.displayMessage("Please select a colour", this.closeMessage)
      return
    }

    if (this.props.onAddOrUpdate) {
      this.props.onAddOrUpdate(this.state.name, this.state.colour.replace("#",""))
    }

  }

  colourChanged = (colour) => {
    this.setState({ colour: colour.hex });
  }
  
  render = () => {
    var title = (this.props.newObjective) ? "Add Goal" : "Edit Goal"
    var buttonTitle = (this.props.newObjective) ? "Add Goal" : "Update Goal"

    return <div>
        { this.state.showMessage 
          ?
          <MessagePopup title={title} message={this.state.showMessageText} onClose={this.state.showMessageCallback}/>
          :
          <Popup open="true" closeOnDocumentClick className='add-objective-popup' onClose={this.props.onClose}>
                <PopupHeader title={title}/>

                <div className='sectionTitle'>NAME</div>

                <input className='editObjectivePopupName' name='name'  onChange={this.onChange} value={this.state.name} placeholder="eg. Professional development" />

                <div className='sectionTitle'>COLOUR</div>
                
                <div className='editObjectivePopupColourPicker'>
                  <CirclePicker color={this.state.colour} onChange={ this.colourChanged } colors={PALETTE}/>
                </div>
            
                <div className='editObjectivePopupCancelButton'>
                  <Button type='secondary' size='small' onClick={this.props.onClose} action='Cancel'/>
                </div>
                <div className='editObjectivePopupAddButton'>
                  <Button type='primary' size='small' onClick={this.buttonClicked} action={buttonTitle}/> 
                </div>   
          </Popup>
        }
    </div>;
  }
};

export default EditObjectivePopup;
