import React, { Component } from 'react';

import Avatar from '../ui/Avatar';

import { calculateLevel } from '../util/PointsUtils';

class AppHeader extends Component {
  constructor(props) {
    super(props);
  }

  backClicked = () => {
    this.props.history.goBack()
  }

  avatarClicked = () => {
    this.props.history.push("/profile")
  }

  logoClicked = () => {
      this.props.history.push("/team")
  }

  render() {
    var title = this.props.title;
    var items = []
    var avatarBadge = null;

    if (this.props.firebase && this.props.firebase.user && this.props.team) {
      if (this.props.team.settings && this.props.team.settings.showPoints) {
        avatarBadge = calculateLevel(this.props.team, this.props.firebase.user.uid)
      }
    }

    items.push(
      <div className='headerTitle'>
        <div className='navTitle'>{title}</div>
      </div>
    );

    if (this.props.back) {
      items.push(<div className="App-header-back" onClick={this.backClicked} />);
    }

    if (!this.props.title)
      items.push(<p className='headerIconContainer'><img className='headerIcon' src='/lasoo_mark_small.png' /></p>);

    if (this.props.firebase && this.props.firebase.user && !this.props.back) 
      items.push(<img className='App-header-logo' onClick={this.logoClicked} src='/lasoo_icon.png'/>);

    if (this.props.firebase && this.props.firebase.user) 
      items.push(<div className='App-header-avatar' onClick={this.avatarClicked}><Avatar name={this.props.firebase.user.displayName} badge={avatarBadge} image={this.props.firebase.user.photoURL} firebase={this.props.firebase.user}/></div>);

    return (
      <div className="App-header">{items}</div>
    );
  }
}

export default AppHeader;