import React, { Component } from 'react';

import moment from 'moment'

import AppChrome from '../layout/AppChrome'
import TeamMembersPopup from './TeamMembersPopup'

import Avatar from '../ui/Avatar'
import Button from '../ui/Button'
import MessagePopup from '../ui/MessagePopup'

import { getUserAndTeam } from '../util/DatabaseUtils'
import { sendSharedGoalInvitesFunction } from '../util/FunctionUtils';

import 'react-datepicker/dist/react-datepicker.css'
import 'react-toggle/style.css';

const calendarDateFormat = 'YYYY-MM-DD';

const INITIAL_STATE = {
  dataLoaded : false,

  messagePopup: false,
  messagePopupMessage: '',
  messagePopupCallback: null,

  teamMembersPopup: false,
  teamMembers : [],
  personalisedMessage : ''
};

class ChallengeTeammates extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  } 

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {

      getUserAndTeam(this.props.firebase, (user, team) => {
        var challenge = user.challenges[this.props.match.params.challenge]

        if (challenge) {
          this.setState({ 
            dataLoaded: true, 
            team: team, 
            teamMembers : this.createTeamMembers(team),
            challenge : challenge,
            personalisedMessage: this.createPersonalisedMessage(challenge)
          });
        }
        else {
          alert("Invalid challenge id")
        }
      })
    }
  }

  createTeamMembers = (team) => {
    var teamMembers = [];

    for (var memberEmailKey of Object.keys(team.member)) {
  
      if (memberEmailKey) {

        var member = team.member[memberEmailKey];

        if (member['user-id'] != this.props.firebase.user.uid && !member.disabled) {
          teamMembers.push({
            userId : member['user-id'],
            name : member.name,
            photoURL : member.photoURL
          })
        }
      } 
    }
    teamMembers.sort((m1, m2) => {
      if (m1.name < m2.name)
        return -1;
      else if (m1.name > m2.name)
        return 1;
      else
        return 0;
    })

    return teamMembers;
  }

  closeMessagePopup = () => {
    this.setState({
      messagePopup: false,
      messagePopupMessage: '',
      messagePopupCallback: null
    })
  }

  showMessagePopup = (message, callback) => {
    this.setState({
      messagePopup: true,
      messagePopupMessage: message,
      messagePopupCallback: callback
    })
  }

  showTeamMembersPopup = (callback) => {
    this.setState({
      teamMembersPopup: true
    })
  }

  closeTeamMembersPopup = () => {
    this.setState({
      teamMembersPopup: false
    })
  }

  teamMembersPopupCallback = (teamMember) => {
    // First check the teamMember is not already in the list

    for (var member of this.state.teamMembers) {
      if (member.userId === teamMember.userId) {
        this.setState({ teamMembersPopup: false })
        return;
      }
    }
    teamMember.inviteSent = false;
    this.state.teamMembers.push(teamMember);
    
    this.setState({
      teamMembers : this.state.teamMembers,
      teamMembersPopup : false
    })
  }

  removeTeamMember = (i, e) => {
    this.state.teamMembers.splice(i, 1)

    this.setState({
      teamMembers : this.state.teamMembers
    })

    e.stopPropagation();
  }

  createPersonalisedMessage = (challenge) => {
    return "Hi everyone,\n\n" +
           "I've set a goal to practice the habit '" + challenge.name + "'.\n\n" +
           "Would anyone like to join me?"
  }

  stringIsBlank = (str) => {
    return (!str || /^\s*$/.test(str));
  } 

  validateFields = () => {
    if (this.state.teamMembers.length === 0) {
      this.showMessagePopup("Please pick a team member to challenge", this.closeMessagePopup);
      return false;
    }

    if (this.state.personalisedMessage.trim() === '') {
      this.showMessagePopup("Please enter a personalised message", this.closeMessagePopup);
      return false; 
    }     
    return true;
  }

  navigateHome = () => {
    this.props.history.push("/");
  }

  calculateDays = (challenge) => {
    var days = 0
    var currentDateFormatted = moment(challenge.startDate).format(calendarDateFormat)
    var endDateFormatted = moment(challenge.endDate).format(calendarDateFormat)

    while (currentDateFormatted <= endDateFormatted) {
      if (challenge.daysOfWeek.indexOf(moment(currentDateFormatted,calendarDateFormat).format('ddd')) >= 0) {
          days += 1
      }
      currentDateFormatted = moment(currentDateFormatted, calendarDateFormat).add(1, 'days').format(calendarDateFormat);
    }
    return days
  }

  onSubmission = (e) => {
    if (!this.validateFields())
      return;

    var teamMembers = []
    for (var teamMember of this.state.teamMembers) {
      teamMembers.push({
        userId: teamMember.userId,
        inviteSent : false
      })
    }

    var updates = {};
    updates['teamMembers'] = teamMembers;
    updates['personalisedMessage'] = this.state.personalisedMessage;
    updates['challengerName'] = this.props.firebase.user.displayName;

    this.props.firebase.database.ref('users/' + this.props.firebase.user.uid + '/challenges/' + this.props.match.params.challenge).update(updates)
    .then(() => {
      sendSharedGoalInvitesFunction(this.props.firebase.user.uid, this.props.match.params.challenge)
      this.showMessagePopup("Challenge sent!", this.navigateHome);  
    });
  }
  
  showTeamMembersPopup = (callback) => {
    this.setState({
      teamMembersPopup: true
    })
  }

  closeTeamMembersPopup = () => {
    this.setState({
      teamMembersPopup: false
    })
  }

  renderTeamMembers = () => { 
    const items = [];

    for (var i = 0; i < this.state.teamMembers.length; i++) { 
      var teamMember = this.state.teamMembers[i];

      items.push(<div className='cardRow'>
        <div className='cardRowIcon'>
          <Avatar size='small' name={teamMember.name} image={teamMember.photoURL}/>
        </div>
        <div className='cardRowText'>{teamMember.name}</div>
        <div className='cardRowDeleteIcon' onClick={ this.removeTeamMember.bind(this, i) }>
          <div class="material-icons md-30">delete_outline</div>
        </div>
      </div>);  
    }

    if (this.state.teamMembers.length < Object.keys(this.state.team.members).length - 1) {
      items.push(<div className='cardRow' onClick={ this.showTeamMembersPopup.bind(this, -1)}>
        <div className='cardRowPlusIcon'>
          <div className="material-icons md-light md-28">add</div>
        </div>
        <div className='cardRowText'>Add team member</div>
      </div>);
    }

    return <div className='habitFormCalendarEventsCard'>{items}</div>
  }

  teamMembersPopupCallback = (teamMember) => {
    // First check the teamMember is not already in the list

    for (var member of this.state.teamMembers) {
      if (member.userId === teamMember.userId) {
        this.setState({ teamMembersPopup: false })
        return;
      }
    }
    teamMember.inviteSent = false;
    this.state.teamMembers.push(teamMember);
    this.state.teamMembers.sort((m1, m2) => {
      if (m1.name < m2.name)
        return -1
      else if (m1.name > m2.name)
        return 1
      else 
        return 0;
    })
    
    this.setState({
      teamMembers : this.state.teamMembers,
      teamMembersPopup : false
    })
  }

  removeTeamMember = (i, e) => {
    this.state.teamMembers.splice(i, 1)

    this.setState({
      teamMembers : this.state.teamMembers
    })

    e.stopPropagation();
  }

  changePersonalisedMessage = (e) => {
    this.setState({
      personalisedMessage: e.target.value
    })
  }

  renderChallengeForm = () => {
    if (!this.state.dataLoaded)
      return <div/>

    var sectionTitle = "CHALLENGE " + this.state.teamMembers.length + " " + (this.state.teamMembers.length === 1 ? "TEAMMATE" : "TEAMMATES")

    return <div>
        <div className='sectionTitle'></div>
        <div>
            <div className='addHabitFormHabitCard' style={{ borderLeft: '2px solid #' + this.state.challenge.colour }}>
              <div className='addHabitFormHabitName'>{ this.state.challenge.name }</div>
            </div>
        </div>
        <div className='sectionTitle'>{sectionTitle}</div>

        { this.renderTeamMembers() }

        <div className='sectionTitle'>PERSONALISED MESSAGE</div>
        <div><textarea rows="7" cols="50" value={this.state.personalisedMessage} onChange={this.changePersonalisedMessage}></textarea></div>
        
        <p><Button type='primary' size='large' onClick={this.onSubmission} action="Send Challenge"/></p>
    </div>
  }

  render = () => {
    var firebase = this.props.firebase;

    return ( 
      <AppChrome title="Challenge teammates" team={this.state.team} history={this.props.history} requireLogin={true} firebase={this.props.firebase} back={true} tab="Today">
          { this.renderChallengeForm() }

          { this.state.teamMembersPopup
                ?
                <TeamMembersPopup exclude={ this.state.teamMembers.map(tm => tm.userId) } firebase={this.props.firebase} callback={this.teamMembersPopupCallback} onClose={this.closeTeamMembersPopup} />
                :
                <div></div>
          }

          { this.state.messagePopup
                ?
                <MessagePopup title='Habit Form' message={this.state.messagePopupMessage} onClose={this.state.messagePopupCallback} />
                :
                <div></div>
          }
      </AppChrome>
    )
  }

  
}



export default ChallengeTeammates;