import { useHistory, withRouter } from "react-router-dom"
import React, { useEffect } from "react";

const logCurrentPage = (firebase) => {
    firebase.analytics.setCurrentScreen(window.location.pathname)
    firebase.analytics.logEvent('screen_view')
  };
  
const AnalyticsComponent = (props) => {
    const firebase = props.firebase;
    const history = useHistory();
    useEffect(() => {
        logCurrentPage(firebase); // log the first page visit
        history.listen(() => {
            logCurrentPage(firebase);
        });
    }, [history]);
    return (<div></div>);
};

export default withRouter(AnalyticsComponent);