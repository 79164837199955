import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';

import Button from '../ui/Button'
import LumosTouchpoint from '../lumos/LumosTouchpoint'
import MessagePopup from '../ui/MessagePopup';

import EditObjectivePopup from './EditObjectivePopup';

import moment, { min } from 'moment';

import { getPercentageTicked } from '../util/ChallengeUtils'
import { getUserAndTeam } from '../util/DatabaseUtils'
import ReloadDataCard from '../ui/ReloadDataCard';

const calendarDateFormat = 'YYYY-MM-DD';

const INITIAL_STATE = {
  challenges: {},
  dataLoaded: false,
  messagePopup: false,
  messagePopupMessage: '',
  showEditObjectivePopup: false,
  isOffline: false
};

class Objective extends Component {

    constructor(props) {
       super(props);
       this.state = { ...INITIAL_STATE };
    }

    checkDataLoaded = () => {
      if (this.props.firebase.user && !this.state.dataLoaded) {

        getUserAndTeam(this.props.firebase, (user, team) => {
          if (!user || !user.team) {
            this.props.history.push("/create-team")
            return
          }

          this.setState({
            data: user,
            team: team,
            dataLoaded: true,
            objective: this.getObjective(user, this.props.match.params.objective),
            activeChallenges: this.getActiveChallengesForObjective(user, this.props.match.params.objective)
          });
        },
        () => {
          this.setState({
            isOffline: true
          })
        })
      }
    }

    getObjective = (userData, objectiveId) => {
      if (userData && userData.objectives && userData.objectives[objectiveId])
          return userData.objectives[objectiveId];
      else
          return null
    }

    componentWillMount = () => {
      this.checkDataLoaded();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
      this.checkDataLoaded();
    }

    componentWillUnmount() {
    }

    signout = () => this.props.firebase.doSignOut().then(() => this.forceUpdate())
   
    isChallengeActiveAndForObjective = (challenge, objectiveId) => {
      var todayMoment = moment();

      if (challenge.objective !== objectiveId)
        return false;
 
      return (!challenge.endDate || challenge.endDate > todayMoment.format(calendarDateFormat))
    }

    getActiveChallengesForObjective = (userData, objectiveId) => {
      var challenges = []

      if (userData.challenges) {
        Object.keys(userData.challenges).forEach( (challengeId) => {
          var challenge = userData.challenges[challengeId]

          if (this.isChallengeActiveAndForObjective(challenge, objectiveId))
              challenges.push({ id: challengeId, challenge: challenge } )
        });
      }

      challenges.sort((c1, c2) => {
        if (c1.challenge.name < c2.challenge.name)
          return -1
        else if (c1.challenge.name > c2.challenge.name)
          return 1;
        else
          return 0;
      })

      return challenges
    }

    renderLoading = () => {
      return <span className='loading'>Loading...</span>
    }

    renderChallengesPlaceholder = () => {
      const items = []

      items.push(<div className='challengeCardPlaceholder'/>)
      items.push(<div className='challengeCardPlaceholder'/>)
      items.push(<div className='challengeCardPlaceholder'/>)

      return <div>{items}</div>;
    }

    renderChallengeDaysOfWeek = (challenge) => {
      var daysOfWeek = [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ]
      var items = [];

      daysOfWeek.forEach((dayOfWeek, i) => {
          var className = (challenge.period !== 'day' || challenge.daysOfWeek.indexOf(dayOfWeek) >= 0) ? 'challengeCardDayOfWeek' : 'challengeCardDayOfWeekNotSelected';
          items.push(<div className={className}>{dayOfWeek}</div>);
      })

      return <div className='challengeCardDaysOfWeek'>{items}</div>
    }

    getProgressBarWidth = (challenge) => {
      // Progress % = days since start / days from start to endDate
      // This should probably work on number of habit days though?

      if (!challenge.endDate)
        return 100.0

      var startDateMoment = moment(challenge.startDate, calendarDateFormat);
      var endDateMoment = moment(challenge.endDate, calendarDateFormat);
      var todayMoment = moment(new Date());
      var todayFormatted = todayMoment.format(calendarDateFormat)
    
      var progress = 0;

      if (todayFormatted <= challenge.startDate)
        progress = 0;
      else if (todayFormatted > challenge.endDate)
        progress = 1;
      else if (challenge.startDate === challenge.endDate)
          progress = 0.0;
      else {
          var daysSinceStart = todayMoment.diff(startDateMoment, 'days');
          var daysFromStartToEnd = endDateMoment.diff(startDateMoment, 'days');
          progress = daysSinceStart / daysFromStartToEnd;
      }

      return Math.min(100, 100 * progress);
    }

    goalClicked = (challengeId) => {
      this.props.history.push('/goal-report/'+ challengeId);
    }

    updateButtonClicked = (challengeId, e) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();

      this.props.history.push('/update-goal/'+ challengeId);
    }

    getPracticePeriod = (challenge) => {
      if (challenge.period === 'day') {
        if (challenge.daysOfWeek.length === 7) {
          return "Daily"
        }
        else {
          return challenge.daysOfWeek.join(', ')
        }
      }
      else if (challenge.period === 'week')
        return "Weekly"
      else
        return "Monthly"
    }

    getVisibility = (challenge) => {
      if (challenge.visibility === 'team')
        return "Team"
      else if (challenge.visibility === 'manager')
        return "Manager"
      else
        return "Private"
    }

    getVisibilityIcon = (challenge) => {
      if (challenge.visibility === 'team')
        return "groups"
      else if (challenge.visibility === 'manager')
        return "group"
      else
        return "person"
    }


    renderChallenge = (challengeId, challenge) => {
      var percentage = getPercentageTicked(challengeId, challenge, this.state.data);

      var objective = this.state.data.objectives[challenge.objective].name

      return <div className='challengeCard' style={{ borderLeft: "4px solid #" + challenge.colour }} onClick={this.updateButtonClicked.bind(this, challengeId)}>

        <div className='challengeCardName'>{challenge.name}</div>

        <div className='challengeCardCalendar'>
          <div class="material-icons-outlined md-20">event_available</div> 
        </div>

        <div className='challengeCardPeriod'>{this.getPracticePeriod(challenge)}</div>
        <div className='challengeCardVisibility'>
          <div class="material-icons-outlined md-28">{this.getVisibilityIcon(challenge)}</div> 
        </div>

      </div>;
    }

    addHabit = () => {
      this.props.history.push("/add-habit-1");
    }

    
  closeMessagePopup = () => {
    this.setState({
      messagePopup: false,
      messagePopupMessage: '',
    })
  }

  showMessagePopup = (message) => {
    this.setState({
      messagePopup: true,
      messagePopupMessage: message
    })
  }

  showAskLumos = () => {
    this.props.history.push("/goal-ask-lumos/" + this.props.match.params.objective)
  }

    renderActiveChallenges = () => {
      var items = [];
  
      this.state.activeChallenges.forEach( (challengeIdAndObject) => {
          var challengeId = challengeIdAndObject.id
          var challenge = challengeIdAndObject.challenge

          items.push(this.renderChallenge(challengeId, challenge));
      });
      return items;
    }

    editObjective = () => {
      this.setState({
        showEditObjectivePopup: true
      })
    }

    closeEditObjectivePopup = () => {
      this.setState({
        showEditObjectivePopup: false
      })
    }

    onUpdateObjective = (name, colour) => {


        this.props.firebase.database.ref('users/' + this.props.firebase.user.uid + '/objectives/' + this.props.match.params.objective)
        .update({
          name: name,
          colour: colour
        })
        .then(() => {
          if (this.state.activeChallenges) {
            this.state.activeChallenges.forEach((challengeIdAndObject) => {
              var challengeId = challengeIdAndObject.id

              this.props.firebase.database.ref('users/' + this.props.firebase.user.uid + '/challenges/' + challengeId)
              .update({
                colour: colour
              })
            })
          }
          
          this.setState({ showEditObjectivePopup: false })
          this.props.history.push('/goals');
        })
    }

    endObjective = () => {
      if (this.state.activeChallenges.length > 0) {
        this.showMessagePopup("You can't end a goal with active habits");
        return;
      }
      else {
        var todayFormatted = moment().format(calendarDateFormat)

        this.props.firebase.database.ref('users/' + this.props.firebase.user.uid + '/objectives/' + this.props.match.params.objective + '/endedDate').set(todayFormatted)
        .then(() => {
            this.props.history.push('/goals');
        });
      }
    }

    refresh = () => {
      this.setState({ isOffline: false })
      this.checkDataLoaded()
    }
    
    renderChallenges = () => {
      var items = [];

      items.push(<LumosTouchpoint description='Can I suggest some habits for this goal?' onClick={this.showAskLumos}/>)

      if (this.state.isOffline)
        items.push(<ReloadDataCard firebase={this.props.firebase} message="Could not load habits for goal" onClick={this.refresh} />)
      else if (!this.state.dataLoaded)
        items.push(this.renderChallengesPlaceholder());
      else {
        var challengesItems = this.renderActiveChallenges();

        if (challengesItems.length === 0) {
          items.push(
            <div className='habitActivityEmptyStateCard'>
              <img src='/illustration/home-empty-state.png' className='habitActivityEmptyStateImage'/>
              <div className='habitActivityEmptyStateMessage'>You have no active habits for this goal</div>
            </div>);
        }
        else {
          items.push(challengesItems)
        }

        items.push(<p><Button type='primary' size='large' onClick={() => this.addHabit()} action='Try a new habit'/></p>);
        items.push(<p><Button type='secondary' size='large' onClick={() => this.editObjective()} action='Edit goal'/></p>);
        items.push(<p><Button type='secondary' size='large' onClick={() => this.endObjective()} action='End goal'/></p>);
      }

      return <div>
        {items}
      </div>
    }

    render = () => {
      var user = this.props.firebase.user
      var history = this.props.history;

      var title = this.state.data && this.state.data.objectives && this.state.data.objectives[this.props.match.params.objective] ? this.state.data.objectives[this.props.match.params.objective].name : " ";
      
      return (
        <AppChrome title={title} history={history} requireLogin={true} team={this.state.team} firebase={this.props.firebase} back="/goals" tab="Habits">
            <div>
              {this.renderChallenges()}
            </div>

            { this.state.showEditObjectivePopup ? 
              <EditObjectivePopup newObjective={false} onAddOrUpdate={this.onUpdateObjective} name={this.state.objective.name} colour={this.state.objective.colour} onClose={this.closeEditObjectivePopup} />
              :
              <div></div>
            }

            { this.state.messagePopup
                ?
                <MessagePopup title="End Goal" message={this.state.messagePopupMessage} onClose={this.closeMessagePopup} />
                :
                <div></div>
            }
        </AppChrome>
      )      
    }
}

export default Objective;