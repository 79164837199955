import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Button from '../ui/Button';
import HabitCard from './HabitCard'

import { getUserAndTeam } from '../util/DatabaseUtils';

const INITIAL_STATE = {
  habits: {},
  categoryName : "",
  dataLoaded: false
};

class EditHabits extends Component {

    constructor(props) {
       super(props);
       this.state = { ...INITIAL_STATE };
    }

    checkDataLoaded = () => {

      if (this.props.firebase.user && !this.state.dataLoaded) {
        getUserAndTeam(this.props.firebase, (user, team) => {
          var habits = {};

          if (team.habits) {
            Object.keys(team.habits).forEach((habitId) => {
              var habit = team.habits[habitId]; 
               if (habit.category === this.props.match.params.category)
                habits[habitId] = habit;
            });
          }
          var categoryName = '';
          
          if (team['habit-categories'] && team['habit-categories'][this.props.match.params.category]) 
             categoryName = team['habit-categories'][this.props.match.params.category].name;

          this.setState({ dataLoaded: true, habits : habits, categoryName : categoryName, team: team });
        })
      }
    }

    componentWillMount = () => {
      this.checkDataLoaded();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
      this.checkDataLoaded();
    }

    componentWillUnmount() {}

    habitSelected = (habitId) => { 
      this.props.history.push("/habit/" + habitId)
    }

    createHabit = () => {
      this.props.history.push("/create-habit-post/" + this.props.match.params.category);
    }

    onHabitEditClick = (id) => {
      this.props.history.push("/edit-habit/" + id);

    }

    renderHabits = () => {
      if (!this.state.dataLoaded)
        return this.renderHabitsLoading();

      var items = [];
      items.push(<div className='contentManagerWarning'>Changes made here will be visible to your team.</div>);

      if (this.state.habits.size === 0)
        items.push(<div>No habits</div>);

      Object.keys(this.state.habits).sort().map((id) => {
        var habit = this.state.habits[id];
        var buttonStyles = { 'background-color' : '#' + habit.colour }

        items.push(<HabitCard id={id} visible={habit.visible} creator={habit['creator-name']} displayChevron='true' onClick={this.onHabitEditClick.bind(this, id)} name={habit.name} colour={habit.colour}/>);
      })

      items.push(<p><Button type='secondary' size='large' onClick={this.createHabit} action='Create a new habit'/></p>);

      return items;
    }

    renderHabitsLoading = () => {
       const items = [];

       for (var i = 1; i <= 5; i++) 
        items.push(<div className='habitCardPlaceholder'/>);

       return <div>{items}</div>;
    }

    render = () => {
      var history = this.props.history;
      var title = (this.state.categoryName !== '') ? "Edit Habits - " + this.state.categoryName : "";
      var backUrl = "/category/" + this.props.match.params.category;

      return (
        <AppChrome title={title} history={history} requireLogin={true} team={this.state.team} firebase={this.props.firebase} back={true} tab="Discover">
            <div>
              { this.renderHabits() } 
            </div>
        </AppChrome>
      )
    }
}

export default EditHabits;