import React, { Component } from 'react';

import Button from '../ui/Button';

const INITIAL_STATE = {
};

class LumosTouchpoint extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  render = () => {
    return (
      <div class="lumosTouchpointCardContainer">
        <div class="lumosTouchpointCard">
          <div class="lumosTouchpointCardAvatar"><img width='40' height='40' class='lumosAvatar' src='/illustration/lumos.png'/></div>
          { this.props.onClick ? 
          <div>
            <div class="lumosTouchpointCardDescription">{this.props.description}</div>
            <div class="lumosTouchpointCardButton"><Button type='secondary' size='small' action='Ask Lumos' onClick={this.props.onClick}/></div>
          </div>
             : 
             <div class="lumosTouchpointCardDescriptionNoButton">{this.props.description}</div>
             }

        </div>
      </div>
    );
  }
}

export default LumosTouchpoint;

