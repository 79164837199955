import React, { Component } from 'react';

import moment from 'moment';
import Award from '../ui/Award'
import Button from '../ui/Button'
import Popup from 'reactjs-popup';

import { getUserAndTeam } from '../util/DatabaseUtils';
import { getDefaultVisibility } from '../util/VisibilityUtils';

import 'reactjs-popup/dist/index.css';

const calendarDateFormat = 'YYYY-MM-DD';

class GoalFormConfirmPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {};
  } 

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {
      getUserAndTeam(this.props.firebase, (user, team) => {
        var challenge = user.challenges[this.props.challengeId]
        this.setState({ dataLoaded: true, challenge: challenge, data: user, team: team  });
      })
    }
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  componentWillUnmount() {}

  renderCTAMessage = () => {
    if (this.getAllowChallenges())
      return "Would you like to challenge any teammates to complete the same goal?"
    else 
      return "Habit successfully created, good luck with it!"
  }

  getAllowChallenges = () => {
    if (!this.state.challenge)
      return false;

    if (this.state.dataLoaded && this.state.team && this.state.team.settings && this.state.team.settings.allowChallenges && Object.keys(this.state.team.member).length > 1 && !this.state.challenge.isSharedChallenge && this.state.challenge.visibility && this.state.challenge.visibility === getDefaultVisibility())
      return true;
    else 
      return false;
  }

  onChallenge = () => {
    this.props.history.push("/challenge-teammates/" + this.props.challengeId)
  }

  calculateDays = () => {
    var days = 0
    var currentDateFormatted = moment(this.state.challenge.startDate).format(calendarDateFormat)
    var endDateFormatted = moment(this.state.challenge.endDate).format(calendarDateFormat)

    while (currentDateFormatted <= endDateFormatted) {

      if (this.state.challenge.daysOfWeek.indexOf(moment(currentDateFormatted,calendarDateFormat).format('ddd')) >= 0) {
          days += 1
      }
      currentDateFormatted = moment(currentDateFormatted, calendarDateFormat).add(1, 'days').format(calendarDateFormat);
    }
    return days
  }

  renderButtons = () => {
    if (this.getAllowChallenges())
      return (<div>
        <div className='goalConfirmPopupSureButton'>
          <Button type='secondary' size='large' action='Yes please' onClick={this.onChallenge}/>   
        </div>
        <div className='goalConfirmPopupNoThanksButton'>
          <Button type='secondary' size='large' action='No thanks' onClick={this.props.onClose}/>   
        </div>
      </div>)
    else 
      return (<div className='goalConfirmPopupOKButton'>
        <Button type='primary' size='large' action='Okay' onClick={this.props.onClose}/>   
      </div>) 
  }

  render = () => {
    if (!this.state.dataLoaded) {
      return <div/>
    }
    else {
      return (
          <Popup open="true" closeOnDocumentClick className='goal-form-confirm-popup' onClose={this.onClose}>
            <div className='goalConfirmPopup'>
              <div className='goalConfirmPopupTips'>
                <div className='goalConfirmPopupTipsHeader'>4 tips for starting a new habit or routine</div>
                <div className='goalConfirmPopupTipTitle'>1. Start small - establish the practice and build on it.</div>
                <div className='goalConfirmPopupTipTitle'>2. Be clear when you will practice - either at a specific time or place in your daily routine.</div>
                <div className='goalConfirmPopupTipTitle'>3. Make it enjoyable - it will be easier to stick with.</div>
                <div className='goalConfirmPopupTipTitle'>4. Track and journal your habit or routine - reflection is key.</div>
                <hr/>
              </div>

              <div className='goalConfirmPopupMessage'>
                  <p><b>{this.renderCTAMessage()}</b></p>
              </div>

              { this.renderButtons() } 
            </div>           
          </Popup>
      );
    }
  }
};

export default GoalFormConfirmPopup;
