import React, { Component } from 'react';
import moment, { calendarFormat } from 'moment';

import { isChallengeInEffectOnDate } from '../util/ChallengeUtils';

const calendarDateFormat = 'YYYY-MM-DD';
const calendarDaysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];


class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  componentWillMount = () => {
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
  }

  calculateWeekRange = () => {
    var dateMoment = moment(this.props.date, calendarDateFormat);

    this.startOfCurrentWorkWeek = this.startOfWorkWeek(dateMoment);
    this.endOfCurrentWorkWeek = this.endOfWorkWeek(dateMoment);

 /*   alert('Date is: ' + dateMoment.format(calendarDateFormat));

    alert('Start of work week: ' + moment(this.startOfCurrentWorkWeek).format(calendarDateFormat));
    alert('End of work week: ' + moment(this.endOfCurrentWorkWeek).format(calendarDateFormat));*/
    
    this.firstDayOfMonth = dateMoment.clone().startOf('month');
    this.lastDayOfMonth = dateMoment.clone().endOf('month');
  }

  startOfWorkWeek = (dateMoment) => {
    if (dateMoment.isoWeekday() === 7)
      return dateMoment.clone();
    else
      return dateMoment.clone().subtract(dateMoment.isoWeekday(), 'days');
  }

  endOfWorkWeek = (dateMoment) => {
    if (dateMoment.isoWeekday() === 7)
      return dateMoment.clone().add(6, 'days');
    else
      return dateMoment.clone().add(6 - dateMoment.isoWeekday(), 'days');
  }

  isCurrentWeek = (dateMoment) => {
    var dateFormatted = dateMoment.format(calendarDateFormat);
    return dateFormatted >= this.startOfCurrentWorkWeek.format(calendarDateFormat) && dateFormatted <= this.endOfCurrentWorkWeek.format(calendarDateFormat);
  }

  changeMonth = (delta) => {
    var dateMoment = moment(this.props.date, calendarDateFormat);
    dateMoment.add(delta, 'months');
    this.props.onChangeDate(dateMoment.format(calendarDateFormat));
  }

  changeWeek = (delta) => {
    var dateMoment = moment(this.props.date, calendarDateFormat);
    dateMoment.add(delta * 7, 'days');
    this.props.onChangeDate(dateMoment.format(calendarDateFormat));
  }

  renderDaysOfWeek = () => {
    return <div className='calendarDatesRow'> {
       calendarDaysOfWeek.map((day) => {
        return <div className='calendarDayOfWeek'>{day}</div>;
      })
    }
    </div>
  }

  dateSelected = (selectedDate) => {
  //  alert("Date selected: " + selectedDate);
    this.props.onChangeDate(selectedDate);
  }

  getStatusForDate = (date) => {
    var challengesCount = 0;
    var doneCount = 0;
    var dateFormatted = moment(date).format(calendarDateFormat);
    var dayOfWeek = moment(date).format('ddd');

    var challenges = this.props.challenges;
    var days = this.props.days;

    if (challenges) {
      Object.keys(challenges).forEach((challengeId) => {

        if (isChallengeInEffectOnDate(challengeId, challenges[challengeId], dateFormatted, days)) {
          challengesCount += 1;
          if (days && dateFormatted in days && challengeId in days[dateFormatted] && days[dateFormatted][challengeId].done)
            doneCount += 1;
        }
      });
    }

    if (challengesCount === 0)
      return "calendarDateNoHabits";
    else if (challengesCount === doneCount)
      return "calendarDateHabitsDone";
    else 
      return "calendarDateHabitsNotDone";
  }

  renderCalendarDay = (day) => {
    var dayFormatted = day.format(calendarDateFormat);
    var isCurrentDay = (dayFormatted === this.props.date);
    var statusClass = this.getStatusForDate(day);
    var todayClass = (dayFormatted === moment().format(calendarDateFormat)) ? "calendarDateToday" : "";
 
    const items = [];
    items.push(
      <div className={`calendarDate ${statusClass} ${todayClass}` } 
           onClick={() => { this.dateSelected(dayFormatted)} }>
             {day.date()}
      </div>);

    if (isCurrentDay)
      items.push(<div className='calendarCurrentDateMarker'></div>);

    return <div className='calendarDateCell'>{items}</div>
  }

  renderCalendarWeek = (startOfWeek) => {
    var days = []
    var day = startOfWeek.clone();
    for (var i = 1; i <= 7; i++) {
        days.push(this.renderCalendarDay(day));
        day.add(1, 'days');
    }

    var className = (this.isCurrentWeek(startOfWeek)) ? "calendarDatesRowCurrent" : "calendarDatesRowNotCurrent"
    return <div className={className}>{days}</div>
  }

  renderCalendarDays = () => {
    var weeks = []
    var currentWeek = this.startOfWorkWeek(this.firstDayOfMonth); 
    var lastSundayOfMonth = this.startOfWorkWeek(this.lastDayOfMonth);

    while (currentWeek <= lastSundayOfMonth) {
      weeks.push(this.renderCalendarWeek(currentWeek));
      currentWeek.add(1, 'weeks');
    }
    return weeks;
  }

  render = () => {
    this.calculateWeekRange();

    var habit = this.props.habit;

    return (
      <div className='calendar' style={{ marginTop: '10px' }} align='center'>
        <div className='calendarPrevMonth' onClick={() => this.changeMonth(-1)}>&lt;</div>
        <div className='calendarPrevWeek' onClick={() => this.changeWeek(-1)}>&lt;</div>
        <div className='calendarMain'>
          { this.renderDaysOfWeek() }
          { this.renderCalendarDays() }
        </div>
        <div className='calendarNextMonth' onClick={() => this.changeMonth(1)}>&gt;</div>
        <div className='calendarNextWeek' onClick={() => this.changeWeek(1)}>&gt;</div>
      </div>
    );
  }
};

export default Calendar;
