import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import EditHabitForm from './EditHabitForm'

import 'reactjs-popup/dist/index.css';

import 'react-toggle/style.css';
import { isThisTypeNode } from 'typescript';

import { getUserAndTeam, fetchTeamFromServer } from '../util/DatabaseUtils'
import SpinnerPanel from '../ui/SpinnerPanel';

const INITIAL_STATE = {
  dataLoaded: false,

  name: "",
  description: "",
  videoURL: '',
  colour: '#FF0000',
  category: '',
  visible: true,
  isContentAdmin: false,
  creatingHabit: false
};

class CreateHabitPost extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  /* This class is designed to be sub-classed, and the following five methods overridden */
  getAction = () => {
    return "Create Habit";
  }

  getTitle = () => {
    return "Create Habit";
  }
  
  getAllowEditVisibility = () => {
    return false;
  }

  getDefaultVisibility = () => {
    return true;
  }

  getDefaultRecommended = () => {
    return false;
  }

  getAllowEditRecommended = () => {
    return false;
  }

  getSendSuggestionReminder = () => {
    return false;
  }

  habitCreated = (habitId, teamId) => {
    // Do nothing. This may be overridded by subclasses.
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {
      getUserAndTeam(this.props.firebase, (user, team) => {
        var state = {
          teamId : user.team,
          team: team,
          dataLoaded: true,
          isContentAdmin: (team['content-admin'] && team['content-admin'].indexOf(this.props.firebase.user.email) >= 0) ? true : false,
        }

        if (this.props.match.params.category && team['habit-categories'] && team['habit-categories'][this.props.match.params.category]) {
          var category = team['habit-categories'][this.props.match.params.category];
          state.colour = category.colour;
          state.category = this.props.match.params.category
          state.recommended = this.getDefaultRecommended();
          state.visible = this.getDefaultVisibility();
        }
        
        this.setState(state)
      })
    }
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  isAlphaNumeric = (ch) => {
    return (ch.match(/^[0-9a-zA-Z]+$/));
  }

  /* TODO. This needs to be improved! */

  generateHabitId = (name) => {
    var id = "";
    var nameTrimmed = name.trim();

    for (let i = 0; i < nameTrimmed.length; i++) {
       var ch = nameTrimmed[i];
       if (this.isAlphaNumeric(ch)) 
          id += ch.toLowerCase()
       else 
          id += "-";
    }

    return id;
  }

  onSubmit = (name, videoURL, description, colour, recommended, visible, order) => {

    this.setState({
      creatingHabit : true
    })

    var habitId = this.generateHabitId(name);

    var user = this.props.firebase.user;
    var http = new XMLHttpRequest();

   // alert("Posting " + JSON.stringify(habit));

    var data = {
      name: name,
      videoURL: (videoURL) ? videoURL : '',
      description: description,
      colour: colour,
      category: this.state.category,
      recommended: recommended,
      visible: visible,
      creator: user.uid,
      'creator-name': user.displayName,
      teamId: this.state.teamId,
      habitId: habitId
    }

    if (order)
      data.order = order

   if (user.photoURL) 
      data['creator-photo-url'] = user.photoURL
 
    var url = 'https://us-central1-lasoo-d9fad.cloudfunctions.net/updateHabit'
    http.open('POST', url, true);
    http.setRequestHeader('Content-type', 'application/json')

    //Send the proper header information along with the request
   // http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    http.onreadystatechange = () => {//Call a function when the state changes.
        this.setState({ creatingHabit: false })
        if (http.readyState == 4 && http.status == 200) {
          fetchTeamFromServer(this.props.firebase, this.state.teamId, (doc) => { 
             this.props.history.push("/habit/" + habitId)
          })
        }
    }
    http.send(JSON.stringify(data));
  }

  renderHabitForm = () => { 
    if (!this.state.dataLoaded || this.state.creatingHabit)
      return <SpinnerPanel/>;
    else 
      return <EditHabitForm action={this.getAction()} onSubmit={this.onSubmit} 
                            category={this.state.category}
                            name={this.state.name} description={this.state.description} 
                            colour={this.state.colour} 
                            recommended={this.state.recommended}
                            videoURL={this.state.videoURL}
                            allowEditOrder={this.state.isContentAdmin}
                            allowEditRecommended={this.getAllowEditRecommended()}
                            visible={this.state.visible}
                            allowEditVisibility={this.getAllowEditVisibility()}/>
  }

  render = () => {
    var history = this.props.history;
    var title = this.getTitle();

    return (
      <AppChrome title={title} team={this.state.team} history={history} requireLogin={true} firebase={this.props.firebase} back={true} tab="Discover">
          <div>
            { this.renderHabitForm() } 
          </div>
      </AppChrome>
    ) 
  }
}

export default CreateHabitPost;