import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import * as firebase from 'firebase'
import config from './firebaseConfig';
import { isJsxOpeningFragment } from 'typescript';


class Firebase {
  constructor(onAuthStateChangedCallback) {
    this.onAuthStateChangedCallback = onAuthStateChangedCallback;

    this.reinitFirebase();
  }

  reinitFirebase = () => {
    app.initializeApp(config);

    this.auth = app.auth();
    this.initialised = false;
    this.redirectLogin = false;
    this.connected = false;

    this.analytics = app.analytics();
    this.firestore = app.firestore();

  /// XXX remove debugging
    this.firestorePersistenceEnabled = true;
    this.firestore.enablePersistence()
    .catch((err) => {
        if (err.code == 'failed-precondition') {
            // Multiple tabs open, persistence can only be enabled
            // in one tab at a time.
            // ...
            console.log(err.code)

            
            this.firestorePersistenceEnabled = false;
            
        } else if (err.code == 'unimplemented') {
            // The current browser does not support all of the
            // features required to enable persistence
            // ...
            // alert("Persistenence unimplemented")
            console.log(err.code)
            
           this.firestorePersistenceEnabled = false;
        }
    });

    this.firstConnected = false;
    this.database = app.database();
    this.database.ref(".info/connected").on("value", (snap) => {
      if (snap.val())
        this.firstConnected = true

      this.connected = snap.val()
    });

    this.storage = app.storage();
    this.emailAuthProvider = app.auth.EmailAuthProvider;
    this.googleProvider = new app.auth.GoogleAuthProvider();

    this.auth.onAuthStateChanged((user) => {
      /* This will be invoked if there is a user logged in or not */

      this.user = user;
      this.initialised = true;
      this.onAuthStateChangedCallback(this.user)

      if (this.redirectLogin) {
        this.auth.getRedirectResult().then((result) => {
          this.signInSuccess(result);

          if (this.redirectLoginCallback)
            this.redirectLoginCallback();

          this.redirectLogin = false;
          this.redirectLoginCallback = null;
        })
        .catch(function(error) {
          alert("Got error: " + JSON.stringify(error))
        })
      }
    });
  }

  createUserSuccess = (result) =>
    this.user = result.user;

  signInSuccess = (result) => {
    this.user = result.user;
  }

  signOutSuccess = () => {
    this.user = null;
  }

  doCreateUserWithEmailAndPassword = (name, email, password, callback) => 
    this.auth.createUserWithEmailAndPassword(email, password).then((userCredential) => {
      // [END createwithemail]
      // callSomeFunction(); Optional
      // var user = firebase.auth().currentUser;

      userCredential.user.updateProfile({
          displayName: name
      });        

      this.createUserSuccess(userCredential.user);

      if (callback)
        callback(userCredential.user);
    });

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password).then(this.signInSuccess)


  doSignInWithGoogle = (callback) => {
    var userAgent = window.navigator.userAgent.toLowerCase();
    var safari = /safari/.test( userAgent );
    var ios = /iphone|ipod|ipad/.test( userAgent );

    if (ios && !safari || ('standalone' in window.navigator && window.navigator.standalone)) {
       this.auth.signInWithRedirect(this.googleProvider)
    }
    else {
      return this.auth.signInWithPopup(this.googleProvider).then((result) => {
        this.signInSuccess(result);
        callback();
      })
    }
  }

  doSignInWithGoogleUsingRedirect = () => {
   /* var userAgent = window.navigator.userAgent.toLowerCase();
    var safari = /safari/.test( userAgent );
    var ios = /iphone|ipod|ipad/.test( userAgent ); */

    this.auth.signInWithRedirect(this.googleProvider)
  }

  doSignInWithGoogle = () => {
    return this.auth.signInWithPopup(this.googleProvider).then(this.signInSuccess)
  }

  doSignInWithToken = (token) => {
    this.auth.signInWithCustomToken(token)
    .then(this.signInSuccess);
  }

  doSignOut = () =>
    this.auth.signOut().then(this.signOutSuccess);

  doPasswordReset = email =>
    this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);
}
export default Firebase;