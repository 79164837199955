import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';

import { getProfileFunction } from '../util/FunctionUtils';

import Avatar from '../ui/Avatar';
import Award from '../ui/Award';
import HabitSummaryCard from '../ui/HabitSummaryCard';

const INITIAL_STATE = {
  name: null,
  image : null,
  email: '',
  activeGoals : null, 
  awards : null,
  dataLoaded: false,
};

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {
      getProfileFunction(this.props.user, (results) => {
        this.setState({
          dataLoaded : true,
          name: results.name,
          email: results.email,
          image: results.image,
          showPoints: results.showPoints,
          level: results.level,
          levelName: results.levelName,
          points: (results.points) ? results.points : 0,
          levelMinPoints: results.levelMinPoints,
          levelMaxPoints: results.levelMaxPoints,
          activeGoals : results.activeGoals.sort((g1, g2) => {
            if (g1.name < g2.name)
              return -1;
            else if (g1.name > g2.name)
              return 1;
            else
              return 0;
          }),
          pastGoals : results.pastGoals.sort((g1, g2) => {
            if (g1.startDate > g2.startDate)
              return -1;
            else if (g1.startDate < g2.startDate)
              return 1;
            else
              return 0;
          }),

          awards : results.awards
        })
      });
    }
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  componentWillUnmount() {
  }

  renderAward = (award) => {
    return <Award orientation='portrait' percentage={award.percentage} days={award.days} colour={award.colour} category={award.category} name={award.name}/>;
  }

  renderAwards = () => {
    var items = [];
    var awards = this.getAwardsSorted();

    items.push(<div className='sectionTitle'>AWARDS</div>);

    if (awards && awards.length > 0) {
      for (var r = 0; r <= Math.floor((awards.length - 1) / 2); r++)
        items.push(<div class="row">
          <div class="column">{this.renderAward(awards[r*2])}</div>
          <div class="column">{ ( (r*2+1) < awards.length ) ? this.renderAward(awards[r*2+1]) : "" }</div>
        </div>);
    }
    else
      items.push(<p align='center'>No awards yet</p>);

    return items;
  }

  renderProfilePanel = () => {
    return <div className='teamMemberProfilePanel'>
      <div className='teamMemberProfilePanelAvatar'>
        <Avatar size='large' name={this.state.name} image={this.state.image}/>
      </div>
      <div className='teamMemberProfilePanelName'>
        {this.state.name}
      </div>
      <div className='teamMemberProfilePanelEmail'>
        {this.state.email}
      </div>
    </div>;
  }

  renderActiveGoals = () => {
    var items = [];

    items.push(<div className='sectionTitle'>ACTIVE HABITS</div>);

    if (this.state.activeGoals && this.state.activeGoals.length > 0) {
      for (var goal of this.state.activeGoals) {
        items.push(<p align='center'><HabitSummaryCard size='large'  onClick={this.habitSummaryCardClicked.bind(this, goal.id)} name={goal.name} category={goal.category} colour={goal.colour} days={goal.days} award={goal.award}/></p>)
      }
    }
    else
      items.push(<p align='center'>No active habits</p>);

    return items;
  }

  habitSummaryCardClicked = (goalId) => {
    if (this.props.onGoalClicked) {
      this.props.onGoalClicked(goalId);
    }
  }

  renderPastGoals = () => {
    var items = [];

    items.push(<div className='sectionTitle'>PAST HABITS</div>);

    if (this.state.pastGoals && this.state.pastGoals.length > 0) {
      for (var goal of this.state.pastGoals) {
        items.push(<p align='center'><HabitSummaryCard size='large' onClick={this.habitSummaryCardClicked.bind(this, goal.id)} name={goal.name} category={goal.category} colour={goal.colour} days={goal.days} award={goal.award}/></p>)
      }
    }
    else
      items.push(<p align='center'>No past habits</p>);

    return items;
  }

  profileLevelCardClicked = () => {
    if (this.props.onLevelClicked)
      this.props.onLevelClicked();
  }

  renderLevel = () => {
    if (!this.state.level)
      return <div/>

      var items = []

    var percentage = (this.state.points - this.state.levelMinPoints) / (this.state.levelMaxPoints - this.state.levelMinPoints) * 100.0
    
    items.push(<div className='profileLevelName'>Level {this.state.level} - {this.state.levelName}</div>)
    items.push(<div className='profileLevelPoints'>{this.state.points} POINTS</div>)

    items.push(<div className='profileLevelMin'>{this.state.levelMinPoints}</div>)
    items.push(<div className='profileLevelMax'>{this.state.levelMaxPoints}</div>)

    items.push(
      <div className='profileLevelProgressBorder'>
       <div className='profileLevelProgressBar' style={{width: percentage + '%' }}/>
     </div>
    )

    return <div>
        <div className="sectionTitle">LEVEL</div>
        <div className='profileLevelCardContainer'><div className='profileLevelCard' onClick={this.profileLevelCardClicked}>{items}</div></div>
      </div>

  }

  getTitle = () => {
    if (this.state.dataLoaded)
      return this.state.name;
    else 
      return " ";
  }
  
  render = () => {
    var history = this.props.history;

    var items = [];

    if (!this.state.dataLoaded) {
      items.push(<div className='teamMemberPlaceholderCell'/>);
      items.push(<div className='teamMemberPlaceholderCell'/>);
      items.push(<div className='teamMemberPlaceholderCell'/>);
    }
    else {
      items.push(this.renderProfilePanel());
      if (this.state.showPoints)
        items.push(this.renderLevel())
      items.push(this.renderActiveGoals());
      items.push(this.renderPastGoals());
    }

    return items;
  }
}

export default Profile;