import React, { Component } from 'react';

import Banner from './Banner';
import Star from './Star';

const INITIAL_STATE = {

};

/*
 * Props 
 *   colour = hex
 */
class Award extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  } 

  getColour = () => {
    return this.props.colour
  }

  getBorderColour = () => {
    return this.adjustBrightness(this.getColour(), -20.0)
  }

  getStarPoints = () => {
    if (this.props.percentage >= 95)
      return 7;
    else if (this.props.percentage >= 85)
      return 5
    else 
      return 4
  }

  getStarClass = () => {
  if (this.props.percentage >= 95)
    return "award7PointStar";
  else if (this.props.percentage >= 85)
    return "award5PointStar"
  else 
    return "award4PointStar"
  }

  capitaliseFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render = () => {
    var daysLabel = (this.props.days === 1) ? "DAY" : "DAYS";

    // Orientation should be 'portrait' or 'landscape'
    var orientationCapitalised = this.capitaliseFirstLetter(this.props.orientation)

    if (this.props.orientation === 'portrait')
      return ( 
        <div className="awardPortrait">
          <div className="awardBannerPortrait"><Banner/></div>
          <div className="awardCategoryPortrait" style={{ backgroundColor: "#" + this.props.colour }}>{this.props.category}</div>
          <div className={this.getStarClass() + "Portrait"}><Star colour={this.getColour()} points={this.getStarPoints()}/></div>
          <div className="awardPercentagePortrait">{this.props.percentage}</div>
          <div className="awardNamePortrait">{this.props.name}</div>
          <div className="awardDaysPortrait">{this.props.days} {daysLabel}</div>
        </div>
      )
    else 
    return ( 
      <div className="awardLandscape">
        <div className="awardCategoryLandscape" style={{ backgroundColor: "#" + this.props.colour }}>{this.props.category}</div>
        <div className={this.getStarClass() + "Landscape"}><Star colour={this.getColour()} points={this.getStarPoints()}/></div>
        <div className="awardPercentageLandscape">{this.props.percentage}</div>
        <div className="awardNameLandscape">{this.props.name}</div>
        <div className="awardDaysLandscape">{this.props.days} {daysLabel}</div>
      </div>
    )
  }
}

export default Award;