import React, { Component } from 'react';

import { Line } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

class GoalTimesChart extends Component {

  getOptions = () => {
    return {
      maintainAspectRatio: false,

      animations: false,

      responsive: true,
      bezierCurve: true,

      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false
        },
        line: {
          tension: 10 // disables bezier curves
        }
      },

      scales: {
        x: {
          grid: {
            display: false
          },
          ticks: {
            color: 'black',
            font: {
              size: 8,
            },
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0
          }
        },
        y: {
          display: false,
          grid: {
            display: false
          }
        }
      }
    }
  }

  getData = () => {
    const labels = ['', '6AM', '', '12PM', '', '6PM', '' ];

    return {
      labels,
      datasets: [
        {
          borderWidth: 2,
          label: 'Dataset 1',
          data: this.props.report.stats.completionsByHour,

          borderColor: '#7b39ED',
          backgroundColor: 'rgba(125, 57, 237, 0.5)',
        }
      ],
    };
  }

  render = () => {
    return <Line options={this.getOptions()} data={this.getData()} />
  }

}

export default GoalTimesChart;