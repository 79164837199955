import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import HabitCategoryCard from '../discover/HabitCategoryCard'
import HabitCategories from '../discover/HabitCategories'

import { getUserAndTeam } from '../util/DatabaseUtils'

const INITIAL_STATE = {
  habitCategories: {},
  dataLoaded: false
};

class AddHabit1 extends Component {

    constructor(props) {
       super(props);
       this.state = { ...INITIAL_STATE };
    }

    checkDataLoaded = () => {
      if (this.props.firebase.user && !this.state.dataLoaded) {
        getUserAndTeam(this.props.firebase, (user, team) => {
          this.setState({
            user: user,
            team: team,
            dataLoaded: true
          })
        })
      }
    }

    componentWillMount = () => {
      this.checkDataLoaded();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
      this.checkDataLoaded();
    }

    componentWillUnmount() {
    }   

    addNewHabit = () => {
      this.props.history.push("/add-new-habit");
    }

    createNewHabitSelected = (habitId) => {
      this.props.history.push("/add-new-habit");
    }

    isParentHabitCategory = (categoryId) => {
      for (var id of Object.keys(this.state.team['habit-categories'])) {
        var habitCategory = this.state.team['habit-categories'][id]

        if (habitCategory.parent && habitCategory.parent === categoryId)
          return true
  
      }
      return false;
    }

    categorySelected = (habitCategory) => {
      if (this.isParentHabitCategory(habitCategory))
        this.props.history.push('/add-habit-1/' + habitCategory)
      else
        this.props.history.push('/add-habit-2/' + habitCategory)
    }


    allowPersonalHabits = () => {
      return false;
    }

    render = () => {
      var history = this.props.history;
      var categoriesMode = (this.props.match.params.parent) ? 'children' : 'top-level';

      return (
        <AppChrome title="Select a Category" history={history} team={this.state.team} requireLogin={true} firebase={this.props.firebase} back={true} tab="Today">
            <div>
                <HabitCategories mode={categoriesMode} parent={this.props.match.params.parent} firebase={this.props.firebase} onClick={this.categorySelected}/>

                { this.allowPersonalHabits() ?
                <div>
                    <div className='sectionTitle'>OR CREATE A PERSONAL HABIT</div>
                    <HabitCategoryCard id="personal" visible={true} colour="555555" icon="https" name="Personal" description="Personal habits are private to you" illustration="/illustration/personal-habit.jpg" onClick={this.addNewHabit}  />
                </div>
                  : 
                <div/> 
                } 
            </div>
        </AppChrome>
      )
      
    }
}

export default AddHabit1;