import React, { Component } from 'react';

import Button from '../ui/Button'
import FeedbackPopup from '../today/FeedbackPopup';
import InviteUsersPopup from './InviteUsersPopup';
import TabBar from './TabBar';

const INITIAL_STATE = {
  showInviteUsersPopup: false,
  showFeedbackPopup: false
};

class AppSidebar extends Component {
    constructor(props) {
      super(props);
      this.state = { ...INITIAL_STATE };
   }

   showInviteUsersPopup = () => {
     this.setState({ showInviteUsersPopup : true });
   }

   closeInviteUsersPopup = () => {
    this.setState({ showInviteUsersPopup : false });
   }

   showFeedbackPopup = () => {
    this.setState({ showFeedbackPopup : true });
   }

   closeFeedbackPopup = () => {
    this.setState({ showFeedbackPopup : false });
   }

    render() {
      var tab = this.props.tab;
      var history = this.props.history;

      return (
        <div className="App-sidebar">

          <TabBar firebase={this.props.firebase} tab={tab} history={history}/>

          <div className='sidebarFeedbackPrompt'>
            Have questions or<br/>feedback about Lasoo?
          </div>

          <div className='sidebarFeedbackButton'>
             <Button type='secondary' size='small' onClick={() => this.showFeedbackPopup()} action='Send feedback'/>
          </div>
          
          <div className='habitOfTheDayPanel'>
             <div className='habitOfTheDayPanelContainer'>
                <div className='habitOfTheDayPanelTitle'>HABIT OF THE DAY</div>
                <div className='habitOfTheDayPanelName'>Active listening</div>
                <img className='habitOfTheDayPanelIllustration' src="/illustration/active-listening.png"/>
              </div>
          </div>
          { this.state.showInviteUsersPopup 
            ? 
            <InviteUsersPopup onClose={this.closeInviteUsersPopup} firebase={this.props.firebase} />
            :
            <div/>
          }

          { this.state.showFeedbackPopup 
            ? 
            <FeedbackPopup onClose={this.closeFeedbackPopup} firebase={this.props.firebase} />
            :
            <div/>
          }
       </div>
      );
    }
}

export default AppSidebar;