import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import EndGoalPopup from './EndGoalPopup';
import GoalForm from './GoalForm';

import Button from '../ui/Button';
import MessagePopup from '../ui/MessagePopup'
import SpinnerPanel from '../ui/SpinnerPanel';

import { getUserAndTeam } from '../util/DatabaseUtils';
import { sendCalendarEventsFunction, sendSharedGoalInvitesFunction } from '../util/FunctionUtils';
import { challengeStatsUpdate } from '../util/StatsUtils';
import { getDefaultVisibility } from '../util/VisibilityUtils';

import moment from 'moment';

const calendarDateFormat = 'YYYY-MM-DD';

class UpdateGoal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataLoaded : false,
      updatingGoal: false,
      habit: '',
      name: '',
      category: '',
      objective: null,
      objectives: null,
      period: 'day',
      goal: '',
      reward: '',
      reminders : [ "09:00", "16:30"],
      calendarEvents : [],
      teamMembers : [],
      colour: 'FFFFFF',
      startDate: moment(new Date()).format(calendarDateFormat),
      endDate: null,
      daysOfWeek: this.getDefaultSelectedDays(),
      visibility: getDefaultVisibility(),
      programType: null,
      disableTeamVisibility: false,
      showEndGoalPopup: false
    };
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && this.props.match.params.challenge && !this.state.dataLoaded) {
     
      getUserAndTeam(this.props.firebase, (user, team) => {
        const challenge = user.challenges[this.props.match.params.challenge];
        const objectives = (user.objectives) ? user.objectives: null;

        if (!challenge) // This can happen when we delete the habit
          return;

        this.dataLoaded = true;
        this.setState({
          data: user,
          team: team,
          habit: (challenge.habit) ? challenge.habit : '',
          name: challenge.name,
          category: (challenge.category) ? challenge.category : '',
          reminders : challenge.reminders ? challenge.reminders : [],
          calendarEvents : challenge.calendarEvents ? challenge.calendarEvents : [],
          teamMembers : challenge.teamMembers ? challenge.teamMembers : [],
          colour: challenge.colour,
          objective: challenge.objective ? challenge.objective : null,
          objectives: objectives,
          startDate: challenge.startDate,
          endDate: (challenge.endDate) ? challenge.endDate: null,
          daysOfWeek: challenge.daysOfWeek,
          period: challenge.period,
          daysOfWeek: challenge.daysOfWeek,
          dataLoaded: true,
          visibility: challenge.visibility ? challenge.visibility : getDefaultVisibility(),
          programType: team.settings['program-type'],
          disableTeamVisibility : (team.settings.disableTeamVisibility) ? true: false,
        });
      });
    }
  } 

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }
 
  navigateToGoals = () => {
    this.props.history.push("/goal/" + this.state.objective);
  }

  updateChallenge = (objective, habit, name, category, period, selectedDays, startDate, endDate, colour, reminders, calendarEvents, teamMembers, visibility, showMessagePopup) => {
    var challengeId = this.props.match.params.challenge
    var challenge = {
      objective: objective,
      habit: habit,
      name: name,
      category: category,
      startDate: startDate,
      period: period,
      daysOfWeek: selectedDays,
      reminders: reminders,
      calendarEvents: calendarEvents,
      teamMembers : teamMembers,
      colour: colour,
      visibility: visibility
    }
    if (endDate) {
      challenge.endDate = endDate
    }

    this.setState({ updatingGoal: true })

    this.props.firebase.database.ref('users/' + this.props.firebase.user.uid + '/challenges/' + challengeId).set(challenge)
      .then(() => {
        challengeStatsUpdate(this.props.firebase, this.props.firebase.user.uid, challengeId, () => {
           sendCalendarEventsFunction(this.props.firebase.user.uid, challengeId)
           sendSharedGoalInvitesFunction(this.props.firebase.user.uid, challengeId)
           this.navigateToGoals()
        });
      })
      .catch((error) => {
        this.setState({ updatingGoal: false })

        alert("Error writing document: ", error);
      });
  }

  endHabitClicked = () => {
    this.setState({ 
      showEndHabitPopup: true 
    });
  }

  deleteChallenge = () => {
    var challengeId = this.props.match.params.challenge;
    this.props.firebase.database.ref('users/' + this.props.firebase.user.uid + '/challenges/' + challengeId).remove()
    .then(() => {
      this.navigateToGoals();
    })
    .catch((error) => {
      alert("Error deleting document: ", error);
    });
 }

  endGoalPopupClosed = (goalEnded) => {
    this.setState({
      showEndHabitPopup : false
    })

    if (goalEnded)
      this.props.history.push("/goals/" + this.state.objective)
  }

  getDefaultSelectedDays = () => {
    return [ 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
  }

  login = () => {
    this.props.history.push("/login");
  }

  signUp = () => {
    this.props.history.push("/signup");
  }

  onChallengeTeammates = () => {
    this.props.history.push("/challenge-teammates/" + this.props.match.params.challenge);
  }

  render = () => {
    var history = this.props.history;

    var back = (this.state.dataLoaded) ? "/goal/" + this.state.objective : "/goals";

    var title = (this.state.dataLoaded) ? this.state.name : " ";
    
    return (
      <AppChrome title={title} history={history} requireLogin={true} firebase={this.props.firebase} team={this.state.team} back={back} tab="Habits">
          { ! this.state.dataLoaded || this.state.updatingGoal
          ? 
          <SpinnerPanel/>
          :
          <div>
            <GoalForm history={history} firebase={this.props.firebase} actionName={'Update habit'} objective={this.state.objective} habit={this.state.habit} name={this.state.name} category={this.state.category} period={this.state.period} daysOfWeek={this.state.daysOfWeek} startDate={this.state.startDate} endDate={this.state.endDate} colour={this.state.colour} reminders={this.state.reminders} calendarEvents={this.state.calendarEvents} visibility={this.state.visibility} programType={this.state.programType} disableTeamVisibility={this.state.disableTeamVisibility} teamMembers={this.state.teamMembers} onSubmission={this.updateChallenge}/>
            { true || !this.state.endDate ?
              <p><Button type='secondary' size='large' onClick={this.endHabitClicked} action="End habit"/></p> 
              :
              <div/>
            }
            <p><Button type='secondary' size='large' onClick={this.onChallengeTeammates} action="Challenge teammates"/></p>
          </div>
          }

          {this.state.showEndHabitPopup
                ?
                <EndGoalPopup challenge={this.state.name} challengeId={this.props.match.params.challenge} firebase={this.props.firebase} onClose={this.endGoalPopupClosed}/>
                :
                <div></div>
          }

          {this.state.messagePopup
                ?
                <MessagePopup title="Update Habit" message={this.state.messagePopupMessage} onClose={this.state.messagePopupCallback} />
                :
                <div></div>
          }
      </AppChrome>
    )
  }
}


export default UpdateGoal;