import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import SpinnerPanel from '../ui/SpinnerPanel'

const INITIAL_STATE = {
  loggingIn: true,
  token: "unknown"
};

class LoginWithToken extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    window.loginWithToken = ((firebase, token) => {
//      this.setState({"token" : token})
      this.props.firebase.doSignInWithToken(token);
    })
    .bind(this, this.props.firebase);
  }

  render = () => {

    // In the case of doSignInWithGoogleUsingRedirect, Google redirects back to
    // this page but the user will be logged in

    if (this.props.firebase.user) {
      this.props.history.push("/");
      return <div/>;
    }

    var history = this.props.history;

    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
            <div className='verticalCenter'>
              <SpinnerPanel/> 
            </div>
        </div>
      </div>
    );
  }
}

export default LoginWithToken;

