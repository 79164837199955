import React, { Component } from 'react';

import Popup from 'reactjs-popup';
import PopupHeader from './PopupHeader'
import SquircleIcon from './SquircleIcon';

import 'reactjs-popup/dist/index.css';

const INITIAL_STATE = {
  icons: null,
  dataLoaded: false,
  search: ""
};

class IconSelector extends Component {

  constructor(props) {
      super(props);
      this.state = INITIAL_STATE;
  } 

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  checkDataLoaded = () => {
    if (!this.state.dataLoaded) {
      fetch('/icons/material-icons.txt')
        .then(response => response.text())
        .then(data => data.split('\n'))
        .then(icons => {
            this.setState({ dataLoaded : true, icons : icons });
        })
      }
  }

  onChangeSearch = (e) => {
    this.setState({ search : e.target.value })
  }

  getSearchResults = () => {
    var results = [];

    for (var i = 0; i < this.state.icons.length; i++) {
      var icon = this.state.icons[i];
      var search = this.state.search.trim().toLowerCase();

      if (search.length === 0 || icon.replace('_','').indexOf(search) >= 0) {
        results.push(icon);
        if (results.length >= 32) {
           return results;
        }
      }
    }
    return results;
  }

  iconSelected = (icon) => {
    this.props.onSelection(icon);
  }

  renderIconGrid = () => {
    if (this.state.dataLoaded) {
      var items = [];

      this.getSearchResults().map(icon => { 
        items.push(<div className='iconSearchGridItem'>
          <div className='iconSelectorIcon' onClick={this.iconSelected.bind(this,icon)}>
            <SquircleIcon size='medium' icon={icon} colour={this.props.colour}/>
          </div>
        </div>);
      });

      return <div className='iconSearchGridContainer'>
        {items}
      </div>}
    else
      return <div className='iconSearchGridContainer'/>
  }

  render = () => {
    return <div>
      <Popup open="true" closeOnDocumentClick className='icon-selector-popup' onClose={this.props.onClose}>
          <PopupHeader title="Select Icon"/>
          <input className='iconSearchBox' onChange={this.onChangeSearch} value={this.state.search} placeholder="Search icons" />
          {this.renderIconGrid()}
      </Popup>
    </div>;
  }
};

export default IconSelector;
