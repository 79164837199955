import React, { Component } from 'react';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';

import AppChrome from '../layout/AppChrome';
import Button from '../ui/Button';
import MessagePopup from '../ui/MessagePopup';
import RadioButton from '../ui/RadioButton';

import { getUserAndTeam, setUserField } from '../util/DatabaseUtils';

const INITIAL_STATE = {
  team : {},
  dataLoaded: false,
  messagePopup: false,
  messagePopupCallback : null,
  messagePopupMessage : '',
  selectedCategories: [],
};

class Onboarding2Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {
      getUserAndTeam(this.props.firebase, (user, team) => {
        this.setState({ 
          dataLoaded: true, 
          user: user,
          team: team,
          categories: this.createCategories(team)
         });
      })
    }
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  componentWillUnmount() {
  }

  createCategories = (team) => {
    var categories = [];

    for (var categoryId of Object.keys(team['habit-categories'])) {
      categories.push({
        id : categoryId,
        name: team['habit-categories'][categoryId].name
      })
    }
    categories.sort((c1, c2) => {
      if (c1.name < c2.name)
        return -1
      else if (c1.name === c2.name)
        return 0
      else 
        return 1;
    })

    return categories
  }

  radioButtonChanged = (category) => {
    if (this.state.selectedCategories.indexOf(category) >= 0) {
      var filtered = this.state.selectedCategories.filter(function(value, index, arr) { 
        return value !== category;
      });
      this.setState({
        selectedCategories: filtered
      })
    }
    else {
      this.state.selectedCategories.push(category)
      this.setState({ selectedCategories: this.state.selectedCategories })
    }
  }

  renderRadioButton = (i) => {
    if (i < this.state.categories.length) {
      var category = this.state.categories[i]
      var on = this.state.selectedCategories.indexOf(category.id) >= 0
      return <RadioButton label={category.name} on={on} onChange={this.radioButtonChanged.bind(this, category.id)}/>
    }
    else
      return <div/>
  }

  continueClicked = () => {
    if (this.state.selectedCategories.length < 6)
      this.showMessagePopup("Please select atleast 6 categories", this.closeMessagePopup)
    else {
      var wheelOfWork = {};
      for (var categoryId of this.state.selectedCategories) {
        wheelOfWork[categoryId] = 5;
      }
      setUserField(this.props.firebase, 'wheel-of-work', wheelOfWork, () => {
        this.props.history.push("/onboarding-2-step-3");
      });
    }
  }

  closeMessagePopup = () => {
    this.setState({
      messagePopup: false,
      messagePopupMessage: '',
      messagePopupCallback: null
    })
  }

  showMessagePopup = (message, callback) => {
    this.setState({
      messagePopup: true,
      messagePopupMessage: message,
      messagePopupCallback: callback
    })
  }

  renderOnboarding = () => {
    var items = [];

   // items.push(<img class='curvesImage' src='/illustration/curves.png'/>);
    items.push(<div className='onboardingTitle'>Wheel of Work</div>);
    items.push(<div className='onboardingContent'>Select areas of your work life you would like to focus on.</div>);

    if (this.state.dataLoaded) {
      var rows = [];

      for (var i=0; i < this.state.categories.length; i=i+2)  {
        var rb1 = this.renderRadioButton(i)
        var rb2 = this.renderRadioButton(i+1)

        rows.push(<tr>
          <td width='50%'>{rb1}</td>
          <td width='50%'>{rb2}</td>
        </tr>);
      }
      
      items.push(<table width='100%'>{rows}</table>)
    }
    else {
      items.push(<div className='onboardingCategoriesPlaceholder'/>);
    }
    items.push(<div><p></p><p><Button size="large" type="primary" onClick={this.continueClicked} action="Continue"/></p></div>)

    return items;
  }

  render = () => {
    var history = this.props.history;
    
    return (
      <AppChrome title="" history={history} requireLogin={true} firebase={this.props.firebase} back="/onboarding-2-step-1" tab="Today">
      {
          this.renderOnboarding()
      }
      { this.state.messagePopup
            ?
            <MessagePopup title='Wheel of Work' message={this.state.messagePopupMessage} onClose={this.state.messagePopupCallback} />
            :
            <div></div>
      }
      </AppChrome>
    )
  }
}

export default Onboarding2Step2;