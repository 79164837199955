export function getVisibilityOptions(programType, disableTeamVisibility) {

  var options = [];

  if (!disableTeamVisibility) {
    options.push({ label: "Team", value: "team", icon: "groups", recommended: true });
  }

  if (programType && programType === 'coaching-and-mentoring') {
    options.push({ label: "Coach or Mentor", value: "manager", icon: "group", recommended: disableTeamVisibility });
    options.push({ label: "Private", value: "private", icon: "person", recommended: false })
  }
  else {
    options.push({ label: "Manager", value: "manager", icon: "group", recommended: disableTeamVisibility })
    options.push({ label: "Private", value: "private", icon: "person", recommended: false })
  }
  return options;
}

export function getVisibilityOption(programType, disableTeamVisibility, value) {
  for (var option of getVisibilityOptions(programType, disableTeamVisibility)) {
    if (option.value === value)
      return option;
  }
}

export function getDefaultVisibility(disableTeamVisibility) {
  return disableTeamVisibility ? "manager" : "team";
}




