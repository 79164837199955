import React, { Component } from 'react';

class HabitSummaryCard extends Component {
  constructor(props) {
    super(props);
  }

  getClassName = () => {
    if (!this.props.size || this.props.size === 'small')
      return 'habitSummaryCardSmall'
    else
      return 'habitSummaryCardLarge'
  }

  renderDays = () => {
    if (this.props.days) {
      var daysLabel = this.props.days + ((this.props.days === 1) ? " day" : " days");
      return <div className='habitSummaryCardDays' style={{color : '#' + this.props.colour}}>{daysLabel}</div>;
    }
    else 
      return null;
  }

  renderAward = () => {
    if (this.props.award)
      return <div className='habitSummaryCardAward'>🏆</div>
    else
      return null;
  }

  onClick = () => {
    if (this.props.onClick)
      this.props.onClick();
  }

  render = () => {
    var categoryOrObjective = (this.props.objective) ? this.props.objective : this.props.category;

    return (
      <div className={this.getClassName()} onClick={this.onClick}>
        <div className='habitSummaryCardCategory' style={{color : '#' + this.props.colour}}>
          {categoryOrObjective}
        </div>
        <div className='habitSummaryCardDivider' style={{backgroundColor : '#' + this.props.colour}}/>
        <div className='habitSummaryCardName'>
          {this.props.name}
        </div>
        { this.renderDays() }
        { this.renderAward() }
      </div>
    )
  }
}

export default HabitSummaryCard;