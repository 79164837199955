import React, { Component } from 'react';

import Button from '../ui/Button'
import Popup from 'reactjs-popup';
import PopupHeader from '../ui/PopupHeader'

import FileUploader from "react-firebase-file-uploader";

import SpinnerPanel from '../ui/SpinnerPanel';

import { sendBadge, sendImmediateNudgesFunction } from '../util/FunctionUtils';

import 'reactjs-popup/dist/index.css';
import moment from 'moment';

import { activityHabitUpdate } from '../activity/ActivityUtils';
import { challengeStatsUpdate, calculateCurrentStreak, calculateDaysDoneBeforeDate } from '../util/StatsUtils';

import CheckinConfirmationPopup from './CheckinConfirmationPopup';

const calendarDateFormat = 'YYYY-MM-DD';
const calendarDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

const journalPrompts = [
  "Do you have any thoughts to share?",
  "What did you do well?",
  "What can you improve?",
  "What did you learn?",
  "How did you feel after?",
  "Does the habit align with your long term goals?",
  "Is the habit becoming easier?",
  "Did you have any challenges?",
  "Did you have any distractions?",
  "Are you find the habit beneficial?",
  "Are you enjoying the habit?",
  "Are you finding the habit easy or difficult?",
  "Are you finding the habit useful?",
  "Are you finding the habit worthwhile?",
  "Are you making progress towards your objective?",
  "Is the habit becoming more ingrained?",
  "Is the habit becoming more natural?",
  "Is the habit becoming more routine?",
  "Is the habit becoming more second nature?",
  "Is the habit becoming more straightforward?",
  "Are there any obstacles?",
  "Do you notice any patterns?",
  "Would you recommend the habit to others?",
  "Do you have any advice about this habit?",
  "How can you make the habit more effective?",
  "How can you make the habit more enjoyable?",
  "How can you make the habit more rewarding?",
  "How can you make the habit more satisfying?",
  "How can you make the habit more fun?",
  "How can you make the habit more interesting?",
  "How can you make the habit more motivating?",
  "What value have you got from practicing this habit?",
  "What value does this habit bring to your work?",
  "Can you scale the habit?",
  "Can you combine this habit with others?",
  "Has this habit changed how you view yourself?",
  "Has this habit changed how you view others?",
  "How long do you want to continue with this habit?"
]

class HabitPopupWithImageUploader extends Component {

  constructor(props) {
      super(props);
      this.state = { journal : props.journal, 
                     streak: calculateCurrentStreak(this.props.challengeId, this.props.userData, this.props.date), 
                     habitDone: calculateDaysDoneBeforeDate(this.props.challengeId, this.props.userData, this.props.date),
                     displayCheckinConfirmation : false,
                     displayImageUploader : false,
                     images : [],
                     fileToUpload : null,
                     uploadingFile : false,
                     uploadPercentage: 0,
                     placeholderText: "How did it go? " + this.getRandomPrompt() };
  } 

  //create ref
  fileInputRef = React.createRef();  

  componentWillMount = () => {

  }

  handleJournalChange = (event) => {
    this.setState({ journal: event.target.value });
  }

  habitUpdate = (done) => {
      if (done && this.state.journal === "") {
        return
      }

      const firstCheckIn = (!this.props.userData || !this.props.userData.days);

      var database = this.props.firebase.database;    
      var dataRef = database.ref('users/' + this.props.firebase.user.uid + "/days/" + this.props.date + "/" + this.props.challengeId);

      var value = {
        "timestamp" : moment().format(calendarDateTimeFormat),
        "done" : done,
      };
      if (this.state.journal) 
        value.journal = this.state.journal;

      if (this.state.images.length > 0)
        value.images = this.state.images;

      var today = moment().format(calendarDateFormat);
      if (this.props.date === today) {
        sendBadge(this.props.firebase.user.uid, today, () => {});
      }

      dataRef.set(value, (error) => {
        if (done)
          this.setState({ displayCheckinConfirmation : true })
        else
          this.props.onClose(true);

        challengeStatsUpdate(this.props.firebase, this.props.firebase.user.uid, this.props.challengeId, () => {});
        activityHabitUpdate(this.props.firebase, this.props.firebase.user.uid, this.props.date, this.props.challengeId, done, this.state.journal, this.state.images, this.state.streak + 1)

        if (firstCheckIn) {
          sendImmediateNudgesFunction(this.props.firebase.user.email, this.props.userData.team, 'Tracked Habit', () => {});
        }
      });

  }

  getRandomPrompt = () => {
    return journalPrompts[Math.floor(Math.random() * journalPrompts.length)];
  }

  habitClicked = () => {
    if (this.props.habitClicked)
      this.props.habitClicked();
  }

  fileChange = (event) => {
    this.setState({ fileToUpload : event.target.files[0], uploadingFile: true })
  }

  handleUploadStart = () => {
    this.setState({ uploadingFile: true, uploadPercentage: 0 })
  }

  handleUploadSuccess = (file) => {

    const fullUrl = "https://firebasestorage.googleapis.com/v0/b/lasoo-d9fad.appspot.com/o/images%2F" + file + "?alt=media";

    var images = this.state.images
    images.push(fullUrl)

    this.setState({ uploadingFile: false, images: images, uploadPercentage: 0 })
  }

  handleUploadError = (e) => {
    this.setState({ uploadingFile: false })
  }

  handleUploadProgress = (percentage) => {
    this.setState( { uploadPercentage : percentage })
  }

  getProgressBarWidth = () => {
    return this.state.uploadPercentage * 280.0 / 100.0
  }

  renderImages = () => {
    var images = []
    for (var i = 0; i < Math.min(this.state.images.length, 5); i++) {
      images.push(<img width="35" height="35" className='habitPopupImageUploaderImage' src={this.state.images[i]} alt=''/>)
    }

    return <div className='habitPopupImageUploaderImageContainer'>{images}</div>
  }

  renderChallengeUpdate = () => {
    var challenge = this.props.challenge;

    return <Popup open="true" closeOnDocumentClick className='habit-popup-with-image-uploader' onClose={this.props.onClose}>
        <PopupHeader title={challenge.name} onClick={this.habitClicked}/>
        <div width="100%" height="100%">

            <textarea className="habitPopupHabitJournal" placeholder={this.state.placeholderText} id="journal" name="journal" onChange={ (event) => { this.handleJournalChange(event); }} value={this.state.journal}></textarea>

            <div className='habitPopupImageUploaderContainer' onClick={this.showImageUploader}>
              { this.renderImages() }
              <div className='habitPopupImageUploader'>
                  <label className='habitPopupImageUploaderIcon'>
                       <img src="/icons/camera.png" width={30} height={30} alt="" />
                      <FileUploader
                        accept="image/*"
                        name="image"
                        hidden
                        multiple
                        randomizeFilename
                        storageRef={this.props.firebase.storage.ref("images")}
                        onUploadStart={this.handleUploadStart}
                        onUploadError={this.handleUploadError}
                        onUploadSuccess={this.handleUploadSuccess}
                        onProgress={this.handleUploadProgress}
                      />
                  </label>     
              </div>
            </div>

            { this.state.uploadingFile ?
              <div className='habitPopupImageUploaderProgressContainer'>
                  <div className='habitPopupImageUploaderProgressBarBackground'/>
                  <div className='habitPopupImageUploaderProgressBar' style={{ width: this.getProgressBarWidth() + 'px' }}/>
              </div>
            :

            <div>
              <div className='habitPopupSkipButton'>
                <Button size='large' disabled={this.state.journal === ''} type='secondary' onClick={() => this.habitUpdate(false)} action='Missed'/>
              </div>    

              <div className='habitPopupDoneButton'>
                <Button size='large' disabled={this.state.journal === ''} type={this.state.journal === '' ? 'secondary' : 'primary'} onClick={() => this.habitUpdate(true)} action='Completed'/>
              </div>  
            </div>
            }             
        </div>
    </Popup>
  }

  renderCheckinConfirmation = () => {
    return <CheckinConfirmationPopup challengeId={this.props.challengeId} challenge={this.props.challenge} userData={this.props.userData} team={this.props.team} journal={this.props.journal} date={this.props.date} onClose={this.props.onClose} onViewReport={this.props.onViewReport} firebase={this.props.firebase} />
  }

  render = () => {
    if (this.state.displayCheckinConfirmation)
      return this.renderCheckinConfirmation()
    else
      return this.renderChallengeUpdate()
  }
};

export default HabitPopupWithImageUploader;
