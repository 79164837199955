import React, { Component } from 'react';

import Button from '../ui/Button';
import ConfirmPopup from '../ui/ConfirmPopup'
import MessagePopup from '../ui/MessagePopup'

import HabitVideo from './HabitVideo';
import MDEditor from '@uiw/react-md-editor';

import 'reactjs-popup/dist/index.css';

import Toggle from 'react-toggle'

import 'react-toggle/style.css';

const INITIAL_STATE = {
  messagePopup: false,
  messagePopupMessage: '',
  messagePopupCallback: null,

  confirmPopup: false,
  confirmPopupCallback: null,
};

class EditHabitForm extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

    this.state.name = this.props.name;
    this.state.description = this.props.description;
    this.state.order = (this.props.order) ? this.props.order.toString() : '';
    this.state.videoURL = this.props.videoURL;
    this.state.colour = this.props.colour;
    this.state.category = this.props.category;
    this.state.recommended = this.props.recommended;
    this.state.visible = this.props.visible;
  }

  componentWillMount = () => {
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
  }

  closeMessagePopup = () => {
    this.setState({
      messagePopup: false,
      messagePopupMessage: '',
      messagePopupCallback: null
    })
  }

  showMessagePopup = (message, callback) => {
    this.setState({
      messagePopup: true,
      messagePopupMessage: message,
      messagePopupCallback: callback
    })
  }

  closeConfirmPopup = () => {
    this.setState({
      confirmPopup: false,
      confirmPopupMessage: '',
      confirmPopupCallback: null
    })
  }

  showConfirmPopup = (message, callback) => {
    this.setState({
      confirmPopup: true,
      confirmPopupMessage: message,
      confirmPopupCallback: callback
    })
  }

  stringIsBlank = (str) => {
    return (!str || /^\s*$/.test(str));
  }

  validateFields = () => {
    if (this.stringIsBlank(this.state.name) || this.state.name.trim().length < 7 || this.state.name.trim().length > 40) {
      this.showMessagePopup("Name must be between 7 and 40 characters.", this.closeMessagePopup);
      return false;
    }

    if (this.stringIsBlank(this.state.description) || this.state.description.trim().length === 0) {
      this.showMessagePopup("Please enter a description", this.closeMessagePopup);
      return false;
    }

    if (!this.stringIsBlank(this.state.order) && isNaN(Number(this.state.order))) {
      this.showMessagePopup("Order must be a number.", this.closeMessagePopup);
      return false;
    }

    return true;
  }

  isAlphaNumeric = (ch) => {
    return (ch.match(/^[0-9a-zA-Z]+$/));
  }

  onChangeName = (e) => {
    this.setState({ name: e.target.value })
  }

  onChangeDescription = (desc) => {
    this.setState({ description: desc })
  }

  onChangeVideoURL = (e) => {
    this.setState({ videoURL: e.target.value })
  }

  onChangeVisibility = () => {
    this.setState({ visible: !this.state.visible })
  }

  onChangeRecommended = () => {
    this.setState({ recommended: !this.state.recommended })
  }

  onChangeOrder = (e) => {
    this.setState({ order: e.target.value })
  }

  onDelete = () => {
    this.showConfirmPopup('Are you sure you want to delete this habit?', this.onDeleteConfirm);
  }

  onDeleteConfirm = (confirmed) => {
    if (confirmed) {
      this.props.onDelete();
    }
    else
      this.closeConfirmPopup();
  }

  getVideoId = (videoURL) => {
    if (videoURL && videoURL !== '') {
      try {
        const url = new URL(videoURL);
        return url.searchParams.get('v');
      }
      catch (e) {
        // If we can't parse the URL then return null
        return null;
      }
    }
    else
      return null;
  }

  onSubmission = () => {
    if (this.validateFields()) {
      var order = (this.state.order.trim() !== '') ? parseInt(this.state.order) : null;
      
      this.props.onSubmit(this.state.name, this.state.videoURL, this.state.description, this.state.colour, this.state.recommended, this.state.visible, order)
    }
  }

  renderHabitForm = () => {
    var visibiltyDesc = (this.state.visible) ? "Habit is visible to team" : "Habit is not visible to team";
    var recommendedDesc = (this.state.recommended) ? "Habit is recommended during onboarding" : "Habit is not recommended during onboarding";

    return <div>
      <div className='editHabitForm'>
      <div className='sectionTitle'>NAME</div>
      <input className='addHabitFormName' onChange={this.onChangeName} value={this.state.name} placeholder="A short name for the habit" />

      <div className='sectionTitle'>VIDEO URL</div>
      <input className='addHabitFormVideoURL' onChange={this.onChangeVideoURL} value={this.state.videoURL} placeholder="Link to video (optional)" />

      { this.state.videoURL ? 
        <HabitVideo url={this.state.videoURL}/>
        :
        <div/>
      }

      <div className='sectionTitle'>DESCRIPTION</div>
      <div className="editHabitDescription">
        <MDEditor
          preview='edit'
          value={this.state.description}
          onChange={this.onChangeDescription}
        />
      </div>

      {this.props.allowEditRecommended? 
        <div>
          <div className='sectionTitle'>RECOMMENDED</div>
          <div className='editHabitVisibilityLine'>
            <div className='editHabitVisibilityToggleContainer'>
              <Toggle className='editHabitVisibilityToggle' defaultChecked={this.state.recommended} onChange={this.onChangeRecommended} />
            </div>
            <div className='editHabitVisibilityToggleMessage'>{recommendedDesc}</div>
          </div>
        </div>
        :
        <div/>
      }

      {this.props.allowEditVisibility ? 
        <div>
          <div className='sectionTitle'>VISIBILITY</div>
          <div className='editHabitVisibilityLine'>
            <div className='editHabitVisibilityToggleContainer'>
              <Toggle className='editHabitVisibilityToggle' defaultChecked={this.state.visible} onChange={this.onChangeVisibility} />
            </div>
            <div className='editHabitVisibilityToggleMessage'>{visibiltyDesc}</div>
          </div>
        </div>
        :
        <div/>
      }

      { this.props.allowEditOrder ? 
        <div>
          <div className='sectionTitle'>DISPLAY ORDER</div>
          <input className='addHabitFormOrder' onChange={this.onChangeOrder} value={this.state.order} placeholder="An optional order number" />

        </div>
        :
        <div/>
      }

      </div>

      <div className='editHabitButtonLine'>
        <Button type='primary' size='large' onClick={this.onSubmission} action={this.props.action}/>
      </div>

      {this.props.onDelete ?
        <p><Button type='secondary' size='large' onClick={this.onDelete} action='Delete habit'/></p>: <div/>
      }

      {this.state.confirmPopup
        ?
        <ConfirmPopup title="Habit Form" message={this.state.confirmPopupMessage} callback={this.state.confirmPopupCallback} />
        :
        <div/>
      }

      {this.state.messagePopup
        ?
        <MessagePopup title="Habit Form" message={this.state.messagePopupMessage} onClose={this.state.messagePopupCallback} />
        :
        <div/>
      }

    </div>
  }

  render = () => {  
    return (
      <div>      
          { this.renderHabitForm() } 
      </div>
    )
  }
}

export default EditHabitForm;