import React, { Component } from 'react';
import { getJSDocClassTag, isTemplateExpression } from 'typescript';

const INITIAL_STATE = {

};

/*
 * Props 
 *   label = string
 *   on = 'true' | 'false'
 *   onChange = function
 */
class RadioButton extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  } 

  getClassNameProperty = () => {
    if (this.props.on)
      return 'radioButtonPressed'
    else
      return 'radioButtonNotPressed';
  }

  render = () => {
    var disabled = (this.props.disabled) ? true : false;
    return ( 
      <div className='buttonContainer'>
        <button className={this.getClassNameProperty()} onClick={this.props.onChange}>
          {this.props.label}
        </button>
      </div>
    )
  }
}

export default RadioButton;