import React, { Component } from 'react';

import Button from './Button';

const INITIAL_STATE = {
};

class PinnedCard extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  render = () => {
    return (
      <div class="pinnedCardContainer">
        <div class="pinnedCard">
          <div class="pinnedCardPinCircle">
            <div class="pinnedCardPin material-icons-outlined">push_pin</div>
          </div>
          <div class="pinnedCardDescription">{this.props.description}</div>
          <div class="pinnedCardButton"><Button type='secondary ' size='small' action={this.props.action} onClick={this.props.onButtonClick}/></div>
        </div>
      </div>
    );
  }
}

export default PinnedCard;

