import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Button from '../ui/Button';
import MessagePopup from '../ui/MessagePopup';
import SpinnerPanel from '../ui/SpinnerPanel';

import moment from 'moment';

import { v4 as uuidv4 } from 'uuid';

import { activityJoined } from '../activity/ActivityUtils'
import { getUserAndTeam } from '../util/DatabaseUtils'

import passwordValidator from "password-validator";
import { sendImmediateNudgesFunction } from '../util/FunctionUtils';

const INITIAL_STATE = {
  name: '',
  password: '',
  passwordConfirm: '',
  error: null,
  dataLoaded: false,
  inviteAccepted: false,
  creatingUser: false,
  showMessagePopup: false,
  message: ""
};

const calendarDateFormat = 'YYYY-MM-DD';

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them.
`;

var dataLoading = false;

class Invitation extends Component {

  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = (invitationId) => {
    if (!this.state.dataLoaded && !dataLoading) {
      dataLoading = true;

      var xhr = new XMLHttpRequest()
      xhr.addEventListener('load', () => {
        var invitation = JSON.parse(xhr.responseText)
        this.setState({ dataLoaded: true, invitation: invitation, inviteAccepted: invitation['invitee-accepted'] });
      })
      var url = 'https://us-central1-lasoo-d9fad.cloudfunctions.net/getInvitation?id=' + this.props.match.params.invitation;
      // open the request with the verb and the url
      xhr.open('GET', url)
      // send the request
      xhr.send()
    }
  }

  componentWillMount = () => {
    this.checkDataLoaded(this.props.match.params.invitation)
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded(this.props.match.params.invitation)

  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getPasswordValidator = () => {
    var schema = new passwordValidator();

    schema
    .is().min(8)                                    // Minimum length 8
    .is().max(100)                                  // Maximum length 100
    .has().uppercase()                              // Must have uppercase letters
    .has().lowercase()                              // Must have lowercase letters
    .has().digits(1)                                // Must have at least 2 digits
    .has().not().spaces()   

    return schema
  }

  updateUser = () => {
    var user = this.props.firebase.auth.currentUser;
    user.reload()

    var userData = {};
    userData.team = this.state.invitation.team;

    if (this.state.invitation['default-objective']) {
      userData.objectives = {}

      var objectiveId = "objective-" + uuidv4();

      var objective = {
        name: this.state.invitation['default-objective'],
        colour: "ff2e92",
        createdDate: moment().format(calendarDateFormat)
      }

      userData.objectives[objectiveId] = objective;
    }

    this.props.firebase.database.ref('users/' + user.uid + '').set(userData)
      .then(() => {

        var xhr = new XMLHttpRequest()
        xhr.addEventListener('load', () => {
          // This is required to get the emailVerified update
          this.props.firebase.user.reload().then(() => {
            getUserAndTeam(this.props.firebase, (user, team) => {
              activityJoined(this.props.firebase, this.props.firebase.user.uid, moment().format(calendarDateFormat))

              sendImmediateNudgesFunction(this.props.firebase.user.email, user.team, 'Activated', () => {})

              if (team && team.settings && team.settings['program-type'] && team.settings['program-type'] === 'performance-management')   
                this.props.history.push("/onboarding-3-step-1")
              else
                this.props.history.push("/onboarding-1-step-1");
            });
          });
        })
        var url = 'https://us-central1-lasoo-d9fad.cloudfunctions.net/invitationAccepted?id=' + this.props.match.params.invitation;
        // open the request with the verb and the url
        xhr.open('GET', url)
        // send the request
        xhr.send()

      })
      .catch((error) => {
        alert("Error writing document: ", error);
      });
  }

  validateFields = () => {

    if (this.state.name.trim().length < 3 || this.state.name.indexOf("@") >= 0 || this.state.name.indexOf(" ") < 0) {
      this.showMessagePopup("Please enter a valid full name");
      return false;
    }

    if (this.state.name.trim().length < 3 || this.state.name.indexOf("@") >= 0 || this.state.name.indexOf(" ") < 0) {
      this.showMessagePopup("Please enter a valid full name");
      return false;
    }


    if (!this.getPasswordValidator().validate(this.state.password)) {
      this.showMessagePopup("Passwords must be alteast 8 characters, with an uppercase, a lowercase and a digit");
      return false;
    }

    if (this.state.password !== this.state.confirmPassword) {
      this.showMessagePopup("Confirmation password does not match");
      return false;
    }
    
    return true;
  }

  
  showMessagePopup = (message) => {
    this.setState({
      showMessagePopup: true,
      message: message
    })
  }

  closeMessagePopup = () => {
    this.setState({ showMessagePopup: false })
  }


  continueClicked = event => {
    if (!this.validateFields())
      return;

    this.setState({ creatingUser : true })

    this.props.firebase
      .doCreateUserWithEmailAndPassword(this.state.name, this.state.invitation['invitee-email'], this.state.password)
      .then(() => {
        this.updateUser();
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ creatingUser: false, error });
      });
  };

  signInWithGoogle = () => {
    this.setState({ creatingUser : true })

    this.props.firebase.doSignInWithGoogle()
      .then(() => {
        this.updateUser();
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ creatingUser: false, error });
      });
  }

  doOnboarding = () => {
    this.props.history.push("/onboarding-1-step-1")
  }

  render = () => {
    var history = this.props.history;

    const isInvalid =
      this.state.password === '' ||
      this.state.username === '';

    var instructions = '';
    if (this.state.dataLoaded)
      instructions = "Join " + this.state.invitation['team-name'] + " on Lasoo";

    return (
      <AppChrome title="Lasoo - Invitation" history={history} requireLogin={false} firebase={this.props.firebase} back="/" tab="Today">
        {
          !this.state.dataLoaded ?
            <SpinnerPanel/>
            :
            this.state.creatingUser ?
              <SpinnerPanel/>
            :
            this.state.inviteAccepted ?
              <div className='onboardingInstructions'>Invite already accepted.</div>
            :
            <div>
                <div className='onboardingInstructions'>{instructions}</div>

                <input
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                  type="text"
                  placeholder="Full Name"
                />

                <input
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  type="password"
                  placeholder="Pick a password for Lasoo"
                />

                <input
                  name="confirmPassword"
                  value={this.state.confirmPassword}
                  onChange={this.onChange}
                  type="password"
                  placeholder="Confirm password"
                />

                <Button type='primary' size='large' action="Continue" onClick={this.continueClicked}/>

                { false && 
                <p>
                  <button type="button" className='btn-large btn-google' onClick={this.signInWithGoogle}>
                    <img src="../google_logo.png" width="18" height="18" />&nbsp;&nbsp;Continue with Google
                  </button>
                </p> 
                }

            </div>
          }      

          { this.state.showMessagePopup ? 
            <MessagePopup message={this.state.message} title="Signup" onClose={this.closeMessagePopup}/>
            : 
            <div/>
          }
      </AppChrome>
    );
  }
}

export default Invitation;

