import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Button from '../ui/Button';
import SpinnerPanel from '../ui/SpinnerPanel';

import GoalDaysChart from './GoalDaysChart';
import GoalReportCalendarGrid from './GoalReportCalendarGrid';
import GoalReportShareLinkPopup from './GoalReportShareLinkPopup';
import GoalTimesChart from './GoalTimesChart';
import GoalWeeksChart from './GoalWeeksChart';

import { getUser } from '../util/DatabaseUtils';
import { getGoalReportFunction } from '../util/FunctionUtils'; 
import moment, { calendarFormat } from 'moment';

import ImageCarouselPopup from '../ui/ImageCarouselPopup';

const calendarDateFormat = 'YYYY-MM-DD';
const calendarDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

const INITIAL_STATE = {
  showImageCarouselPopup: false,
  imageCarouselPopupSelectedImage: 0,
  imageCarouselPopupImages: []

};

class GoalReportComponent extends Component {

    constructor(props) {
       super(props);
       this.state = { ...INITIAL_STATE };
    }

    getTimestampSlot = (timestamp) => {
      var t = moment(timestamp, calendarDateTimeFormat)
      var hours = t.hours()

      if (hours >= 0 && hours <= 4) {
        return 0
      }
      else if (hours >= 5 && hours <= 7) {
        return 1
      }
      else if (hours >= 8 && hours <= 10) {
        return 2
      }
      else if (hours >= 11 && hours <= 13) {
        return 3
      } 
      else if (hours >= 14 && hours <= 16) {
        return 4
      }
      else if (hours >= 17 && hours <= 19) {
        return 5
      }
      else {
        return 6
      }
    }


    headerClicked = () => {
      if (this.props.report.habit.habit)  
        this.props.history.push("/habit/" + this.props.report.habit.habit)
    }

    renderLoading = () => {
      return <span className='loading'>Loading...</span>
    }

    renderHeader = () => {
      var objectiveOrCategory = (this.props.report.habit.objective) ? this.props.report.habit.objective : this.props.report.habit.category;

      return <div className='goalReportHeader' onClick={this.headerClicked}>
        <div className='goalReportCategory'>
          <div className='goalReportCategoryLabel' style={{ backgroundColor: "#" + this.props.report.habit.colour }}>{objectiveOrCategory}</div>
        </div> 
        <div className='goalReportHabitName'>
          {this.props.report.habit.name}
        </div>
        <div className='goalReportChevron'>
          <img className='goalReportChevronImage' src="/icons/chevron-right-icon.png"/>
        </div>

      </div>
    }

    renderStats = () => {
      return <div className='goalReportStats'>
        <div className='goalReportCompletionRate'>{this.props.report.stats.completionRate}%</div>
        <div className='goalReportCompletionRateLabel'>Completion Rate</div>
        <div className='goalReportCompletions'>{this.props.report.stats.completions}</div>
        <div className='goalReportCompletionsLabel'>Completions</div>
        <div className='goalReportBestStreak'>{this.props.report.stats.bestStreak}</div>
        <div className='goalReportBestStreakLabel'>Best Streak</div>             
      </div>
    }

    renderCharts = () => {
      return <div className='goalReportCharts'>
        <div className='goalReportChartsLabel'>Completions By Week</div>
        <div className='goalReportWeeksChart'><GoalWeeksChart report={this.props.report}/></div>
      </div>
    }

    displayImageCarouselPopup = (images, selectedIndex)  => {
      this.setState({ showImageCarouselPopup : true, imageCarouselPopupImages: images, imageCarouselPopupSelectedImage: selectedIndex })
    }

    closeImageCarouselPopup = () => {
      this.setState({ showImageCarouselPopup : false })
    }


    renderCalendar = () => {
      return <div className='goalReportCalendar'>
        <div className='goalReportCalendarGrid'><GoalReportCalendarGrid report={this.props.report}/></div>
      </div>;

      return <div className='goalReportCalendar'>
        <div className='goalReportCalendarLabel'>Calendar</div>
        <div className='goalReportCalendarGrid'><GoalReportCalendarGrid report={this.props.report}/></div>
      </div>
    }

    getJournalEntries = () => {
      var report = this.props.report;

      if (!report.activity)
        return [];

      var entries = [];
      for (var date of Object.keys(report.activity).sort()) {
        if (report.activity[date].journal && report.activity[date].journal.trim().length > 0) {
          entries.push({
            date: date,
            entry: report.activity[date].journal,
            images: report.activity[date].images && report.activity[date].images.length > 0 ? report.activity[date].images : null,
            endJournal: false,
            done: report.activity[date].done,
          })
        }
      }

      if (report.habit && report.habit.endDate && report.habit.endJournal) {
        entries.push({
          date: report.habit.endDate,
          entry: report.habit.endJournal,
          endJournal: true
        })
      }

      return entries;
    }

    renderJournalEntry = (entry) => {
      var dateFormatted = moment(entry.date, calendarDateFormat).format('DD MMM');

      var dateCellClass = (entry.endJournal) ? 'goalReportJournalDateCellEndJournal' : (entry.done) ? 'goalReportJournalDateCell' : 'goalReportJournalDateCellMissed';
      var journalCellClass = (entry.endJournal) ? 'goalReportJournalEntryCellEndJournal' : 'goalReportJournalEntryCell'

      const items = []
      items.push(<tr className='goalReportJournalRow'>
        <td className={dateCellClass}>{dateFormatted}</td>
        <td className={journalCellClass}>{entry.entry}</td>
      </tr>)

      if (entry.images) {


        var imagesHtml = []
        for (var i = 0; i < Math.min(entry.images.length, 5); i++) {
          imagesHtml.push(<img onClick={this.displayImageCarouselPopup.bind(this,entry.images, i)} width="35" height="35" className='teamActivityCardImage' src={entry.images[i]} alt=''/>)
        }
    
        items.push(<tr>
          <td className={dateCellClass}></td>

          <td>
            <div className='goalReportJournalImageContainer'>
              {imagesHtml}
            </div>
          </td>
        </tr>)
      }

      return items;

    }

    renderJournal = (journal) => {
      var rows = [];

      journal.forEach((entry) => {
        rows.push(this.renderJournalEntry(entry))
      });

      return <div className='goalReportJournal'>
       <div className='goalReportJournalLabel'>Journal</div>
       <table className='goalReportJournalTable'>
        {rows}
       </table>
      </div>
    }

    render = () => {
       
      if (Object.keys(this.props.report).length === 0) {
        return <div className='goalReportHeader'>Goal has been deleted</div>
      }
          
      var report = [];

      report.push(this.renderHeader())
      report.push(this.renderStats())
      report.push(this.renderCharts())
      report.push(this.renderCalendar())

      var journal = this.getJournalEntries()
      if (journal.length > 0)
        report.push(this.renderJournal(journal))

      if (this.state.showImageCarouselPopup) {
        report.push(<ImageCarouselPopup selectedItem={this.state.imageCarouselPopupSelectedImage} images={this.state.imageCarouselPopupImages} onClose={this.closeImageCarouselPopup}/>)
      }

      return report;  
    }
}

export default GoalReportComponent;