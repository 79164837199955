import moment, { min } from 'moment';

import firebase from 'firebase/app';

import { fetchTeamFromServer } from './DatabaseUtils';
import { toast } from 'react-hot-toast';

const calendarDateFormat = 'YYYY-MM-DD';

export function calculateLevel(team, userId) {
  const userPoints = getUserPoints(team, userId);

  return calculateLevelForPoints(team, userPoints);
}

export function calculateLevelForPoints(team, userPoints) {
  const levels = getOrderedLevels(team);

  // Find the first level that has more points than the user has
  
  var maxLevel = 1;

  for (var i = 0; i < levels.length; i++) {
    if (userPoints >= levels[i].points) {
      maxLevel = i + 1;
    }
  }
  return maxLevel;
}


export function getUserPoints(team, userId) {
  for (const memberKey of Object.keys(team.member)) {
    const member = team.member[memberKey];
    if (member['user-id'] === userId) {
      return member.points ? parseInt(member.points) : 0;
    }
  }
  return 0;
}

export function getOrderedLevels(team) {
  var levels = [];

  if (team.levels) {
    for (var levelId in team.levels) {
      var levelData = team.levels[levelId];
      levels.push(levelData);
    }
  }
  levels.sort((l1, l2) => {
    return l1.points - l2.points;
  })

  return levels;
}

export function getEventDescription(event) {
  switch (event) {
    case 'addHabit':
      return "trying a new habit";
    case 'checkin':
      return "checking in";
    case 'fiveStreak':
        return "checking in (>5 streak)";
    case 'tenStreak':
      return "checking in (>10 streak)";
    case 'twentyStreak':
        return "checking in (>20 streak)";
    case 'fiftyStreak':
          return "checking in (>50 streak)";
    case 'like':
      return "liking a post";
    case 'comment':
      return "posting a comment";
  }
  return "";
}


export function awardPoints (fb, teamId, team, userId, userEmail, eventName, callback) {
  const userPointsBefore = getUserPoints(team, userId);
  const userLevelBefore = calculateLevelForPoints(team, userPointsBefore);

  const pointsForEvent = (team.points && team.points[eventName]) ? parseInt(team.points[eventName]) : 0;
  const newPoints = userPointsBefore + pointsForEvent;

  const level = calculateLevelForPoints(team, newPoints);

  const orderedLevels = getOrderedLevels(team);
  const levelName = (orderedLevels.length > 0) ? orderedLevels[level - 1].name : "Habit Novice";

  if (team.settings && team.settings.showPoints && pointsForEvent > 0) {
    const eventDescription = getEventDescription(eventName);

    const toastMessage = "+" + pointsForEvent + " " + ((pointsForEvent === 1) ? "point" : "points") + " for " + eventDescription
    toast(toastMessage, { 
      icon: '👏'
    })

    if (level !== userLevelBefore) {
      const newLevelMessage = "Congratulations! You've reached level " + level + " - " + levelName + "."
      toast(newLevelMessage, { 
        icon: '🌟',
      })
    }
  }

  if (pointsForEvent > 0) {
      // Update points for member

    const userEmailKey = userEmail.replaceAll('.', '_');

    const fields = {
      [`member.${userEmailKey}.points`]: newPoints
    }

    fb.firestore
    .collection('teams')
    .doc(teamId)
    .update(fields)
    .then(() => {
      callback({
        points: pointsForEvent,
        newPoints: newPoints,
        newLevel: (level !== userLevelBefore),
        level: level,
        levelName: levelName
      })
    })
    .catch((error) => {
        alert(error);
    });
  }
  else {
    callback({
      points: pointsForEvent,
      newPoints: newPoints,
      newLevel: (level !== userLevelBefore),
      level: level,
      levelName: levelName
    })
  }
}