import React, { Component } from 'react';

import Button from '../ui/Button'
import Popup from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';

class AwardNotWonPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {};
  } 

  componentWillMount = () => {

  }

  render = () => {
    return (
        <Popup open="true" closeOnDocumentClick className='award-not-won-popup' onClose={this.props.onClose}>
          <div className='awardNotWonPopup'>
    
            <div className='awardNotWonPopupMessage'><b>Aww!</b> Goal not complete.</div>
            <div className='awardNotWonPopupComment'><p>Unfortunately you missed too many days on your goal around <b>{this.props.name}</b></p>
                 <p>You can greatly increase your chances of sticking with a habit formation by following the <b>Four Rules of Behaviour Change</b></p> 
            </div>

            <div className='awardNotWonPopupOKButton'>
              <Button type='primary' size='large' action="Let's try again" onClick={this.props.onClose}/>   
            </div>  
          </div>           
        </Popup>
    );
  }
};

export default AwardNotWonPopup;
