import moment, { min } from 'moment';

import firebase from 'firebase/app';
import { getDefaultVisibility } from '../util/VisibilityUtils';

import { fetchTeamFromServer } from '../util/DatabaseUtils';

const calendarDateFormat = 'YYYY-MM-DD';

function getChallengeDays(challenge) {
    var numOfDays = 0;

    var dateMoment = moment(challenge.startDate, calendarDateFormat);
    var endDateMoment = moment(challenge.endDate, calendarDateFormat);

    while (dateMoment <= endDateMoment) {
      if (challenge.period != 'day' || challenge.daysOfWeek.indexOf(dateMoment.format('ddd')) >= 0) { 
        numOfDays += 1;
      }
      dateMoment.add(1, 'days');
    }

    return numOfDays;
}

function sendActivityEmail(type, team, date, activity, userFromId, userToId) {
  var xhr = new XMLHttpRequest()
  xhr.addEventListener('load', () => {})
  var url = 'https://us-central1-lasoo-d9fad.cloudfunctions.net/sendActivityEmail?type=' + type + '&team=' + team + '&date=' + date + '&activity=' + activity + '&userfrom=' + userFromId + '&userto=' + userToId

  // open the request with the verb and the url
  xhr.open('GET', url)
  // send the request
  xhr.send()  
}

function postActivity(fb, team, activity, date) {
  if (!activity.visibility || activity.visibility === 'private')
      return

  var url = 'https://us-central1-lasoo-d9fad.cloudfunctions.net/addActivity'

  var http = new XMLHttpRequest();
  http.open('POST', url, true);
  http.setRequestHeader('Content-type', 'application/json')

  var data = {
    teamId: team,
    activity: activity,
    date: date
  }

  http.onreadystatechange = () => {
      if (http.readyState == 4 && http.status == 200) {
          // Fetch the team from the server to update the cache
      //    fetchTeamFromServer(fb, team, () => {})

          console.log("Post activity Response good");
      }
  }
  http.send(JSON.stringify(data));

  return;
} 

export function postActivityComment(fb, team, date, index, userToId, isReply, comment, callback) {
  var commentRecord = {
    userId : fb.user.uid,
    comment : comment,
    date : moment().format(calendarDateFormat)
  }

  if (isReply)
    commentRecord.replyTo = userToId;

  var update = {};
  update['activityComments.' + date + "." + index] = firebase.firestore.FieldValue.arrayUnion(commentRecord)

  fb.firestore
  .collection('teams')
  .doc(team)
  .update(update)
  .then(callback)
  .catch((error) => {
    alert(error);
  });

  sendActivityEmail(isReply ? 'reply' : 'comment', team, date, index, fb.user.uid, userToId)
}

export function postActivityLike(fb, team, date, index, userFromId, userToId, callback) {
  var update = {};
  update['activityLikes.' + date + "." + index + "." + fb.user.uid + ".sent"] = false 

  fb.firestore
  .collection('teams')
  .doc(team)
  .update(update)
  .then(callback)
  .catch((error) => {
    alert(error);
  });

  sendActivityEmail('like', team, date, index, userFromId, userToId)
}

export function activityNewGoal(fb, userId, date, challengeId) {
    fb.database.ref().child("users").child(userId).get().then((snapshot) => {
      if (snapshot.exists()) {
        var userData = snapshot.val();
  
        if (userData.team && userData.challenges && userData.challenges[challengeId] && userData.challenges[challengeId].habit) {
          var challenge = userData.challenges[challengeId];

          var activity = {
              goalId: challengeId,
              userId : userId,
              habit : userData.challenges[challengeId].habit,
              action : 'started',
              days : getChallengeDays(userData.challenges[challengeId]),
              visibility: (challenge.visibility) ? challenge.visibility : getDefaultVisibility()
          }

          if (challenge.objective) {
            activity.objective = userData.objectives[challenge.objective].name
            activity.colour = userData.objectives[challenge.objective].colour
          }

          postActivity(fb, userData.team, activity, date);
        }
      }
    });
};

export function activityHabitEnded(fb, userId, date, challengeId, journal, recommend, ingrained) {
  fb.database.ref().child("users").child(userId).get().then((snapshot) => {
    if (snapshot.exists()) {
      var userData = snapshot.val();

      if (userData.team && userData.challenges && userData.challenges[challengeId] && userData.challenges[challengeId].habit) {
        var challenge = userData.challenges[challengeId];

        var activity = {
            userId : userId,
            goalId: challengeId,
            habit : userData.challenges[challengeId].habit,
            action : 'ended',
            visibility: (challenge.visibility) ? challenge.visibility : getDefaultVisibility(),
            ingrained: ingrained,
            recommend:  recommend
        }

        if (challenge.objective) {
          activity.objective = userData.objectives[challenge.objective].name
          activity.colour = userData.objectives[challenge.objective].colour
        }

        if (journal.trim() !== '')
            activity.journal = journal.trim()

        postActivity(fb, userData.team, activity, date);
      }
    }
  });
};

export function activityJoined(fb, userId, date) {
  fb.database.ref().child("users").child(userId).get().then((snapshot) => {
    if (snapshot.exists()) {
      var userData = snapshot.val();

      if (userData.team) {
        var activity = {
            userId : userId,
            action : 'joined',
            visibility: getDefaultVisibility()
        }

        postActivity(fb, userData.team, activity, date);
      }
    }
  });
};

export function activityHabitUpdate(fb, userId, date, challengeId, done, journal, images, streak) {
    fb.database.ref().child("users").child(userId).get().then((snapshot) => {
      if (snapshot.exists()) {
        var userData = snapshot.val();
  
        if (done && userData.team && userData.challenges && userData.challenges[challengeId] && userData.challenges[challengeId].habit) {
          var challenge = userData.challenges[challengeId];

          var activity = {
              userId : userId,
              goalId: challengeId,
              habit : userData.challenges[challengeId].habit,
              action : (done) ? 'complete' : 'skipped',
              streak : streak,
              visibility: (challenge.visibility) ? challenge.visibility : getDefaultVisibility()
          }

          if (challenge.objective) {
            activity.objective = userData.objectives[challenge.objective].name
            activity.colour = userData.objectives[challenge.objective].colour
          }

          if (journal.trim() !== '')
              activity.journal = journal.trim()

          if (images && images.length > 0)
              activity.images = images
  
          postActivity(fb, userData.team, activity, date);
        }
      }
    });
};



export function activityAwardWon(fb, userId, date, challengeId, percentage, days) {

  fb.database.ref().child("users").child(userId).get().then((snapshot) => {
    if (snapshot.exists()) {
      var userData = snapshot.val();
 
      if (userData.team && userData.challenges && userData.challenges[challengeId] && userData.challenges[challengeId].habit) {
        var challenge = userData.challenges[challengeId] 

        var activity = {
            action: 'award',
            userId : userId,
            goalId: challengeId,
            habit : challenge.habit,
            name: challenge.name,
            category : challenge.category,
            colour: challenge.colour,
            percentage: percentage,
            days: days,
            visibility: (challenge.visibility) ? challenge.visibility : getDefaultVisibility()
        }

        if (challenge.objective) {
          activity.objective = userData.objectives[challenge.objective].name
          activity.colour = userData.objectives[challenge.objective].colour
        }
 
        postActivity(fb, userData.team, activity, date);
      }
    }
  });
};

export function isActivityItemVisible(userId, activity) {
  return !activity.visibility || activity.visibility === getDefaultVisibility();
}

