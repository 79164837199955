import React, { Component } from 'react';

import moment from 'moment';

import MiniCalendar from '../ui/MiniCalendar';

const calendarDateFormat = 'YYYY-MM-DD';
const calendarMonthFormat = 'YYYY-MM';

class GoalReportCalendarGrid extends Component {

  getMonths = () => {
    var months = [];
    var month = moment(this.props.report.habit.startDate, calendarDateFormat).format(calendarMonthFormat)
    var endMonth = this.props.report.habit.endDate ? moment(this.props.report.habit.endDate, calendarDateFormat).format(calendarMonthFormat) : moment().format(calendarMonthFormat)

    do {
      months.push(month)
      month = moment(month, calendarMonthFormat).add(1, 'months').format(calendarMonthFormat)
    } 
    while (month <= endMonth);

    return months;
  }

  calculateHabitDays = (month) => {
    var habitDays = [];
    var dateMoment = moment(month, calendarMonthFormat);

    var firstDayOfMonth = dateMoment.clone().startOf('month');
    var lastDayOfMonth = dateMoment.clone().endOf('month');

    var date = firstDayOfMonth;
    while (date <= lastDayOfMonth) {
      var dateFormatted = date.format(calendarDateFormat);
      var dayOfWeek = moment(date).format('ddd')

      if (dateFormatted >= this.props.report.habit.startDate && (!this.props.report.habit.endDate || dateFormatted <= this.props.report.habit.endDate) && (this.props.report.habit.period !== 'days' || this.props.report.habit.daysOfWeek.indexOf(dayOfWeek) >= 0))
        habitDays.push(dateFormatted)

      date.add(1, 'days');
    }

    return habitDays;
  }

  calculateCompletedDays = (month) => {
    var completedDays = [];
    var dateMoment = moment(month, calendarMonthFormat);

    var firstDayOfMonth = dateMoment.clone().startOf('month');
    var lastDayOfMonth = dateMoment.clone().endOf('month');

    var date = firstDayOfMonth;
    while (date <= lastDayOfMonth) {
      var dateFormatted = date.format(calendarDateFormat);

      if (this.props.report.activity && dateFormatted in this.props.report.activity && this.props.report.activity[dateFormatted].done)
        completedDays.push(dateFormatted)

      date.add(1, 'days');
    }

    return completedDays;

  }

  renderMonth = (month) => {
    if (!month)
      return null;

    var monthLabel = moment(month, calendarMonthFormat).format("MMM YYYY")
    
    return <div>
      <div className='goalReportCalendarGridCellLabel'>{monthLabel}</div>
      <div className='goalReportCalendarGridCellCalendar'><MiniCalendar month={month} habitDays={this.calculateHabitDays(month)} completedDays={this.calculateCompletedDays(month)} today={this.props.report.today}/></div>
    </div>
  }

  renderRow = (row, months) => {
    var leftMonthIndex = (row-1)*2;
    var leftMonth = months[leftMonthIndex]
    var rightMonth = (leftMonthIndex+1 < months.length) ? months[leftMonthIndex+1] : null;

    return <tr className='goalReportCalendarGridRow'>
      <td className='goalReportCalendarGridCell'>{ this.renderMonth(leftMonth) }</td>
      <td className='goalReportCalendarGridCell'>{ this.renderMonth(rightMonth) }</td>
    </tr>;
  }

  renderRows = () => {
    var months = this.getMonths();
    var rowCount = Math.floor((months.length - 1) / 2) + 1;

    var rows = [];

    for (var r = 1; r <= rowCount; r++)
      rows.push(this.renderRow(r, months))
 
    return rows;
  }

  render = () => {
    return <table className='goalReportCalendarGridTable'>
      { this.renderRows() }
    </table>
  }

}

export default GoalReportCalendarGrid;