import React, { Component, useLayoutEffect } from 'react';
import moment, { calendarFormat } from 'moment';

const calendarDateFormat = 'YYYY-MM-DD';
const calendarMonthFormat = 'YYYY-MM';

const calendarDaysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

/*
 * Props
 *   month = "2022-06" - the month to display
 *   habitDays = ["2022-06-01", "2022-06-02"] - the days the habit is active
 *   completionDays = ["2022-06-02"] - the days the habit was completed
 *   today = "2022-06-2"  "today" in the user's timezone
 */
class MiniCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  componentWillMount = () => {
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
  }

  calculateWeekRange = () => {
    var dateMoment = moment(this.props.month, calendarMonthFormat);

    this.firstDayOfMonth = dateMoment.clone().startOf('month');
    this.lastDayOfMonth = dateMoment.clone().endOf('month');
  }

  startOfWorkWeek = (dateMoment) => {
    if (dateMoment.isoWeekday() === 7)
      return dateMoment.clone();
    else
      return dateMoment.clone().subtract(dateMoment.isoWeekday(), 'days');
  }

  endOfWorkWeek = (dateMoment) => {
    if (dateMoment.isoWeekday() === 7)
      return dateMoment.clone().add(6, 'days');
    else
      return dateMoment.clone().add(6 - dateMoment.isoWeekday(), 'days');
  }

  renderDaysOfWeek = () => {

    return <tr className='miniCalendarRow'> {
       calendarDaysOfWeek.map((day) => {
        return <td className='miniCalendarCell'>{day.charAt(0)}</td>;
      })
    }
    </tr> 
  }

  isChallengeInEffectOnDate = (challenge, dateFormatted, dayOfWeek) => {
    return challenge.startDate <= dateFormatted && challenge.endDate >= dateFormatted && challenge.daysOfWeek.indexOf(dayOfWeek) >= 0;
  }

  renderCalendarDay = (day) => {    
    if (day.format(calendarMonthFormat) === this.props.month) {
      var labelStyle = 'miniCalendarDayLabel';

      if (this.props.completedDays && this.props.completedDays.indexOf(day.format(calendarDateFormat)) >= 0)
        labelStyle = 'miniCalendarDayLabelCompleted';
      else if (this.props.habitDays && this.props.habitDays.indexOf(day.format(calendarDateFormat)) >= 0)
        labelStyle = (day.format(calendarDateFormat) < this.props.today) ? 'miniCalendarDayLabelNotCompleted' : 'miniCalendarDayLabelHabitDay';
     
      return <td className='miniCalendarCell'>
        <div className={labelStyle}/>
        { (day.format(calendarDateFormat) === this.props.today) ?
        <div className="miniCalendarDayToday"/>
          :
          <div/>
        }
      </td>
    }
    else
      return <td className='miniCalendarCell'></td>
  }

  renderCalendarWeek = (startOfWeek) => {
    var days = []
    var day = startOfWeek.clone();
    for (var i = 1; i <= 7; i++) {
        days.push(this.renderCalendarDay(day));
        day.add(1, 'days');
    }

    return <tr className='miniCalendarRow'>{days}</tr>
  }

  renderCalendarDays = () => {
    this.calculateWeekRange();

    var weeks = []
    var currentWeek = this.startOfWorkWeek(this.firstDayOfMonth); 
    var lastSundayOfMonth = this.startOfWorkWeek(this.lastDayOfMonth);

    while (currentWeek <= lastSundayOfMonth) {
      weeks.push(this.renderCalendarWeek(currentWeek));
      currentWeek.add(1, 'weeks');
    }
    return weeks;
  }

  render = () => {
    return (
      <table className='miniCalendar'>
        { this.renderDaysOfWeek() }
        { this.renderCalendarDays() }
      </table>
    );
  }
};

export default MiniCalendar;
