import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import SpinnerPanel from '../ui/SpinnerPanel';

import GoalReportComponent from './GoalReportComponent'

import { getSharedGoalReportFunction } from '../util/FunctionUtils'; 

const INITIAL_STATE = {
  dataLoaded: false,
};

class SharedGoalReport extends Component {

    constructor(props) {
       super(props);
       this.state = { ...INITIAL_STATE };
    }

    checkDataLoaded = () => {
      if (!this.state.dataLoaded) {
          getSharedGoalReportFunction(this.props.match.params.report, (report) => {
            this.setState({
              dataLoaded: true,
              report: report,
            });
          })
      }
    }

    componentWillMount = () => {
      this.checkDataLoaded();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
      this.checkDataLoaded();
    }

    componentWillUnmount() {
    }   

    headerClicked = () => {
    }

    render = () => {
      var history = this.props.history;
      var title = (this.state.dataLoaded && this.state.report.user) ? this.state.report.user.name : " ";
      
      return (
        <AppChrome title={title} history={history} requireLogin={false} firebase={this.props.firebase} tab="Today">
            { !this.state.dataLoaded ?
              <div className='goalReportCenter'>
                <SpinnerPanel/>
              </div>
              :
              <div>
                <GoalReportComponent report={this.state.report}/>                  
              </div>
            }
        </AppChrome>
      )      
    }
}

export default SharedGoalReport;