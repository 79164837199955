import React, { Component } from 'react';

import Button from '../ui/Button'

import Popup from 'reactjs-popup';
import PopupHeader from '../ui/PopupHeader'

import 'reactjs-popup/dist/index.css';

class ActivityCommentPopup extends Component {

  constructor(props) {
      super(props);
      this.state = { 
        comment : ''
      };
  } 

  componentWillMount = () => {
  }

  handleCommentChange = (event) => {
    this.setState({ comment: event.target.value });
  }

  postComment = () => {
    this.props.onSubmit(this.state.comment)
  }

  render = () => {
    return <Popup open="true" closeOnDocumentClick className='activity-comment-popup' onClose={this.props.onClose}>
      <PopupHeader title={this.props.title}/>
      <div width="100%" height="100%">
          <textarea className="activityCommentPopupComment" placeholder="Comment here..." id="journal" name="journal" onChange={ (event) => { this.handleCommentChange(event); }} value={this.state.comment}></textarea>

          <div className='activityCommentPopupCancelButton'>
            <Button size='large' type='secondary' onClick={this.props.onClose} action='Cancel'/>
          </div>
          <div className='activityCommentPopupOKButton'>
            <Button size='large' type='primary' onClick={this.postComment} action='Post'/>
          </div>               
      </div>
    </Popup>
  }
};

export default ActivityCommentPopup;
