import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Button from '../ui/Button';

const INITIAL_STATE = {
  data : {},
  dataLoaded: false
};

class Onboarding3Step3 extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = () => {

  }

  componentWillMount = () => {
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
  }

  componentWillUnmount() {
  }

  getTitle = () => {
     return " "; 
  }

  continueClicked = () => {
    this.props.history.push("/");
  }

  renderOnboarding = () => {
    var items = [];

    items.push(<div className='objectiveCard' style={{ borderLeft: "4px solid #FF0000" }}>
    <div className='objectiveCardName'><div className="objectiveCardObjectiveName" style={{ color: "#FF0000" }}>IMPROVE MY COMMUNICATION SKILLS</div></div>
    <div className='objectiveCardHabits'>
       <table className='objectiveCardHabit'>

          <tr>
           <td className='objectiveCardHabitTickCell'><div className='objectiveCardHabitTickContainer' style={{ background: '#FF0000' }}><img className='objectiveCardHabitTickImage' src='/icons/tick-symbol.png'/></div></td>
           <td className='objectiveCardHabitName'>Practice storytelling</td>
            <td className='objectiveCardHabitCompletion'>92%</td>
          </tr>
        </table>
    </div>
  </div>);
    items.push(<div className='onboardingTitle'><br/><p>Objectives and habits</p></div>);
    items.push(<div className='onboardingContent'>Add your work objectives to Lasoo and then practice habits and routines that will make those objectives a reality.</div>);
    items.push(<div className='onboardingContent'></div>);

    items.push(<Button onClick={this.continueClicked} type='primary' size='large' action="OK, let's go!"/>);

    return items;
  }

  render = () => {
    var history = this.props.history;
    
    return (
      <AppChrome title="" history={history} requireLogin={true} firebase={this.props.firebase} back="/onboarding-1-step-1" tab="Today">
      {
          this.renderOnboarding()
      }
      </AppChrome>
    )
  }
}

export default Onboarding3Step3;