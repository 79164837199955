import moment, { min } from 'moment';

import async from 'async';

import { activityAwardWon } from '../activity/ActivityUtils'
import { calculateTotalDays, calculateDaysDone } from './StatsUtils';
import { getDefaultVisibility } from './VisibilityUtils';

const calendarDateFormat = 'YYYY-MM-DD';

export function challengeIsCompleteWithAward(fb, challengeId, challenge, days, percentage, callback) {
  var awardId = 'award-' + challengeId;
  var visibility = (challenge.visibility) ? challenge.visibility : getDefaultVisibility();

  var award = {
    category : challenge.category,
    challenge: challengeId,
    colour: challenge.colour,
    date: challenge.endDate,
    days: days,
    habit: challenge.habit,
    name: challenge.name,
    percentage: percentage,
    visibility: visibility
  }

  const updates = {};
  updates['users/' + fb.user.uid + '/challenges/' + challengeId + "/finished"] = true;
  updates['users/' + fb.user.uid + '/challenges/' + challengeId + "/finishedDisplayed"] = false;
  updates['users/' + fb.user.uid + '/challenges/' + challengeId + "/award"] = awardId;
  updates['users/' + fb.user.uid + '/awards/' + awardId] = award;

  fb.database.ref()
  .update(updates)
  .then(() => {

      if (challenge.category !== 'Personal' && challenge.visibility === getDefaultVisibility()) {
          activityAwardWon(fb, fb.user.uid, challenge.endDate, challengeId, percentage, days);
      }
      callback();
  })
  .catch((error) => {
     alert("Error writing document: ", error);
     callback()
  });
  
}

export function challengeIsCompleteWithNoAward(fb, challengeId, challenge, callback) {
  const updates = { finished: true, finishedDisplayed: false }
  fb.database.ref('users/' + fb.user.uid + '/challenges/' + challengeId).update(updates).then(callback);
}

export function challengeResultIsDisplayed(fb, challengeId, callback) {
  const updates = { finishedDisplayed: true }
  fb.database.ref('users/' + fb.user.uid + '/challenges/' + challengeId).update(updates).then(callback);
}

export function checkForCompletedChallenges(fb, userData, userId, callback) {
  // A challenge is complete if either:
  //  1/ We've passed the end date
  //  2/ We are on the end date and the habit is complete

  // For challenges that are complete we:
  //  1/ Mark the challenge as finished
  //  2/ Create an Award (if threshold is reached)

  var today = moment().format(calendarDateFormat);

  if (!userData.challenges) {
    callback();
    return;
  }

  var completed = []

  for (var challengeId in userData.challenges) {
    var challenge = userData.challenges[challengeId];

    if (!challenge.finished) {      
      if (challenge.endDate === today && userData.days && userData.days[today] && userData.days[today][challengeId] && userData.days[today][challengeId].done)
        completed.push({ challengeId: challengeId, challenge: challenge })
      else if (challenge.endDate < today)
        completed.push({ challengeId: challengeId, challenge: challenge })
    }
  }

  async.eachSeries(completed, (challengeRecord, iteratorCallback) => {
    // Determine the number of done days and number of total days
    // If above threshold create an Award record

    const totalDays = calculateTotalDays(challengeRecord.challengeId, userData)
    const daysDone = calculateDaysDone(challengeRecord.challengeId, userData)
    
    const percentage = Math.floor(100 * daysDone / totalDays)

    if (percentage >= 75) 
      challengeIsCompleteWithAward(fb, challengeRecord.challengeId, challengeRecord.challenge, totalDays, percentage, iteratorCallback)
    else 
      challengeIsCompleteWithNoAward(fb, challengeRecord.challengeId, challengeRecord.challenge, iteratorCallback)
  },
  callback);
}
