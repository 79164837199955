import React, { Component } from 'react';

import moment from 'moment';
import Button from '../ui/Button'
import SpinnerPanel from '../ui/SpinnerPanel'

import Popup from 'reactjs-popup';

import Typewriter from 'typewriter-effect';

import 'reactjs-popup/dist/index.css';

import { askLumosForObjectivesFunction } from '../util/FunctionUtils';


const INITIAL_STATE = {
    showQuery: true,
    loadingResponse: false,
    query: '',
    response : ''
  };

class AskLumosPopup extends Component {

  constructor(props) {
      super(props);
      this.state = INITIAL_STATE;
  } 

  onClose = () => {
    if (this.props.onClose)
        this.props.onClose()
  }

  renderAskLumosHeader = () => {
    return <div>
        <table className='askLumosPopupHeader' width="100%">
        <tr>
            <td><img width='50' height='50' src="/illustration/lumos.png"></img></td>
            <td className='askLumosPopupTitle'>Ask Lumos</td>
        </tr>
    </table>
    <hr/>
    </div>
  }

  controlPromptTypeWriter = (typewriter) => {
    typewriter
    .changeDelay(1)
    .typeString("To suggest some goals I need you to tell me about your role and the challenges you are facing...")
    .start();
  }

  controlAnswerTypeWriter = (typewriter) => {
    typewriter
    .changeDelay(1)
    .typeString("As a SaaS company product manager, your primary goal is to drive the success of your product. Here are five performance objectives that can help you achieve that:\nIncrease user acquisition: One of your main objectives should be to expand the user base of your SaaS product. Focus on implementing strategies to attract new customers and increase user acquisition. This could involve developing targeted marketing campaigns, optimizing the product's onboarding process, and collaborating with the sales and marketing teams to generate leads.<br/>Improve user retention and engagement: Retaining existing customers is crucial for the long-term success of your SaaS product. Set objectives to increase user engagement and improve customer retention rates. This can be achieved through regular product updates, feature enhancements, gathering user feedback, and implementing customer success initiatives.").start();
  }

  onContinue = () => {
    this.setState({ showQuery: true, loadingResponse: true });

    askLumosForObjectivesFunction(this.props.firebase.user.uid, this.state.query, (response) => {
      this.setState({ showQuery: false, response: response.reply, loadingResponse: false }); 
    })
  
  }

  onChangeQuery = (event) => {  
    this.setState({ query: event.target.value });
  }

  renderQueryScreen = () => {
    return <div>

      <div>
          <div className='askLumosPopupHeader'>
            <table width="100%">
                <tr>
                    <td><img width='50' height='50' src="/illustration/lumos.png"></img></td>
                    <td>
                      <div className='askLumusPopupPrompt'>
                        <Typewriter id='query' onInit={this.controlPromptTypeWriter}/>
                      </div>
                    </td>
                </tr>
            </table>
            <hr/>

          </div>


          { this.state.loadingResponse ?
            <div className='askLumosPopupSpinner'>
              <SpinnerPanel/>
            </div>
          :
          <div>
            <div>
              <textarea className='askLumosPopupQuery' onChange={this.onChangeQuery} value={this.state.query}/>
            </div>
          
            <div className='askLumosPopupContinueButton'>
              <Button className='askLumosPopupContinueButton' disabled={this.state.query.length < 5} type='primary' size='large' action='Continue' onClick={this.onContinue}/>
            </div>
          </div>
        }
      </div>
    </div>
  }

  renderAnswerScreen = () => {
    return <div>
        
            <div className='askLumosPopupHeader'>
              <table width="100%">
                  <tr>
                      <td className='askLumosPopupResponseTable'><img width='50' height='50' src="/illustration/lumos.png"></img></td>
                      <td>
                        <div className='askLumusPopupResponse'>
                            {this.state.response}
                        </div>
                      </td>
                  </tr>
              </table>
              <div className='askLumosPopupCloseButton'>
                <Button  type='primary' size='large' action='Close' onClick={this.onClose}/>
              </div>
            </div>
      
    </div>
  }

  render = () => {
    var className = (this.state.showQuery ? 'ask-lumos-popup-query' : 'ask-lumos-popup')
      return (
          <Popup open="true" closeOnDocumentClick className={className} onClose={this.onClose}>
            { 
              this.state.showQuery ? 
                this.renderQueryScreen()
                :
                this.renderAnswerScreen()
            }
          </Popup>
      );
    }
};

export default AskLumosPopup;
