const config = {
    apiKey: "AIzaSyD42Qqzq5nLyFXwSUPwrNR71NTvPhf979M",
    authDomain: "lasoo-d9fad.firebaseapp.com",
    databaseURL: "https://lasoo-d9fad.firebaseio.com",
    projectId: "lasoo-d9fad",
    storageBucket: "gs://lasoo-d9fad.appspot.com",
    messagingSenderId: "264198567009",
    appId: "1:264198567009:web:e4f342abb6e3fa40"
  };

export default config;

