import React, { Component } from 'react';

import SquircleIcon from '../ui/SquircleIcon';

class HabitCategoryCard extends Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    var icon = (this.props.icon) ? this.props.icon : "record_voice_over";

    return (
      <div onClick={ () => { this.props.onClick(this.props.id); }} className='habitCategoryCard'>
        <div className='habitCategoryCardSquircle'>
          <SquircleIcon icon={icon} colour={this.props.colour} size='medium'/>
        </div>

        <div className='habitCategoryCardName'>{this.props.name}</div>
        <div className='habitCategoryCardDescription'>{this.props.description}</div>
        <div className='habitCategoryCaret'>›</div>
      </div>
    )
  }
}

export default HabitCategoryCard;