import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Button from '../ui/Button';

import HabitCard from './HabitCard'
import PinnedCard from '../ui/PinnedCard';

import { getUserAndTeam } from '../util/DatabaseUtils'
import ReloadDataCard from '../ui/ReloadDataCard';

const INITIAL_STATE = {
  habits: {},
  categoryName : " ",
  dataLoaded: false,
  isOffline: false
};

class Category extends Component {

    constructor(props) {
       super(props);
       this.state = { ...INITIAL_STATE };
    }

    checkDataLoaded = () => {

      if (this.props.firebase.user && !this.state.dataLoaded) {

        getUserAndTeam(this.props.firebase, (user, team) => {
          var habits = [];

          if (team.habits) {
            Object.keys(team.habits).forEach((habitId) => {
              var habit = team.habits[habitId]; 
               if (habit.category === this.props.match.params.category)
                  habits.push({"id": habitId, "habit" : habit });
            });
          }

          habits.sort((h1, h2) => {
            if (h1.habit.order && h2.habit.order) {
               if (h1.habit.order < h2.habit.order)
                 return -1;
               else if (h1.habit.order > h2.habit.order)
                 return 1;
            }
            else if (h1.habit.order && !h2.habit.order)
               return -1;
            else if (!h1.habit.order && h2.habit.order)
               return 1;
     
           if (h1.habit.name < h2.habit.name)
             return -1;
           else if (h1.habit.name > h2.habit.name)
             return 1
           else 
             return 0
          })

          var categoryName = '';
          
          if (team['habit-categories'] && team['habit-categories'][this.props.match.params.category]) 
             categoryName = team['habit-categories'][this.props.match.params.category].name;

          this.setState({ dataLoaded: true, team: team, habits : habits, categoryName : categoryName });
        },
        () => {
          this.setState({ isOffline: true })
        })
      }
    }

    componentWillMount = () => {
      this.checkDataLoaded();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
      this.checkDataLoaded();
    }

    componentWillUnmount() {}

    signout = () => this.props.firebase.doSignOut().then(() => this.forceUpdate())
   
    login = () => {
      this.props.history.push("/login");
    }

    signUp = () => {
      this.props.history.push("/signup");
    }

    habitSelected = (habitId) => { 
      this.props.history.push("/habit/" + habitId)
    }

    editHabits = () => {
      this.props.history.push("/edit-habits/" + this.props.match.params.category);
    }

    suggestHabit = () => {
      this.props.history.push("/suggest-habit/" + this.props.match.params.category);
    }

    onCreate = () => {
      this.props.history.push("/create-habit-post/" + this.props.match.params.category);
    }

    refresh = () => {
      this.setState({ isOffline: false })
      this.checkDataLoaded();
    }

    renderHabits = () => {
      var user = this.props.firebase.user

      var items = [];

      items.push(<PinnedCard description='Browse habits or create a new habit in this category' action="Create" onButtonClick={this.onCreate}/>)

      if (this.state.isOffline)
        items.push(<ReloadDataCard firebase={this.props.firebase} message='Could not load habits in category.' onClick={this.refresh}/>)
      else if (!this.state.dataLoaded)
        items.push(this.renderHabitsLoading());
      else if (this.state.habits.length === 0)
        items.push(<div>No habits</div>);
      else {
        for (var habitRecord of this.state.habits) {
          var id = habitRecord.id;
          var habit = habitRecord.habit;
          if (habit.visible)
            items.push(<HabitCard id={id} visible='true' displayChevron='true' team={this.state.team} onClick={this.habitSelected} name={habit.name} creator={habit['creator-name']} colour={habit.colour}/>)
        }
      }

      if (this.state.dataLoaded) {
        var isContentManager = false;
        if (user && this.state.team && this.state.team['content-admins'])
          isContentManager = Object.values(this.state.team['content-admins']).indexOf(user.uid) >= 0;
  
        if (isContentManager)
          items.push(<p><Button type='secondary' size='large' onClick={() => { this.editHabits() }} action='Edit habits'/></p>);
      }

      return items;
    }

    renderHabitsLoading = () => {
       const items = [];

       for (var i = 1; i <= 3; i++) 
          items.push(<div className='habitCardPlaceholder'/>);

       return <div>{items}</div>;
    }

    render = () => {
      var user = this.props.firebase.user
      var userLoaded = this.props.firebase.initialised;
      var history = this.props.history;
      var title = this.state.categoryName;

      return (
        <AppChrome title={title} history={history} team={this.state.team} requireLogin={true} firebase={this.props.firebase} back={true} tab="Discover">
            <div>
              { this.renderHabits() } 
            </div>
        </AppChrome>
      )
      
    }
}

export default Category;