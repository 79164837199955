import React, { Component } from 'react';
import 'material-icons/iconfont/material-icons.css';

import AvatarPile from '../ui/AvatarPile';

class HabitCard extends Component {
  constructor(props) {
    super(props);
  }

  findMember = (userId, team) => {
    for (var memberEmailKey in team.member) {
      var member = team.member[memberEmailKey];
      if (member['user-id'] === userId)
        return member;
    }
  }

  getTeamMembers = () => {
    var names = []
    var images = []
    var team = this.props.team;

    if (team && team.stats && team.stats.habits && team.stats.habits[this.props.id] && team.stats.habits[this.props.id].users) {
      var users = team.stats.habits[this.props.id].users;

      for (var userId of Object.keys(users).sort()) {
        const member = this.findMember(userId, team);
        if (member) {
          names.push(member.name)
          images.push(member['photo-url'])
        }
      }
    }

    return {
      names : names,
      images : images
    }
  }

  render = () => {
    var styles = {
      backgroundColor: "#" + this.props.colour
    };

    if (!this.props.visible)
      styles.opacity = 0.5;

    var creator = (this.props.creator) ? "Created by " + this.props.creator : "Created by Lasoo Team";

    var teamMembers = this.getTeamMembers();

    return (
      <div className='habitCard' onClick={() => { this.props.onClick(this.props.id) }}>
        <div className='habitCardName'>{this.props.name}</div>
        <div className='habitCardCreator'>{creator}</div>
        <div className='habitCardAvatarPile'><AvatarPile names={teamMembers.names} images={teamMembers.images}/></div>

        { this.props.displayPlus ? 
           <div className='habitCardPlus material-icons-outlined'>Add</div>
          : 
          this.props.displayChevron ?
          <div className='habitCardChevron'>›</div>
          :
          <div/>
        }
      </div>
    )
  }
}

export default HabitCard;