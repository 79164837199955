import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Button from '../ui/Button';

import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

import { getUserAndTeam, setUserField } from '../util/DatabaseUtils';

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';

import { Radar } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const INITIAL_STATE = {
  currentCategory: 0
};

class Onboarding2Step3 extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {
      getUserAndTeam(this.props.firebase, (user, team) => {
        var wheel = user['wheel-of-work']
        this.setState({ 
          dataLoaded: true, 
          categories: this.createCategories(wheel, team),
          user: user,
          team: team,
          wheel: wheel
         });
      })
    }
  }

  createCategories = (wheel, team) => {
    var categories = [];

    for (var categoryId of Object.keys(wheel).sort()) {
      categories.push({
        id : categoryId,
        name: team['habit-categories'][categoryId].name
      })
    }
    categories.sort((c1, c2) => {
      if (c1.name < c2.name)
        return -1
      else if (c1.name === c2.name)
        return 0
      else 
        return 1;
    })

    return categories
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  componentWillUnmount() {
  }

  renderWheel = () => {
    if (!this.state.dataLoaded)
      return <div/>;

    var options = {
      animation: {
        duration: 0
      },
      elements: {
          line: {
              borderWidth: 1
          }
      },

      scales: {
          r: {
                beginAtZero: true,
                max: 10,
                min: 0,
                stepSize: 1,
                ticks: {
                  callback: function() {return ""}
              }
          }
      },
      plugins: {
          legend: {
              display: false
          }
      }
    }

    const data = {
      labels: this.state.categories.map(c => c.name),
      datasets: [
        {
          label: "Score",
          data: this.getData(),
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
      ],
    };

    var category = this.state.categories[this.state.currentCategory]
    var items = [];

    items.push(<div className='wheelOfWorkContainer'><Radar options={options} data={data} /></div>);
    items.push(<div className='wheelOfWorkCategory'>{category.name}</div>);
    items.push(<div className='onboardingQuestion'>How would you rate this area of your work?</div>);

    const marks = {
      0: '0',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10' 
    };

    var value = this.state.wheel[category.id];
    items.push(
      <div className='wheelOfWorkSliderContainer'>
        <div className='wheelOfWorkSlider'><Slider value={value} min={0} max={10} marks={marks} step={1} onChange={this.onChangeSlider} defaultValue={5} /></div>
      </div>    
    )
    items.push(<div className='wheelOfWorkSliderLabels'>
      <div className='wheelOfWorkSliderLabelsLeft'>Very dissatisfied</div>
      <div className='wheelOfWorkSliderLabelsRight'>Very satisfied</div>

    </div>);

    items.push(<p><Button type='primary' size='large' onClick={this.continueClicked} action='Continue'/></p>);

    return items;
  }

  getData = () => {
    var data = [];

    for (var categoryId of Object.keys(this.state.wheel).sort()) {
      data.push(this.state.wheel[categoryId])
    }
    return data;
  }

  continueClicked = () => {
    var currentCategory = this.state.currentCategory + 1;

    if (currentCategory < this.state.categories.length) {
      this.setState({
        currentCategory: currentCategory
      })
    }
    else {
      setUserField(this.props.firebase, 'wheel-of-work', this.state.wheel, () => {
        this.props.history.push("/onboarding-2-step-4");
      });
    }
  }

  onChangeSlider = (e) => {
    var wheel = this.state.wheel;

    wheel[this.state.categories[this.state.currentCategory].id] = e;
    this.setState({
      wheel: wheel
    })
  }

  render = () => {
    var history = this.props.history;
    
    return (
      <AppChrome title="" history={history} requireLogin={true} firebase={this.props.firebase} back={true} tab="Today">
      { 
         this.renderWheel()
      }
      </AppChrome>
    )
  }
}

export default Onboarding2Step3;