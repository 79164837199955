

export function showObjectives(fb) {
    return true
}

var superAdminTeams = {
    'antony@lasoohabits.com': 'x6PyI4ewOL7jJMMSi7rt',
    'g.kid@icloud.com' : 'x6PyI4ewOL7jJMMSi7rt',
    'keith.abbott@adlerianconsulting.com.au' : 'x6PyI4ewOL7jJMMSi7rt',
    'david.royle@adlerianconsulting.com.au' : 'x6PyI4ewOL7jJMMSi7rt',
    'sally@lasoohabits.com' : 'mzRkw0HNBYEir4dsUCCa'
}

export function isSuperAdmin(user) {
    return (user && Object.keys(superAdminTeams).indexOf(user.email) >= 0)
}

export function isSuperAdminHomeTeam(user, teamId) {
    var superAdminTeamId = superAdminTeams[user.email];
    return (superAdminTeamId && superAdminTeamId === teamId);
}

