import React, { Component } from 'react';

import Award from '../ui/Award'
import Button from '../ui/Button'
import Popup from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';

class AwardWonPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {};
  } 

  componentWillMount = () => {

  }

  render = () => {
    var comment = (this.props.category === 'Personal') ? "Awards for personal goals remain private to you" : "Your award has been shared on the activity feed and added to your profile"

    return (
        <Popup open="true" closeOnDocumentClick className='award-won-popup' onClose={this.props.onClose}>
          <div className='awardWonPopup'>
            <div className='awardWonPopupAward'>
              <Award orientation='portrait' percentage={this.props.percentage} days={this.props.days} colour={this.props.colour} category={this.props.category} name={this.props.name}/>
            </div>
            <div className='awardWonPopupMessage'><b>Congratulations!</b> You finished your goal period and won an award</div>
            <div className='awardWonPopupComment'>{comment}</div>

            <div className='awardWonPopupOKButton'>
              <Button type='primary' size='large' action="I'm amazing" onClick={this.props.onClose}/>   
            </div>  
          </div>           
        </Popup>
    );
  }
};

export default AwardWonPopup;
