import React, { Component } from 'react';

import Button from '../ui/Button';

import Popup from 'reactjs-popup';
import PopupHeader from '../ui/PopupHeader'
import 'reactjs-popup/dist/index.css';

class GoalReportShareLinkPopup extends Component {

  constructor(props) {
      super(props);
      this.state = { };
  } 

  componentWillMount = () => {

  }

  onOK = () => {
    this.props.onClose();
  }

  onCopyURL = () => {
    navigator.clipboard.writeText(this.props.link)
  }

  render = () => {
    return (
        <Popup open="true" closeOnDocumentClick className='report-share-link-popup' onClose={this.props.onClose}>
          <PopupHeader title="Publically Sharable Link"/>
          <div className='confirmPopupMessage'>
            <span className='reportShareLinkPopupLink'>{this.props.link}</span>
          </div>
          <div className='reportShareLinkPopupCopyURLButton'>
            <Button type='secondary' size='large' onClick={this.onCopyURL} action='Copy URL'/>
          </div>

          <div className='reportShareLinkPopupOKButton'>
           <Button type='primary' size='large' onClick={this.onOK} action='OK'/>
          </div>               
        </Popup>
    );
  }
};

export default GoalReportShareLinkPopup;
