import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Button from '../ui/Button';

import ActivityCommentPopup from './ActivityCommentPopup'
import TeamActivityComment from './TeamActivityComment'
import TeamActivityItem from './TeamActivityItem'

import { postActivityComment } from './ActivityUtils'
import { getUserAndTeam, fetchTeamFromServer } from '../util/DatabaseUtils'
import { awardPoints } from '../util/PointsUtils'

const INITIAL_STATE = {
  dataLoaded: false,
  item: null,
  teamId: null,
  team: null,
  displayCommentPopup: false,
  displayReplyToCommentPopup: false,
  replyToCommentIndex : null,
  replyToCommentRecord : null
};

class ActivityItem extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = (force) => {
    if ((this.props.firebase.user && !this.state.dataLoaded) || force) {

      getUserAndTeam(this.props.firebase, (user, team) => {
        if (!user || !team) {
          this.props.history.push("/create-team")
          return
        }

        var date = this.props.match.params.date
        var index = this.props.match.params.index
        var item = null;
  
        if (team.activity && team.activity[date] && team.activity[date][index]) {
          item = team.activity[date][index]
        }

        this.setState({ dataLoaded: true, item: item, data: user, team : team, teamId : user.team });
      });
    }     
  }

  showCommentPopup = () => {
    this.setState({ displayCommentPopup : true })
  }
  
  hideCommentPopup = () => {
    this.setState({ displayCommentPopup : false })
  }

  showReplyToCommentPopup = (commentIndex, commentRecord) => {
    this.setState({ 
      displayReplyToCommentPopup : true,
      replyToCommentIndex : commentIndex,
      replyToCommentRecord : commentRecord
    })
  }

  hideReplyToCommentPopup = () => {
    this.setState({ 
      displayReplyToCommentPopup : false,
      replyToCommentIndex : null,
      replyToCommentRecord : null
    })
  }

  componentWillMount = () => {
    this.checkDataLoaded(false);
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded(false);
  }

  componentWillUnmount = () => {
  }

  awardPointsToUser = () => {
    if (this.state.team.member[this.props.firebase.user.email.replaceAll(".","_")]) {
      awardPoints(this.props.firebase, this.state.teamId, this.state.team, this.props.firebase.user.uid, this.props.firebase.user.email, "comment", (results) => {

        // This is necessary to make sure we get the user latest level and points

        fetchTeamFromServer(this.props.firebase, this.props.teamId, (team) => {})
      });
    }
  }

  postComment = (comment) => {
     this.hideCommentPopup();

    // postActivityComment(fb, team, date, index, userToId, replyTo, comment, callback) {

    this.awardPointsToUser()
    
     postActivityComment(this.props.firebase, this.state.teamId, this.props.match.params.date, this.props.match.params.index, this.state.item.userId, false, comment, () => {
        this.checkDataLoaded(true)
     })
  }

  postReply = (reply) => {
    this.hideReplyToCommentPopup();

    this.awardPointsToUser()

    postActivityComment(this.props.firebase, this.state.teamId, this.props.match.params.date, this.props.match.params.index, this.state.replyToCommentRecord.userId, true, reply, () => {
        fetchTeamFromServer(this.props.firebase, this.state.teamId, () => {
          this.checkDataLoaded(true)
        });
    })
  }

  avatarClicked = (userId) => {
    this.props.history.push("/team-member-activity/" + userId);
  }

  goalClicked = (item) => {
    if (item.userId && item.goalId)
      this.props.history.push("/goal-report-activity/" + item.userId + "/" + item.goalId);
  }

  findTeamMember = (userId) => {
    for (var memberEmailKey in this.state.team.member) {

      if (this.state.team.member[memberEmailKey]['user-id'] === userId) {
        return this.state.team.member[memberEmailKey];
      }
    }
    return null;
  }

  displayActivityItem = () => {
    if (!this.state.dataLoaded)
      return <div className='teamActivityCardLoadingPlaceholder'/>
    else {
      if (this.state.item) {
        var item = this.state.item
        var replyToMember = (this.state.replyToCommentRecord) ? this.findTeamMember(this.state.replyToCommentRecord.userId) : null

        return <div>
          <TeamActivityItem firebase={this.props.firebase} teamId={this.state.teamId} onClickAvatar={this.avatarClicked} onClickGoal={this.goalClicked} team={this.state.team} date={this.props.match.params.date} index={this.props.match.params.index} item={item}/>
          { this.state.displayCommentPopup ?
            <ActivityCommentPopup title='Add Comment' onClose={this.hideCommentPopup} onSubmit={this.postComment}/>
            : <div/>
          }
          { this.state.displayReplyToCommentPopup ?
            <ActivityCommentPopup title={'Reply to ' + replyToMember.name} onClose={this.hideReplyToCommentPopup} onSubmit={this.postReply}/>
            : <div/>
          }
 
        </div>
      }
      else 
        return <div>No such activity item</div>
    }
  }

  displayActivityItemComments = () => {
    var date = this.props.match.params.date;
    var index =  this.props.match.params.index;

    if (this.state.dataLoaded && this.state.team.activityComments && this.state.team.activityComments[date] && this.state.team.activityComments[date][index]) {
      var comments = this.state.team.activityComments[date][index];
      var commentItems = [];

      for (var commentIndex in comments) {
        var commentRecord = comments[commentIndex];
        commentItems.push(<TeamActivityComment team={this.state.team} replyToComment={this.showReplyToCommentPopup.bind(this, commentIndex, commentRecord)} replyTo={commentRecord.replyTo} userId={commentRecord.userId} date={commentRecord.date} comment={commentRecord.comment}/>)
      }
      return commentItems;
    }
    else {
      return <div/>
    }
  }

  displayActivityItemScreen = () => {
    var content = []

    content.push(this.displayActivityItem())
    content.push(this.displayActivityItemComments())
    content.push(<p><Button type='primary' size='large' onClick={this.showCommentPopup} action='Add a comment'/></p>)

    return content;
  }
  
  render = () => {
    var history = this.props.history;

    return (
      <AppChrome title="Activity Item" history={history} team={this.state.team} requireLogin={true} firebase={this.props.firebase} back={true} tab="Activity">
        { this.displayActivityItemScreen() }
      </AppChrome>
    )
  }
}

export default ActivityItem;