import React, { Component } from 'react';

const INITIAL_STATE = {

};

class SpinnerPanel extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  } 

  render = () => {
    var size = this.props.size ? this.props.size : 70;
    return ( 
      <div className='spinnerPanel'>
        <img width={size} height={size} src='/spinner-transparent.gif'/>
      </div>
    )
  }
}

export default SpinnerPanel;