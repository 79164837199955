import React, { Component } from 'react';

import { Bar  } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

class GoalWeeksChart extends Component {

  getOptions = () => {
    return {
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'right',
        },
        title: {
          display: false,
          text: 'HABIT CHECKINS BY WEEK',
        },
      },
      scales: {
        y: {
            ticks: {
                precision: 0
            }
        }
    }

    }
  }

  getData = () => {

    const labels = this.props.report.stats.startOfWeeks;

    return {
      labels,
      datasets: [
        {
          data: this.props.report.stats.completionsByWeek,

          label: 'Goal count',
          backgroundColor: 'rgba(123, 57, 237, 1)', // '#7b39ED', // 'rgba(234, 41, 82, 0.5)',
          borderColor: '#7b39ED', //'#ea2952',

          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
        }
      ],
    };
  }

  render = () => {
    return <Bar options={this.getOptions()} data={this.getData()} />
  }

}

export default GoalWeeksChart;