import React, { Component } from 'react';

import Button from '../ui/Button'
import Popup from 'reactjs-popup';
import PopupHeader from '../ui/PopupHeader'

import { sendFeedbackFunction } from '../util/FunctionUtils';

import 'reactjs-popup/dist/index.css';
import moment from 'moment';
import SpinnerPanel from '../ui/SpinnerPanel';

class FeedbackPopup extends Component {

  constructor(props) {
      super(props);
      this.state = { isSendingFeedback: false, showConfirmation: false, feedback: "" };
  } 

  componentWillMount = () => {

  }

  sendFeedback = () => {
    this.setState({ isSendingFeedback: true })

    sendFeedbackFunction(this.props.firebase.user.uid, this.state.feedback, () => {
      this.setState({ isSendingFeedback: false, showConfirmation: true })
    });
  }

  cancelFeedback = () => {
    this.props.onClose();
  }

  closeFeedback = () => {
    this.props.onClose();
  }

  handleFeedbackChange = (event) => {
    this.setState({ feedback: event.target.value });
  }

  renderFeedbackForm = () => {
    return <Popup open="true" closeOnDocumentClick className='feedback-popup' onClose={this.props.onClose}>
      <PopupHeader title='Send Feedback'/>

      <div width="100%" height="100%">
        { this.state.isSendingFeedback ?
          <div className='feedbackPopupSendingFeedback'>
            <SpinnerPanel/>  
          </div>
          :
          this.state.showConfirmation ?
          <>
            <div className='feedbackPopupConfirmation'>Thank your for your feedback, we'll be in contact by email soon.</div>

            <div className='feedbackPopupOKButton'>
              <Button size='large' type='primary' onClick={this.closeFeedback} action='OK'/>
            </div> 
          </> 
          :
          <>
            <textarea className="feedbackPopupInput" placeholder="We love hearing your questions, suggestions and feedback." onChange={(event) => { this.handleFeedbackChange(event); }} value={this.state.feedback}></textarea>

            <div className='feedbackPopupCancelButton'>
              <Button size='large' type='primary' onClick={this.cancelFeedback} action='Cancel'/>
            </div>

            <div className='feedbackPopupSendButton'>
              <Button size='large' disabled={this.state.feedback === ''} type={'primary'} onClick={this.sendFeedback} action='Send'/>
            </div> 
          </> 
        }
      </div>
    </Popup>
  }

  render = () => {
      return this.renderFeedbackForm();
  }
};

export default FeedbackPopup;
