import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Button from '../ui/Button'

const INITIAL_STATE = {
  email: '',
  error: null,
  passwordSent: false
};

class ResetMyPassword extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }


  onSubmit = event => {
    const { email, password } = this.state;

    event.preventDefault();

    this.props.firebase
      .auth.sendPasswordResetEmail(email)
      .then(() => {
        this.setState({ passwordSent: true })
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  login = () => {
    this.props.history.push("/login");
  }

  render = () => {
    var history = this.props.history;

    const {
      email,
      error,
    } = this.state;

    const isInvalid =
      email === '';

    return (
      <AppChrome title="Reset My Password" history={history} requireLogin={false} firebase={this.props.firebase} back={true} tab="Today">
      { 
        this.state.passwordSent 
        ?
        <div>
          <div className='onboardingInstructions'>A link to reset your password has been sent your email address</div>
          <Button type='primary' size='large' action='Login' onClick={this.login} />
        </div>
        :
        <form onSubmit={this.onSubmit}>
          <div className='onboardingInstructions'>Having trouble logging in?</div>

          <p>Enter your email and we'll send you instructions on how to reset your password.</p>

          <input
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Email"
          />

          <button type="submit" className='btn-large btn-primary' disabled={isInvalid} >
            Reset password
          </button>

          {error && <p>{error.message}</p>}
        </form>
      }
      </AppChrome>
    );
  }
}

export default ResetMyPassword;

