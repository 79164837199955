import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import AccountSettings from "./settings/AccountSettings";
import Activity from "./activity/Activity";
import ActivityItem from "./activity/ActivityItem";
import AddHabit1 from "./today/AddHabit1";
import AddHabit2 from "./today/AddHabit2";
import AddNewHabit from "./today/AddNewHabit";
import AddSelectedHabit from "./today/AddSelectedHabit";
import AddSharedHabit from "./today/AddSharedHabit";
import AddViewedHabit from "./today/AddViewedHabit";
import AnalyticsComponent from "./layout/AnalyticsComponent";
import Category from "./discover/Category";
import ChallengeTeammates from "./today/ChallengeTeammates";
import CreateHabit from "./discover/CreateHabit";
import CreateHabitPost from "./discover/CreateHabitPost";
import CreateTeam from "./auth/CreateTeam"
import Discover from "./discover/Discover";
import DiscoverAskLumos from "./discover/DiscoverAskLumos";
import EditCategories from "./discover/EditCategories";
import EditCategory from "./discover/EditCategory";
import EditHabits from "./discover/EditHabits";
import EditHabit from "./discover/EditHabit";
import EditHabitPost from "./discover/EditHabitPost";
import GoalReport from "./goals/GoalReport";
import GoalNotificationsStop from "./settings/GoalNotificationsStop";
import Habit from "./discover/Habit";
import Home from "./today/Home";
import Invitation from "./auth/Invitation";
import LevelsAndPoints from "./profile/LevelsAndPoints";
import Login from "./auth/Login";
import LoginWithToken from "./auth/LoginWithToken";
import MonthlySummaryStop from "./settings/MonthlySummaryStop";
import MyProfile from "./profile/MyProfile";
import NewHabitsStop from "./settings/NewHabitsStop";
import NudgesStop from "./settings/NudgesStop";
import WeeklyHabitStop from "./settings/WeeklyHabitStop";
import Objectives from "./objectives/Objectives";
import ObjectiveAskLumos from "./objectives/ObjectiveAskLumos";
import Objective from "./objectives/Objective";
import Onboarding1Step1 from "./onboarding-1/Onboarding1Step1";
import Onboarding1Step2 from "./onboarding-1/Onboarding1Step2";
import Onboarding1Step3 from "./onboarding-1/Onboarding1Step3";
import Onboarding2Step1 from "./onboarding-2/Onboarding2Step1"
import Onboarding2Step2 from "./onboarding-2/Onboarding2Step2"
import Onboarding2Step3 from "./onboarding-2/Onboarding2Step3"
import Onboarding2Step4 from "./onboarding-2/Onboarding2Step4"
import Onboarding3Step1 from "./onboarding-3/Onboarding3Step1";
import Onboarding3Step2 from "./onboarding-3/Onboarding3Step2";
import Onboarding3Step3 from "./onboarding-3/Onboarding3Step3";
import ResetMyPassword from "./auth/ResetMyPassword";
import SharedGoalReport from "./goals/SharedGoalReport";
import Signup from "./auth/Signup";
import Switcher from "./layout/Switcher";
import TeamMember from './team/TeamMember';
import TeamMembers from './team/TeamMembers';
import UpdateGoal from "./goals/UpdateGoal";
import VerifyEmail from "./auth/VerifyEmail";

import Firebase from './Firebase';
import 'firebase/firestore';

class AppRouter extends Component {
   constructor(props) {
      super(props);

      this.firebase = new Firebase((user) => {
         this.forceUpdate()
      });
   }

   render() {
      return (
         <Router>
           <AnalyticsComponent firebase={this.firebase}/>
               <Switch>
                  <Route path="/" exact render={(props) =>
                     <Home {...props} firebase={this.firebase} />}
                  />
                  <Route path="/add-habit-1/:parent" render={(props) =>
                     <AddHabit1 {...props} firebase={this.firebase} />}
                  />
                  <Route path="/add-habit-1" render={(props) =>
                     <AddHabit1 {...props} firebase={this.firebase} />}
                  />
                  <Route path="/add-habit-2/:category" render={(props) =>
                     <AddHabit2 {...props} firebase={this.firebase} />}
                  />
                  <Route path="/add-new-habit" render={(props) =>
                     <AddNewHabit {...props} firebase={this.firebase} />}
                  />
                  <Route path="/add-selected-habit/:habit" render={(props) =>
                     <AddSelectedHabit {...props} firebase={this.firebase} />}
                  />
                  <Route path="/add-viewed-habit/:habit" render={(props) =>
                     <AddViewedHabit {...props} firebase={this.firebase} />}
                  />
                  <Route path="/category/:category" render={(props) =>
                     <Category {...props} firebase={this.firebase} />}
                  />

                  <Route path="/activity" render={(props) =>
                     <Activity {...props} firebase={this.firebase} />}
                  />
                  <Route path="/activity-item/:date/:index" render={(props) =>
                     <ActivityItem {...props} firebase={this.firebase} />}
                  />
                  
                  <Route path="/create-team" render={(props) =>
                     <CreateTeam {...props} firebase={this.firebase} />}
                  />

                  <Route path="/discover/:parent" render={(props) =>
                     <Discover {...props} firebase={this.firebase} />}
                  />
                  
                  <Route path="/discover" render={(props) =>
                     <Discover {...props} firebase={this.firebase} />}
                  />
                  <Route path="/discover-ask-lumos" render={(props) =>
                     <DiscoverAskLumos {...props} firebase={this.firebase} />}
                  />
                  /* The habit screen is mapped to multiple paths. This is we know the tab and back button behaviour */
                  <Route path="/habit/:habit" render={(props) =>
                     <Habit {...props} firebase={this.firebase} />}
                  />
                  <Route path="/habit-add/:habit" render={(props) =>
                     <Habit {...props} firebase={this.firebase} />}
                  />
                  <Route path="/habit-home/:habit" render={(props) =>
                     <Habit {...props} firebase={this.firebase} />}
                  />
                  <Route path="/habit-onboarding-1/:habit" render={(props) =>
                     <Habit {...props} firebase={this.firebase} />}
                  />
                  <Route path="/habit-onboarding-2/:habit" render={(props) =>
                     <Habit {...props} firebase={this.firebase} />}
                  />
                  <Route path="/habit-invite" render={(props) =>
                     <AddSharedHabit {...props} firebase={this.firebase} />}
                  />

                  <Route path="/goals" render={(props) =>
                     <Objectives {...props} firebase={this.firebase} />}
                  />
                  <Route path="/goal/:objective" render={(props) =>
                     <Objective {...props} firebase={this.firebase} />}
                  />
                  
                  <Route path="/goal-ask-lumos/:objective" render={(props) =>
                     <ObjectiveAskLumos {...props} firebase={this.firebase} />}
                  />
                  <Route path="/goal-report/:goal" render={(props) =>
                     <GoalReport {...props} firebase={this.firebase} />}
                  />
                  <Route path="/goal-report-home/:goal" render={(props) =>
                     <GoalReport {...props} firebase={this.firebase} />}
                  />
                  <Route path="/goal-report-activity/:member/:goal" render={(props) =>
                     <GoalReport {...props} firebase={this.firebase} />}
                  />
                  <Route path="/goal-report-member/:member/:goal" render={(props) =>
                     <GoalReport {...props} firebase={this.firebase} />}
                  />
                  <Route path="/goal-report-member-activity/:member/:goal" render={(props) =>
                     <GoalReport {...props} firebase={this.firebase} />}
                  />
                  <Route path="/goal-report-profile/:goal" render={(props) =>
                     <GoalReport {...props} firebase={this.firebase} />}
                  />
                  <Route path="/login" render={(props) =>
                     <Login {...props} firebase={this.firebase} />}
                  />
                  <Route path="/login-with-token" render={(props) =>
                     <LoginWithToken {...props} firebase={this.firebase} />}
                  />
                  <Route path="/levels-and-points" render={(props) =>
                     <LevelsAndPoints {...props} firebase={this.firebase} />}
                  />
                  <Route path="/reset-my-password" render={(props) =>
                     <ResetMyPassword {...props} firebase={this.firebase} />}
                  />
                  <Route path="/team" render={(props) =>
                     <TeamMembers {...props} firebase={this.firebase} />}
                  />
                  <Route path="/team-member/:user" render={(props) =>
                     <TeamMember {...props} firebase={this.firebase} />}
                  />
                  <Route path="/team-member-activity/:user" render={(props) =>
                     <TeamMember {...props} firebase={this.firebase} />}
                  />
                  <Route path="/profile" render={(props) =>
                     <MyProfile {...props} firebase={this.firebase} />}
                  />
                  <Route path="/account-settings" render={(props) =>
                     <AccountSettings {...props} firebase={this.firebase} />}
                  />
                  <Route path="/signup" render={(props) =>
                     <Signup {...props} firebase={this.firebase} />}
                  />
                  <Route path="/report/:report" render={(props) =>
                     <SharedGoalReport {...props} firebase={this.firebase} />}
                  />
                  <Route path="/update-goal/:challenge" render={(props) =>
                     <UpdateGoal {...props} firebase={this.firebase} />}
                  />
                  <Route path="/challenge-teammates/:challenge" render={(props) =>
                     <ChallengeTeammates {...props} firebase={this.firebase} />}
                  />
                  <Route path="/verify-email" render={(props) =>
                     <VerifyEmail {...props} firebase={this.firebase} />}
                  />
                  <Route path="/invitation/:invitation" render={(props) =>
                     <Invitation {...props} firebase={this.firebase} />}
                  />
                  <Route path="/edit-categories" render={(props) =>
                     <EditCategories {...props} firebase={this.firebase} />}
                  />
                  <Route path="/add-category" render={(props) =>
                     <EditCategory {...props} firebase={this.firebase} />}
                  />
                  <Route path="/edit-category/:category" render={(props) =>
                     <EditCategory {...props} firebase={this.firebase} />}
                  />
                  <Route path="/edit-habits/:category" render={(props) =>
                     <EditHabits {...props} firebase={this.firebase} />}
                  />
                  <Route path="/create-habit/:category" render={(props) =>
                     <CreateHabit {...props} firebase={this.firebase} />}
                  />
                  <Route path="/create-habit-post/:category" render={(props) =>
                     <CreateHabitPost {...props} firebase={this.firebase} />}
                  />
                  <Route path="/switch" render={(props) =>
                     <Switcher {...props} firebase={this.firebase} />}
                  />
                  <Route path="/edit-habit/:habit" render={(props) =>
                     <EditHabit {...props} firebase={this.firebase} />}
                  />
                  <Route path="/edit-habit-post/:habit" render={(props) =>
                     <EditHabitPost {...props} firebase={this.firebase} />}
                  />
                  <Route path="/onboarding-1-step-1" render={(props) =>
                     <Onboarding1Step1 {...props} firebase={this.firebase} />}
                  />
                  <Route path="/onboarding-1-step-2" render={(props) =>
                     <Onboarding1Step2 {...props} firebase={this.firebase} />}
                  />
                  <Route path="/onboarding-1-step-3" render={(props) =>
                     <Onboarding1Step3 {...props} firebase={this.firebase} />}
                  />
                  <Route path="/onboarding-2-step-1" render={(props) =>
                     <Onboarding2Step1 {...props} firebase={this.firebase} />}
                  />
                  <Route path="/onboarding-2-step-2" render={(props) =>
                     <Onboarding2Step2 {...props} firebase={this.firebase} />}
                  />
                  <Route path="/onboarding-2-step-3" render={(props) =>
                     <Onboarding2Step3 {...props} firebase={this.firebase} />}
                  />
                  <Route path="/onboarding-2-step-4" render={(props) =>
                     <Onboarding2Step4 {...props} firebase={this.firebase} />}
                  />
                  <Route path="/onboarding-3-step-1" render={(props) =>
                     <Onboarding3Step1 {...props} firebase={this.firebase} />}
                  />
                  <Route path="/onboarding-3-step-2" render={(props) =>
                     <Onboarding3Step2 {...props} firebase={this.firebase} />}
                  />
                  <Route path="/onboarding-3-step-3" render={(props) =>
                     <Onboarding3Step3 {...props} firebase={this.firebase} />}
                  />
                  <Route path="/monthly-summary-stop" render={(props) =>
                     <MonthlySummaryStop {...props} firebase={this.firebase} />}
                  />
                  <Route path="/new-habits-stop" render={(props) =>
                     <NewHabitsStop {...props} firebase={this.firebase} />}
                  />
                  <Route path="/nudges-stop" render={(props) =>
                     <NudgesStop {...props} firebase={this.firebase} />}
                  />
                  <Route path="/goal-notifications-stop" render={(props) =>
                     <GoalNotificationsStop {...props} firebase={this.firebase} />}
                  />
                  <Route path="/weekly-habit-stop" render={(props) =>
                     <WeeklyHabitStop {...props} firebase={this.firebase} />}
                  />
               </Switch> 
         
         </Router>
      );
   }
}

export default AppRouter;