import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Award from '../ui/Award';
import Button from '../ui/Button';

import { getUserAndTeam } from '../util/DatabaseUtils';

import Profile from '../team/Profile';

import SpinnerPanel from '../ui/SpinnerPanel';

const INITIAL_STATE = {
  dataLoaded: false,
};

class LevelsAndPoints extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = () => {

    if (this.props.firebase.user && !this.state.dataLoaded) {

      getUserAndTeam(this.props.firebase, (user, team) => {
        this.setState({ 
            dataLoaded: true, 
            user: user, 
            team : team, 
            levels: this.getLevels(team),
         });
      },
      () => {
        this.setState({ isOffline: true })
      })
    }

  }

  getLevels = (team) => {
    if (!team.levels) 
      return []

    var levels = []
    for (var level of Object.values(team.levels)) {
      levels.push({
        level: level,
      }) 
    }

    levels.sort((l1, l2) => {
      return l1.level.points - l2.level.points;
    })

    for (var i = 0; i < levels.length; i++) {
      levels[i].index = i+1;
    }

    return levels;
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  renderLevels = () => {
    if (this.state.dataLoaded) {
      var items = [];

      for (var level of this.state.levels) {
        items.push(
          <div className='levelsListCell' key={level.level}>
            <div className="levelsListCellLevel">{level.index}</div>
            <div className='levelsListCellName'>{level.level.name}</div>
            <div className='levelsListCellPoints'>{level.level.points}</div>
          </div>
        )
      }

      return (
        <div>
          { items }
        </div>
      )
    }
    else {
      return <SpinnerPanel/>
    }
  }

  
  render = () => {
    var history = this.props.history;

    return (
      <AppChrome back="/" title="Levels" history={history} requireLogin={true} user={this.state.user} team={this.state.team} firebase={this.props.firebase} tab="Today">
        { this.renderLevels() }
      </AppChrome>
    )
  }
}

export default LevelsAndPoints;