import moment from 'moment';

const calendarDateFormat = 'YYYY-MM-DD';

export function weeklyOrMonthlyChallengeIsCompleteAlready(challengeId, challenge, currentDateFormatted, days) {
    var date = moment(currentDateFormatted,calendarDateFormat).clone().startOf(challenge.period)

    while (date.format(calendarDateFormat) < currentDateFormatted) {
        var dateFormatted = date.format(calendarDateFormat);

        if (days && dateFormatted in days && challengeId in days[dateFormatted] && days[dateFormatted][challengeId].done) {
            return true
        }

        date.add(1, 'days');
    }

    return false
}

export function isChallengeInEffectOnDate(challengeId, challenge, date, days) {
    var dateFormatted = moment(date).format(calendarDateFormat);
    var dayOfWeek = moment(date).format('ddd');

    if (challenge.period === 'day')
        return challenge.startDate <= dateFormatted && (!challenge.endDate || challenge.endDate >= dateFormatted) && challenge.daysOfWeek.indexOf(dayOfWeek) >= 0;
    else
        return challenge.startDate <= dateFormatted && (!challenge.endDate || challenge.endDate >= dateFormatted) && !weeklyOrMonthlyChallengeIsCompleteAlready(challengeId, challenge, dateFormatted, days);
}

export function getPercentageTicked(challengeId, challenge, userData) {
    var numOfDays = 0;
    var numOfDaysTicked = 0;
    var numOfWeeks = 1;
    var numOfMonths = 1;

    var dateMoment = moment(challenge.startDate, calendarDateFormat);
    var startDateMoment = moment(challenge.startDate, calendarDateFormat)
    var todayMoment = moment(new Date());
    var todayFormatted = todayMoment.format(calendarDateFormat);
    var endDateMoment = (challenge.endDate) ? moment(challenge.endDate, calendarDateFormat) : todayMoment;

    while (dateMoment <= endDateMoment && dateMoment <= todayMoment) {
      var dateFormatted = dateMoment.format(calendarDateFormat);

      if (dateMoment > startDateMoment) {
        // Sunday is the first day of the week

        if (dateMoment.day() === 0)
          numOfWeeks += 1;

        if (dateMoment.date() == 1)
          numOfMonths += 1;
      }

      if (userData.days && dateFormatted in userData.days && challengeId in userData.days[dateFormatted] && userData.days[dateFormatted][challengeId].done)
        numOfDaysTicked += 1;

      if (challenge.period === 'day') { 
        if (challenge.daysOfWeek.indexOf(dateMoment.format('ddd')) >= 0) {
          numOfDays += 1;
        }
      }
      else {
        numOfDays += 1;
      }
      dateMoment.add(1, 'days');
    }

    var completionRate = 0;
    
    if (challenge.period === 'day')
      completionRate = (numOfDays > 0) ? Math.round(100 * (numOfDaysTicked / numOfDays)): 0;
    else if (challenge.period === 'week')
      completionRate = Math.round(100 * (numOfDaysTicked / numOfWeeks));
    else if (challenge.period === 'month')
      completionRate = Math.round(100 * (numOfDaysTicked / numOfMonths));

    return completionRate;
  }