import React, { Component } from 'react';

import Button from './Button'
import Popup from 'reactjs-popup';
import PopupHeader from './PopupHeader'

import 'reactjs-popup/dist/index.css';

class MessagePopup extends Component {

  constructor(props) {
      super(props);
      this.state = { };
  } 

  componentWillMount = () => {

  }

  render = () => {
    return (
        <Popup open="true" closeOnDocumentClick className='message-popup' onClose={this.props.onClose}>
          <PopupHeader title={this.props.title}/>
          <div className='messagePopupMessage'>{this.props.message}</div>
          <div className='messagePopupOKButton'>
            <Button type='primary' size='large' action='OK' onClick={this.props.onClose}/>   
          </div>             
        </Popup>
    );
  }
};

export default MessagePopup;
