import React, { Component } from 'react';

import Avatar from './Avatar'

const INITIAL_STATE = {

};

class AvatarPile extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  getNameForSlot = (n) => {
    var i = (this.props.alignment && this.props.alignment === "left") ? n - 1 : 4 - n; 

    if (n === 4 && this.props.names.length > 4)
      return "+ " + (this.props.names.length - 3).toString()

    if (i < this.props.names.length)
      return this.props.names[i]
    else 
      return null;
  }

  getImageForSlot = (n) => {
      var i = (this.props.alignment && this.props.alignment === "left") ? n - 1 : 4 - n; 

      if (i < this.props.images.length)
        return this.props.images[i]
      else 
        return null;
    }

  renderSlot = (n) => {
    var name = this.getNameForSlot(n);
    var image = this.getImageForSlot(n);      
    
    if (name)
      return <Avatar size='small' name={name} image={image}/>
    else 
      return null;
  }

  render = () => {
    return ( 
      <div className="avatarPile">
        <div className="avatarPileSlot1">{this.renderSlot(1)}</div>
        <div className="avatarPileSlot2">{this.renderSlot(2)}</div>
        <div className="avatarPileSlot3">{this.renderSlot(3)}</div>
        <div className="avatarPileSlot4">{this.renderSlot(4)}</div>
      </div>
    )
  }
}

export default AvatarPile;