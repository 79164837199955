import React, { Component } from 'react';

import Button from '../ui/Button'
import MessagePopup from '../ui/MessagePopup'
import Popup from 'reactjs-popup';
import PopupHeader from '../ui/PopupHeader'
import SpinnerPanel from '../ui/SpinnerPanel'

import Toggle from 'react-toggle';
import "react-toggle/style.css";

import 'reactjs-popup/dist/index.css';
import moment from 'moment';

import { activityHabitEnded } from '../activity/ActivityUtils';

const calendarDateFormat = 'YYYY-MM-DD';
const calendarDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

class EndGoalPopup extends Component {

  constructor(props) {
      super(props);
      this.state = { 
        journal : '', 
        displayConfirmation : false,
        ingrained : true,
        recommend : true,
        endingHabit : false
      };
  } 

  componentWillMount = () => {

  }

  handleIngrainedChange = (event) => {
    this.setState({ ingrained: !this.state.ingrained });
  }

  handleRecommendChange = (event) => {
    this.setState({ recommend: !this.state.recommend });
  }

  handleJournalChange = (event) => {
    this.setState({ journal: event.target.value });
  }

  endHabitClicked = () => {
      if (this.state.journal === "") {
        return
      }

      this.setState({ endingHabit : true })
      
      var database = this.props.firebase.database;    
      var today = moment().format(calendarDateFormat);

      var updates = {};
      updates['endDate'] = today;
      updates['endJournal'] = this.state.journal
      updates['recommend'] = this.state.recommend
      updates['ingrained'] = this.state.ingrained

      database.ref('users/' + this.props.firebase.user.uid + "/challenges/" + this.props.challengeId)
      .update(updates, (error) => {
        this.setState({ displayConfirmation : true })
        activityHabitEnded(this.props.firebase, this.props.firebase.user.uid, today, this.props.challengeId, this.state.journal, this.state.recommend, this.state.ingrained)
      });
  }

  renderEndGoalPopup = () => {
    return <Popup open="true" closeOnDocumentClick className='end-habit-popup' onClose={this.props.onClose}>
        <PopupHeader title={this.props.challenge}/>

        { this.state.endingHabit ?
        <SpinnerPanel/>
        :
        <div width="100%" height="100%">
            <table className='endHabitQuestionTable'>
              <tr>
                <td className='endHabitQuestionToggle'><Toggle defaultChecked={this.state.recommend} onChange={this.handleRecommendChange} /></td>
                <td className="endHabitQuestion">Do you recommend this habit?</td>
              </tr>
              <tr>
                <td className='endHabitQuestionToggle'><Toggle defaultChecked={this.state.ingrained} onChange={this.handleIngrainedChange} /></td>
                <td className="endHabitQuestion">Did Lasoo help you practice this habit?</td>
              </tr>

            </table>
            <textarea rows={6} className="endHabitPopupHabitJournal" placeholder="Did the habit help you and did you learn anything from practicing it?" id="journal" name="journal" onChange={ (event) => { this.handleJournalChange(event); }} value={this.state.journal}></textarea>

            <div className='endHabitPopupDoneButton'>
              <Button size='large' disabled={this.state.journal === ''} type={this.state.journal === '' ? 'secondary' : 'primary'} onClick={() => this.endHabitClicked()} action='End habit'/>
            </div>               
        </div>
        }
    </Popup>
  }

  renderConfirmationMessage = () => {
    return <MessagePopup title="End habit" message="Habit successfully ended." onClose={this.props.onClose.bind(true)}/>
  }

  render = () => {

    if (this.state.displayConfirmation)
      return this.renderConfirmationMessage()
    else
      return this.renderEndGoalPopup()
  }
};

export default EndGoalPopup;
