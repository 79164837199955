import React, { Component } from 'react';

import YouTube from 'react-youtube'

const INITIAL_STATE = {

};

class HabitVideo extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  } 

  getVideoId = () => {

    if (this.props.url && this.props.url !== '') {
      if (this.props.url.startsWith('https://youtu.be/')) {
        return this.props.url.substring(17, 28)
      }
      else if (this.props.url.indexOf('youtube.com') >= 0) {
        const url = new URL(this.props.url);
        return url.searchParams.get('v');
      }
      else
        return null;
    }
    else
      return null;
  }

  getTimestamp = (videoURL) => {
    if (this.props.url && this.props.url !== '') {
        const url = new URL(this.props.url);
        var t = url.searchParams.get('t');
        if (t)
          return parseInt(t.replace('s',''))
        else 
          return null;
    }
    else
      return null;
  }

  getVideoOpts = () => {
    var timestamp = this.getTimestamp(this.props.url);

    var width = Math.min(window.screen.width, 380);

    var opts = {
      width: width,
      height: '225'
    }
    if (timestamp) {
      opts.playerVars = { start: timestamp }
    }

    return opts;
  }

  render = () => {
    var videoId = this.getVideoId();
    var opts = this.getVideoOpts();

    return ( 
      <div className='habitYouTube'><YouTube videoId={videoId} opts={opts}/></div>
    )
  }
}

export default HabitVideo;