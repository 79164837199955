import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';

import Button from '../ui/Button';
import ConfirmPopup from '../ui/ConfirmPopup'
import IconSelector from '../ui/IconSelector'
import MessagePopup from '../ui/MessagePopup'
import SquircleIcon from '../ui/SquircleIcon'

import { HexColorPicker } from "react-colorful";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import firebase from 'firebase/app'

import moment from 'moment';
import Toggle from 'react-toggle'

import 'react-toggle/style.css';
import { isThisTypeNode } from 'typescript';

import { getUserAndTeam, fetchTeamFromServer } from '../util/DatabaseUtils';

const INITIAL_STATE = {
  dataLoaded: false,

  messagePopup: false,
  messagePopupMessage: '',
  messagePopupCallback: null,

  confirmPopup: false,
  confirmPopupCallback: null,

  colourPicker: false,
  iconSelector: false,

  name: "",
  description: "",
  order: "",
  colour: "FF0000",
  icon: "check_box",
  visible: false,
  parent: null
};

class EditCategory extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {

      getUserAndTeam(this.props.firebase, (user, team) => {
        var state = {
          teamId : user.team,
          team: team,
          dataLoaded: true
        }

        if (this.props.match.params.category && team['habit-categories'] && team['habit-categories'][this.props.match.params.category]) {
           var category = team['habit-categories'][this.props.match.params.category];
           state.name = category.name;
           state.description = category.description;
           state.icon = (category.icon) ? category.icon : "check_box";
           state.order = (category.order) ? category.order.toString() : "";
           state.colour = category.colour;
           state.visible = (category.visible) ? true : false;
           state.parent = (category.parent) ? category.parent : null;
        }

        this.setState(state)
      })
    }
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  closeMessagePopup = () => {
    this.setState({
      messagePopup: false,
      messagePopupMessage: '',
      messagePopupCallback: null
    })
  }

  showMessagePopup = (message, callback) => {
    this.setState({
      messagePopup: true,
      messagePopupMessage: message,
      messagePopupCallback: callback
    })
  }

  closeConfirmPopup = () => {
    this.setState({
      confirmPopup: false,
      confirmPopupMessage: '',
      confirmPopupCallback: null
    })
  }

  showConfirmPopup = (message, callback) => {
    this.setState({
      confirmPopup: true,
      confirmPopupMessage: message,
      confirmPopupCallback: callback
    })
  }

  closeColourPicker = () => {
    this.setState({
      colourPicker: false
    })
  }

  showColourPicker = () => {
    this.setState({
      colourPicker: true
    })
  }

  iconSelected = (icon) => {
    this.setState({ icon: icon, iconSelector: false })
  }

  showIconSelector = (icon) => {
    this.setState({ iconSelector: true })
  }

  closeIconSelector = (icon) => {
    this.setState({ iconSelector: false })
  }

  stringIsBlank = (str) => {
    return (!str || /^\s*$/.test(str));
  }

  validateFields = () => {
    if (this.stringIsBlank(this.state.name) || this.state.name.trim().length < 3 || this.state.name.trim().length > 25) {
      this.showMessagePopup("Name must be between 3 and 25 characters.", this.closeMessagePopup);
      return false;
    }

    if (this.stringIsBlank(this.state.description) || this.state.description.trim().length < 4 || this.state.description.trim().length > 80) {
      this.showMessagePopup("Description must be between 4 and 80 characters.", this.closeMessagePopup);
      return false;
    }

    if (!this.stringIsBlank(this.state.order) && isNaN(Number(this.state.order))) {
      this.showMessagePopup("Order must be a number.", this.closeMessagePopup);
      return false;
    }

    return true;
  }

  isAlphaNumeric = (ch) => {
    return (ch.match(/^[0-9a-zA-Z]+$/));
  }

  /* TODO. This needs to be improved! */

  generateCategoryId = () => {
    var id = "";
    var name = this.state.name.trim();

    for (let i = 0; i < name.length; i++) {
       var ch = name[i];
       if (this.isAlphaNumeric(ch)) 
          id += ch.toLowerCase()
       else 
          id += "-";
    }
    return id;
  }

  onSubmission = (e) => {
    if (!this.validateFields())
      return;

    var categoryId = (this.props.match.params.category) ? this.props.match.params.category : this.generateCategoryId();

    var category = {
      name: this.state.name,
      colour: this.state.colour,
      description: this.state.description,
      icon: this.state.icon,
      visible: this.state.visible
    }

    if (this.state.order.trim() !== '')
      category.order = parseInt(this.state.order);

    if (this.state.parent)
      category.parent = this.state.parent;

    // Update Team 'habit-categories/id'

    var fields = {}
    fields["habit-categories." + categoryId] = category

    this.props.firebase.firestore
      .collection('teams')
      .doc(this.state.teamId)
      .update(fields)
      .then(() => {
        fetchTeamFromServer(this.props.firebase, this.state.teamId, (doc) => {
          this.props.history.push("/edit-categories")
        })
      })
      .catch((error) => {
        alert(error);
      });
  }

  onChangeName = (e) => {
    this.setState({ name: e.target.value })
  }

  onChangeDescription = (e) => {
    this.setState({ description: e.target.value })
  }

  onChangeOrder = (e) => {
    this.setState({ order: e.target.value})
  }

  onChangeColour = (colour) => {
    this.setState({ colour: colour.replace("#", "") })
  }

  onChangeVisibility = () => {
    this.setState({ visible: !this.state.visible})
  }

  onEditHabits = () => {
    this.props.history.push("/edit-habits/" + this.props.match.params.category)
  }

  onDelete = () => {
    this.showConfirmPopup('Are you sure you want to delete this category?', this.onDeleteConfirm);
  }

  onDeleteConfirm = (confirmed) => {
    if (confirmed) {
      // First check there are no habits in this category
      
      var hasHabits = false;
      if (this.state.team.habits) {
        Object.keys(this.state.team.habits).forEach((habitId) => {
          var habit = this.state.team.habits[habitId]; 
          if (habit.category === this.props.match.params.category) {
            hasHabits = true;
          }
        });
      }

      if (hasHabits) {
        this.closeConfirmPopup();
        this.showMessagePopup("Please delete all habits in category first.", this.closeMessagePopup);
        return;
      }

      var fields = {}
      fields["habit-categories." + this.props.match.params.category] = firebase.firestore.FieldValue.delete();

      this.props.firebase.firestore
      .collection('teams')
      .doc(this.state.teamId)
      .update(fields)
      .then(() => {
          fetchTeamFromServer(this.props.firebase, this.state.teamId, (doc) => {
            this.props.history.push("/edit-categories");
          });
      });
    }
    else
      this.closeConfirmPopup();
  }

  handleChangeParent = (event) => {
    this.setState({ "parent": event.target.value});
  }

  renderCategories = () => {
    var items = [];

    items.push(<option></option>);
    for (var categoryId of Object.keys(this.state.team['habit-categories']).sort())
      if (!this.props.match.params.category || this.props.match.params.category !== categoryId)
        items.push(<option value={categoryId}>{this.state.team['habit-categories'][categoryId].name}</option>);
    
    return items;
  }

  renderCategoryForm = () => {
    var action = (this.props.match.params.category) ? "Update category" : "Add category";

    if (!this.state.dataLoaded)
      return <div/>;

    var visibiltyDesc = (this.state.visible) ? "Category is visible to team" : "Category is not visible to team";

    return <div>
      <div className='editCategoryForm'>
      <div className='sectionTitle'>NAME</div>
      <input className='addHabitFormName' onChange={this.onChangeName} value={this.state.name} placeholder="A short name for the category" />

      <div className='sectionTitle'>DESCRIPTION</div>
      <textarea rows={3} className='addHabitFormDescription' onChange={this.onChangeDescription} value={this.state.description} placeholder="A description for the category" />

      <div className='categoryColourImageSection'>
        <div className='categoryImageContainer'>
          <div className="sectionTitle">ICON</div>
          <div className='categoryIconSelector' onClick={this.showIconSelector}>
              <SquircleIcon size='medium' icon={this.state.icon} colour="ea2952"/>
          </div>
        </div>
      </div>

      
      <div className='sectionTitle'>ORDER (OPTIONAL)</div>
      <input className='addHabitFormName' onChange={this.onChangeOrder} value={this.state.order} placeholder="" />

      <div className='sectionTitle'>PARENT CATEGORY</div>
      <select value={this.state.parent} onChange={this.handleChangeParent} className='categoryParentCategory'>
        { this.renderCategories() }
      </select>

      <div className='sectionTitle'>VISIBILITY</div>
      <div className='editCategoryVisibilityLine'>
        <div className='editCategoryVisibilityToggleContainer'>
          <Toggle className='editCategoryVisibilityToggle' defaultChecked={this.state.visible} onChange={this.onChangeVisibility} /></div>
        <div className='editCategoryVisibilityToggleMessage'>{visibiltyDesc}</div>
      </div>
      </div>

      <Button type='primary' size='large' onClick={this.onSubmission} action={action}/>

      { false && this.props.match.params.category ?
      <p><Button type='secondary' size='large' onClick={this.onEditHabits} action='Edit category habits'/></p> : <div />}

      {this.props.match.params.category ?
        <p><Button type='secondary' size='large' onClick={this.onDelete} action='Delete category'/></p> : <div />}

  
      {this.state.confirmPopup
        ?
        <ConfirmPopup title={this.getTitle()} message={this.state.confirmPopupMessage} callback={this.state.confirmPopupCallback} />
        :
        <div></div>
      }

      {this.state.messagePopup
        ?
        <MessagePopup title={this.getTitle()} message={this.state.messagePopupMessage} onClose={this.state.messagePopupCallback} />
        :
        <div></div>
      }

      { this.state.iconSelector
        ?
        <IconSelector firebase={this.props.firebase} colour={this.state.colour} onSelection={this.iconSelected} onClose={this.closeIconSelector} />
        :
        <div></div>
      }

      {this.state.colourPicker
        ?
        <Popup open="true" closeOnDocumentClick className='colour-picker-popup' onClose={this.closeColourPicker}>
          <HexColorPicker color={this.state.colour} onChange={this.onChangeColour} />
        </Popup>
        :
        <div></div>
      }
    </div>
  }

  getTitle = () => {
    return (this.props.match.params.category) ? "Edit Category" : "Add Category";
  }

  render = () => {
    var history = this.props.history;
    var title = this.getTitle();
  
    return (
      <AppChrome title={title} history={history} requireLogin={true} firebase={this.props.firebase} team={this.state.team} back={true} tab="Discover">
          <div>
            { this.renderCategoryForm() } 
          </div>
      </AppChrome>
    )
  }
}

export default EditCategory;