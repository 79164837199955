import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import EditHabitForm from './EditHabitForm'

import 'reactjs-popup/dist/index.css';

import 'react-toggle/style.css';
import { isThisTypeNode } from 'typescript';

import { getUserAndTeam } from '../util/DatabaseUtils'

const INITIAL_STATE = {
  dataLoaded: false,

  name: "",
  description: "",
  videoURL: '',
  colour: '#FF0000',
  category: '',
  visible: true,
};

class CreateHabit extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  /* This class is designed to be sub-classed, and the following five methods overridden */
  getAction = () => {
    return "Create Habit";
  }

  getTitle = () => {
    return "Create Habit";
  }
  
  getAllowEditVisibility = () => {
    return false;
  }

  getDefaultVisibility = () => {
    return true;
  }

  getDefaultRecommended = () => {
    return false;
  }

  getAllowEditRecommended = () => {
    return false;
  }

  getSendSuggestionReminder = () => {
    return false;
  }

  habitCreated = (habitId, teamId) => {
    // Do nothing. This may be overridded by subclasses.
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }
  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {
      getUserAndTeam(this.props.firebase, (user, team) => {
        var state = {
          teamId : user.team,
          team: team,
          dataLoaded: true
        }

        if (this.props.match.params.category && team['habit-categories'] && team['habit-categories'][this.props.match.params.category]) {
          var category = team['habit-categories'][this.props.match.params.category];
          state.colour = category.colour;
          state.category = this.props.match.params.category
          state.recommended = this.getDefaultRecommended();
          state.visible = this.getDefaultVisibility();
        }
        
        this.setState(state)
      })
    }
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  isAlphaNumeric = (ch) => {
    return (ch.match(/^[0-9a-zA-Z]+$/));
  }

  /* TODO. This needs to be improved! */

  generateHabitId = (name) => {
    var id = "";
    var nameTrimmed = name.trim();

    for (let i = 0; i < nameTrimmed.length; i++) {
       var ch = nameTrimmed[i];
       if (this.isAlphaNumeric(ch)) 
          id += ch.toLowerCase()
       else 
          id += "-";
    }

    return id;
  }

  onSubmit = (name, videoURL, description, colour, recommended, visible) => {
    var habitId = this.generateHabitId(name);

    var user = this.props.firebase.user;

    var habit = {
      name: name,
      videoURL: (videoURL) ? videoURL : '',
      description: description,
      colour: colour.replace('#', ''),
      category: this.state.category,
      recommended: recommended,
      visible: visible,
      creator: user.uid,
      'creator-name': user.displayName,
    }

    if (user.photoURL) 
      habit['creator-photo-url'] = user.photoURL;

    // Update Team 'habits/id'

    var fields = {}
    fields["habits." + habitId] = habit

    this.props.firebase.firestore
      .collection('teams')
      .doc(this.state.teamId)
      .update(fields)
      .then(() => {
        this.habitCreated(habitId, this.state.teamId);
        this.props.history.push("/habit/" + habitId)
      })
      .catch((error) => {
        alert(error);
      });   
  }

  renderHabitForm = () => { 
    if (!this.state.dataLoaded)
      return <div/>;
    else 
      return <EditHabitForm action={this.getAction()} onSubmit={this.onSubmit} 
                            category={this.state.category}
                            name={this.state.name} description={this.state.description} 
                            colour={this.state.colour} 
                            recommended={this.state.recommended}
                            videoURL={this.state.videoURL}
                            allowEditRecommended={this.getAllowEditRecommended()}
                            visible={this.state.visible}
                            allowEditVisibility={this.getAllowEditVisibility()}/>
  }

  render = () => {
    var history = this.props.history;
    var title = this.getTitle();

    return (
      <AppChrome title={title} team={this.state.team} history={history} requireLogin={true} firebase={this.props.firebase} back={true} tab="Discover">
          <div>
            { this.renderHabitForm() } 
          </div>
      </AppChrome>
    )
  }
}

export default CreateHabit;