import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';

import GoalForm from '../goals/GoalForm';

import MessagePopup from '../ui/MessagePopup'

import { sendCalendarEventsFunction } from '../util/FunctionUtils';
import { challengeStatsUpdate } from '../util/StatsUtils';
import { getDefaultVisibility } from '../util/VisibilityUtils';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

const calendarDateFormat = 'YYYY-MM-DD';

class AddNewHabit extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount = () => {}

  componentDidUpdate = (prevProps, prevState, snapshot) => {}
 
  navigateHome = () => {
    this.props.history.push("/");
  }
 
  addHabit = (habit, name, category, period, selectedDays, startDate, endDate, colour, reminders, calendarEvents, teamMembers, visibility, showCreationConfirmPopup) => {    
    var challengeId = 'challenge-' + uuidv4();
    var challenge = {
      habit : habit,
      name: name,
      category: category,
      startDate: moment(startDate).format(calendarDateFormat),
      endDate: moment(endDate).format(calendarDateFormat),
      period: period,
      daysOfWeek: selectedDays,
      reminders: reminders,
      calendarEvents: calendarEvents,
      teamMembers : teamMembers,
      colour: colour,
      visibility: visibility
    }

    this.props.firebase.database.ref('users/' + this.props.firebase.user.uid + '/challenges/' + challengeId).set(challenge)
      .then(() => {
        challengeStatsUpdate(this.props.firebase, this.props.firebase.user.uid, challengeId, () => {
          sendCalendarEventsFunction(this.props.firebase.user.uid, challengeId);
          showCreationConfirmPopup(challengeId, this.navigateHome)
       });
      })
      .catch((error) => {
        alert("Error writing document: ", error);
      });
  }

  getDefaultSelectedDays = () => {
    return [ 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
  }

  render = () => {
    var history = this.props.history;

    var todayFormatted = moment(new Date()).format(calendarDateFormat);
    var threeWeeksFormatted = moment(new Date()).add(3, 'weeks').format(calendarDateFormat)

    return (
      <AppChrome title="Add Goal" history={history} requireLogin={true} firebase={this.props.firebase} back={true} tab="Today">
          <div>
            <GoalForm history={history} firebase={this.props.firebase} actionName="Add goal" name="" category="Personal" habit="" period='day' daysOfWeek={this.getDefaultSelectedDays()} startDate={todayFormatted} endDate={threeWeeksFormatted} colour='000000' calendarEvents={[]} teamMembers={[]} visibility={ getDefaultVisibility() } onSubmission={this.addHabit}/>
          </div>
          { this.state.messagePopup
                ?
                <MessagePopup title='Add Personal Habit' message={this.state.messagePopupMessage} onClose={this.state.messagePopupCallback} />
                :
                <div></div>
          }
      </AppChrome>
    )
  }
}

export default AddNewHabit;