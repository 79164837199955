import React, { Component } from 'react';
import { render } from 'react-dom';

import AppRouter from './AppRouter';
import './App.scss';

class App extends Component {
  render = () => {
    return (  
       <AppRouter/>
    );
  }
}

export default App;
