import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import HabitCategories from './HabitCategories'
import LumosTouchpoint from '../lumos/LumosTouchpoint'
import PinnedCard from '../ui/PinnedCard'

import { getUserAndTeam } from '../util/DatabaseUtils'
import { isSuperAdmin } from '../util/SwitchUtils'

class Discover extends Component {

    constructor(props) {
       super(props);
       this.state = { dataLoaded : false, isOffline: false };
    }

    checkDataLoaded = () => {
      if (this.props.firebase.user && !this.state.dataLoaded) {

        getUserAndTeam(this.props.firebase, (user, team) => {

          if (!team) {
            this.props.history.push("/create-team")
            return
          }

          this.setState({
            user: user,
            team: team,
            dataLoaded: true,
            isOffline: false
          })
        },
        () => {
          this.setState({ isOffline: true })
        })
      }
    }

    componentWillMount = () => {
      this.checkDataLoaded();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
      this.checkDataLoaded();
    }

    componentWillUnmount() {
    }

    isParentHabitCategory = (categoryId) => {
      for (var id of Object.keys(this.state.team['habit-categories'])) {
        var habitCategory = this.state.team['habit-categories'][id]

        if (habitCategory.parent && habitCategory.parent === categoryId)
          return true
  
      }
      return false;
    }
    
    showAskLumos = () => {
      this.props.history.push("/discover-ask-lumos")
    }

    habitCategorySelected = (habitCategory) => {
      if (this.isParentHabitCategory(habitCategory))
        this.props.history.push('/discover/' + habitCategory)
      else
        this.props.history.push('/category/' + habitCategory)
    }

    editCategories = () => {
      this.props.history.push('/edit-categories');
    }

    getTitle = () => {
      if (this.props.match.params.parent) {
        if (this.state.dataLoaded) {
          var category = this.state.team['habit-categories'][this.props.match.params.parent];
          if (category)
            return category.name;
        }
        else {
          return " ";
        }
      }
      else
        return null;
    }

    render = () => {
      var user = this.props.firebase.user
      var userLoaded = this.props.firebase.initialised;
      var history = this.props.history;

      if (false && user && !user.emailVerified)
        this.props.history.push("/verify-email");

      var isContentManager = false;
      if (user && this.state.dataLoaded && this.state.team['content-admin'])
        isContentManager = Object.values(this.state.team['content-admin']).indexOf(user.email) >= 0 || isSuperAdmin(user);

      var categoriesMode = (this.props.match.params.parent) ? 'children' : 'top-level';

      return (
        <AppChrome title={this.getTitle()}  team={this.state.team} back={categoriesMode === 'children'} history={history} requireLogin={true} firebase={this.props.firebase} tab="Discover">
            <div>

              <LumosTouchpoint description='Can I suggest some habits for your objectives?' onClick={this.showAskLumos}/>
  
              <HabitCategories mode={categoriesMode} parent={this.props.match.params.parent} onClick={this.habitCategorySelected} firebase={this.props.firebase}/>

              { isContentManager  ?
                <p>
                  <PinnedCard description='As a team administrator you can change habit categories' action='Edit' onButtonClick={this.editCategories}/>
                  </p>
              : 
                <div/>
              }
            </div>
        </AppChrome>
      )
      
    }
}

export default Discover;