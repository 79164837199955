import React, { Component } from 'react';

import Button from './Button';
import Popup from 'reactjs-popup';
import PopupHeader from './PopupHeader'
import 'reactjs-popup/dist/index.css';

class ConfirmPopup extends Component {

  constructor(props) {
      super(props);
      this.state = { };
  } 

  componentWillMount = () => {

  }

  onOK = () => {
    this.props.callback(true);
  }

  onCancel = () => {
    this.props.callback(false);
  }

  render = () => {
    var habit = this.props.habit;

    return (
        <Popup open="true" closeOnDocumentClick className='confirm-popup' onClose={this.props.onCancel}>
          <PopupHeader title={this.props.title}/>
          <div className='confirmPopupMessage'>{this.props.message}</div>
          <div className='confirmPopupCancelButton'>
            <Button type='secondary' size='large' onClick={this.onCancel} action='Cancel'/>
          </div>

          <div className='confirmPopupOKButton'>
           <Button type='primary' size='large' onClick={this.onOK} action='OK'/>
          </div>               
        </Popup>
    );
  }
};

export default ConfirmPopup;
