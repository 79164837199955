import React, { Component } from 'react';

import SuperEllipse from "react-superellipse";
import { Preset } from "react-superellipse";

const INITIAL_STATE = {
};

class SquircleIcon extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  render = () => {
    var ellipseSize = (this.props.size === 'medium') ? 42 : 18;
    var fontSize = (this.props.size === 'medium') ? "25" : "10"
    var fontClasses = "material-icons-outlined md-light md-" + fontSize;
    var paddingTop = (this.props.size === 'medium') ? "9px" : "0px"
    var paddingLeft = (this.props.size === 'medium') ? "9px" : "4px"

    return (
      <SuperEllipse r1={Preset.iOS.r1} r2={Preset.iOS.r2} style={{width: ellipseSize, height: ellipseSize, backgroundColor: "#" + this.props.colour, paddingTop: paddingTop, paddingLeft: paddingLeft }}>
        <div className={fontClasses} style={{  color: "white" }}>{this.props.icon}</div>
      </SuperEllipse> 
    );
  }
}

export default SquircleIcon;