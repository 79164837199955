import React, { Component } from 'react';

import  { showObjectives } from '../util/SwitchUtils';


const objectiveTabs = ['Today', 'Habits', 'Activity', 'Discover']
const objectiveTabHomes = { 'Today' : '/', 'Activity' : '/activity', 'Habits' : '/goals', 'Discover' : '/discover' }


class TabBar extends Component {

    constructor(props) {
      super(props);
   }

    selectTab(tab) {
      this.props.history.push(objectiveTabHomes[tab]);

    }
    
    render() {
      var selectedTab = this.props.tab;
      const items = []

      for (const [i, tab] of objectiveTabs.entries()) {
        var icon = (tab === selectedTab) ? "/icons/" + tab.toLowerCase() + "-icon.png" : "/icons/" + tab.toLowerCase() + "-icon-disabled.png";
        var labelClass = (tab === selectedTab) ? "tabBarLabel" : "tabBarLabelDisabled"

        items.push(         
          <div key={tab} className="tabBarItem" onClick={() => this.selectTab(tab)}>  
            <div className="tabBarIconContainer">
                <img className="tabBarIcon" src={icon}/>
            </div>
            <div className="tabBarLabelContainer">
              <span className={labelClass}>{tab}</span>
            </div>        
          </div>);
      }

      return (
        <div className="tabBarContainer">
          { items }
        </div>
      );
    }
}

export default TabBar;