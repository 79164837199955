import React, { Component } from 'react';

import AppChrome from './AppChrome';

import { getTeams } from '../util/DatabaseUtils';

const INITIAL_STATE = {
  teams : {},
  dataLoaded: false
};

class Switcher extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded && (this.props.firebase.user.email === 'antony@lasoohabits.com' || this.props.firebase.user.email === 'g.kid@icloud.com' || this.props.firebase.user.email === 'sally@lasoohabits.com' || this.props.firebase.user.email === 'keith.abbott@adlerianconsulting.com.au' || this.props.firebase.user.email === 'david.royle@adlerianconsulting.com.au')) {
      getTeams(this.props.firebase, (teams) => {
        var teamsSorted = [];
        teams.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            if (this.props.firebase.user.email === 'keith.abbott@adlerianconsulting.com.au' || this.props.firebase.user.email === 'david.royle@adlerianconsulting.com.au') {        
              if (doc.id === 'ozCI0HHoPHBOe1j6lRDT' || doc.id === 'x6PyI4ewOL7jJMMSi7rt')
                teamsSorted.push({ id: doc.id, data : doc.data() });
            }
            else {
              teamsSorted.push({ id: doc.id, data : doc.data() });

            }
            teamsSorted.sort((t1, t2) => {
              if (t1.data.name < t2.data.name)
                return -1;
              else if (t1.data.name > t2.data.name)
                return 1;
              else
                return 0;
            })
        })
        
        this.setState({ dataLoaded : true, teams : teamsSorted })
      });
    }
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  componentWillUnmount() {
  }

  teamClicked = (id) => {
    this.props.firebase.database.ref('users/' + this.props.firebase.user.uid + '/team').set(id)
    .then(() => {
      // This should cause 
      this.props.history.push("/team");
    })
    .catch((error) => {
      alert("Error writing document: ", error);
    });

  }

  renderTeams = () => {
    if (this.state.dataLoaded) {
      var items = [];
      this.state.teams.map((team) => {
        if (team.data.settings.teamDisabled) 
          return;
        
        items.push(
          <div className='teamCell' onClick={this.teamClicked.bind(this, team.id)}>
            <div className='teamCellName'>{team.data.name}</div>
            <div className='teamCellNumber'>{Object.keys(team.data.member).length}</div>
            <div className='teamCellAdmin'>{team.data['creator-name']}</div>
          </div>
        );
      });
      return <div>{items}</div>
    }
    else
      return <div/>;
  }

  getTitle = () => {
     return " "; 
  }

  render = () => {
    var history = this.props.history;
    
    return (
      <AppChrome title="Switch" history={history} requireLogin={true} firebase={this.props.firebase} back={true} tab="Today">
        {
          this.renderTeams()
        }
      </AppChrome>
    )
  }
}

export default Switcher;