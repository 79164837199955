import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import Button from '../ui/Button'

const INITIAL_STATE = {
};

class VerifyEmail extends Component {

    constructor(props) {
       super(props);
       this.state = INITIAL_STATE;
    }

    componentWillMount = () => {
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
    }

    componentWillUnmount() {
    }

    createTeam = async () => {
      await this.props.firebase.user.reload();

      if (this.props.firebase.user.emailVerified)
        this.props.history.push("/create-team");
    }

    renderEmailVerification = () => {
      if (!this.props.firebase.user) {
        return <div/>
      }
      return <div>
          <div className='welcomeTitle'><p>Check your email</p></div>
          <div className='welcomeDescription'><p>Please check your email for a verification email. If you cannot see it check your Spam folder.</p><p>Click the link in the email and then click Continue below.</p></div>
          <p><Button type='primary' size='large' action='Continue' onClick={() => this.createTeam()}/></p>
      </div>
    }

    render = () => {
      var history = this.props.history;

      return (
        <AppChrome title="Verify Email" history={history} requireLogin={true} firebase={this.props.firebase} tab="Today">
        {
          this.renderEmailVerification()
        }
        </AppChrome>
      )
    }
}

export default VerifyEmail;