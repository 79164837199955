import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';
import EditHabitForm from './EditHabitForm'
import SpinnerPanel from '../ui/SpinnerPanel'

import firebase from 'firebase/app'
import 'react-toggle/style.css';

import { getUserAndTeam } from '../util/DatabaseUtils';

const INITIAL_STATE = {
  dataLoaded: false,

  name: "",
  videoURL: "",
  description: "",
  colour: '#FF0000',
  category: '',
  order: "",
  recommended: false,
  visible: true,
  updatingHabit: false,
  isContentAdmin: false
};

class EditHabit extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  checkDataLoaded = () => {
    if (this.props.firebase.user && !this.state.dataLoaded) {

      getUserAndTeam(this.props.firebase, (user, team) => {
        var state = {
          teamId : user.team,
          team: team,
          dataLoaded: true,
          isContentAdmin: (team['content-admin'] && team['content-admin'].indexOf(this.props.firebase.user.email) >= 0 ) ? true : false,
        }

        if (this.props.match.params.habit && team.habits && team.habits[this.props.match.params.habit]) {
           var habit = team.habits[this.props.match.params.habit];
           state.name = habit.name;
           state.videoURL = (habit.videoURL) ? habit.videoURL : '';
           // We support quoted newlines because the Firebase Firestore editor does support entry of \n characters
           state.description = habit.description.replaceAll("\\n","\n"); 
           state.colour = habit.colour
           state.category = habit.category
           state.order = habit.order ? habit.order.toString() : ''
           state.recommended = (habit.recommended) ? true : false
           state.visible = (habit.visible) ? true : false
           state.creator = (habit.creator) ? habit.creator : user.uid
           state.creatorName = habit['creator-name'] ? habit['creator-name']  : user.displayName
           if (habit['creator-photo-url'])
              state.creatorPhotoURL = habit['creator-photo-url']
           else if (user.photoURL)
              state.creatorPhotoURL = user.photoURL
        }
        
        this.setState(state)
      });
    }
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  isAlphaNumeric = (ch) => {
    return (ch.match(/^[0-9a-zA-Z]+$/));
  }

  /* TODO. This needs to be improved! */

  generateHabitId = (name) => {
    var id = "";
    var nameTrimmed = name.trim();

    for (let i = 0; i < nameTrimmed.length; i++) {
       var ch = nameTrimmed[i];
       if (this.isAlphaNumeric(ch)) 
          id += ch.toLowerCase()
       else 
          id += "-";
    }

    return id;
  }

  onSubmit = (name, videoURL, description, colour, recommended, visible, order) => {
    this.setState({ updatingHabit: true })

    var habitId = this.props.match.params.habit;

    var habit = {
      name: name,
      videoURL: videoURL,
      description: description,
      colour: colour.replace('#', ''),
      category: this.state.category,
      recommended : recommended,
      visible: visible,
    }

    if (order)
      habit.order = order

    if (this.state.creator)
      habit.creator = this.state.creator

    if (this.state.creatorName)
      habit['creator-name'] = this.state.creatorName

    if (this.state.creatorPhotoURL)
      habit['creator-photo-url'] = this.state.creatorPhotoURL

    // Update Team 'habits/id'

    var fields = {}
    fields["habits." + habitId] = habit

    this.props.firebase.firestore
      .collection('teams')
      .doc(this.state.teamId)
      .update(fields)
      .then(() => {
        this.props.history.push("/habit/" + habitId)
      })
      .catch((error) => {
        this.setState({ updatingHabit: false })
        alert(error);
      });   
  }

  onDelete = () => {   
    this.setState({ updatingHabit: true })
 
    var fields = {}
    fields["habits." + this.props.match.params.habit] = firebase.firestore.FieldValue.delete();

    this.props.firebase.firestore
    .collection('teams')
    .doc(this.state.teamId)
    .update(fields)
    .then(() => {
        this.props.history.push("/category/" + this.state.category);
    });

  }

  renderHabitForm = () => {
    var action = (this.props.match.params.habit) ? "Update habit" : "Add habit";

    if (!this.state.dataLoaded)
      return <div/>;
    else 
      return <EditHabitForm action={action} onSubmit={this.onSubmit} onDelete={this.onDelete}
                            videoURL={this.state.videoURL} 
                            category={this.state.category}
                            name={this.state.name} description={this.state.description} 
                            colour={this.state.colour} 
                            recommended={this.state.recommended}
                            visible={this.state.visible}
                            allowEditOrder={this.state.isContentAdmin}
                            allowEditVisibility={false}
                            allowEditRecommended={false}
                            />
  }

  render = () => {
    var history = this.props.history;
    var title = "Edit Habit";
  
    return (
      <AppChrome title={title} history={history} requireLogin={true} team={this.state.team} firebase={this.props.firebase} back={true} tab="Discover">
        { this.state.updatingHabit ?
          <SpinnerPanel/>
        :
          <div>
            { this.renderHabitForm() } 
          </div>
        }
      </AppChrome>
    )
  }
}

export default EditHabit;