import React, { Component } from 'react';

import Avatar from '../ui/Avatar'
import moment from 'moment';

const calendarDateFormat = 'YYYY-MM-DD';

const INITIAL_STATE = {};

class TeamActivityComment extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  formatDate = (date) => {
    var todayMoment = moment();
    var tomorrowMoment = todayMoment.clone().add(1, 'days');
    var yesterdayMoment = todayMoment.clone().subtract(1, 'days');

    if (date === todayMoment.format(calendarDateFormat))
      return "Today";
    else if (date=== tomorrowMoment.format(calendarDateFormat))
      return "Tomorrow";
    else if (date === yesterdayMoment.format(calendarDateFormat))
      return "Yesterday";
    else
      return moment(date, calendarDateFormat).format('D MMM');
  }

  findMember = (userId) => {
    for (var memberEmailKey in this.props.team.member) {
      var member = this.props.team.member[memberEmailKey];
      if (member['user-id'] === userId)
        return member;
    }
  }

  displayCommentDescription = (userId, replyTo) => {
    var user = this.findMember(userId)

    var userName = user ? user.name : "A Lasoo staff member"

    if (replyTo) {
      var replyingTo = this.findMember(replyTo)
      var replyingToName = replyingTo ? replyingTo.name : "a Lasoo staff member" 
      return userName + " replied to " + replyingToName;
    }
    else {
      return userName + " commented"
    }
  }

  renderActivityComment = (date, userId, replyTo, comment) => {
      var user = this.findMember(userId)

      var userDisplayName = "";
      var userPhotoURL = null;

      // The user might not be a member of the team if they are a Lasoo admin

      if (user) {
        userDisplayName = user.displayName;
        userPhotoURL = user.photoURL;
      }
      else {
        userDisplayName = "Lasoo";
        userPhotoURL = "https://app.lasoohabits.com/lasoo_icon.png"
      }

      var commentDescription = this.displayCommentDescription(userId, replyTo)
  
      return <div className='teamActivityCommentCard'>
        <div className='teamActivityCommentCardHeader'>
          <div className='teamActivityCommentCardAvatar'>
            <Avatar name={userDisplayName} image={userPhotoURL}  size="medium" round={true} />
          </div>

          <div className='teamActivityCommentCardDescription'>{commentDescription}</div>
          <div className='teamActivityCommentCardDate'>{this.formatDate(date)}</div>    
        </div>

        <div className='teamActivityCommentCardReply' onClick={this.props.replyToComment}>
          REPLY
        </div>

        <div className='teamActivityCommentCardComment'>&quot;{comment}&quot;</div>
        <div className='teamActivityCommentCardSeparator'></div>
      </div>
    }
  

  render = () => {
      return this.renderActivityComment(this.props.date, this.props.userId, this.props.replyTo, this.props.comment)
  }
}

export default TeamActivityComment;

