import React, { Component } from 'react';

import Button from './Button';
import Popup from 'reactjs-popup';
import PopupHeader from './PopupHeader'
import 'reactjs-popup/dist/index.css';

import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires loader

class ImageCarouselPopup extends Component {

  constructor(props) {
      super(props);
      this.state = { };
  } 

  componentWillMount = () => {

  }

  onChange = (index, item) => {
    console.log(index)
  }

  onClickItem = (index, item) => {

  }

  onClickThumb = (index, item) => {
      
    }

  renderCarousel = () => {

    var items = [];

    for (var i = 0; i < this.props.images.length; i++) {
      items.push(
        <div>
          <img src={this.props.images[i]} />
        </div>
      )
    }

    return <Carousel selectedItem={this.props.selectedItem} onChange={this.onChange} onClickItem={this.onClickItem} onClickThumb={this.onClickThumb} showArrows={true}>{items}</Carousel>
  }

  render = () => {

    return (
        <Popup open="true" closeOnDocumentClick className='image-carousel-popup' onClose={this.props.onClose}>
          <link rel="stylesheet" href="carousel.css"/>
            { this.renderCarousel() }
        </Popup>
    );
  }
};

export default ImageCarouselPopup;
