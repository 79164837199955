import React, { Component, useImperativeHandle } from 'react';
import 'material-icons/iconfont/material-icons.css';

import AppChrome from '../layout/AppChrome';
import InviteUsersPopup from '../layout/InviteUsersPopup';
import TeamActivity from './TeamActivity';

import { getUserAndTeam } from '../util/DatabaseUtils'
import ReloadDataCard from '../ui/ReloadDataCard';

const INITIAL_STATE = {
  circle : {},
  dataLoaded: false,
  showInviteUsersPopup: false,
  searchText : '',
  isOffline: false,
};

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = () => {

    if (this.props.firebase.user && !this.state.dataLoaded) {

      getUserAndTeam(this.props.firebase, (user, team) => {
        if (!user || !team) {
          this.props.history.push("/create-team")
          return;
        }

        this.setState({ 
            dataLoaded: true, 
            user: user, 
            team : team, 
            teamId : user.team,
            isOffline: false 
         });
      },
      () => {
        this.setState({ isOffline: true })
      })
    }

  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  addTeammates = (e) => {
    e.stopPropagation();

    this.setState({ showInviteUsersPopup: true });    
  }

  closeInviteUsersPopup = () => {
    this.setState({ showInviteUsersPopup: false });
  }

  refresh = () => {
    this.setState({ isOffline: false })
    this.checkDataLoaded();
  }

  renderActivity = () => {
    if (this.state.isOffline) {
      return <ReloadDataCard firebase={this.props.firebase} message="Could not load activity log." onClick={this.refresh}/>
    }
    else if (!this.state.dataLoaded)
      return <div>
        <div className='teamActivityCardLoadingPlaceholder'>&nbsp;</div>
        <div className='teamActivityCardLoadingPlaceholder'>&nbsp;</div>
        <div className='teamActivityCardLoadingPlaceholder'>&nbsp;</div>
        <div className='teamActivityCardLoadingPlaceholder'>&nbsp;</div>
        <div className='teamActivityCardLoadingPlaceholder'>&nbsp;</div>
      </div>
    else if (this.state.team.activity) {
      return <div>
        <TeamActivity history={this.props.history} search={this.state.searchText} firebase={this.props.firebase} teamId={this.state.teamId} team={this.state.team} max={10000}/>
      </div>
    }
    else {
      return <p align='center'>No team activity yet.</p>
    }
  }

  onChangeSearchText = (e) => {
    this.setState({ searchText : e.target.value });
  }

  renderSearchBox = () => {
    return <div className='activitySearchContainer'><input className='activitySearchBox' onChange={this.onChangeSearchText} value={this.state.searchText} placeholder="Search activity" /></div>
  }

  renderTeam = () => {
    var items = [];
    items.push(this.renderSearchBox())
    items.push(this.renderActivity())

    if (this.state.showInviteUsersPopup) {
      items.push(<InviteUsersPopup onClose={this.closeInviteUsersPopup} firebase={this.props.firebase} />)
    }
    return items; 
  }

  render = () => {
    var user = this.props.firebase.user
    var history = this.props.history;
    
    if (false && user && !user.emailVerified)
      this.props.history.push("/verify-email");

    return (
      <AppChrome title="" history={history} user={this.state.user} team={this.state.team} requireLogin={true} firebase={this.props.firebase} tab="Activity">
          <div className='circle'>
            {this.renderTeam()}
          </div>
      </AppChrome>
    )        
  }
}


export default Activity;